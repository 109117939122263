import { VFC, useCallback, useEffect, useMemo, useState }                     from "react";
import { useMatomo }                                                          from "@datapunt/matomo-tracker-react";
import useBreakpoint                                                          from "antd/lib/grid/hooks/useBreakpoint";
import { parse, stringify }                                                   from "query-string";
import { Helmet }                                                             from "react-helmet-async";
import { useHistory, useLocation }                                            from "react-router";
import { ProductFilter, getAllCollections, getAllSubCategories, getProducts } from "@/data/shop-api";
import Button                                                                 from "@/designSystem/Button/Button";
import Pagination                                                             from "@/designSystem/Pagination/Pagination";
import Text                                                                   from "@/designSystem/Text/Text";
import Title                                                                  from "@/designSystem/Title/Title";
import { dash, scrollToTop, toTitleCase }                                     from "@/helpers/index";
import Container                                                              from "@/molecules/Container/Container";
import ProductListing                                                         from "@/molecules/ProductListing/ProductListing";
import ShopUsaMenu                                                            from "@/molecules/ShopUsaMenu/ShopUsaMenu";
import { ProductsHeader }                                                     from "@/pages/Products/Products";
import "./ProductSearch.less";

const descriptions = [
    {
        category: "Food",
        description: "Grab and gulp down the best snacks, drinks, and more from U.S. based brands.",
    },
    {
        category: "Apparel",
        description: "Be ready for any season with clothing for women, men, and kids.",
    },
    {
        category: "Home/Office",
        description: "Update your home or office with furnishing and accessories from American-made brands.",
    },
    {
        category: "Outdoor",
        description: "Get everything you need for your adventures in the great outdoors.",
    },
    {
        category: "Gifts",
        description: "It is always a good time to give a gift, but so are anniversaries, birthdays, and holidays.",
    },
    {
        category: "Pets",
        description: "Take care of your favorite friends with top pet toys, treats, and accessories.",
    },
    {
        category: "Veteran-Owned",
        description: "Browse through all of our featured products from Veteran-Owned brands.",
    },
];
const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 10;

const ProductSearch: VFC = () => {
    const { trackEvent } = useMatomo();
    const history = useHistory<{ clearFilters?: boolean }>();
    const location = useLocation<{ clearFilters?: boolean }>();
    const { md, lg } = useBreakpoint();

    // Initialize filter against URL
    const [filter, setFilter] = useState<ProductFilter>(() => parseURLFilters(location.search));

    // Only update products on filter change
    const { products, paginated } = useMemo(() => getProducts(filter), [filter]);

    // Keep filter state in sync with URL
    useEffect(() => {
        setFilter(parseURLFilters(location.search));
    }, [location.search]);

    // Reset page number to 1 if there aren't enough products to reach current page
    useEffect(() => {
        if (!filter.page || filter.page > Math.ceil(products.length / DEFAULT_PER_PAGE)) {
            setFilter(current => ({
                ...current,
                page: 1,
                perPage: DEFAULT_PER_PAGE,
            }));
        }
    }, [filter.page, products.length]);

    const trackPaginationChange = useCallback(
        (page: number) => {
            // Track pagination change - page num only
            trackEvent({
                category: "shop us",
                name: "pagination",
                action: page.toString(),
            });

            // Track pagination change - current category, subcategory and page
            trackEvent({
                category: "shop us",
                name: "pagination",
                action: `${filter.category},${filter.subCategory},${page.toString()}`,
            });
        },
        [trackEvent, filter.category, filter.subCategory]
    );

    const trackFilterChange = useCallback(
        (name: "price filter" | "shop us category page sub category " | "shop us category collection filter", action: string) => {
            // Track price filter change
            trackEvent({
                category: "shop us",
                name,
                action,
            });
        },
        [trackEvent]
    );

    const onFilterChange = useCallback(
        (filterItemObj: object) => {
            const newSearchQuery = stringify({ ...filter, ...filterItemObj }, { arrayFormat: "bracket" });
            history.push({
                pathname: `/product`,
                search: newSearchQuery,
            });
        },
        [history, filter]
    );

    const collections = useMemo(
        () =>
            getAllCollections(filter.category).map(collection => (
                <Text
                    className={filter.collection === collection ? "filtered-link" : "filter-link"}
                    key={collection}
                    onClick={() => {
                        const newCollection = filter.collection?.toLowerCase() === collection.toLowerCase() ? undefined : collection;
                        onFilterChange({ collection: newCollection });
                        scrollToTop({ behavior: "auto" });
                        trackFilterChange("shop us category collection filter", collection || "not selected");
                    }}
                >
                    {collection}
                    {filter.collection?.toLowerCase() === collection.toLowerCase()}
                </Text>
            )),
        [filter, onFilterChange, trackFilterChange]
    );

    const subCategories = useMemo(
        () =>
            getAllSubCategories({ category: filter.category, collection: filter.collection }).map(
                (subCategory, i) =>
                    subCategory && (
                        <Text
                            className={filter.subCategory === subCategory ? "filtered-link" : "filter-link"}
                            key={subCategory || i}
                            onClick={() => {
                                const newSubcategory = filter.subCategory?.toLowerCase() === subCategory.toLowerCase() ? undefined : subCategory;
                                onFilterChange({ subCategory: newSubcategory });
                                scrollToTop({ behavior: "auto" });
                                trackFilterChange("shop us category page sub category ", newSubcategory || "not selected");
                            }}
                        >
                            {subCategory}
                            {subCategory.toLowerCase() && filter.subCategory?.toLowerCase() === subCategory.toLowerCase()}
                        </Text>
                    )
            ),
        [filter, onFilterChange, trackFilterChange]
    );

    const priceRange = useMemo(
        () =>
            [...Array(4)].map((_, i) => (
                <Button
                    className="price"
                    key={i}
                    type={filter.priceRange?.includes(i) ? "primary" : "default"}
                    size={md ? "large" : "middle"}
                    onClick={() => {
                        const newFilter = {
                            ...filter,
                            priceRange: filter.priceRange?.includes(i)
                                ? filter.priceRange.filter(item => item !== i)
                                : [...(filter.priceRange || []), i],
                        };
                        const newSearchQuery = stringify(newFilter, { arrayFormat: "bracket" });
                        history.push({
                            pathname: `/product`,
                            search: newSearchQuery,
                        });

                        trackFilterChange(
                            "price filter",
                            newFilter.priceRange?.map(filterItem => "$".repeat(filterItem + 1)).toString() || "not selected"
                        );
                    }}
                >
                    {"$".repeat(i + 1)}
                </Button>
            )),
        [filter, history, md, trackFilterChange]
    );

    return (
        <div className="ProductSearch">
            <Helmet>
                <link rel="canonical" href={`https://freespoke.com/product?${stringify(filter, { arrayFormat: "bracket" })}`} />
                <title>Products Search - Freespoke</title>
            </Helmet>
            <ProductsHeader />
            <ShopUsaMenu mobile={!md} />
            <Container className="ProductSearch-content">
                <div className={dash("filters", md ? "desktop" : "mobile")}>
                    {md && (
                        <div className="group">
                            <Text className="group-title">
                                {!filter.category && filter.collection ? toTitleCase(filter.collection) : filter.category}
                            </Text>
                            <Text className="group-body">
                                {
                                    descriptions.find(
                                        ({ category }) =>
                                            filter.category?.toLowerCase() === category.toLowerCase() ||
                                            filter.collection?.toLowerCase() === category.toLowerCase()
                                    )?.description
                                }
                            </Text>
                        </div>
                    )}
                    <div className="group">
                        {md && <Text className="group-subtitle">Price Range</Text>}
                        <div className={dash("toggle-group", md ? "desktop" : "mobile")}>{priceRange}</div>
                    </div>
                    {subCategories.length > 0 && (
                        <div className={dash("group", !md && "subcategories")}>
                            {md && <Text className="group-subtitle">Filter by Sub-Category</Text>}
                            {subCategories}
                        </div>
                    )}
                    {md && collections.length > 0 && (
                        <div className="group">
                            <Text className="group-subtitle">Filter by Collection</Text>
                            {collections}
                        </div>
                    )}
                </div>
                <div className="products">
                    {paginated.length > 0 && (
                        <div className="items">
                            {paginated.map(({ price, company, productUrl, productName, productImage, originalProductImage }) => (
                                <ProductListing
                                    price={price || 0}
                                    brand={company || ""}
                                    href={productUrl || ""}
                                    title={productName || ""}
                                    img={productImage || ""}
                                    fallbackImg={originalProductImage || ""}
                                    size={lg ? "default" : "small"}
                                    key={productUrl}
                                />
                            ))}
                        </div>
                    )}
                    {products.length > 0 ? (
                        <div className="pagination">
                            <Pagination
                                current={filter.page}
                                onChange={page => {
                                    trackPaginationChange(page);
                                    onFilterChange({
                                        page,
                                    });
                                    scrollToTop({ behavior: "auto" });
                                }}
                                total={products.length}
                                showSizeChanger={false}
                            />
                        </div>
                    ) : (
                        <Title level={md ? 3 : 4} className="no-products">
                            No products
                        </Title>
                    )}
                </div>
            </Container>
        </div>
    );
};

function parseURLFilters(queryString: string) {
    const parsedQuery = parse(queryString, { arrayFormat: "bracket" });
    return {
        // Spread string based filter params (no cleanup required)
        ...parsedQuery,
        // Pagination
        ...(parsedQuery.page && typeof parsedQuery.page === "string" && { page: parseInt(parsedQuery.page) || DEFAULT_PAGE }),
        ...(parsedQuery.perPage && typeof parsedQuery.perPage === "string" && { perPage: parseInt(parsedQuery.perPage) || DEFAULT_PER_PAGE }),
        // If price range is included, convert the string array to a number array
        ...(Array.isArray(parsedQuery.priceRange) && { priceRange: parsedQuery.priceRange.map(range => Number.parseInt(range || "")) }),
    };
}

export default ProductSearch;
