import { FC, useEffect, useState }         from "react";
import { CloseOutlined }                   from "@ant-design/icons";
import { animated, config, useTransition } from "react-spring";
import Text                                from "@/designSystem/Text/Text";
import EmailCapture                        from "@/molecules/EmailCapture/EmailCapture";
import "./Hero.less";

const Hero: FC = () => {
    const [visible, setVisible] = useState(true);
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        if (sessionStorage.getItem("show-hero") === "no") {
            setVisible(false);
            setInitial(false);
        }
    }, []);

    const close = () => {
        sessionStorage.setItem("show-hero", "no");
        setVisible(false);
    };

    const transitions = useTransition(visible, {
        ...(initial && {
            from: { opacity: 1, maxHeight: "1000px" },
            leave: { opacity: 0, maxHeight: "0px" },
        }),
        config: config.default,
    });

    return transitions(
        (styles, item) =>
            item && (
                <animated.div className="Hero" style={styles}>
                    <div className="hero-container">
                        <Text className="header">Join the Movement</Text>
                        <EmailCapture />
                        <CloseOutlined onClick={close} />
                    </div>
                </animated.div>
            )
    );
};

export default Hero;
