import { FC, useCallback }                    from "react";
import { useMatomo }                          from "@datapunt/matomo-tracker-react";
import useBreakpoint                          from "antd/lib/grid/hooks/useBreakpoint";
import { TooltipPlacement }                   from "antd/lib/tooltip";
import { AnalyticsMeta }                      from "src/@types/custom";
import Button                                 from "@/designSystem/Button/Button";
import Text                                   from "@/designSystem/Text/Text";
import { createShareUrl }                     from "@/helpers/index";
import useClipboard                           from "@/hooks/useClipboard";
import ShareDropdown, { ShareDropdownItems }  from "@/molecules/ShareDropdown/ShareDropdown";
import { ReactComponent as EmailOutlined }    from "@/static/icons/email-outlined.svg";
import { ReactComponent as FacebookOutlined } from "@/static/icons/facebook-outlined.svg";
import { ReactComponent as LinkOutlined }     from "@/static/icons/link-outlined.svg";
import { ReactComponent as ShareOutlined }    from "@/static/icons/share.svg";
import { ReactComponent as TwitterOutlined }  from "@/static/icons/twitter-outlined.svg";
import "./ShareCompressed.less";

interface ShareCompressedProps {
    url: string;
    placement?: TooltipPlacement;
    type: "story" | "freespoke";
    analyticsMeta: AnalyticsMeta & { name: string };
    hasText?: boolean;
    iconType?: "light" | "dark";
    forStoryPage?: boolean;
}

const emailBodyForSharingAStory = (url: string) => `Hi,  

I found this story on Freespoke.com you need to check out!  

See the full story here, and let me know what you think:

${url}`;

const emailBodyForSharingFreespoke = `Hey,

I found this new search engine that helps you find the truth faster. They elevate all sides of important topics and don't bury information like those other platforms.

Here's the link, let me know what you think: https://freespoke.com`;

const shareTypes = {
    story: {
        twitter: {
            text: "See the full story here:",
        },
        email: {
            subject: "📫 Check Out this Story from Freespoke!",
            getEmailBody: (url: string) => emailBodyForSharingAStory(url),
        },
    },
    freespoke: {
        twitter: {
            text: "Find the truth faster:",
        },
        email: {
            subject: "📫 Check Out Freespoke!",
            getEmailBody: () => emailBodyForSharingFreespoke,
        },
    },
};

const ShareCompressed: FC<ShareCompressedProps> = ({ url, placement, type, hasText, iconType = "dark", forStoryPage, analyticsMeta }) => {
    const { copy } = useClipboard({ defaultText: url });
    const { trackEvent } = useMatomo();
    const { md } = useBreakpoint();

    const reportClick = useCallback(
        (label: string) => {
            if (label === "Copy link") {
                copy();

                trackEvent({
                    category: analyticsMeta.category || "share",
                    action: "story share - copy link",
                    name: analyticsMeta.name || "story name",
                });
            } else {
                trackEvent({
                    category: analyticsMeta.category || "share",
                    action: `${analyticsMeta.actionPrefix ? `${analyticsMeta.actionPrefix} ` : ""}${analyticsMeta.action || "click"} - ${label}`,
                    name: analyticsMeta.name || "share",
                });
            }
        },
        [analyticsMeta, copy, trackEvent]
    );

    const items: ShareDropdownItems = [
        {
            icon: <FacebookOutlined />,
            label: "Facebook",
            href: createShareUrl.facebook(url),
            onClick: () => reportClick("Facebook"),
        },
        {
            icon: <TwitterOutlined />,
            label: "Twitter",
            href: createShareUrl.twitter(url, { text: shareTypes[type].twitter.text, via: "FreespokeSearch", hashtags: ["Freespoke"] }),
            onClick: () => reportClick("Twitter"),
        },
        {
            icon: <EmailOutlined />,
            label: "Email",
            href: createShareUrl.email({ subject: shareTypes[type].email.subject, body: shareTypes[type].email.getEmailBody(url) }),
            onClick: () => reportClick("Email"),
        },
        {
            icon: <LinkOutlined />,
            label: "Copy link",
            onClick: () => reportClick("Copy link"),
        },
    ];

    return (
        <div className="ShareCompressed">
            <ShareDropdown placement={placement} items={items}>
                {forStoryPage ? (
                    <Button className="share-button">
                        <Text className="share-button-text">{md ? "Share this Story" : "Share Story"}</Text>
                    </Button>
                ) : hasText ? (
                    <Button className="share-compressed-btn" type="text">
                        <Text className={`btn-text ${iconType === "light" ? "light" : ""}`}>Share</Text>
                        <ShareOutlined className={`ShareCompressedIcon ${iconType === "light" ? "light" : ""}`} />
                    </Button>
                ) : (
                    <ShareOutlined className="ShareCompressedIcon" />
                )}
            </ShareDropdown>
        </div>
    );
};

export default ShareCompressed;
