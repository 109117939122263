import { FC, memo, useCallback }                              from "react";
import { useMatomo }                                          from "@datapunt/matomo-tracker-react";
import clsx                                                   from "clsx";
import { useLocation }                                        from "react-router-dom";
import { getAnalyticsCategory, getAnalyticsCategoryResponse } from "@/analytics/analytics";
import Paragraph                                              from "@/designSystem/Paragraph/Paragraph";
import AppDownloadBadge                                       from "@/molecules/AppDownloadBadge/AppDownloadBadge";
import "./AppDownloadBanner.less";

export interface AppDownloadBannerProps {
    className?: string;
    analyticsMeta?: {
        category?: getAnalyticsCategoryResponse;
        name: string;
        action: string;
    };
}

const AppDownloadBanner: FC<AppDownloadBannerProps> = ({ className, analyticsMeta }) => {
    const { trackEvent } = useMatomo();
    const location = useLocation();

    const reportClick = useCallback(() => {
        if (analyticsMeta) {
            trackEvent({
                category: analyticsMeta?.category || getAnalyticsCategory(location.pathname),
                name: analyticsMeta.name,
                action: analyticsMeta.action,
            });
        }
    }, [analyticsMeta, location.pathname, trackEvent]);

    return (
        <div className={clsx("AppDownloadBanner", className && className)}>
            <div className="container">
                <div className="text-block">
                    <div className="text-container">
                        <Paragraph className="title">Get the Freespoke App for the Best Experience</Paragraph>
                        <Paragraph className="subtitle">Search with freedom on the go.</Paragraph>
                    </div>
                </div>

                <div className="cta-block" onClick={reportClick}>
                    <AppDownloadBadge type="ios-alt" />
                    <AppDownloadBadge type="android" />
                </div>
            </div>
        </div>
    );
};

export default memo(AppDownloadBanner);
