import { FC, useCallback, useMemo }           from "react";
import { useMatomo }                          from "@datapunt/matomo-tracker-react";
import clsx                                   from "clsx";
import { useLocation }                        from "react-router-dom";
import { getAnalyticsCategory }               from "@/analytics/analytics";
import Link                                   from "@/designSystem/Link/Link";
import { createShareUrl }                     from "@/helpers/index";
import useClipboard                           from "@/hooks/useClipboard";
import ShareDropdown, { ShareDropdownItems }  from "@/molecules/ShareDropdown/ShareDropdown";
import { ReactComponent as EmailOutlined }    from "@/static/icons/email-outlined.svg";
import { ReactComponent as FacebookOutlined } from "@/static/icons/facebook-outlined.svg";
import { ReactComponent as LinkOutlined }     from "@/static/icons/link-outlined.svg";
import { ReactComponent as LinkedInOutlined } from "@/static/icons/linkedin-outlined.svg";
import { ReactComponent as MoreOutlined }     from "@/static/icons/more-outlined.svg";
import { ReactComponent as RedditOutlined }   from "@/static/icons/reddit-outlined.svg";
import { ReactComponent as TwitterOutlined }  from "@/static/icons/twitter-outlined.svg";
import "./ShareExpanded.less";

interface ShareProps {
    className?: string;
    href?: string;
    onClick?(): void;
}
interface ShareExpandedProps extends ShareProps {
    title: string;
    url: string;
    hideMore?: boolean;
    page?: string;
}

const ShareIcon: FC<ShareProps> = ({ children, className, href, ...rest }) => {
    if (href) {
        return (
            <Link href={href} className={clsx("ShareIcon", className && className)} {...rest}>
                {children}
            </Link>
        );
    }

    return (
        <div className={clsx("ShareIcon", className && className)} {...rest}>
            {children}
        </div>
    );
};

const emailBody = (url: string) => `Hi,  

I found this story on Freespoke.com you need to check out!  

See the full story here, and let me know what you think:

${url}`;

const ShareExpanded: FC<ShareExpandedProps> = ({ url, title, hideMore, page }) => {
    const location = useLocation();
    const { copy } = useClipboard({ defaultText: url });
    const { trackEvent } = useMatomo();

    // Get analytics category name - 'home', 'freespoke topic' etc
    const analytics = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const reportClickOnIcon = useCallback(
        (label: string) => () => {
            if (label === "copy link") {
                copy();
            }
            trackEvent({
                category: analytics.category,
                name: title,
                action: `${page || "share"} - ${label}`,
            });
        },
        [analytics.category, copy, page, title, trackEvent]
    );

    const dropdownItems: ShareDropdownItems = useMemo(
        () => [
            {
                icon: <LinkedInOutlined />,
                label: "LinkedIn",
                href: createShareUrl.linkedin(url),
                onClick: reportClickOnIcon("linkedIn"),
            },
            {
                icon: <RedditOutlined />,
                label: "Reddit",
                href: createShareUrl.reddit(url, { title }),
                onClick: reportClickOnIcon("reddit"),
            },
        ],
        [reportClickOnIcon, title, url]
    );

    return (
        <div className="ShareExpanded">
            <ShareIcon className="bg-facebook" href={createShareUrl.facebook(url)} onClick={reportClickOnIcon("facebook")}>
                <FacebookOutlined />
            </ShareIcon>
            <ShareIcon
                className="bg-twitter"
                onClick={reportClickOnIcon("twitter")}
                href={createShareUrl.twitter(url, { text: "See the full story here:", via: "FreespokeSearch", hashtags: ["Freespoke"] })}
            >
                <TwitterOutlined />
            </ShareIcon>
            <ShareIcon
                className="bg-email"
                onClick={reportClickOnIcon("email")}
                href={createShareUrl.email({ subject: "📫 Check Out this Story from Freespoke!", body: emailBody(url) })}
            >
                <EmailOutlined />
            </ShareIcon>
            <ShareIcon className="bg-link" onClick={reportClickOnIcon("copy link")}>
                <LinkOutlined />
            </ShareIcon>
            {!hideMore && (
                <ShareDropdown items={dropdownItems}>
                    <ShareIcon className="bg-more">
                        <MoreOutlined />
                    </ShareIcon>
                </ShareDropdown>
            )}
        </div>
    );
};

export default ShareExpanded;
