import { FC, memo, useCallback, useMemo }  from "react";
import clsx                                from "clsx";
import ImageLoader                         from "@/components/utils/ImageLoader";
import Avatar                              from "@/designSystem/Avatar/Avatar";
import Divider                             from "@/designSystem/Divider/Divider";
import Link                                from "@/designSystem/Link/Link";
import Text                                from "@/designSystem/Text/Text";
import { ReactComponent as VideoPlayIcon } from "@/static/icons/video-circle-icon.svg";
import ContentFooter                       from "../ContentFooter/ContentFooter";
import "./ContentCard.less";

interface ContentCardProps {
    title: string;
    images?: string[];
    url: string;
    datePublished?: string;
    publisherName: string;
    publisherIcon?: string;
    publisherTypes?: string[];
    bias?: string;
    reportClick?: (url: string, publisher: string, title: string) => void;
    reportNewsSearchClick?: (url: string) => void;
    boldHeadline?: boolean;
    displayPlayIcon?: boolean;
    footerLabel?: string;
}

const ContentCard: FC<ContentCardProps> = ({
    title,
    images,
    url,
    datePublished,
    publisherName,
    publisherIcon,
    publisherTypes,
    bias,
    reportClick,
    reportNewsSearchClick,
    boldHeadline,
    displayPlayIcon = false,
    footerLabel,
}) => {
    const handleClick = useCallback(() => {
        // click news article in trending news topic on homepage. action is "story name"
        if (typeof reportClick === "function") {
            reportClick(url, publisherName, title);
        } else if (typeof reportNewsSearchClick === "function") {
            reportNewsSearchClick(url);
        } else {
            console.error("reportClick is not defined in Tweet component");
        }
    }, [publisherName, reportClick, reportNewsSearchClick, title, url]);

    const publisherLabel = useMemo(
        () => (Array.isArray(publisherTypes) && typeof publisherTypes[0] === "string" && publisherTypes[0].toUpperCase()) || null,
        [publisherTypes]
    );

    return (
        <Link rel="noopener" className="ContentCard" href={url || "#"} onClick={handleClick}>
            <ImageLoader className="image" srcList={images} alt={publisherName}>
                {displayPlayIcon && (
                    <span className="play-background">
                        <VideoPlayIcon />
                    </span>
                )}
            </ImageLoader>

            <div className="content">
                <div className="publisher">
                    {publisherIcon && <Avatar className="publisher-logo" size={20} src={publisherIcon} />}
                    <Text className="publisher-name">{publisherName}</Text>
                </div>
                <Text className={clsx("title", boldHeadline && "bold")}>{title}</Text>
            </div>

            <Divider className="divider" />
            <ContentFooter label={footerLabel || publisherLabel || bias || ""} datePublished={datePublished} />
        </Link>
    );
};

export default memo(ContentCard);
