import React, { FC }     from "react";
import { AnalyticsMeta } from "src/@types/custom";
import Title             from "@/designSystem/Title/Title";
import NewsStory         from "@/organisms/NewsStory/NewsStory";
import "./NewsSnapshots.less";

interface NewsSnapshotsProps {
    topics?: { [key: string]: any }[];
    analyticsMeta: AnalyticsMeta;
    pageName?: string;
}

const NewsSnapshots: FC<NewsSnapshotsProps> = ({ topics, analyticsMeta, pageName }) => {
    return (
        <div className="NewsSnapshots">
            <Title level={3}>Related Headlines</Title>
            {topics &&
                topics.map((topic, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            <NewsStory id={topic.id} isLast={false} showUpdatedAt={false} analyticsMeta={analyticsMeta} pageName={pageName} />
                        </React.Fragment>
                    );
                })}
        </div>
    );
};

export default NewsSnapshots;
