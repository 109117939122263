import axios                    from "axios";
import { stringify }            from "query-string";
import useSWR, { KeyedMutator } from "swr";
import { IArticle, ITweet }     from "@/@types/custom";
import { useBackendAccess }     from "@/api/useBackendAccess";
import { BACKEND_URL }          from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export interface IPreviewRelatedTopics {
    id?: string;
    title?: string;
    image?: string;
    content?: { title: string }[];
}

export interface IPreviewRelatedStories {
    id?: string;
    title?: string;
    leftBiasCount: number;
    rightBiasCount: number;
    content?: {
        title: string;
        publisherName: string;
        publisherIcon: string;
        images: string[];
    }[];
    updatedAt: string;
}

export interface ISimilarity {
    id: string;
    sentence: string;
    source_name: string;
    source_url?: string;
}

interface StoryVideo {
    id: string;
    thumbnailURL: string;
    url: string;
}

interface StorySpangld {
    url: string;
    data?: {
        id: string;
        slug: string;
        link: string;
        title: string;
        textSummary: string;
        rawHTML: string;
        images: string[];
    };
}

export type ContentList = (IArticle & ITweet)[];

export type ContentGroupedByBias = {
    left: ContentList;
    middle: ContentList;
    right: ContentList;
};
export interface useGetStoryByIdResponse<T = ContentList | ContentGroupedByBias> {
    name: string;
    category: string;
    images: string[];
    ogp_image_url: string;
    ogp_twitter_image_url: string;
    updated_at: string;
    content?: T;
    videos?: StoryVideo[];
    spangld?: StorySpangld;
    similarity?: {
        left?: ISimilarity[];
        middle?: ISimilarity[];
        right?: ISimilarity[];
    };
    related_topics?: IPreviewRelatedTopics[];
    related_stories?: IPreviewRelatedStories[];
}

interface useGetStoryByIdReturnType<T = ContentList | ContentGroupedByBias> {
    data: useGetStoryByIdResponse<T> | undefined;
    isLoading: boolean;
    error: any;
    refresh: KeyedMutator<useGetStoryByIdResponse<T>>;
}

export interface useGetStoryByIdParams {
    format: "list" | "group_by_bias";
    count: number;
    include?: ("similarity" | "related_topics" | "related_stories")[];
}

function useGetStoryById<T>(story_id: string, params: useGetStoryByIdParams): useGetStoryByIdReturnType<T> {
    const { authHeaders } = useBackendAccess();
    const url = `${BACKEND_URL}/story/${story_id}?${stringify(params)}`;
    const fetcher = () => axios.get(url, { headers: authHeaders }).then(res => res.data);

    const { data, error, isValidating, mutate } = useSWR<useGetStoryByIdResponse<T>>(authHeaders && story_id ? url : null, fetcher, config);

    return {
        data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
}

export default useGetStoryById;
