import axios                from "axios";
import useSWR               from "swr";
import { useBackendAccess } from "@/api/useBackendAccess";
import { BACKEND_URL }      from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export interface TweetContent {
    id: string;
    type: "tweet";
    tweet?: {
        id: string;
        tweet_id?: string;
        datePublished?: string;
        bias?: string;
        url?: string;
        text?: string;
        author?: {
            name: string;
            profile_image_url: string;
            username: string;
        };
    };
}
export interface ArticleContent {
    id: string;
    type: "article";
    article?: {
        id: string;
        title?: string;
        images?: string[];
        datePublished?: string;
        bias?: string;
        url?: string;
        text?: string;
        publisherName?: string;
        publisherIcon?: string;
        publisherTypes?: string[];
    };
}
export interface SpangldContent {
    id: string;
    type: "spangld";
    article?: {
        id: string;
        title?: string;
        description?: string;
        url?: string;
        images?: string[];
    };
    textSummary: string;
    rawHTML: string;
}

export interface CensoredContent {
    id: string;
    title: string;
    bias: string;
    datePublished: string;
    publisherName: string;
    images: string[];
    url: string;
    subcategory: string;
}

export interface Topic {
    id: string;
    name: string;
    images?: string[];
    updatedAt: string;
    ogp_image_url?: string;
    related_deep_dive_topics?: {
        id: string;
        name: string;
    }[];
    related_stories?: {
        id: string;
        name: string;
        images?: string[];
    }[];
    news: (TweetContent | ArticleContent)[];
    spangld: SpangldContent[];
    related_anso: CensoredContent[];
    other_content: any[];
}
export interface useGetDeepDiveTopicData {
    topic: Topic;
    total: number;
}

const useGetDeepDiveTopic = (topic_id = "") => {
    const { authHeaders } = useBackendAccess();
    const fetcher = (URL: string) =>
        axios.get(URL, { headers: authHeaders }).then(res => {
            return { topic: res.data.topic, total: res.data.total };
        });
    const url = `${BACKEND_URL}/deepdive/topic/${topic_id}`;

    const { data, error, isValidating, mutate } = useSWR<useGetDeepDiveTopicData>(authHeaders ? url : null, fetcher, config);

    return {
        data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
};

export default useGetDeepDiveTopic;
