import { FC, memo, useCallback } from "react";
import Avatar                    from "@/designSystem/Avatar/Avatar";
import Divider                   from "@/designSystem/Divider/Divider";
import TwitterOutlined           from "@/designSystem/Icons/TwitterOutlined";
import Link                      from "@/designSystem/Link/Link";
import Paragraph                 from "@/designSystem/Paragraph/Paragraph";
import Tooltip                   from "@/designSystem/Tooltip/Tooltip";
import { removeTweetURLs }       from "@/helpers/index";
import ContentFooter             from "../ContentFooter/ContentFooter";
import "./TweetCard.less";

export interface TweetCardProps {
    data: {
        id: string;
        tweet_id?: string;
        author: {
            name?: string;
            username?: string;
            profile_image_url?: string;
            image_url?: string;
        };
        datePublished: string;
        bias?: string;
        text: string;
        url: string;
    };
    reportClick: (url: string, publisher: string, title: string) => void;
    maxLines: number;
    showBias?: boolean;
}

const TweetCard: FC<TweetCardProps> = ({ data, maxLines, reportClick, showBias }) => {
    const reportClickOnTweetAuthor = useCallback(
        () => reportClick(`https://twitter.com/${data.author.username || ""}`, data.author.username || "", ""),
        [data.author.username, reportClick]
    );

    const reportClickOnTweetBody = useCallback(
        () => reportClick(data.url, data.author.username || "", ""),
        [data.author.username, data.url, reportClick]
    );

    return (
        <div className="TweetCard">
            <div className="tweet-card-header">
                <div className="tweet-header-left">
                    <Avatar size={32} srcSet={data.author.image_url} src={data.author.profile_image_url || ""} alt="Tweet author avatar" />
                    <div className="tweet-card-author">
                        <Tooltip title={data.author.name}>
                            <span className="tweet-card-author-title">{data.author.name}</span>
                        </Tooltip>
                        <Link
                            className="username"
                            href={`https://twitter.com/${data.author.username || ""}`}
                            rel="noopener noreferrer"
                            onClick={reportClickOnTweetAuthor}
                        >
                            <Tooltip title={`@${data.author.username}`}>{`@${data.author.username}`}</Tooltip>
                        </Link>
                    </div>
                </div>
                <div className="twitter-card-logo">
                    <TwitterOutlined />
                </div>
            </div>

            <div className="tweet-card-body">
                <Link href={data.url} title={data.text} rel="noopener noreferrer" onClick={reportClickOnTweetBody}>
                    <Paragraph className="tweet-body-text" ellipsis={{ rows: maxLines }}>
                        {removeTweetURLs(data.text)}
                    </Paragraph>
                </Link>
            </div>

            <Divider className="tweet-card-divider" />

            <ContentFooter label={data?.bias || ""} datePublished={data?.datePublished} />
        </div>
    );
};

export default memo(TweetCard);
