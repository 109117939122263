import axios                from "axios";
import useSWRImmutable      from "swr/immutable";
import { useBackendAccess } from "@/api/useBackendAccess";
import { BACKEND_URL }      from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export interface Category {
    id: string;
    name: string;
    images?: string[];
    topics?: Array<{
        id: string;
        name: string;
    }>;
}

type FetchedData = {
    categories?: Array<Category>;
    total?: number;
};

const useGetCategoriesAndTopics = () => {
    const { authHeaders } = useBackendAccess();
    const fetcher = (URL: string) => axios.get(URL, { headers: authHeaders }).then(res => res.data);
    const url = `${BACKEND_URL}/v2/category/list`;

    const { data, error, isValidating, mutate } = useSWRImmutable<FetchedData>(authHeaders ? url : null, fetcher, config);

    return {
        data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
};

export default useGetCategoriesAndTopics;
