import { VFC, useCallback, useMemo }                  from "react";
import { useMatomo }                                  from "@datapunt/matomo-tracker-react";
import { CarouselProps }                              from "antd";
import useBreakpoint                                  from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet }                                     from "react-helmet-async";
import { getHighlightedProducts, getPopularProducts } from "@/data/shop-api";
import Link                                           from "@/designSystem/Link/Link";
import Paragraph                                      from "@/designSystem/Paragraph/Paragraph";
import Text                                           from "@/designSystem/Text/Text";
import Title                                          from "@/designSystem/Title/Title";
import Container                                      from "@/molecules/Container/Container";
import ProductCard                                    from "@/molecules/ProductCard/ProductCard";
import ProductListing                                 from "@/molecules/ProductListing/ProductListing";
import ScrollableArticles                             from "@/molecules/ScrollableArticles/ScrollableArticles";
import ShopUsaMenu                                    from "@/molecules/ShopUsaMenu/ShopUsaMenu";
import freespoke_torch                                from "@/static/icons/freespoke_torch.svg";
import Coffee                                         from "@/static/images/shop-usa/coffee.png";
import Cups                                           from "@/static/images/shop-usa/cups.png";
import DogBone                                        from "@/static/images/shop-usa/dog-bone.png";
import Rabbit                                         from "@/static/images/shop-usa/rabbit.png";
import Ring                                           from "@/static/images/shop-usa/ring.png";
// import Backpack from "@/static/images/shop-usa/backpack.png";
// import GirlAndBalloon from "@/static/images/shop-usa/girl-and-balloon.png";
// import JeansAndShirts from "@/static/images/shop-usa/jeans-and-shirts.png";
// import BootsAndBackpack from "@/static/images/shop-usa/boots-and-backpack.png";
// import Valentine from "@/static/images/shop-usa/valentine.jpeg";
import UsFlag from "@/static/images/shop-usa/us-flag.jpg";
import Watch  from "@/static/images/shop-usa/watch.png";
import "./Products.less";

export const ProductsHeader: VFC = () => {
    return (
        <Container className="Products-header">
            <Link to="/products">
                <Title className="page-title">american-made products for american jobs.</Title>
            </Link>
        </Container>
    );
};

const NEWS_CAROUSEL_SETTINGS: CarouselProps = {
    dots: false,
    arrows: true,
    infinite: false,
    rows: 1,
    variableWidth: false,
    draggable: true,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 9999,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
    ],
};

const Products: VFC = () => {
    const { trackEvent } = useMatomo();
    const { md } = useBreakpoint();
    const highlightedProducts = useMemo(() => getHighlightedProducts(), []);
    const popularProducts = useMemo(() => getPopularProducts(), []);

    const recordClickOnLargeProductCard = useCallback(
        (title: string) => {
            trackEvent({
                category: "shop us",
                action: "click",
                name: title?.toLowerCase(),
            });
        },
        [trackEvent]
    );

    const recordClickOnSmallProductCard = useCallback(
        (title: string) => {
            trackEvent({
                category: "shop us",
                action: title?.toLowerCase(),
                name: "shop us home page sub category",
            });
        },
        [trackEvent]
    );

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://freespoke.com/products" />
                <meta name="description" content="Easily find American Made products" />
                <title>Find USA-Made Products</title>
            </Helmet>
            <ProductsHeader />
            <ShopUsaMenu mobile={!md} />
            <Container className="Products">
                <div className="content">
                    <div>
                        <div className="banner">
                            <Title className="title">Support American Businesses</Title>
                            <Text className="subtitle">Find the products you want from brands worth supporting.</Text>
                        </div>
                    </div>

                    <div className="highlighted">
                        <div className="highlighted-header">
                            <Link href="https://goldriverco.com?sca_ref=2119461.yxMOBftjw6" className="header-link">
                                <img className="icon" src={freespoke_torch} alt="freespoke torch" />
                                Highlighted Partner
                            </Link>
                            <Title className="highlighted-title">
                                <Link href="https://goldriverco.com?sca_ref=2119461.yxMOBftjw6" className="title-link">
                                    Gold River Trading Company
                                </Link>
                            </Title>
                            <Paragraph className="highlighted-subtitle">
                                Serving modern pioneers forging new frontiers and charting new paths. Use promo code{" "}
                                <span className="promo-code">FREESPOKE10</span> for 10% off.{" "}
                                <Link href="https://goldriverco.com?sca_ref=2119461.yxMOBftjw6">Shop now</Link>
                            </Paragraph>
                        </div>
                        <div className="highlighted-products">
                            <ScrollableArticles
                                items={highlightedProducts}
                                settings={{ ...NEWS_CAROUSEL_SETTINGS, slidesToShow: 3, slidesToScroll: 3 }}
                                render={list =>
                                    list.map(popularProduct => (
                                        <ProductListing
                                            key={popularProduct.id}
                                            brand={popularProduct.brand || ""}
                                            title={popularProduct.title}
                                            price={popularProduct.price || 0}
                                            img={popularProduct.img}
                                            fallbackImg={popularProduct.fallbackImg || ""}
                                            alt={popularProduct.alt}
                                            href={popularProduct.href}
                                            size={md ? "default" : "small"}
                                            fullWidth
                                        />
                                    ))
                                }
                            />
                        </div>
                    </div>

                    <div className="popular">
                        <Title className="popular-title">Popular Products</Title>
                        {/* <Text className="popular-subtitle">
                            Highlighting the best gifts for your special someone. <Link to="/product?collection=Valentine%27s%20Day">View All</Link>
                        </Text> */}
                        <div className="popular-products">
                            <ScrollableArticles
                                items={popularProducts}
                                settings={{ ...NEWS_CAROUSEL_SETTINGS, slidesToShow: 3, slidesToScroll: 3 }}
                                render={list =>
                                    list.map(popularProduct => (
                                        <ProductListing
                                            key={popularProduct.id}
                                            brand={popularProduct.brand || ""}
                                            title={popularProduct.title}
                                            price={popularProduct.price || 0}
                                            img={popularProduct.img}
                                            fallbackImg={popularProduct.fallbackImg || ""}
                                            alt={popularProduct.alt}
                                            href={popularProduct.href}
                                            size={md ? "default" : "small"}
                                            fullWidth
                                        />
                                    ))
                                }
                                // onScroll={reportStoryContentScroll}
                            />
                        </div>
                    </div>

                    <div className="small-categories">
                        {[
                            {
                                title: "Jewelry",
                                image: Ring,
                                to: "/product?category=Gifts&subCategory=Jewelry",
                            },
                            {
                                title: "Coffee",
                                image: Coffee,
                                to: "/product?subCategory=Coffee",
                            },
                            {
                                title: "Toys",
                                image: Rabbit,
                                to: "/product?category=Gifts&subCategory=Toys",
                            },
                            {
                                title: "Kitchenware",
                                image: Cups,
                                to: "/product?category=Home%2Foffice&subCategory=Kitchen",
                            },
                            {
                                title: "Accessories",
                                image: Watch,
                                to: "/product?category=Apparel&subCategory=Accessories",
                            },
                            {
                                title: "Animal Toys",
                                image: DogBone,
                                to: "/product?category=Pets&subCategory=Toys",
                            },
                        ].map(({ title, image, to }, key) => (
                            <ProductCard onCardClick={recordClickOnSmallProductCard} img={image} title={title} small key={key} to={to} />
                        ))}
                    </div>
                    <div className="large-categories">
                        {[
                            {
                                title: "Veteran-Owned Collection",
                                subtitle: "A collection of products from Veteran-Owned businesses.",
                                image: UsFlag,
                                to: "/product?collection=Veteran-Owned",
                            },
                            // {
                            //     title: "Valentine’s Day Collection",
                            //     subtitle: "American-made products for all of your Valentines.",
                            //     image: Valentine,
                            //     to: "/product?collection=Valentine%27s%20Day",
                            // },
                            // {
                            //     title: "Women’s Apparel",
                            //     subtitle: "Find women’s tops, pants, and more!",
                            //     image: JeansAndShirts,
                            //     to: "/product?category=apparel&subCategory=Women%27s",
                            // },
                            // {
                            //     title: "Kids",
                            //     subtitle: "Shop kid’s wear for all your little ones.",
                            //     image: GirlAndBalloon,
                            //     to: "/product?category=apparel&subCategory=Kid’s",
                            // },
                            // {
                            //     title: "Sports Gear",
                            //     subtitle: "Get American-made gear, and hit the great outdoors.",
                            //     image: Backpack,
                            //     to: "/product?category=apparel&subCategory=Hunting%2FCamping",
                            // },
                        ].map(({ title, subtitle, image, to }, key) => (
                            <ProductCard
                                onCardClick={recordClickOnLargeProductCard}
                                to={to}
                                img={image}
                                title={title}
                                subtitle={subtitle}
                                key={key}
                            />
                        ))}
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Products;
