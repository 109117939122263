import { FC } from "react";
import clsx   from "clsx";
import "./Container.less";

interface ContainerProps {
    className?: string;
}

/**
 * Core container component for Freespoke.
 *
 * @description Creates a container with a max-width of 1200px;
 */
const Container: FC<ContainerProps> = ({ children, className }) => <div className={clsx("Container", className)}>{children}</div>;

export default Container;
