import React, { FC, useCallback }            from "react";
import { useMatomo }                         from "@datapunt/matomo-tracker-react";
import Link                                  from "@/designSystem/Link/Link";
import Text                                  from "@/designSystem/Text/Text";
import { NoticeOfCollection, PrivacyPolicy } from "@/legal/index";
import "./Footer.less";

const Footer: FC = () => {
    const { trackEvent } = useMatomo();

    const recordEvent = useCallback(
        (type: string) => {
            trackEvent({
                category: "footer",
                name: "footer click",
                action: type,
            });
        },
        [trackEvent]
    );

    const recordClickOnNoticeOfCollection = useCallback(() => recordEvent("notice of collection"), [recordEvent]);
    const recordClickOnFreespokeTerms = useCallback(() => recordEvent("freespoke terms"), [recordEvent]);
    const recordClickOnPrivacyPolicy = useCallback(() => recordEvent("privacy policy"), [recordEvent]);
    const recordClickOnContactUs = useCallback(() => recordEvent("contact us"), [recordEvent]);

    return (
        <div className="Footer">
            <div className="container">
                <Text className="copy">© 2022 Freespoke</Text>
                <div className="links">
                    <Link to="/terms-of-service" onClick={recordClickOnFreespokeTerms}>
                        Freespoke's Terms
                    </Link>
                    <PrivacyPolicy onClick={recordClickOnPrivacyPolicy} />
                    <NoticeOfCollection onClick={recordClickOnNoticeOfCollection} />
                    <Link href="https://freespoke-support.freshdesk.com/support/tickets/new" onClick={recordClickOnContactUs}>
                        Contact Us
                    </Link>
                </div>
                <Text className="love">Made with ❤️ {"\u00A0"}in the U.S.A.</Text>
            </div>
        </div>
    );
};

export default React.memo(Footer);
