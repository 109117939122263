import { uniqBy }        from "lodash-es";
import { isEmptyObject } from "@/helpers/index";

function getArticleBias(article) {
    // for the new gRPC version
    if (article.bias) {
        return article.bias;
    } else if (article.publishers) {
        // TODO: remove once we succesfully switch to the new backend (core)
        let key = Object.keys(article.publishers)[0];
        if (article.publishers[key].tags) {
            if (article.publishers[key].tags.length > 1 && article.publishers[key].tags.includes("satire")) {
                return "satire";
            }
            return article.publishers[key].tags[0]
                ? article.publishers[key].tags[0] !== "center"
                    ? article.publishers[key].tags[0]
                    : "middle"
                : "middle";
        }
    }
}

function getPublisherName(article) {
    // for the new gRPC version
    if (article.publisherName) {
        return article.publisherName;
    } else if (article.publishers && !isEmptyObject(article.publishers)) {
        // TODO: remove once we succesfully switch to the new backend (core)
        return article.publishers[Object.keys(article.publishers)[0]].name;
    }
    return "";
}

function normalizeArticle(article) {
    if (!article) return article;
    return {
        id: article.id || article.url /* making sure we always have id */,
        datePublished: article.datePublished,
        headline: article.headline,
        images: article.images,
        url: article.url,
        bias: getArticleBias(article) /* getting bias from the publisher */,
        publisherName: getPublisherName(article),
        publisherIcon: article && article.publisherIcon,
    };
}

export function normalizeArticles(payload) {
    if (!payload) {
        return payload;
    }

    let newData = [];

    // for the new gRPC version
    if (payload.data && Array.isArray(payload.data)) {
        newData.push(...payload.data);
    }

    if (payload.data && Array.isArray(payload.data)) {
        newData.push(...payload.data);
    }

    if (payload.data && Array.isArray(payload.data.results)) {
        newData.push(...payload.data.results);
    }

    if (payload.data && Array.isArray(payload.data.bingResults)) {
        newData.push(...payload.data.bingResults);
    }

    newData = newData.map(normalizeArticle);

    /* Remove possible article duplicates */
    newData = uniqBy(newData, "id");

    return newData;
}

export function normalizeBreakingNews(payload) {
    if (!payload || !Array.isArray(payload.data)) {
        return payload;
    }

    payload.data = payload.data.map(item => {
        if (item.type === "article" && item.article.full_info) {
            item.article.full_info = normalizeArticle(item.article.full_info);
        }
        return item;
    });

    return payload;
}
