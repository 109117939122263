/* eslint-disable no-multi-spaces */
import React                              from "react";
import { datadogRum }                     from "@datadog/browser-rum";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import "react-app-polyfill/stable";
import ReactDOM                           from "react-dom";
import { Helmet, HelmetProvider }         from "react-helmet-async";
import "whatwg-fetch";
// eslint-disable-next-line align-import/align-import
import { DATADOG_ENV, ENABLE_ANALYTICS, ENABLE_DATADOG, MATOMO_SITE_ID, MATOMO_SRC_URL, MATOMO_TRACKER_URL } from "@/config";
import { DEFAULT_OGP_IMAGE_ABSOLUTE_PATH }                                                                   from "@/ogp/ogp";
import { version }                                                                                           from "@/root/package.json";
import App                                                                                                   from "@/src/App";
import "./index.css";

// import reportWebVitals from './reportWebVitals';

if (ENABLE_DATADOG) {
    datadogRum.init({
        applicationId: "c4c30171-3387-4b2e-9f98-eb1693574c12",
        clientToken: "pubadccc4eb5e12278f6d37393614badfaa",
        site: "datadoghq.com",
        service: "freespoke", // Specify a version number to identify the deployed version of your application in Datadog // version: '1.0.0',
        sampleRate: 100,
        env: DATADOG_ENV,
        version: version || "unknown contact admin",
        trackInteractions: false,
        replaySampleRate: 0,
    });
}

// MATOMO Analytics
// prettier-ignore
const instance = createInstance({
    urlBase:            window.origin,
    siteId:             MATOMO_SITE_ID!,
    // userId:          '',                 // optional, default value: `undefined`.
    trackerUrl:         MATOMO_TRACKER_URL, // optional, default value: `${urlBase}matomo.php`
    srcUrl:             MATOMO_SRC_URL,     // optional, default value: `${urlBase}matomo.js`
    disabled:           !ENABLE_ANALYTICS,  // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
        // optional, enabled by default
        active: true,       // optional, default value: true
        seconds: 10,        // optional, default value: `15
    },
    linkTracking: false,    // optional, default value: true
    // configurations: {    // optional, default value: {}
    //     // any valid matomo configuration, all below are optional
    //     disableCookies: true,
    //     setSecureCookie: true,
    //     setRequestMethod: 'POST'
    // }
    // setLinkTrackingTimer: 750,
});

ReactDOM.render(
    <React.StrictMode>
        <MatomoProvider value={instance}>
            <HelmetProvider>
                <Helmet>
                    {/* Nested or latter components will override duplicate changes */}
                    <meta name="description" content="Search results you can trust, no censorship and 100% privacy." />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:title" content="Freespoke.com – Your new search engine." />
                    <meta property="og:image" content={DEFAULT_OGP_IMAGE_ABSOLUTE_PATH} />
                    <meta property="twitter:image" content={`${window.location.origin}/_ogp/freespoke_twitter_ogp_image.jpg?version=1`} />
                    <meta property="og:logo" content={`${window.location.origin}/freespoke-logo.png`} />
                    <meta property="og:url" content="https://freespoke.com" />
                    <meta property="og:description" content="Search results you can trust, no censorship and 100% privacy." />
                </Helmet>
                <App />
            </HelmetProvider>
        </MatomoProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
