import { FC, useCallback, useMemo }    from "react";
import { useMatomo }                   from "@datapunt/matomo-tracker-react";
import useBreakpoint                   from "antd/lib/grid/hooks/useBreakpoint";
import { useLocation }                 from "react-router-dom";
import { ITopStory }                   from "@/@types/custom";
import { getAnalyticsCategory }        from "@/analytics/analytics";
import useGetHomepage                  from "@/api/useGetHomepage";
import Link                            from "@/designSystem/Link/Link";
import Paragraph                       from "@/designSystem/Paragraph/Paragraph";
import Text                            from "@/designSystem/Text/Text";
import Title                           from "@/designSystem/Title/Title";
import FreespokeLoader                 from "@/molecules/FreespokeLoader/FreespokeLoader";
import { ReactComponent as ArrowLeft } from "@/static/icons/arrow-left.svg";
import { slugify }                     from "@/utils/slugify";
import "./TopStoriesPage.less";

const TopStoriesPage: FC = () => {
    // TODO: probably need to create a separate API to get just "top_stories", without other homepage related data. But fine for now, since it's a POC feature.
    const { data, isLoading } = useGetHomepage({ include: ["featured_stories", "top_stories"] });
    const { lg } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const location = useLocation<{ clearFilters?: boolean }>();

    const showColumnView = useMemo(() => lg && data?.top_stories?.length > 10, [data?.top_stories, lg]);

    const recordStoryClickEvent = useCallback(
        storyTitle => {
            // Matomo analytics Story link click
            trackEvent({
                category: getAnalyticsCategory(location.pathname),
                name: "whats hot story click",
                action: storyTitle,
            });
        },
        [location.pathname, trackEvent]
    );

    const recordBackButtonClickEvent = useCallback(() => {
        // Matomo analytics for Back to Newsfeed click
        trackEvent({
            category: getAnalyticsCategory(location.pathname),
            name: "Back to newsfeed",
            action: "click",
        });
    }, [location.pathname, trackEvent]);

    const renderStory = (story: ITopStory, i: number) => {
        const { id, title } = story;
        const url = `/story/${id}/${slugify(title)}`;

        return (
            <div key={`top-story-${id}`} className="top-story-container">
                <div className="item-number-block">
                    <span>{i + 1}</span>
                </div>
                <div className="title-block">
                    <Link to={url} className="story-title" onClick={() => recordStoryClickEvent(title)}>
                        {title}
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div className="TopStoriesPage">
            {!isLoading && Array.isArray(data?.top_stories) && data.top_stories.length > 0 ? (
                <>
                    {showColumnView ? (
                        <div className="col-container">
                            <div className="col-left">
                                <>
                                    <div className="header">
                                        <div className="link-container">
                                            <Link className="header-link" to="/" onClick={recordBackButtonClickEvent}>
                                                <ArrowLeft />
                                                {lg && <Text>Back to Newsfeed</Text>}
                                            </Link>
                                        </div>
                                        {!lg && <Paragraph className="header-text">{`Showing ${data.top_stories.length} results for`}</Paragraph>}

                                        <Title className="header-title">What's Hot</Title>
                                    </div>
                                    <div className="body">
                                        <ul className="stories-list">{data.top_stories.slice(0, 10).map(renderStory)}</ul>
                                    </div>
                                </>
                            </div>
                            <div className="col-right">
                                <div className="body">
                                    <ul className="stories-list">
                                        {data.top_stories.slice(10, 20).map((story: ITopStory, i: number) => renderStory(story, i + 10))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="header">
                                <div className="link-container">
                                    <Link className="header-link" to="/">
                                        <ArrowLeft />
                                        {lg && <Text>Back to Newsfeed</Text>}
                                    </Link>
                                </div>
                                {!lg && <Paragraph className="header-text">{`Showing ${data.top_stories.length} results for`}</Paragraph>}

                                <Title level={2} className="header-title">
                                    What's Hot
                                </Title>
                            </div>
                            <div className="body ">
                                <ul className="stories-list">{data.top_stories.map((story: ITopStory, i: number) => renderStory(story, i))}</ul>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="spinner">
                    <FreespokeLoader />
                </div>
            )}
        </div>
    );
};

export default TopStoriesPage;
