import { FC }      from "react";
import EmailHeader from "@/molecules/EmailHeader/EmailHeader";
import "./FunHeader.less";

interface FunHeaderProps {}

const FunHeader: FC<FunHeaderProps> = () => (
    <EmailHeader
        className="FunHeader"
        title="Best of the Web"
        subtitle="The best stories from around the internet. Sign up for the best stories in your inbox every week!"
    />
);

export default FunHeader;
