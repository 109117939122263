import { useCallback, useEffect, useState } from "react";
import { useMatomo }                        from "@datapunt/matomo-tracker-react";
import { useHistory, useLocation }          from "react-router-dom";
import { getAnalyticsCategory }             from "@/analytics/analytics";
import CustomSearch                         from "@/designSystem/Icons/CustomSearch";
import ImageIcon                            from "@/designSystem/Icons/ImageIcon";
import ReadOutlined                         from "@/designSystem/Icons/ReadOutlined";
import Tabs                                 from "@/designSystem/Tabs/Tabs";
import ImageSearchFilter                    from "@/organisms/ImageSearchFilter/ImageSearchFilter";
import ImagesSearch                         from "@/pages/ImagesSearch/ImagesSearch";
import NewsSearch                           from "@/pages/NewsSearch/NewsSearch";
import WebSearch                            from "@/pages/WebSearch/WebSearch";
import "./SearchPage.less";

const { TabPane } = Tabs;
const SearchPage = () => {
    const location = useLocation();
    const history = useHistory();
    const { trackEvent } = useMatomo();

    const getTabKey = useCallback(() => {
        if (location.pathname.includes("/search/news")) {
            return "news";
        } else if (location.pathname.includes("/search/web")) {
            return "web";
        } else if (location.pathname.includes("/search/images")) {
            return "images";
        }
        return null;
    }, [location.pathname]);

    const [tabKey, setTabKey] = useState<"news" | "web" | "images" | null>(getTabKey);

    useEffect(() => {
        const currentKey = getTabKey();
        if (currentKey === null) {
            history.push(`/search/web${location.search}`);
        } else {
            setTabKey(currentKey);
        }
    }, [getTabKey, history, location.pathname, location.search]);

    const onTabChange = useCallback(
        key => {
            trackEvent({
                category: getAnalyticsCategory(location.pathname),
                name: `${key} tab click`,
                action: "click",
            });

            const pageParams = new URLSearchParams(location.search);
            history.push({ pathname: `/search/${key}`, search: `?${new URLSearchParams({ q: pageParams.get("q") || "" })}` });
        },
        [history, location.pathname, location.search, trackEvent]
    );

    const onTabClick = useCallback(
        key => {
            if (key === "images") {
                const pageParams = new URLSearchParams(location.search);
                if (pageParams.has("id")) {
                    pageParams.delete("id");
                }
                history.push({
                    pathname: location.pathname,
                    search: `?${pageParams}`,
                });
            }
        },
        [history, location.pathname, location.search]
    );

    return (
        <div className="SearchPage">
            <Tabs
                defaultActiveKey={tabKey ?? "web"}
                activeKey={tabKey ?? "web"}
                onChange={onTabChange}
                className="tabs-style"
                destroyInactiveTabPane
                tabBarExtraContent={location.pathname === "/search/images" && <ImageSearchFilter />}
                onTabClick={onTabClick}
            >
                <TabPane
                    tab={
                        <span className="tab-option">
                            <CustomSearch />
                            All
                        </span>
                    }
                    key="web"
                >
                    <WebSearch />
                </TabPane>
                <TabPane
                    tab={
                        <span className="tab-option">
                            <ReadOutlined />
                            News
                        </span>
                    }
                    key="news"
                >
                    <NewsSearch />
                </TabPane>
                <TabPane
                    tab={
                        <span className="tab-option">
                            <ImageIcon />
                            Images
                        </span>
                    }
                    key="images"
                >
                    <ImagesSearch />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default SearchPage;
