import { VFC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useMatomo }                                            from "@datapunt/matomo-tracker-react";
import useBreakpoint                                            from "antd/lib/grid/hooks/useBreakpoint";
import { isAndroid, isIOS, isMacOs }                            from "react-device-detect";
import { useLocation }                                          from "react-router";
import { getAnalyticsCategory }                                 from "@/analytics/analytics";
import useGetHeroStory                                          from "@/api/useGetHeroStory";
import { slugify }                                              from "@/components/utils/slugify";
import Button                                                   from "@/designSystem/Button/Button";
import RightArrow                                               from "@/designSystem/Icons/RightArrow";
import Link                                                     from "@/designSystem/Link/Link";
import Text                                                     from "@/designSystem/Text/Text";
import { useAppBar }                                            from "@/hooks/useAppBar";
import useWindowSize                                            from "@/hooks/useWindowSize";
import AppDownloadBadge                                         from "@/molecules/AppDownloadBadge/AppDownloadBadge";
import Hamburger                                                from "@/molecules/Hamburger/Hamburger";
import SearchBar                                                from "@/molecules/SearchBar/SearchBar";
import ShareCompressed                                          from "@/molecules/ShareCompressed/ShareCompressed";
import { ReactComponent as FreespokeBeta }                      from "@/static/logo/logo.svg";
import { ReactComponent as TorchLogo }                          from "@/static/logo/logo_small.svg";
// import HomepageMobileJPG                                  from "@/static/search-screen/hero/gas-prices-mobile.jpg";
// import HomepageMobileWEBP                                 from "@/static/search-screen/hero/gas-prices-mobile.webp";
// import HomepageJPG                                        from "@/static/search-screen/hero/gas-prices.jpg";
// import HomepageWEBP                                       from "@/static/search-screen/hero/gas-prices.webp";
import "./SearchScreen.less";

interface SearchScreenProps {}

const analyticsMeta = { category: "home", name: "search screen" } as const;

const SearchScreen: VFC<SearchScreenProps> = () => {
    const { trackEvent } = useMatomo();
    const { ref } = useAppBar();
    const { xs, lg } = useBreakpoint();
    const { width } = useWindowSize();
    const { pathname } = useLocation();
    const [isExpanded, setIsExpanded] = useState(false);

    const { data: heroData } = useGetHeroStory({ width: width || 0 });

    const heroDataObject = useMemo(() => {
        return heroData && heroData[0];
    }, [heroData]);

    const heroImage = useMemo(() => {
        return heroDataObject && heroDataObject.images[0];
    }, [heroDataObject]);

    const heroTitle = useMemo(() => {
        return heroDataObject ? heroDataObject.title : "";
    }, [heroDataObject]);

    const heroShortTitle = useMemo(() => {
        return heroDataObject ? heroDataObject.shortTitle : "";
    }, [heroDataObject]);

    useEffect(() => {
        setIsExpanded(true);
    }, []);

    // const reportClickOnLearnMore = useCallback(() => {
    //     trackEvent({
    //         category: "home",
    //         name: "about us",
    //         action: "click",
    //     });
    // }, [trackEvent]);

    const reportClickOnJoinButton = useCallback(() => {
        trackEvent({
            category: "jtm cta nav bar",
            name: `cta banner click - ${getAnalyticsCategory(pathname)}`,
            action: "cta banner click",
        });
    }, [pathname, trackEvent]);

    const reportFeaturedStoryClick = useCallback(() => {
        trackEvent({
            category: getAnalyticsCategory(pathname),
            name: "featured hero story ",
            action: heroTitle,
        });
    }, [heroTitle, pathname, trackEvent]);

    const shouldDisplayAppButton = isIOS || isMacOs || isAndroid;

    const imageComponent = useMemo(
        () => (
            <div className="picture-wrapper">
                {/* <source type="image/webp" media="(max-width: 575px)" srcSet={HomepageMobileWEBP} />
    <source type="image/webp" media="(min-width: 576px)" srcSet={HomepageWEBP} />
    <source type="image/jpeg" media="(max-width: 575px)" srcSet={HomepageMobileJPG} />
    <img className="background-img" srcSet={HomepageJPG} alt="statue of liberty" /> */}
                <img className="background-img" srcSet={heroImage} alt={heroTitle} />
            </div>
        ),
        [heroImage, heroTitle]
    );

    return (
        <div className="SearchScreen">
            <div className="banner-section">
                {/* absolutely positioned SearchScreen elements */}
                {imageComponent}
                <div className="container">
                    <div className={`slider${isExpanded ? " slider-out" : ""}`}></div>
                    {/* absolutely positioned container elements */}
                    <nav className="navigation">
                        {!xs && (
                            <>
                                {/* <Link to="/about" className="nav-item nav-buttons" onClick={reportClickOnLearnMore}>
                                        About Us
                                    </Link> */}
                                <Link to="/search/categories" className="nav-item nav-buttons">
                                    Topics
                                </Link>
                                <Link to="/products" className="nav-item nav-buttons">
                                    Shop USA
                                </Link>
                                <Button type="ghost" className="nav-item share-button">
                                    Share Freespoke
                                    <ShareCompressed
                                        url={window.location.origin}
                                        placement="bottom"
                                        type="freespoke"
                                        hasText={false}
                                        analyticsMeta={analyticsMeta}
                                    />
                                </Button>
                            </>
                        )}
                        {shouldDisplayAppButton && <AppDownloadBadge className="nav-item app-btn" type={isIOS || isMacOs ? "ios" : "android"} />}
                        <Button type="primary" className="join-button" size={lg ? "large" : "middle"} onClick={reportClickOnJoinButton}>
                            <Link to="/join">{width && width < 320 ? "Join" : "Join the Movement"}</Link>
                        </Button>

                        <Hamburger
                            analyticsMeta={{
                                category: "home",
                                name: "menu open",
                                action: "click",
                            }}
                        />
                    </nav>
                    <FreespokeBeta className="freespoke-beta" />
                    <div ref={ref} className="search-bar-wrapper">
                        <SearchBar parent="homepage" />
                    </div>
                    <Text className="about-us">See Clearly. Search Clearly. </Text>
                    <div className="freespoke-story">
                        <Text className="label">{xs ? heroShortTitle : heroTitle}</Text>
                        <Link to={`/story/${heroDataObject?.id}/${slugify(heroTitle)}`} className="link" onClick={reportFeaturedStoryClick}>
                            <span className="logo">
                                <TorchLogo /> FREESPOKE STORY
                            </span>
                            <RightArrow />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(SearchScreen);
