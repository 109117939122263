import { FC, useCallback, useState }   from "react";
import useBreakpoint                   from "antd/lib/grid/hooks/useBreakpoint";
import { useLocation }                 from "react-router";
import { AnalyticsMeta }               from "@/@types/custom";
import { getAnalyticsCategory }        from "@/analytics/analytics";
import { ISimilarity }                 from "@/api/useGetStoryById";
import Text                            from "@/designSystem/Text/Text";
import Title                           from "@/designSystem/Title/Title";
import { toTimeAgo }                   from "@/helpers/index";
import Container                       from "@/molecules/Container/Container";
import ShareExpanded                   from "@/molecules/ShareExpanded/ShareExpanded";
import SimilarityCard                  from "@/molecules/SimilarityCard/SimilarityCard";
import { ReactComponent as TorchMini } from "@/static/icons/torch-mini-white.svg";
import ImageLoader                     from "@/utils/ImageLoader";
import ShareCompressed                 from "../ShareCompressed/ShareCompressed";
import "./StoryHeader.less";

// import { ReactComponent as TrendingRibbon } from "@/static/icons/trending-ribbon.svg";

interface StoryHeaderProps {
    category: string;
    name: string;
    images?: string[];
    updated_at: string;
    similarity?: { left?: ISimilarity[]; middle?: ISimilarity[]; right?: ISimilarity[] };
    noBgImage?: boolean;
    children?: JSX.Element[] | JSX.Element;
    page?: string;
    storyPageUrl?: string;
    analyticsMeta?: AnalyticsMeta;
}

const StoryHeader: FC<StoryHeaderProps> = ({
    category,
    name,
    images = [],
    updated_at,
    similarity,
    noBgImage,
    children,
    page,
    storyPageUrl,
    analyticsMeta,
}) => {
    const { xs, md } = useBreakpoint();
    const location = useLocation();
    const [expandAllSimilarityCards, setExpandAllSimilarityCards] = useState(false);

    // if one of the similarity card extends, force its siblings to do the same
    const onSimilarityCardHeightChange = useCallback(
        (isExpanded: boolean) => {
            if (md) {
                setExpandAllSimilarityCards(isExpanded);
            }
        },
        [md]
    );

    const hasSimilarities = !!(similarity && (similarity.left?.length || similarity.middle?.length || similarity.right?.length));

    return (
        <>
            <div className="StoryHeader">
                <div className="bg-container">
                    {!noBgImage && <ImageLoader srcList={images} alt={`${category} story image`} className="bg-img" />}
                </div>
                <Container className="container">
                    <Title className="title">{name}</Title>
                    <div className="socials-and-info">
                        {category ? (
                            <div className="trending-container">
                                <TorchMini />
                                <Text className="trending-text">{category} Story</Text>
                            </div>
                        ) : (
                            <ShareExpanded url={`${window.location.origin}${window.location.pathname}`} title={name} hideMore page={page} />
                        )}
                        {updated_at && <Text className="updated">Updated: {toTimeAgo(updated_at)}</Text>}
                    </div>
                    <ShareCompressed
                        url={storyPageUrl || ""}
                        type="story"
                        forStoryPage
                        analyticsMeta={{
                            ...analyticsMeta,
                            category: analyticsMeta?.category || getAnalyticsCategory(location.pathname),
                            name: `${name} share`,
                            action: "share",
                        }}
                    />
                    {children}
                </Container>
            </div>

            {hasSimilarities && (
                <div className="summary-container">
                    <div className="header">
                        <Title className="title">{xs ? "SUMMARY" : "See all angles of the story here."}</Title>
                        {xs && <Text className="caption">Top quotes from each side.</Text>}
                    </div>
                    <div className="summaries">
                        {similarity?.left && similarity.left.length > 0 && (
                            <div id="left" className="content">
                                <SimilarityCard
                                    forceExpand={expandAllSimilarityCards}
                                    rows={2}
                                    bias="left"
                                    similarityItems={similarity.left}
                                    analyticsMeta={{ name }}
                                    onSizeChange={onSimilarityCardHeightChange}
                                />
                            </div>
                        )}
                        {similarity?.middle && similarity.middle.length > 0 && (
                            <div id="middle" className="content">
                                <SimilarityCard
                                    forceExpand={expandAllSimilarityCards}
                                    rows={2}
                                    bias="middle"
                                    similarityItems={similarity.middle}
                                    analyticsMeta={{ name }}
                                    onSizeChange={onSimilarityCardHeightChange}
                                />
                            </div>
                        )}
                        {similarity?.right && similarity.right.length > 0 && (
                            <div id="right" className="content">
                                <SimilarityCard
                                    forceExpand={expandAllSimilarityCards}
                                    rows={2}
                                    bias="right"
                                    similarityItems={similarity.right}
                                    analyticsMeta={{ name }}
                                    onSizeChange={onSimilarityCardHeightChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default StoryHeader;
