import { FC, memo } from "react";
import Link         from "@/designSystem/Link/Link";
import Title        from "@/designSystem/Title/Title";
import ImageLoader  from "@/utils/ImageLoader";
import "./GenericContentCard.less";

export interface GenericContentCardProps {
    title: string;
    url: string;
    images: string[] | undefined;
}

const GenericContentCard: FC<GenericContentCardProps> = ({ title, url, images }) => {
    return (
        <Link href={url} className="GenericContentCard">
            <div className="image-container">
                <ImageLoader srcList={images} alt="title" className="image-loader" />
                <div className="gradient-bg" />
                <Title className="card-title" ellipsis={{ rows: 2 }}>
                    {title}
                </Title>
            </div>
        </Link>
    );
};

export default memo(GenericContentCard);
