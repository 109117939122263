import { FC, useCallback, useEffect, useMemo }   from "react";
import { useMatomo }                             from "@datapunt/matomo-tracker-react";
import useBreakpoint                             from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet }                                from "react-helmet-async";
import { useParams }                             from "react-router-dom";
import { IArticle, ITweet }                      from "@/@types/custom";
import useGetStoryById, { ContentGroupedByBias } from "@/api/useGetStoryById";
import { slugify }                               from "@/components/utils/slugify";
import { Col, Row }                              from "@/designSystem/Grid/Grid";
import Text                                      from "@/designSystem/Text/Text";
import Title                                     from "@/designSystem/Title/Title";
import ContentCard                               from "@/molecules/ContentCard/ContentCard";
import ErrorResult                               from "@/molecules/ErrorResult/ErrorResult";
import FreespokeLoader                           from "@/molecules/FreespokeLoader/FreespokeLoader";
import StoryHeader                               from "@/molecules/StoryHeader/StoryHeader";
import TweetCard                                 from "@/molecules/TweetCard/TweetCard";
import AppDownloadBanner                         from "@/organisms/AppDownloadBanner/AppDownloadBanner";
import StoryPageNewsFeed                         from "@/organisms/StoryPageNewsFeed/StoryPageNewsFeed";
import VideoPlayer                               from "@/organisms/VideoPlayer/VideoPlayer";
import "./StoryPage.less";

interface StoryPageParam {
    story_id: string;
    story_slug: string;
}
const StoryPage: FC = () => {
    const { story_id, story_slug } = useParams<StoryPageParam>();
    const { xs, md } = useBreakpoint();

    const { data, isLoading, error } = useGetStoryById<ContentGroupedByBias>(story_id, {
        format: xs ? "list" : "group_by_bias",
        count: xs ? 15 : 5,
        include: ["similarity"],
    });

    const { trackPageView, trackEvent } = useMatomo();

    const reportStoryItemClick = useCallback(
        (type: "tweet" | "article") => () => {
            trackEvent({
                category: "story page",
                name: data?.name,
                action: `story trending news content click - ${type}`,
            });
        },
        [data?.name, trackEvent]
    );

    const filterTweetsFromBias = useCallback(biasData => {
        return biasData.filter(data => data.tweet_id);
    }, []);

    const tweets = useMemo(() => {
        const content = data?.content;
        return md
            ? []
            : xs
            ? (Array.isArray(content) && content?.filter(article => article?.tweet_id)) || []
            : [
                  ...filterTweetsFromBias(content?.left || []),
                  ...filterTweetsFromBias(content?.middle || []),
                  ...filterTweetsFromBias(content?.right || []),
              ];
    }, [data?.content, md, xs, filterTweetsFromBias]);

    const articleWithoutTweets = useMemo(() => {
        const content = data?.content;
        if (Array.isArray(content)) return content?.filter(article => !article?.tweet_id);
    }, [data?.content]);

    useEffect(() => {
        // @ts-ignore
        trackPageView();
    }, [story_id, trackPageView]);

    const hasVideo = useMemo(() => !isLoading && data && Array.isArray(data.videos) && data.videos.length > 0, [data, isLoading]);

    const displayTweet = useCallback(
        (tweet, i) => {
            return (
                <TweetCard
                    key={`tweet-${tweet.id}-${i}`}
                    data={{
                        id: tweet.id,
                        author: {
                            name: tweet.author.name,
                            username: tweet.author.username,
                            profile_image_url: tweet.author.profile_image_url,
                        },
                        text: tweet.text,
                        datePublished: tweet.datePublished,
                        url: tweet.url,
                        bias: tweet.bias,
                    }}
                    maxLines={7}
                    reportClick={reportStoryItemClick("tweet")}
                />
            );
        },
        [reportStoryItemClick]
    );

    const displayArticle = useCallback(
        (article: IArticle & ITweet, i) => {
            return article.tweet_id ? (
                md && displayTweet(article, i)
            ) : (
                <ContentCard
                    key={`left-${article.id}-${i}`}
                    url={article.url}
                    datePublished={article.datePublished}
                    publisherName={article.publisherName}
                    publisherIcon={article.publisherIcon}
                    publisherTypes={article.publisherTypes}
                    images={article.images}
                    bias={article.bias}
                    title={article.title}
                    reportClick={reportStoryItemClick("article")}
                    boldHeadline
                />
            );
        },
        [displayTweet, md, reportStoryItemClick]
    );

    /* If story has a video and thumbnail URL then use it for og:image, else use DEFAULT_OGP_IMAGE */
    const ogp_image_url = useMemo(
        () => (hasVideo && Array.isArray(data?.videos) && data?.videos[0].thumbnailURL ? data.videos[0].thumbnailURL : data?.ogp_image_url),
        [data?.ogp_image_url, data?.videos, hasVideo]
    );
    const ogp_twitter_image_url = useMemo(
        () => (hasVideo && Array.isArray(data?.videos) && data?.videos[0].thumbnailURL ? data.videos[0].thumbnailURL : data?.ogp_twitter_image_url),
        [data?.ogp_twitter_image_url, data?.videos, hasVideo]
    );

    if (error) {
        return <ErrorResult error={error} containerClassName="Story" />;
    }

    return (
        <div className="StoryPage" data-slug={story_slug}>
            {data && !isLoading && (
                <StoryHeader
                    category={data.category}
                    name={data.name}
                    images={hasVideo ? [data.videos![0].thumbnailURL] : data.images}
                    updated_at={data.updated_at}
                    similarity={data.similarity}
                    noBgImage={hasVideo}
                    storyPageUrl={`https://freespoke.com/story/${story_id}/${slugify(data.name)}`}
                    analyticsMeta={{ name: data?.name, actionPrefix: "story" }}
                />
            )}
            <div className="content-container">
                {isLoading && (
                    <div className="StoryPageSpin">
                        <FreespokeLoader />
                    </div>
                )}
                {story_id && data && !isLoading && (
                    <>
                        {/* gets inserted into the head */}
                        <Helmet>
                            <link rel="canonical" href={`https://freespoke.com/story/${story_id}/${slugify(data.name)}`} />
                            <title>{`${data.name} - Freespoke`}</title>
                            <meta property="og:title" content={data.name} />
                            <meta property="og:type" content="article" />
                            <meta property="og:url" content={`${window.location.origin}${window.location.pathname}`} />
                            {!!ogp_image_url && <meta property="og:image" content={ogp_image_url} />}
                            <meta property="twitter:card" content="summary" />
                            <meta property="twitter:title" content={data.name} />
                            {!!ogp_image_url && <meta property="twitter:image" content={ogp_twitter_image_url} />}
                            <meta property="al:ios:url" content={`freespokeapp://story/${story_id}/${slugify(data.name)}`} />
                            <meta property="al:ios:app_store_id" content="1617332602" />
                            <meta property="al:ios:app_name" content="Freespoke" />
                            <meta property="al:android:url" content={`freespokeapp://story/${story_id}/${slugify(data.name)}`} />
                            <meta property="al:android:app_name" content="Freespoke" />
                            <meta property="al:android:package" content="com.freespoke.app" />
                            <meta property="al:web:url" content={`https://freespoke.com/story/${story_id}/${slugify(data.name)}`} />
                        </Helmet>
                        {data && hasVideo ? (
                            <Row>
                                <Col flex={1}>
                                    <VideoPlayer
                                        id={Array.isArray(data.videos) && data.videos[0].id}
                                        parentId={"story-page"}
                                        onClose={() => false}
                                        url={Array.isArray(data?.videos) && data.videos[0].url}
                                        thumbnailURL={Array.isArray(data?.videos) && data.videos[0].thumbnailURL}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <div className="trending-news">
                                <div className="header">
                                    <Title className="news-title">{xs ? "TRENDING NEWS" : "Trending News"}</Title>
                                    <Text className="caption"> See all angles of the story here.</Text>
                                </div>

                                <div className="bias-main-container">
                                    {xs ? (
                                        <div className="bias-container">{articleWithoutTweets?.map(displayArticle)}</div>
                                    ) : (
                                        <>
                                            <div className="bias-container left">{data.content?.left.map(displayArticle)}</div>
                                            <div className="bias-container middle">{data.content?.middle.map(displayArticle)}</div>
                                            <div className="bias-container right">{data.content?.right.map(displayArticle)}</div>{" "}
                                        </>
                                    )}
                                </div>

                                {!!tweets.length && !md && (
                                    <div className="bias-main-container">
                                        <Text className="bias-title tweets">Social Highlights</Text>
                                        <div className="bias-container tweets">{tweets.map(displayTweet)}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
            {story_id && data && !isLoading && (
                <AppDownloadBanner className="app-banner" analyticsMeta={{ name: data.name, action: "story app dowload CTA click" }} />
            )}
            {story_id && data && !isLoading && (
                <StoryPageNewsFeed analyticsMeta={{ category: "story page", name: data.name, actionPrefix: "story newsfeed" }} />
            )}
        </div>
    );
};

export default StoryPage;
