import { FC, useMemo }                  from "react";
import Text                             from "@/designSystem/Text/Text";
import { toTimeAgo }                    from "@/helpers/index";
import { ReactComponent as MiddleIcon } from "@/static/icons/center.svg";
import { ReactComponent as LeftIcon }   from "@/static/icons/democrat.svg";
import { ReactComponent as RightIcon }  from "@/static/icons/republican.svg";
import ImageLoader                      from "@/utils/ImageLoader";
import "./ArticleContext.less";

export interface ArticleContextProps {
    date?: string;
    publisher?: string;
    publisherLogo?: string;
    bias: "left" | "middle" | "right";
    showPublisher?: boolean;
    showBias?: boolean;
}

const ArticleContext: FC<ArticleContextProps> = ({ publisher, publisherLogo, bias, date, showPublisher = true, showBias = true }) => {
    const distance = useMemo(() => toTimeAgo(date ?? "", true), [date]);
    return (
        <div className="ArticleContext">
            {publisherLogo && <ImageLoader src={publisherLogo} alt={publisher || "unknown publisher"} />}
            {showPublisher && <Text>{publisher}</Text>}
            {showBias && bias && bias === "left" && (
                <>
                    <LeftIcon />
                    <Text>Left</Text>
                </>
            )}
            {showBias && bias && bias === "middle" && (
                <>
                    <MiddleIcon />
                    <Text>Middle</Text>
                </>
            )}
            {showBias && bias && bias === "right" && (
                <>
                    <RightIcon />
                    <Text>Right</Text>
                </>
            )}
            {distance && <Text>{distance}</Text>}
        </div>
    );
};

export default ArticleContext;
