import { FC, ReactNode, RefObject, forwardRef, useEffect, useRef, useState } from "react";
import { useMatomo }                                                         from "@datapunt/matomo-tracker-react";
import { Helmet }                                                            from "react-helmet-async";
import { useInView }                                                         from "react-intersection-observer";
import { AnalyticsMeta, IFeaturedStory }                                     from "@/@types/custom";
import { useScrollContext }                                                  from "@/context/useScrollContext";
import { Col, Row }                                                          from "@/designSystem/Grid/Grid";
import useScrollPosition                                                     from "@/hooks/useScrollPosition";
import SpangldCTA                                                            from "@/molecules/SpangldCTA/SpangldCTA";
import FeaturePreviewBox                                                     from "@/organisms/FeaturePreviewBox/FeaturePreviewBox";
import FeaturedStory                                                         from "@/organisms/FeaturedStory/FeaturedStory";
import NewsStory                                                             from "@/organisms/NewsStory/NewsStory";
import "./ContentHolder.less";

const STORIES_TO_RENDER_INITIALLY = 5; // how many stories to load initially
const STORIES_TO_RENDER = 2; // how many more stories to load once the user scrolls to the last one

interface SpecialTopicArticle {
    id: string;
    title: string;
    url: string;
    subcategory: string;
    images: string[];
    createdAt: string;
}
interface ContentHolderProps {
    children?: ReactNode;
    stories: any[];
    censored_stories: SpecialTopicArticle[];
    best_of_web: SpecialTopicArticle[];
    spangld?: any[];
    featured_stories: IFeaturedStory[];
    isMobile?: boolean;
    customRef?: any;
    analyticsMeta: Omit<AnalyticsMeta, "action">;
}

const ContentHolder: FC<ContentHolderProps> = forwardRef(
    (
        { stories, censored_stories, best_of_web, spangld, featured_stories, isMobile, customRef, analyticsMeta },
        _ref: RefObject<HTMLDivElement> | null
    ) => {
        const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
        const { itemToScroll, resetItemScroll } = useScrollContext();
        const [storiesToRender, setStoriesToRender] = useState(STORIES_TO_RENDER_INITIALLY);
        const { trackEvent } = useMatomo();
        const lastNumberSent = useRef(0);
        const scrollRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (itemToScroll === "#news") {
                if (scrollRef?.current) {
                    const yOffset = -120;
                    const element = scrollRef.current;
                    const y = element.getBoundingClientRect()?.top + window.pageYOffset + yOffset;

                    window.scrollTo({ top: y, behavior: "smooth" });
                    resetItemScroll();
                }
            }
        }, [itemToScroll, resetItemScroll]);

        const onScrollToLastStory = () => {
            // We don't want to display 'Loading...' if there is no more topics to upload
            if (stories && storiesToRender < stories.length) {
                setStoriesToRender(storiesToRender + STORIES_TO_RENDER);
            }
        };

        const MapContent = (story, i, arr) => {
            const result = [
                <Col key={story.id} span={24}>
                    <NewsStory
                        id={story.id}
                        isLast={arr.length - 1 === i}
                        onInView={onScrollToLastStory}
                        showUpdatedAt={true}
                        analyticsMeta={analyticsMeta}
                        pageName={analyticsMeta.actionPrefix}
                    />
                </Col>,
            ];

            if (isMobile && i === 2 && Array.isArray(spangld) && spangld.length > 0) {
                result.push(
                    <Col key={"spangle"}>
                        <SpangldCTA
                            id={spangld[0].id}
                            images={spangld[0].images}
                            url={spangld[0].url}
                            title={spangld[0].title}
                            textSummary={spangld[0].textSummary}
                            rawHTML={spangld[0].rawHTML}
                            analyticsMeta={analyticsMeta}
                        />
                    </Col>
                );
            }
            return result;
        };

        // Matomo scroll tracking
        // prettier-ignore
        useScrollPosition(({ prevPos, currPos }) => {
            if (Math.abs(currPos.y - prevPos.y) > 4 && stories && stories.length) {
                // get the direction
                const scrollDown = prevPos.y < currPos.y;
                // number of visible stories
                let numberOfStories = 0;
                // there is probably a better way of doing this...
                const stories = document.getElementsByClassName("NewsStory");
                Array.prototype.forEach.call(stories, el => {
                    const threshold = scrollDown
                        ? el.getBoundingClientRect().top - el.getBoundingClientRect().height
                        : el.getBoundingClientRect().top + el.getBoundingClientRect().height;
                    if (threshold < 0) {
                        numberOfStories++;
                    }
                });
                if (numberOfStories > 0 && lastNumberSent.current !== numberOfStories && numberOfStories % 4 === 0) {
                    trackEvent({
                        category: analyticsMeta.category || `Scroll ${scrollDown ? "Down" : "Up"}`,
                        name: analyticsMeta.name || `Scrolled ${numberOfStories}`,
                        action: `${analyticsMeta.actionPrefix ? `${analyticsMeta.actionPrefix} ` : ""} page scroll`
                    });
                    lastNumberSent.current = numberOfStories;
                }
            }
        // prettier-ignore
        }, undefined, undefined, true, undefined, undefined);

        return (
            <div ref={ref} className="ContentHolder">
                <Row justify="center" gutter={[0, { xs: 20, sm: 20, md: 20, lg: 24 }]} ref={scrollRef}>
                    {inView && (
                        <Helmet>
                            <link rel="canonical" href="https://freespoke.com/#news" />
                            <meta name="description" content={`Get a 360° view of the news; Right, Middle, Left & more`}></meta>
                        </Helmet>
                    )}

                    {stories && stories.length > 0 && (
                        <Col className="first-story" span={24} ref={customRef} key={stories[0].id}>
                            <NewsStory
                                pageName={analyticsMeta.actionPrefix}
                                id={stories[0].id}
                                isLast={false}
                                showUpdatedAt={true}
                                analyticsMeta={analyticsMeta}
                            />
                        </Col>
                    )}

                    <Col span={24}>
                        <FeaturePreviewBox analyticsMeta={analyticsMeta} previewItems={censored_stories} type="Censored Stories" />
                    </Col>

                    {stories && stories.length > 1 && stories.slice(1, 2).map(MapContent)}

                    <Col span={24}>
                        <FeaturePreviewBox analyticsMeta={analyticsMeta} previewItems={best_of_web} type="Best of the Web" />
                    </Col>

                    {stories && stories.length > 2 && stories.slice(2, 4).map(MapContent)}

                    {Array.isArray(featured_stories) && featured_stories.length > 0 && (
                        <Col span={24}>
                            <FeaturedStory
                                url={featured_stories[0].url}
                                title={featured_stories[0].title || "Missing title"}
                                description={featured_stories[0].description || "Missing description"}
                                image={featured_stories[0].images?.[0] || ""}
                                alt={featured_stories[0].title || "Missing title"}
                            />
                        </Col>
                    )}

                    {stories && stories.length > 4 && stories.slice(4, storiesToRender).map(MapContent)}
                </Row>
            </div>
        );
    }
);

export default ContentHolder;
