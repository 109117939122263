import { FC }               from "react";
import { PopoverProps }     from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import clsx                 from "clsx";
import Link                 from "@/designSystem/Link/Link";
import Popover              from "@/designSystem/Popover/Popover";
import Text                 from "@/designSystem/Text/Text";
import "./ShareDropdown.less";

interface ShareMenuItemProps {
    label: string;
    href?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
}

const ShareMenuItem: FC<ShareMenuItemProps> = ({ children, label, href, onClick }) => {
    if (href) {
        return (
            <Link className="ShareMenuItem" onClick={onClick} href={href}>
                {children}
                <Text>{label}</Text>
            </Link>
        );
    }
    return (
        <div className="ShareMenuItem" onClick={onClick}>
            {children}
            <Text>{label}</Text>
        </div>
    );
};

export type ShareDropdownItems = Array<
    {
        icon: JSX.Element;
    } & ShareMenuItemProps
>;

interface ShareDropdownProps extends PopoverProps {
    items: ShareDropdownItems;
    className?: string;
    placement?: TooltipPlacement;
}

const ShareDropdown: FC<ShareDropdownProps> = ({ children, className, placement, items }) => (
    <Popover
        placement={placement || "right"}
        overlayClassName={clsx("ShareDropdown", className && className)}
        arrowContent={null}
        content={
            <div className="ShareMenu">
                {items.map(({ icon, ...rest }, i) => (
                    <ShareMenuItem key={i} {...rest}>
                        {icon}
                    </ShareMenuItem>
                ))}
            </div>
        }
        trigger={["click"]}
    >
        {children}
    </Popover>
);

export default ShareDropdown;
