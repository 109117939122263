import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMatomo }                                            from "@datapunt/matomo-tracker-react";
import { CarouselProps }                                        from "antd";
import useBreakpoint                                            from "antd/lib/grid/hooks/useBreakpoint";
import { useInView }                                            from "react-intersection-observer";
import { useResizeDetector }                                    from "react-resize-detector/build/withPolyfill";
import { AnalyticsMeta, IArticle, ITweet }                      from "src/@types/custom";
import useGetStoryById, { ContentList }                         from "@/api/useGetStoryById";
import { slugify }                                              from "@/components/utils/slugify";
import Card                                                     from "@/designSystem/Card/Card";
import Divider                                                  from "@/designSystem/Divider/Divider";
import { Col, Row }                                             from "@/designSystem/Grid/Grid";
import Link                                                     from "@/designSystem/Link/Link";
import Skeleton                                                 from "@/designSystem/Skeleton/Skeleton";
import Text                                                     from "@/designSystem/Text/Text";
import Title                                                    from "@/designSystem/Title/Title";
import { toTimeAgo }                                            from "@/helpers/index";
import ContentCard                                              from "@/molecules/ContentCard/ContentCard";
import ContentCardV2                                            from "@/molecules/ContentCardV2/ContentCardV2";
import ScrollableArticles                                       from "@/molecules/ScrollableArticles/ScrollableArticles";
import ShareCompressed                                          from "@/molecules/ShareCompressed/ShareCompressed";
import SpangldSummary                                           from "@/molecules/SpangldSummary/SpangldSummary";
import TweetCard                                                from "@/molecules/TweetCard/TweetCard";
import ViewStoryCard                                            from "@/molecules/ViewStoryCard/ViewStoryCard";
import "./NewsStory.less";

const { Meta } = Card;

const NEWS_CAROUSEL_SETTINGS: CarouselProps = {
    dots: false,
    arrows: true,
    infinite: false,
    rows: 1,
    variableWidth: false,
    draggable: true,
};

interface NewsStoryProps {
    id: string;
    isLast: boolean;
    showUpdatedAt: boolean;
    onInView?: () => void;
    onDataLoad?: () => void;
    analyticsMeta: AnalyticsMeta;
    pageName?: string;
}

const NewsStory: FC<NewsStoryProps> = ({ id, isLast, showUpdatedAt, onInView, onDataLoad, analyticsMeta, pageName }) => {
    const { trackEvent } = useMatomo();
    const { xs, sm, md } = useBreakpoint();
    const [slidesToShow, setSlidesToShow] = useState<number>(0);
    const isSmallScreen = !md;
    const { data, error, isLoading } = useGetStoryById<ContentList>(id, { format: "list", count: 15 });
    const { ref, inView } = useInView({ threshold: 0, skip: !isLast || isLoading, triggerOnce: true });

    const onResize = useCallback(
        (width: number) => {
            if (width <= 1000) {
                setSlidesToShow(3);
            } else {
                setSlidesToShow(4);
            }
        },
        [setSlidesToShow]
    );
    // A Hook that listens on the outer HTML element width change
    const { ref: newsStoryRef } = useResizeDetector({
        handleHeight: false,
        handleWidth: isSmallScreen ? false : true, // don't need it on smaller screens
        refreshMode: "debounce",
        refreshRate: 300,
        onResize,
    });

    // Reports to parent component when it becomes visible
    useEffect(() => {
        if (isLast && inView && typeof onInView === "function") {
            onInView();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, isLast]);

    // Reports to parent component when it's done loading data (only if it's the last element isLast=true)
    useEffect(() => {
        if (!isLoading && !error && isLast) {
            if (data && data.content) {
                if (typeof onDataLoad === "function") {
                    onDataLoad();
                }
            } else if (data) {
                // this else statement is needed so we don't break our infinite loader
                // in case when the topic doesn't have any articles or an error has occurred
                if (typeof onInView === "function") {
                    onInView();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLast, isLoading]);

    /* If story has a videos array and have at least one */
    const hasVideo = useMemo(() => !isLoading && data && Array.isArray(data.videos) && data.videos.length > 0, [data, isLoading]);

    const storyPageURL = useMemo(() => `/story/${id}/${slugify(data?.name ?? "")}`, [data?.name, id]);

    const reportViewSummaryClick = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            action: `${pageName ? `${pageName} ` : ""}trending news spangld view summary`,
            name: data?.name || "unknown story",
        });
    }, [trackEvent, analyticsMeta.category, pageName, data?.name]);

    const reportStoryContentClick = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            action: `${pageName ? `${pageName} ` : ""}trending news content click`,
            name: data?.name || "unknown story",
        });
    }, [analyticsMeta.category, data?.name, pageName, trackEvent]);

    const reportStoryContentScroll = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            action: `${pageName ? `${pageName} ` : ""}trending news carousel scroll`,
            name: data?.name || "unknown story",
        });
    }, [analyticsMeta.category, data?.name, pageName, trackEvent]);

    const renderShareCompressed = useCallback(() => {
        return (
            <ShareCompressed
                url={`${window.location.origin}${storyPageURL}`}
                type="story"
                analyticsMeta={{ ...analyticsMeta, name: "trending news share", action: data?.name }}
            />
        );
    }, [storyPageURL, analyticsMeta, data?.name]);

    const recordStoryClickEvent = useCallback(() => {
        // Matomo analytics click Story page link
        trackEvent({
            category: analyticsMeta.category,
            name: data?.name || "unknown story",
            action: `${pageName ? `${pageName} ` : ""}trending news story page click`,
        });
    }, [analyticsMeta.category, data?.name, pageName, trackEvent]);

    const setHeadline = useCallback(
        () => (
            <div className="story-headline-container">
                <div className="story-headline">
                    <div className="category-container">
                        <Text className="category">
                            {data?.category?.toUpperCase() === "FREESPOKE VIDEO" ? "TRENDING STORY" : `${data?.category?.toUpperCase()} STORY`}
                        </Text>
                        {!xs && <div>{data?.name && renderShareCompressed()}</div>}
                    </div>

                    <Link to={storyPageURL} onClick={recordStoryClickEvent}>
                        <Title className={"story-title"} level={4} ellipsis={{ rows: 4 }}>
                            {data?.name}
                        </Title>
                    </Link>

                    {showUpdatedAt && data?.updated_at && (
                        <div className="updated-date-container">
                            <span className="updated-date-text">Updated: {toTimeAgo(data.updated_at)}</span>
                            {xs && renderShareCompressed()}
                            {/* {xs && renderShareCompressed()} */}
                        </div>
                    )}
                </div>

                {Array.isArray(data?.videos) && data?.videos[0].thumbnailURL && (
                    <div className="image-container">
                        <ContentCardV2
                            images={[data.videos[0].thumbnailURL ?? ""]}
                            footerLabel={xs ? "freespoke video" : ""}
                            title={""}
                            url={storyPageURL}
                            publisherName={""}
                            displayPlayIcon
                            useInternalLink
                            imageLoadingType="eager"
                            reportClick={reportStoryContentClick}
                        />
                    </div>
                )}
            </div>
        ),
        [data, xs, renderShareCompressed, storyPageURL, recordStoryClickEvent, showUpdatedAt, reportStoryContentClick]
    );

    const mapArticle = useCallback(
        (article: IArticle & ITweet) => {
            if (article && article != null) {
                if (article.tweet_id) {
                    return <TweetCard key={article.tweet_id} data={article} maxLines={xs ? 5 : 7} reportClick={reportStoryContentClick} />;
                }
                return sm ? (
                    <ContentCard
                        key={article.id}
                        url={article.url}
                        datePublished={article.datePublished}
                        publisherName={article.publisherName}
                        publisherIcon={article.publisherIcon}
                        publisherTypes={article.publisherTypes}
                        images={article.images}
                        bias={article.bias}
                        title={article.title}
                        reportClick={reportStoryContentClick}
                        boldHeadline
                    />
                ) : (
                    <ContentCardV2
                        key={article.id}
                        url={article.url}
                        datePublished={article.datePublished}
                        publisherName={article.publisherName}
                        publisherIcon={article.publisherIcon}
                        publisherTypes={article.publisherTypes}
                        images={article.images}
                        bias={article.bias}
                        title={article.title}
                        reportClick={reportStoryContentClick}
                        imageLoadingType="eager"
                    />
                );
            }
        },
        [reportStoryContentClick, sm, xs]
    );

    const mapMobileArticles = useCallback(() => {
        const endCard = <ViewStoryCard key="view-story-card" storyTitle={data?.name || ""} url={storyPageURL} />;

        if (Array.isArray(data?.content) && data?.content.length) {
            return [...data?.content.map(article => mapArticle(article)), endCard];
        }
    }, [data?.content, data?.name, storyPageURL, mapArticle]);

    if (error) {
        return null;
    }
    // loader
    if (isLoading || !data || (!isSmallScreen && !slidesToShow)) {
        return (
            <div className="NewsStory" ref={newsStoryRef}>
                <Card bordered={false}>
                    <Meta
                        avatar={isSmallScreen ? null : <Skeleton.Image style={{ height: "195px", width: "250px" }} />}
                        title={<Skeleton paragraph={{ rows: isSmallScreen ? 2 : 1, width: "100vw" }} />}
                        description={""}
                    />
                    {/* {!isSmallScreen && (
                        <>
                            <br /> <Divider />
                        </>
                    )} */}
                    <Card type="inner" bordered={false}>
                        <Row gutter={[16, 16]} wrap={false}>
                            <Col span={isSmallScreen ? 24 : 8}>
                                <div className="ArticleContainer" style={{ padding: "16px" }}>
                                    {isSmallScreen && <Skeleton.Image style={{ height: "136px", width: "100%", marginBottom: "16px" }} />}
                                    <Skeleton title={false} paragraph={{ rows: 2, width: "100%" }} />
                                </div>
                            </Col>
                            {!isSmallScreen && (
                                <>
                                    <Col span={8}>
                                        <div className="ArticleContainer" style={{ padding: "16px" }}>
                                            <Skeleton title={false} paragraph={{ rows: 3, width: "100%" }} />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="ArticleContainer" style={{ padding: "16px" }}>
                                            <Skeleton title={false} paragraph={{ rows: 3, width: "100%" }} />
                                        </div>
                                    </Col>
                                </>
                            )}
                        </Row>
                        {!isSmallScreen && <Skeleton title={false} paragraph={{ rows: 1, width: "25%" }} />}
                    </Card>
                </Card>
            </div>
        );
    }

    return (
        <div className={`NewsStory${hasVideo ? " Video" : ""}`} ref={ref}>
            <Card bordered={false} className="card-relative">
                {setHeadline()}
                {data.spangld && data.spangld.data && (
                    <Card.Grid hoverable={false} className="NewsStorySpangld" key="spangld-card">
                        <SpangldSummary
                            data={data.spangld.data}
                            onViewSummaryClick={reportViewSummaryClick}
                            analyticsMeta={{ ...analyticsMeta, action: `${pageName} trending news spangld take`, name: data?.name }}
                        />
                    </Card.Grid>
                )}
                {!isSmallScreen && !hasVideo && data.spangld && data.spangld.data && <Divider className="new-story-divider" />}
                {!hasVideo && (
                    <Card type="inner" bordered={false} className="story-body">
                        {!isLoading && data.content && isSmallScreen && (
                            <div className="top-three-or-all">
                                <div className="article-spacer"> {/* for edge-to-edge iOS scrolling */} </div>
                                {mapMobileArticles()}
                                <div className="article-spacer with-padding"> {/* for edge-to-edge iOS scrolling */} </div>
                            </div>
                        )}
                        {!isSmallScreen && !isLoading && data.content && (
                            <ScrollableArticles
                                items={data.content}
                                settings={{ ...NEWS_CAROUSEL_SETTINGS, slidesToShow: slidesToShow, slidesToScroll: slidesToShow }}
                                render={newsList => newsList.map(item => mapArticle(item)!)}
                                onScroll={reportStoryContentScroll}
                            />
                        )}
                        {!isLoading && (!data || !data.content || !data.content.length) && (
                            <p style={{ textAlign: "center" }}>Currently Unavailable</p>
                        )}
                    </Card>
                )}
            </Card>
        </div>
    );
};

export default React.memo(NewsStory, (prevProps, nextProps) => {
    if (prevProps.isLast !== nextProps.isLast) return false;
    return true;
});
