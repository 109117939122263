import { FC }                           from "react";
import { ArticleContent, TweetContent } from "@/api/useGetDeepDiveTopic";
import Divider                          from "@/designSystem/Divider/Divider";
import ContentCard                      from "@/molecules/ContentCard/ContentCard";
import ScrollableArticles               from "@/molecules/ScrollableArticles/ScrollableArticles";
import TweetCard                        from "@/molecules/TweetCard/TweetCard";
import "./TrendingArticles.less";

interface TrendingArticlesProps {
    articles: (ArticleContent | TweetContent)[];
    isSmallScreen?: boolean;
    onClick: (url: string, publisher: string, title: string) => void;
    onScroll: (currentSlide: number) => void;
}

const TrendingArticles: FC<TrendingArticlesProps> = ({ articles, isSmallScreen = false, onClick, onScroll }) => {
    const mapArticles = (items: (ArticleContent | TweetContent)[]) => {
        return items.map((data, i) => {
            if (data.type === "tweet") {
                return <TweetCard data={data.tweet as any} maxLines={9} key={"article-" + data.tweet?.id} reportClick={onClick} />;
            } else {
                return (
                    <ContentCard
                        key={`${data.article?.id}+${i}`}
                        title={data.article?.title || "Missing title"}
                        bias={data.article?.bias || ""}
                        datePublished={data.article?.datePublished}
                        publisherName={data.article?.publisherName || "Missing publisher name"}
                        publisherIcon={data.article?.publisherIcon}
                        publisherTypes={data.article?.publisherTypes}
                        images={data.article?.images}
                        url={data.article?.url || "#"}
                        reportClick={onClick}
                        boldHeadline
                    />
                );
            }
        });
    };

    return (
        <div className="TrendingArticles">
            <ScrollableArticles items={articles} render={items => mapArticles(items)} onScroll={onScroll} />
            {!isSmallScreen && <Divider />}
        </div>
    );
};

export default TrendingArticles;
