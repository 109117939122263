/* eslint-disable no-mixed-operators */
import { formatDistanceToNowStrict, isValid as isValidDate } from "date-fns";
import { get }                                               from "lodash-es";

export function uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
}

export function getSessionId() {
    const sessionIdInLocalStorage = localStorage.getItem("sessionId");
    // if there is no sessionId - let's create one
    if (!sessionIdInLocalStorage) {
        const sessionId = uuidv4();
        setSessionID(sessionId);
        return sessionId;
    }
    return sessionIdInLocalStorage;
}

export function setSessionID(id) {
    localStorage.setItem("sessionId", id);
}

export function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
}

export function isMobileWidth() {
    return window.innerWidth < 641;
}

export function isTabletWidth() {
    return window.innerWidth <= 1025;
}

export function isModerator(user) {
    return get(user, "app_metadata.roles") && user.app_metadata.roles.includes("moderator");
}

export function getQueryParamsFromUrl(queryString: string) {
    const params = new URLSearchParams(queryString);
    return {
        q: params.get("q"),
        page: parseInt(params.get("page") || "1"),
        bias: params.get("bias"),
    };
}

export function scrollToTop(options?: ScrollOptions) {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: options?.behavior || "smooth",
    });
}

export function isValidEmail(email) {
    const re = new RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
    );
    return re.test(email);
}

export function toTimeAgo(date: string, short?: boolean) {
    const dateObj = new Date(date);
    if (!isValidDate(dateObj)) {
        return "invalid date";
    }
    let formattedDistance = formatDistanceToNowStrict(dateObj, { addSuffix: true });

    // Removes 'in' keyword from future dates (ex: "in 20 minutes" becomes "20 minutes" )
    if (formattedDistance.startsWith("in")) {
        formattedDistance = formattedDistance.slice(3);
    }

    if (short) {
        const distanceSplit = formattedDistance.split(" ");
        // "2 months ago" becomes "2mo ago"
        return `${distanceSplit[0]}${distanceSplit[1].substr(0, distanceSplit[1].includes("month") ? 2 : 1)} ${
            distanceSplit[2] ? distanceSplit[2] : ""
        }`;
    }
    return formattedDistance;
}

const queryParam = (label: string, value: string) => (value ? `&${label}=${encodeURIComponent(value)}` : "");

// URL schemes taken from https://github.com/bradvin/social-share-urls
export const createShareUrl = {
    facebook: (url: string) => `http://www.facebook.com/sharer.php?u=${url}`,
    twitter: (url: string, options?: Partial<{ text: string; via: string; hashtags: string[] }>) =>
        `https://twitter.com/intent/tweet?url=${url}${queryParam("text", options?.text || "")}${queryParam("via", options?.via || "")}${queryParam(
            "hashtags",
            options?.hashtags?.join(",") || ""
        )}`,
    email: (options?: Partial<{ to: string; subject: string; body: string }>) =>
        `mailto:${options?.to ? options.to : ""}?${queryParam("subject", options?.subject || "")}${queryParam("body", options?.body || "")}`,
    linkedin: (url: string) => `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    reddit: (url: string, options?: Partial<{ title: string }>) => `https://reddit.com/submit?url=${url}${queryParam("title", options?.title || "")}`,
};

/* 
    Removes 'https://t.co/..' URLs from text
    Converts this text: "Louisiana probing facility where four nursing home residents died after Ida evacuation https://t.co/4YTYuOForz https://t.co/ZgTMt9MjEb"
    To this: "Louisiana probing facility where four nursing home residents died after Ida evacuation"
*/
export function removeTweetURLs(text: string) {
    if (typeof text === "string") {
        return text.replace(/https:\/\/t.co\S+/g, "").trim();
    }
    return text;
}

/**
 * Dash Class Builder
 * @param className Base class name
 * @param dash Dash class(s).
 * @example dash("Product", "small") => "Product Product--small"
 * @example dash("Product", someBooleanExpression && "small") => "Product Product--small" or "Product"
 * @example dash("Product", ["small", someBooleanExpression && "fullWidth"]) => "Product Product--small" or "Product Product--small Product--fullWidth"
 * @returns Base class plus dash classes
 */
export const dash: (className: string, dash?: boolean | string | Array<boolean | string>) => string = (className, dash) =>
    (d => [className, ...d.map(item => (typeof item === "boolean" ? "" : `${className}--${item}`))].join(" "))(Array.isArray(dash) ? dash : [dash]);

const USDFormat = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const toUSD = (price: number) => USDFormat.format(price);

export const toTitleCase = (str?: string) =>
    str
        ?.replace(/\w\S*/g, text => capitalizeFirstLetter(text))
        .split("/")
        .map(str => str && capitalizeFirstLetter(str))
        .join("/") || str;

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export function stripHTML(html: string) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
}
