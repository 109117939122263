import { FC, useCallback, useMemo } from "react";
import { useMatomo }                from "@datapunt/matomo-tracker-react";
import { useLocation }              from "react-router";
import { getAnalyticsCategory }     from "@/analytics/analytics";
import { slugify }                  from "@/components/utils/slugify";
import Card                         from "@/designSystem/Card/Card";
import Link                         from "@/designSystem/Link/Link";
import Title                        from "@/designSystem/Title/Title";
import "./TopicsCard.less";

interface TopicsCardProps {
    data: {
        id?: string;
        name?: string;
    }[];
}
const TopicsCard: FC<TopicsCardProps> = ({ data }) => {
    const { trackEvent } = useMatomo();
    const location = useLocation();

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const reportClickOnTopic = useCallback(
        (topicName: string) => () => {
            trackEvent({
                category: analyticsMeta.category,
                name: "top freespoke topic click ",
                action: topicName,
            });
        },
        [analyticsMeta.category, trackEvent]
    );

    return (
        <Card className="TopicsCard" bodyStyle={{ padding: 16 }}>
            <div className="red-line"></div>
            <Title level={4} className="title">
                FREESPOKE TOPICS - <i>SHOWING WHAT GOOGLE WON'T</i>
            </Title>
            {data?.length > 0 &&
                data.map((topic, i) => (
                    <Link
                        key={topic.id}
                        to={`/search/topic/${topic.id}/${slugify(topic.name || "")}`}
                        className="topic"
                        onClick={reportClickOnTopic(topic.name || "")}
                    >
                        <h6 className="circle">{i + 1}</h6>
                        <Title level={4} ellipsis={{ rows: 1 }} className="title topic-name">
                            {topic.name}
                        </Title>
                    </Link>
                ))}
        </Card>
    );
};

export default TopicsCard;
