import { FunctionComponent, useEffect, useState } from "react";
import { animated, config, useSpring }            from "react-spring";
import Button                                     from "@/designSystem/Button/Button";
import ReloadIcon                                 from "@/designSystem/Icons/ReloadOutlined";
import UpCircleOutlined                           from "@/designSystem/Icons/UpCircleOutlined";
import "./Notification.less";

interface Props {
    text: string;
    newItemsCount: number;
    sticky?: boolean;
    onClick: () => void;
}

const Notification: FunctionComponent<Props> = ({ text, newItemsCount, sticky, onClick }) => {
    // the reason why I used useState with useEffect
    // is to prevent user seeing "New Stories (0)" right after they click on this notification
    // I could not find another way to prevent it.
    const [count, setCount] = useState(() => newItemsCount);

    useEffect(() => {
        if (!count || newItemsCount) {
            setCount(newItemsCount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newItemsCount]);

    const props = useSpring({
        overflow: "hidden",
        transform: newItemsCount ? "translateY(0)" : "translateY(150%)",
        opacity: newItemsCount ? "1" : "0",
        height: newItemsCount ? "100%" : "0",
        delay: 200,
        config: config.default,
        onDelayEnd: () => setCount(newItemsCount),
    });

    const icon = sticky ? <UpCircleOutlined /> : <ReloadIcon className="refresh-icon" />;
    const styles: React.CSSProperties = sticky
        ? { position: "fixed", bottom: 16, right: 8, height: "40px", zIndex: 999 }
        : { height: "32px", overflow: "hidden" };

    return (
        <div className="Notification" style={styles}>
            <animated.div style={props}>
                <Button className="notification-body" block type="primary" onClick={onClick} icon={icon}>
                    <span>{`${text} (${count})`} </span>
                </Button>
            </animated.div>
        </div>
    );
};

export default Notification;
