import { FC, memo, useEffect, useRef, useState } from "react";
import useBreakpoint                             from "antd/lib/grid/hooks/useBreakpoint";
import Link                                      from "@/designSystem/Link/Link";
import Paragraph                                 from "@/designSystem/Paragraph/Paragraph";
import Title                                     from "@/designSystem/Title/Title";
import NewsSearchResult                          from "@/molecules/NewsSearchResult/NewsSearchResult";
import { TopicVersions }                         from "@/pages/TopicsV2/TopicsV2Data";
import liberty_torch                             from "@/static/icons/freespoke_torch.svg";
import loader_torch                              from "@/static/images/freespoke_loader_torch.gif";
import "./FreespokeLoader.less";

export interface FreespokeLoaderProps {}

const FreespokeLoader: FC<FreespokeLoaderProps> = () => {
    const [isStillLoading, setIsStillLoading] = useState(false);
    const [isFallbackPresent, setIsFallbackPresent] = useState(false);
    const isMounted = useRef(true);

    const { xs } = useBreakpoint();
    const screenSize = xs ? "small" : "large";

    useEffect(() => {
        setTimeout(function () {
            if (isMounted.current) {
                setIsStillLoading(true);
            }
        }, 5000);

        setTimeout(function () {
            if (isMounted.current) {
                setIsFallbackPresent(true);
            }
        }, 30000);

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div className="FreespokeLoader">
            {!isFallbackPresent ? (
                <div className="loading-container">
                    <img src={loader_torch} alt="freespoke loading torch" className="loading-img" />
                    <Title className="loading-text">{isStillLoading ? "Still loading..." : "Loading..."}</Title>
                </div>
            ) : (
                <div className="fallback-container">
                    <div className="fallback-header">
                        <div className="inner-container">
                            <Title className="fallback-title" level={1}>
                                Maybe... We’re more popular than We Thought
                            </Title>
                            <Paragraph className="fallback-text">
                                Our site is experiencing a lot of traffic right now, and causing some delays. Try reloading your page in a second.
                            </Paragraph>
                            <Link href={window.location.href} className="reload-link">
                                Reload Page
                            </Link>
                            <Paragraph className="fallback-text mb-0">
                                P.S. - You can check out some of our recommended reading while you wait.
                            </Paragraph>
                        </div>
                    </div>
                    <div className="fallback-body">
                        <div className="fallback-about-container">
                            <img src={liberty_torch} alt="freespoke liberty torch" className="about-torch-icon" />
                            <Paragraph className="about-text">
                                Freespoke is the new frontier for those people looking to search with America’s founding principles of innovation,
                                freedom and anti-censorship. We know you’re capable of finding answers, you just need a platform that doesn’t bury the
                                full view.
                            </Paragraph>
                            <Link to="/about" className="about-link">
                                Learn more about Freespoke
                            </Link>
                        </div>

                        <div className="fallback-news-container">
                            <Title className="fallback-news-title" level={2}>
                                {TopicVersions[0].topicHighlights[0].title}
                            </Title>
                            {TopicVersions[0].topicHighlights[0].newsArticles.slice(0, 2).map((article, i) => (
                                <div key={article.id}>
                                    <NewsSearchResult
                                        article={article}
                                        size={screenSize}
                                        showUrl
                                        analyticsMeta={{ action: "topic 2 Response highlights click" }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(FreespokeLoader);
