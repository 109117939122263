import { Children, FC, ReactNode, useState }       from "react";
import { useCallback }                             from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Collapse }                                from "antd";
import clsx                                        from "clsx";
import "./ExpandableContainer.less";

const { Panel } = Collapse;

interface ExpandableContainerProps {
    children: ReactNode[];
    className?: string;
    panelKey: string | number;
    compactSize: number;
    compactBtnText: string;
    expandedBtnText: string;
}

const ExpandableContainer: FC<ExpandableContainerProps> = ({ children, className, compactSize, compactBtnText, expandedBtnText, panelKey }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = useCallback(() => {
        setIsExpanded(prev => !prev);
    }, []);

    return (
        <div className={clsx("ExpandableContainer", className)}>
            {children.slice(0, compactSize)}
            {Children.count(children) > compactSize && (
                <Collapse
                    collapsible="disabled"
                    className="show-more"
                    destroyInactivePanel={true}
                    ghost
                    expandIconPosition="right"
                    onChange={handleClick}
                >
                    <Panel
                        collapsible="header"
                        showArrow={false}
                        header={
                            isExpanded ? (
                                <span className="header-text">
                                    <MinusCircleOutlined /> {expandedBtnText}
                                </span>
                            ) : (
                                <span className="header-text">
                                    <PlusCircleOutlined />
                                    {compactBtnText}
                                </span>
                            )
                        }
                        key={panelKey}
                    >
                        {children.slice(compactSize)}
                    </Panel>
                </Collapse>
            )}
        </div>
    );
};

export default ExpandableContainer;
