import { FC, useCallback } from "react";
import { useMatomo }       from "@datapunt/matomo-tracker-react";
import Link                from "@/designSystem/Link/Link";
import Text                from "@/designSystem/Text/Text";
import { dash }            from "@/helpers/index";
import "./FeaturedBrand.less";

export interface FeaturedBrandProps {
    layout: "vertical" | "horizontal";
    img: string;
    alt?: string;
    title: string;
    description: string;
    href: string;
}

const FeaturedBrand: FC<FeaturedBrandProps> = ({ layout, img, alt, title, description, href }) => {
    const { trackEvent } = useMatomo();

    const onClick = useCallback(() => {
        // Track featured product click
        trackEvent({
            category: "shop us",
            name: "featured product click",
            action: title,
        });
    }, [trackEvent, title]);

    return (
        <Link className={dash("FeaturedBrand", layout)} href={href} onClick={onClick}>
            <div className="cover">
                <img src={img} alt={alt || title} className="image" />
            </div>
            <div className="info">
                <Text className="title">{title}</Text>
                <Text className="description">{description}</Text>
            </div>
        </Link>
    );
};

export default FeaturedBrand;
