import { FC, memo, useCallback, useMemo } from "react";
import { useMatomo }                      from "@datapunt/matomo-tracker-react";
import { Avatar }                         from "antd";
import { useLocation }                    from "react-router-dom";
import { AnalyticsMeta }                  from "src/@types/custom";
import { getAnalyticsCategory }           from "@/analytics/analytics";
import ImageLoader                        from "@/components/utils/ImageLoader";
import { slugify }                        from "@/components/utils/slugify";
import Card                               from "@/designSystem/Card/Card";
import Divider                            from "@/designSystem/Divider/Divider";
import Link                               from "@/designSystem/Link/Link";
import Text                               from "@/designSystem/Text/Text";
import Title                              from "@/designSystem/Title/Title";
import { toTimeAgo }                      from "@/helpers/index";
import { ReactComponent as LeftIcon }     from "@/static/icons/summary-card-left-icon.svg";
import { ReactComponent as RightIcon }    from "@/static/icons/summary-card-right-icon.svg";
import "./StoryPreviewCard.less";

export interface StoryPreviewProps {
    id: string; // story id, used to construct the URL where user will be navigated if they click the story preview card
    title: string; // title of the story
    updatedAt: string; // date will be in ISO string format
    leftBiasCount: number;
    rightBiasCount: number;
    content:
        | {
              title: string; // item title, limit title to max of 3 lines using ellipsis.
              publisherName: string;
              publisherIcon: string;
              images: string[]; // 1 or more image URL, use <ImageLoader/> component for displaying images
          }[]
        | undefined;
    analyticsMeta?: AnalyticsMeta;
}

const StoryPreviewCard: FC<StoryPreviewProps> = ({ id, title, content, leftBiasCount, rightBiasCount, updatedAt, analyticsMeta }) => {
    const location = useLocation();
    const { trackEvent } = useMatomo();

    // Get analytics category name - 'home', 'freespoke topic' etc
    const analytics = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const reportClick = useCallback(() => {
        trackEvent({
            category: analytics.category,
            name: title,
            action: analyticsMeta?.action || "related stories click",
        });
    }, [analytics.category, analyticsMeta?.action, title, trackEvent]);

    const url = useMemo(() => `/story/${id}/${slugify(title)}`, [id, title]);

    return (
        <Link to={url} className="StoryPreviewCard" onClick={reportClick}>
            <Card className="card-container">
                <Title className="title heading" ellipsis={{ rows: 4 }}>
                    {title}
                </Title>
                <div className="bias-container">
                    <div className="left">
                        <Text>({leftBiasCount})</Text>
                        <LeftIcon />
                        <Text>LEFT</Text>
                    </div>
                    <div className="right">
                        <Text>({rightBiasCount})</Text>
                        <RightIcon />
                        <Text>RIGHT</Text>
                    </div>
                    <div>
                        <Text className="updated-since">{toTimeAgo(updatedAt, true)}</Text>
                    </div>
                </div>
                <Divider />
                {Array.isArray(content) && content.length > 0 && (
                    <div className="items-container">
                        {content.map((item, i) => (
                            <div className="item" key={i}>
                                <ImageLoader srcList={item.images} alt={item.title || "missing title"} className="image">
                                    {!!item.publisherIcon && <Avatar className="publisher-logo" size={25} src={item.publisherIcon} />}
                                </ImageLoader>
                                <div>
                                    <Text className="title">{item.title}</Text>
                                    <Text className="publisher-name">{item.publisherName}</Text>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Card>
        </Link>
    );
};

export default memo(StoryPreviewCard);
