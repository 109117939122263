import { FC, useCallback, useEffect }                                       from "react";
import { useMatomo }                                                        from "@datapunt/matomo-tracker-react";
import useBreakpoint                                                        from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet }                                                           from "react-helmet-async";
import { useParams }                                                        from "react-router-dom";
import useGetDeepDiveTopic                                                  from "@/api/useGetDeepDiveTopic";
import { slugify }                                                          from "@/components/utils/slugify";
import Button                                                               from "@/designSystem/Button/Button";
import Divider                                                              from "@/designSystem/Divider/Divider";
import { Col, Row }                                                         from "@/designSystem/Grid/Grid";
import CustomSearch                                                         from "@/designSystem/Icons/CustomSearch";
import Link                                                                 from "@/designSystem/Link/Link";
import Text                                                                 from "@/designSystem/Text/Text";
import Title                                                                from "@/designSystem/Title/Title";
import ErrorResult                                                          from "@/molecules/ErrorResult/ErrorResult";
import FreespokeLoader                                                      from "@/molecules/FreespokeLoader/FreespokeLoader";
import RelatedResults                                                       from "@/molecules/RelatedResults/RelatedResults";
import SpangldSummary                                                       from "@/molecules/SpangldSummary/SpangldSummary";
import { DEFAULT_OGP_IMAGE_ABSOLUTE_PATH, TWITTER_OGP_IMAGE_ABSOLUTE_PATH } from "@/ogp/ogp";
import AnsoContainer                                                        from "@/organisms/AnsoContainer/AnsoContainer";
import AppDownloadBanner                                                    from "@/organisms/AppDownloadBanner/AppDownloadBanner";
import NewsSnapshots                                                        from "@/organisms/NewsSnapshots/NewsSnapshots";
import OtherContent                                                         from "@/organisms/OtherContent/OtherContent";
import TrendingArticles                                                     from "@/organisms/TrendingArticles/TrendingArticles";
import "./TopicSearch.less";

const analyticsMeta = { category: "freespoke topic 1.0" } as const;

interface TopicSearchProps {}
interface TopicSearchParam {
    topic_id: string;
}

const TopicSearch: FC<TopicSearchProps> = () => {
    const { lg } = useBreakpoint();
    const { topic_id } = useParams<TopicSearchParam>();
    const { data, isLoading, error } = useGetDeepDiveTopic(topic_id);
    const { trackPageView, trackEvent } = useMatomo();

    useEffect(() => {
        // @ts-ignore
        trackPageView();
    }, [topic_id, trackPageView]);

    const reportClickOnNewsCarouselContent = useCallback(
        (url: string, publisher: string, title: string) => {
            trackEvent({
                category: "freespoke topic 1.0",
                name: title || url,
                action: "topic 1 news carousel content click",
            });
        },
        [trackEvent]
    );

    const reportNewsCarouselScroll = useCallback(
        (_currentSlide: number) => {
            trackEvent({
                category: "freespoke topic 1.0",
                name: "trending news",
                action: "topic 1 topic news carousel article scroll",
            });
        },
        [trackEvent]
    );

    const reportClickOnViewSummary = useCallback(
        (url: string, title: string) => {
            trackEvent({
                category: "freespoke topic 1.0",
                name: title,
                action: "topic 1 spangld view summary",
            });
        },
        [trackEvent]
    );

    const reportClickOnOtherContent = useCallback(
        (url: string, type: "article" | "tweet", title: string) => {
            trackEvent({
                category: "freespoke topic 1.0",
                name: title || url,
                action: `topic 1 top results ${type} click (curated links)`,
            });
        },
        [trackEvent]
    );

    const reportClickOnSearchMoreButton = useCallback(
        (topic: string) => {
            trackEvent({
                category: "freespoke topic 1.0",
                name: topic,
                action: "topic 1 search more on this topic",
            });
        },
        [trackEvent]
    );

    if (error) {
        return <ErrorResult error={error} containerClassName="CategoriesSearch" />;
    }

    return (
        <div className="TopicSearch">
            {data && data.topic && (
                <>
                    {/*<Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/search/categories">Topic categories</Link>
                        </Breadcrumb.Item>
                        {Array.isArray(data.topic.linked_categories) && data.topic.linked_categories.length > 0 && (
                            <Breadcrumb.Item>
                                <Link
                                    to={`/search/categories/${data.topic.linked_categories[0].id}/${slugify(data.topic.linked_categories[0].name)}`}>
                                    {data.topic.linked_categories[0].name}
                                </Link>
                            </Breadcrumb.Item>
                        )}
                        <Breadcrumb.Item>{data.topic.name}</Breadcrumb.Item>
                    </Breadcrumb>
                    */}
                    <div className="TopicSearchTitle">
                        <Text>Showing {data.total} results for</Text>
                        <Title level={3}>{data.topic.name}</Title>
                    </div>
                    <Helmet>
                        <link rel="canonical" href={`https://freespoke.com/search/topic/${topic_id}/${slugify(data.topic.name)}`} />
                        <title>{`${data.topic.name} - Freespoke`}</title>
                        <meta property="og:title" content={data.topic.name} />
                        <meta property="og:type" content="article" />
                        <meta property="og:url" content={`${window.location.origin}${window.location.pathname}`} />
                        <meta property="og:image" content={DEFAULT_OGP_IMAGE_ABSOLUTE_PATH} />
                        <meta property="twitter:card" content="summary" />
                        <meta property="twitter:title" content={data.topic.name} />
                        <meta property="twitter:image" content={TWITTER_OGP_IMAGE_ABSOLUTE_PATH} />
                    </Helmet>
                </>
            )}
            {isLoading && (
                <div className="TopicSearchSpin">
                    <FreespokeLoader />
                </div>
            )}
            {lg && !isLoading && data && data.topic && Array.isArray(data.topic.news) && data.topic.news.length > 0 && (
                <TrendingArticles
                    articles={data.topic.news}
                    isSmallScreen={false}
                    onClick={reportClickOnNewsCarouselContent}
                    onScroll={reportNewsCarouselScroll}
                />
            )}
            {!isLoading && topic_id && data && data.topic && (
                <Row gutter={[{ lg: 32, md: 24, sm: 16 }, 0]}>
                    <Col className="topic-search-main" xs={24} md={24} lg={16}>
                        {lg && (
                            <Title className="top-results" level={3}>
                                Top results
                            </Title>
                        )}
                        {/* only on small screens */}
                        {!lg && Array.isArray(data.topic.news) && data.topic.news.length > 0 && (
                            <TrendingArticles
                                key={"news"}
                                articles={data.topic.news}
                                isSmallScreen={true}
                                onClick={reportClickOnNewsCarouselContent}
                                onScroll={reportNewsCarouselScroll}
                            />
                        )}
                        {!lg && (
                            <Title className="top-results" level={3}>
                                Top results
                            </Title>
                        )}
                        {/* large + small screen */}
                        {data.topic.spangld && Array.isArray(data.topic.spangld) && data.topic.spangld.length > 0 && data.topic.spangld[0].article && (
                            <div className="SpangldSection">
                                <SpangldSummary
                                    data={{
                                        id: data.topic.spangld[0].id,
                                        title: data.topic.spangld[0].article?.title || "",
                                        url: data.topic.spangld[0].article?.url || "",
                                        images: data.topic.spangld[0].article?.images || [],
                                        textSummary: data.topic.spangld[0].textSummary || "",
                                        rawHTML: data.topic.spangld[0].rawHTML || "",
                                    }}
                                    analyticsMeta={{
                                        ...analyticsMeta,
                                        action: "topic 1 spangld take",
                                        name: data.topic.spangld[0].article?.title,
                                    }}
                                    onViewSummaryClick={reportClickOnViewSummary}
                                />
                            </div>
                        )}
                        {!lg && Array.isArray(data.topic.related_anso) && data.topic.related_anso.length > 0 && (
                            <AnsoContainer articles={data.topic.related_anso} />
                        )}
                        {/* 1-3 results */}
                        {Array.isArray(data.topic.other_content) && data.topic.other_content.length > 0 && (
                            <OtherContent
                                key={"other_content_1-3"}
                                articles={data.topic.other_content.slice(0, 3)}
                                onClick={reportClickOnOtherContent}
                            />
                        )}
                        {Array.isArray(data.topic.related_stories) && data.topic.related_stories.length > 0 && (
                            <NewsSnapshots
                                key={"related_stories"}
                                topics={data.topic.related_stories}
                                analyticsMeta={analyticsMeta}
                                pageName="topic 1"
                            />
                        )}

                        {!isLoading && <AppDownloadBanner className="app-banner" />}

                        {/* 4-6 results */}
                        {Array.isArray(data.topic.other_content) && data.topic.other_content.length > 3 && (
                            <OtherContent
                                key={"other_content_4_6"}
                                articles={data.topic.other_content.slice(3, 6)}
                                onClick={reportClickOnOtherContent}
                            />
                        )}
                        {Array.isArray(data.topic.related_deep_dive_topics) && data.topic.related_deep_dive_topics.length > 0 && (
                            <RelatedResults topics={data.topic.related_deep_dive_topics.map(({ name, id }) => ({ topicId: id, topic: name }))} />
                        )}
                        {/* 7-to no limit results */}
                        {Array.isArray(data.topic.other_content) && data.topic.other_content.length > 6 && (
                            <OtherContent
                                key={"other_content_7-no_limit"}
                                articles={data.topic.other_content.slice(6)}
                                onClick={reportClickOnOtherContent}
                            />
                        )}
                        {!isLoading && data && data.topic && (
                            <div className="SearchMoreButton">
                                <Button className="search-more-btn" type="link" block>
                                    <Link
                                        to={`/search/web?${new URLSearchParams({ q: data.topic.name })}`}
                                        onClick={() => reportClickOnSearchMoreButton(data.topic.name)}
                                    >
                                        <CustomSearch />
                                        Search More on this Topic
                                    </Link>
                                </Button>
                            </div>
                        )}
                    </Col>
                    {lg && (
                        <Col lg={1} flex={"0 0 1px"}>
                            <Divider type="vertical" style={{ color: "#e1e5eb", height: "100%", margin: "0" }} />
                        </Col>
                    )}
                    {lg && data && data.topic && (
                        <Col xs={24} md={24} lg={7} style={{ paddingRight: 0 }}>
                            <div className="topic-search-col">
                                {Array.isArray(data.topic.related_anso) && data.topic.related_anso.length > 0 && (
                                    <AnsoContainer articles={data.topic.related_anso} />
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            )}
        </div>
    );
};

export default TopicSearch;
