export type getAnalyticsCategoryResponse =
    | "freespoke topic"
    | "freespoke topic 2.0"
    | "freespoke topics categories"
    | "search results page"
    | "story page"
    | "best of web"
    | "censored stories"
    | "about us"
    | "home"
    | "join"
    | "whats hot"
    | string;

export function getAnalyticsCategory(pathname: string): getAnalyticsCategoryResponse {
    if (pathname.startsWith("/search/topic")) {
        return "freespoke topic";
    } else if (pathname.startsWith("/topic")) {
        return "freespoke topic 2.0";
    } else if (pathname.startsWith("/search/categories")) {
        return "freespoke topics categories";
    } else if (pathname.startsWith("/search/web") || pathname.startsWith("/search/news") || pathname.startsWith("/search/images")) {
        return "search results page";
    } else if (pathname.startsWith("/story")) {
        return "story page";
    } else if (pathname.startsWith("/category/best-of-the-web")) {
        return "best of web";
    } else if (pathname.startsWith("/category/censored-stories")) {
        return "censored stories";
    } else if (pathname.startsWith("/about")) {
        return "about us";
    } else if (pathname.startsWith("/join")) {
        return "join";
    } else if (pathname.startsWith("/what-is-hot")) {
        return "whats hot";
    } else if (pathname === "/") {
        return "home";
    } else {
        return `unknown pathname: ${pathname}`;
    }
}
