import { FC, useCallback, useMemo } from "react";
import { useMatomo }                from "@datapunt/matomo-tracker-react";
import useBreakpoint                from "antd/lib/grid/hooks/useBreakpoint";
import { useLocation }              from "react-router";
import { getAnalyticsCategory }     from "@/analytics/analytics";
import ImageLoader                  from "@/components/utils/ImageLoader";
import Card                         from "@/designSystem/Card/Card";
import Divider                      from "@/designSystem/Divider/Divider";
import Link                         from "@/designSystem/Link/Link";
import Paragraph                    from "@/designSystem/Paragraph/Paragraph";
import Title                        from "@/designSystem/Title/Title";
import "./WikiCard.less";

interface WikiCardProps {
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    source?: string;
}
const WikiCard: FC<WikiCardProps> = ({ title, description, image, url, source }) => {
    const { lg } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const location = useLocation();

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const sourceName = useMemo(() => {
        if (source) return source;
        // fallback - use url domain as source name
        let hostname = "";
        try {
            const urlObj = new URL(url || "");
            hostname = urlObj.hostname;
        } catch (e) {
            console.error("Wikicard received invalid or empty URL:", url);
        }
        return hostname?.charAt(0).toUpperCase() + hostname.slice(1);
    }, [url, source]);

    const reportClickOnSource = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            name: "wiki box",
            action: sourceName,
        });
    }, [analyticsMeta.category, sourceName, trackEvent]);

    const reportClickOnSeeMore = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            name: "wiki box see more",
            action: "click",
        });
    }, [analyticsMeta.category, trackEvent]);

    return (
        <Card className="WikiCard" bodyStyle={{ padding: 16 }}>
            <div className="wrapper">
                {image && <ImageLoader className="image" placeholder={false} src={image} alt="Wiki Card Image" />}
                <Title level={3} ellipsis={{ rows: 3 }} className="title">
                    {title}
                </Title>
            </div>
            <Paragraph
                ellipsis={{
                    rows: lg ? 9 : 4,
                    expandable: true,
                    symbol: <span onClick={reportClickOnSeeMore}>See more</span>,
                    tooltip: false,
                }}
                className="description"
            >
                {description}
            </Paragraph>
            <Divider className="divider" />
            <div className="footer">
                See more on
                <Link href={url || "#"} onClick={reportClickOnSource}>
                    {" "}
                    {sourceName}
                </Link>
            </div>
        </Card>
    );
};

export default WikiCard;
