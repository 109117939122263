import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMatomo }                                                       from "@datapunt/matomo-tracker-react";
import useBreakpoint                                                       from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                                                from "clsx";
import { animated, useSpring }                                             from "react-spring";
import useGetCategoriesAndTopics, { Category }                             from "@/api/useGetCategoriesAndTopics";
import { NoticeOfCollection }                                              from "@/components/legal";
import { slugify }                                                         from "@/components/utils/slugify";
import Button                                                              from "@/designSystem/Button/Button";
import Link                                                                from "@/designSystem/Link/Link";
import Text                                                                from "@/designSystem/Text/Text";
import { useAppBar }                                                       from "@/hooks/useAppBar";
import useLockBodyScroll                                                   from "@/hooks/useLockBodyScroll";
import { ReactComponent as ChevronLeft }                                   from "@/static/icons/chevron-left.svg";
import { ReactComponent as ChevronRight }                                  from "@/static/icons/chevron-right.svg";
import { ReactComponent as Facebook }                                      from "@/static/icons/facebook-outlined.svg";
import { ReactComponent as Instagram }                                     from "@/static/icons/instagram-outlined.svg";
import { ReactComponent as LinkedIn }                                      from "@/static/icons/linkedin-outlined.svg";
import { ReactComponent as Twitter }                                       from "@/static/icons/twitter-outlined.svg";
import Sunset                                                              from "@/static/images/sunset.png";
import FreespokeLoader                                                     from "../FreespokeLoader/FreespokeLoader";
import "./AppBarMenu.less";

interface AppBarMenuProps {
    className?: string;
}

interface HeaderProps {
    title: string;
    to?: string;
    onClick?: VoidFunction;
}

// prettier-ignore
// eslint-disable-next-line no-unused-vars
enum DesktopColumnEnum { left, middle, right }

type DesktopColumns = {
    // eslint-disable-next-line no-unused-vars
    [key in keyof typeof DesktopColumnEnum]: {
        total: number;
        categories: Array<Category>;
    };
};

const defaultDesktopColumns: DesktopColumns = {
    left: {
        total: 0,
        categories: [],
    },
    middle: {
        total: 0,
        categories: [],
    },
    right: {
        total: 0,
        categories: [],
    },
};

const featureLinks = [
    {
        label: "Trending News",
        to: {
            pathname: "/",
            state: {
                autoScroll: true,
            },
        },
    },
    { label: "Shop USA", to: "/products" },
    { label: "Censored Stories", to: "/category/censored-stories" },
    { label: "Freespoke Topics", to: "/search/categories" },
    { label: "Best of the Web", to: "/category/best-of-the-web" },
    { label: "About Us", to: "/about" },
    { label: "Contact Us", href: "https://freespoke-support.freshdesk.com/support/tickets/new" },
];

const Header: FC<HeaderProps> = ({ children, title, to, onClick }) => (
    <div className="header">
        {to ? (
            <Link className="title" to={to} onClick={onClick}>
                {title}
            </Link>
        ) : (
            <Text className="title">{title}</Text>
        )}
        {children && <Text className="snippet">{children}</Text>}
        <div className={clsx("flare", !children && "flare-gap")}></div>
    </div>
);

interface SocialProps {
    onClick: (type: "facebook" | "twitter" | "linkedin" | "instagram") => void;
}
const Social: FC<SocialProps> = ({ onClick }) => (
    <div className="social">
        <Link className="circle" href="https://www.facebook.com/FreespokeSearch" onClick={() => onClick("facebook")}>
            <Facebook />
        </Link>
        <Link className="circle" href="https://twitter.com/FreespokeSearch" onClick={() => onClick("twitter")}>
            <Twitter />
        </Link>
        <Link className="circle" href="https://www.linkedin.com/company/freespoke-search/" onClick={() => onClick("linkedin")}>
            <LinkedIn />
        </Link>
        <Link className="circle" href="https://www.instagram.com/freespokesearch/" onClick={() => onClick("instagram")}>
            <Instagram />
        </Link>
    </div>
);

const AppBarMenu: FC<AppBarMenuProps> = () => {
    const { lg: isLarge } = useBreakpoint();
    const { data, isLoading } = useGetCategoriesAndTopics();
    const [drawerCategory, setDrawerCategory] = useState<string>("");
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [desktopColumns, setDesktopColumns] = useState<DesktopColumns>(defaultDesktopColumns);
    const { showHamburger, closeHamburger } = useAppBar();
    const { trackEvent } = useMatomo();
    const ref = useRef<HTMLDivElement>(null);

    const displayCTA = useMemo(() => {
        if (localStorage.getItem("--app:display_shop_usa_cta") === "false") {
            return false;
        }
        return true;
    }, []);

    // Prevent body from scrolling when menu is open
    useLockBodyScroll(!!showHamburger);

    const closeDrawer = useCallback(() => setDrawerOpen(false), []);

    // Force close drawer if screen changes from small to large
    useEffect(() => {
        if (isLarge) {
            closeDrawer();
        }
    }, [isLarge, closeDrawer]);

    // Prevent menu from scrolling when drawer is open
    useEffect(() => {
        if (drawerOpen && ref.current) {
            ref.current.scrollTop = 0;
        }
    }, [drawerOpen]);

    // Handle placing categories in desktop columns dynamically
    useEffect(() => {
        // Make sure we have data to work with before we proceed
        if (data && data.categories && data.categories.length > 0) {
            // Reset desktopColumns to default
            setDesktopColumns(defaultDesktopColumns);

            // Force push the first category into the first column
            desktopColumns.left.categories.push(data.categories[0]);
            desktopColumns.left.total = data.categories[0].topics?.length || 0;

            // Verify we have another category to force push
            if (data.categories.length > 1) {
                // Force push the last category into the last column
                desktopColumns.right.categories.push(data.categories[data.categories.length - 1]);
                desktopColumns.right.total = data.categories[data.categories.length - 1].topics?.length || 0;
            }

            // Verify we have additional categories to dynamically map
            if (data.categories.length > 2) {
                // Iterate through remaining categories and determine their column
                data.categories.forEach((category, index, arr) => {
                    // Since we force pushed the first and last result, ignore them in this loop
                    if (index !== 0 && index !== arr.length - 1 && category.topics) {
                        // Determine which column is currently the smallest
                        const smallestColumnKey = Object.keys(desktopColumns).reduce((previous, current) =>
                            desktopColumns[current].total < desktopColumns[previous].total ? current : previous
                        ) as keyof typeof DesktopColumnEnum;

                        // Push this category to the smallest column
                        desktopColumns[smallestColumnKey].categories.push(category);
                        desktopColumns[smallestColumnKey].total += category.topics.length;
                    }
                });
            }
        }
    }, [data, desktopColumns]);

    const closeAndRecord = useCallback(
        (route: string) => {
            closeHamburger && closeHamburger();
        },
        [closeHamburger]
    );

    const openDrawer = (category: string) => {
        setDrawerCategory(category);
        setDrawerOpen(true);
    };

    const expandCategory = useCallback(
        (category: string) =>
            data?.categories
                ?.filter(({ name }) => name === category)
                .map(({ id: categoryId, name: categoryName, topics }, index) => (
                    <Fragment key={categoryId}>
                        {/* Prepend category header on first iteration */}
                        {index === 0 && <Text className="category-link">{categoryName}</Text>}
                        {/* List all topics */}
                        {topics?.map(({ id: topicId, name: topicName }) => (
                            <Link
                                className="link"
                                to={`/search/topic/${topicId}/${slugify(topicName)}`}
                                key={topicId}
                                onClick={() => {
                                    closeAndRecord(topicName);
                                    trackEvent({
                                        category: "nav bar",
                                        name: "hamburger menu topic click",
                                        action: topicName,
                                    });
                                }}
                            >
                                {topicName}
                            </Link>
                        ))}
                    </Fragment>
                )),
        [closeAndRecord, data?.categories, trackEvent]
    );

    // Animation for drawer open/close
    const drawerSpring = useSpring({
        transform: drawerOpen ? "translate(0%)" : "translate(200%)",
    });

    // Animation for menu open/close
    const menuSpring = useSpring(
        showHamburger
            ? {
                  transform: `translateY(0%)`,
                  opacity: 1,
              }
            : {
                  transform: `translateY(-150%)`,
                  opacity: 0,
              }
    );

    const reportClickOnSocialItem = useCallback(
        (socialType: string) => {
            trackEvent({
                category: "nav bar",
                name: "hamburger menu social click",
                action: socialType,
            });
        },
        [trackEvent]
    );

    const reportClickOnNoticeOfCollection = useCallback(() => {
        trackEvent({
            category: "nav bar",
            name: "notice of collection",
            action: "click",
        });
    }, [trackEvent]);

    const reportClickOnShopCTA = useCallback(() => {
        closeAndRecord("Shop America");
        trackEvent({
            category: "nav bar",
            name: "shop now cta",
            action: "click",
        });
    }, [closeAndRecord, trackEvent]);

    // NOTE: Currying
    const reportClickOnFeatureLink = useCallback(
        (label: string) => () => {
            closeAndRecord(label);
            trackEvent({
                category: "nav bar",
                action: "hamburger menu feature click",
                name: label,
            });
        },
        [closeAndRecord, trackEvent]
    );

    const featureLink = useCallback(
        ({ href, label, to }, key: number) => {
            return (
                <Link className="link" {...{ href, key, onClick: reportClickOnFeatureLink(label), to }}>
                    {label}
                </Link>
            );
        },
        [reportClickOnFeatureLink]
    );

    return (
        <animated.div className={clsx("AppBarMenu", drawerOpen && "AppBarMenuLock", displayCTA && "display-shop-cta")} style={menuSpring} ref={ref}>
            <div className="navigation">
                <div className="features">
                    <Header title="Features" />
                    <div className="feature-links">{featureLinks.map(featureLink)}</div>
                    {isLarge && <Social onClick={reportClickOnSocialItem} />}
                    {isLarge && (
                        <div className="shop-cta">
                            <Link to="/products" className="shop-now" onClick={reportClickOnShopCTA}>
                                <img src={Sunset} alt="sunset" className="image" />
                                <div className="info">
                                    <Text className="title">Shop America Store</Text>
                                    <Text className="desc">Discover top American-made products and brands.</Text>
                                    <Button className="shop-now" type="primary" size="large">
                                        Shop Now
                                    </Button>
                                </div>
                            </Link>
                        </div>
                    )}
                </div>
                <div className="freespoke-results">
                    <Header title="Freespoke Topics" to="/search/categories" onClick={() => closeAndRecord("Freespoke Topics")}>
                        New “hot” topics every day. Form your own opinion with our full coverage results.
                    </Header>
                    {isLoading ? (
                        <FreespokeLoader />
                    ) : isLarge ? (
                        <div className="desktop-results">
                            <div className="column">{desktopColumns.left.categories.map(({ name }) => expandCategory(name))}</div>
                            <div className="column">{desktopColumns.middle.categories.map(({ name }) => expandCategory(name))}</div>
                            <div className="column">{desktopColumns.right.categories.map(({ name }) => expandCategory(name))}</div>
                        </div>
                    ) : (
                        <div className="mobile-results">
                            {data?.categories?.map(({ name }, key) => (
                                <div
                                    key={key}
                                    className="list-item"
                                    onClick={() => {
                                        openDrawer(name);
                                        // Matomo analytics for clicking topic in the hamburger menu
                                        trackEvent({
                                            category: "nav bar",
                                            name: "hamburger menu category click",
                                            action: name,
                                        });
                                    }}
                                >
                                    <Text className="link">{name}</Text>
                                    <ChevronRight />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {!isLarge && (
                    <div className="shop-cta">
                        <img src={Sunset} alt="sunset" className="image" />
                        <div className="info">
                            <Text className="title">Shop America Store</Text>
                            <Text className="desc">Discover top American-made products and brands.</Text>
                            <Link to="/products" className="shop-now" onClick={() => closeAndRecord("Shop America")}>
                                <Button className="shop-now" type="primary" size="large">
                                    Shop Now
                                </Button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
            <div className="footer">
                {!isLarge && <Social onClick={reportClickOnSocialItem} />}
                <Text className="love">Made with ❤️ in the USA</Text>
                {isLarge && <Text className="dot">•</Text>}
                <NoticeOfCollection className="notice" onClick={reportClickOnNoticeOfCollection} />
            </div>
            <animated.div className="topic-drawer" style={drawerSpring}>
                <div className="back-button" onClick={closeDrawer}>
                    <ChevronLeft />
                </div>
                <div className="items">{expandCategory(drawerCategory)}</div>
            </animated.div>
        </animated.div>
    );
};

export default AppBarMenu;
