import { FC, useCallback, useMemo } from "react";
import { useMatomo }                from "@datapunt/matomo-tracker-react";
import { useLocation }              from "react-router";
import { getAnalyticsCategory }     from "@/analytics/analytics";
import { slugify }                  from "@/components/utils/slugify";
import Button                       from "@/designSystem/Button/Button";
import Link                         from "@/designSystem/Link/Link";
import Text                         from "@/designSystem/Text/Text";
import "./RelatedResults.less";

interface RelatedResultsProps {
    topics: { topicId: string; topic: string }[];
}

const RelatedResults: FC<RelatedResultsProps> = ({ topics }) => {
    const { trackEvent } = useMatomo();
    const location = useLocation();

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const reportClickOnTopic = useCallback(
        (topicName: string) => {
            trackEvent({
                category: analyticsMeta.category,
                name: topicName,
                action: "topic 1 related results topic click",
            });
        },
        [analyticsMeta.category, trackEvent]
    );

    return (
        <div className="RelatedResults">
            <Text className="title">Related Results</Text>
            <div className="topics">
                {topics
                    .map(({ topic, topicId }, index) => (
                        <Link
                            key={index}
                            className="topic"
                            to={`/search/topic/${topicId}/${slugify(topic)}`}
                            onClick={() => reportClickOnTopic(topic)}
                        >
                            <Button ghost>{topic}</Button>
                        </Link>
                    ))
                    .slice(0, 6)}
            </div>
        </div>
    );
};

export default RelatedResults;
