import axios                                        from "axios";
import useSWR                                       from "swr";
import { IImageQueryExpansion, IImageSearchResult } from "@/@types/custom";
import { useBackendAccess }                         from "@/api/useBackendAccess";
import { BACKEND_URL }                              from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

interface useSearchImagesResponse {
    headers: object;
    data: {
        total: number | undefined;
        images: IImageSearchResult[] | undefined;
        queryExpansions: IImageQueryExpansion | undefined; // related images
    };
}

export interface useSearchImagesParams {
    q: string;
    id?: string;
    page?: number;
    perPage?: number;
    aspect?: "Square" | "Wide" | "Tall" | "All";
    color?:
        | "ColorOnly"
        | "Monochrome"
        | "Black"
        | "Blue"
        | "Brown"
        | "Gray"
        | "Green"
        | "Orange"
        | "Pink"
        | "Purple"
        | "Red"
        | "Teal"
        | "White"
        | "Yellow";
    freshness?: "Day" | "Week" | "Month";
    imageContent?: "Face" | "Portrait";
    imageType?: "AnimatedGif" | "AnimatedGifHttps" | "Clipart" | "Line" | "Photo" | "Shopping" | "Transparent";
    license?: "Any" | "Public" | "Share" | "ShareCommercially" | "Modify" | "ModifyCommercially" | "All";
    size?: "Small" | "Medium" | "Large" | "Wallpaper" | "All";
    safeSearch?: "Off" | "Strict";
}

const useSearchImages = (params: useSearchImagesParams) => {
    const { authHeaders } = useBackendAccess();

    const searchClientId = sessionStorage.getItem("x-search-client-id") || "";

    const sanitizedParams = new URLSearchParams();

    Object.keys(params).forEach(key => {
        if (params[key] !== "") {
            sanitizedParams.append(key, params[key]);
        }
    });

    const url: string = `${BACKEND_URL}/search/images?${sanitizedParams}`;

    const fetcher = (URL: string) =>
        axios.get(URL, { headers: { ...authHeaders, "x-search-client-id": searchClientId } }).then(res => {
            return {
                data: res.data,
                headers: res.headers,
            };
        });

    const { data, error, isValidating, mutate } = useSWR<useSearchImagesResponse>(authHeaders && params.q ? url : null, fetcher, config);

    if (!searchClientId && data && data.headers && data.headers["x-search-client-id"]) {
        sessionStorage.setItem("x-search-client-id", data.headers["x-search-client-id"]);
    }

    return {
        data: data && data.data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
};

export default useSearchImages;
