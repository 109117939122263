import { FunctionComponent } from "react";
import Link                  from "@/designSystem/Link/Link";

interface PrivacyPolicyTypes {
    text?: string;
    className?: string;
    onClick?: () => void;
}

const PrivacyPolicy: FunctionComponent<PrivacyPolicyTypes> = ({ text, className, onClick }) => {
    return (
        <Link to="/privacy-policy" className={className} title={text} onClick={onClick}>
            {text ? text : "Privacy Policy"}
        </Link>
    );
};

export default PrivacyPolicy;
