import { FC, memo, useCallback }  from "react";
import { useMatomo }              from "@datapunt/matomo-tracker-react";
import { format }                 from "date-fns";
import { isValid as isValidDate } from "date-fns";
import { useLocation }            from "react-router-dom";
import { getAnalyticsCategory }   from "@/analytics/analytics";
import { slugify }                from "@/components/utils/slugify";
import Link                       from "@/designSystem/Link/Link";
import Text                       from "@/designSystem/Text/Text";
import AntTimeline                from "@/designSystem/Timeline/Timeline";
import "./Timeline.less";

export type TimelineData = {
    title?: string;
    id?: string;
    datePublished?: string;
}[];

export type TimelineProps = {
    data: TimelineData;
};

const Timeline: FC<TimelineProps> = memo(({ data }) => {
    const location = useLocation();
    const { trackEvent } = useMatomo();

    const reportClick = useCallback(
        storyName => {
            trackEvent({
                category: getAnalyticsCategory(location.pathname),
                name: "news tab timeline click",
                action: storyName,
            });
        },
        [location.pathname, trackEvent]
    );

    return (
        <div className="Timeline">
            <AntTimeline mode="left">
                {data.map((item, idx) => {
                    const date = new Date(item.datePublished || "");
                    return item.datePublished ? (
                        <AntTimeline.Item
                            className="timeline-item"
                            key={idx}
                            color="#1890FF"
                            label={
                                isValidDate(date) ? (
                                    <div className="item-date">
                                        <Text className="day">{format(date, "dd")}</Text>
                                        <Text className="month">{format(date, "MMM")}</Text>
                                    </div>
                                ) : (
                                    <span>Invalid Date</span>
                                )
                            }
                        >
                            <Link to={`/story/${item.id}/${slugify(item.title ?? "")}`} className="item-link" onClick={() => reportClick(item.title)}>
                                {item.title}
                            </Link>
                        </AntTimeline.Item>
                    ) : null;
                })}
            </AntTimeline>
        </div>
    );
});

export default Timeline;
