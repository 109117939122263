import { FC, createContext, useCallback, useContext, useEffect, useState } from "react";
import { useInView }                                                       from "react-intersection-observer";

interface AppBarContextProps {
    showAppBar: boolean;
    showHamburger: boolean;
    ref: (node?: Element | null | undefined) => void;
    openHamburger: VoidFunction;
    closeHamburger: VoidFunction;
}

const AppBarContext = createContext<Partial<AppBarContextProps>>({});

export const AppBarProvider: FC = ({ children }) => {
    const { inView, ref } = useInView({ initialInView: false });
    const [showHamburger, setShowHamburger] = useState<boolean>(false);
    const [showAppBar, setShowAppBar] = useState<boolean>(false);

    useEffect(() => {
        if (inView && !showHamburger) {
            setShowAppBar(false);
        } else {
            setShowAppBar(true);
        }
    }, [inView, showHamburger]);

    const openHamburger = useCallback<VoidFunction>(() => {
        if (inView) {
            setShowAppBar(true);
        }
        setShowHamburger(true);
    }, [inView]);

    const closeHamburger = useCallback<VoidFunction>(() => {
        if (inView) {
            setShowAppBar(false);
        }
        setShowHamburger(false);
    }, [inView]);

    const value: AppBarContextProps = { showAppBar, showHamburger, ref, openHamburger, closeHamburger };

    return <AppBarContext.Provider value={value}>{children}</AppBarContext.Provider>;
};

type UseAppBar = () => Partial<AppBarContextProps>;

export const useAppBar: UseAppBar = () => useContext(AppBarContext);
