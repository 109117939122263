import { FC, memo, useCallback, useMemo }  from "react";
import ImageLoader                         from "@/components/utils/ImageLoader";
import Avatar                              from "@/designSystem/Avatar/Avatar";
import Link                                from "@/designSystem/Link/Link";
import Text                                from "@/designSystem/Text/Text";
import { ReactComponent as VideoPlayIcon } from "@/static/icons/play-video.svg";
import ContentFooter                       from "../ContentFooter/ContentFooter";
import "./ContentCardV2.less";

interface ContentCardV2Props {
    title: string;
    images?: string[];
    url: string;
    datePublished?: string;
    publisherName: string;
    publisherIcon?: string;
    publisherTypes?: string[];
    bias?: string;
    footerLabel?: string;
    displayPlayIcon?: boolean;
    useInternalLink?: boolean;
    imageLoadingType?: "eager" | "lazy";
    reportClick?: (url: string, publisher: string) => void;
}

const ContentCardV2: FC<ContentCardV2Props> = ({
    title,
    images,
    url,
    datePublished,
    publisherName,
    publisherIcon,
    publisherTypes,
    bias,
    footerLabel,
    displayPlayIcon,
    useInternalLink,
    imageLoadingType,
    reportClick,
}) => {
    const handleClick = useCallback(() => {
        // click news article in trending news topic on homepage. action is "story name"
        if (typeof reportClick === "function") {
            reportClick(url, publisherName);
        } else {
            console.error("reportClick is not defined in Tweet component");
        }
    }, [publisherName, reportClick, url]);

    const publisherLabel = useMemo(
        () => (Array.isArray(publisherTypes) && typeof publisherTypes[0] === "string" && publisherTypes[0].toUpperCase()) || null,
        [publisherTypes]
    );

    const linkProps = useMemo(() => (useInternalLink ? { to: url || "#" } : { href: url || "#" }), [useInternalLink, url]);

    return (
        <div className="ContentCardV2" onClick={handleClick}>
            <Link className="link-wrapper" {...linkProps}>
                <ImageLoader className="image" srcList={images} alt={publisherName} imageLoadingType={imageLoadingType}>
                    {displayPlayIcon && (
                        <span className="play-background">
                            <VideoPlayIcon />
                        </span>
                    )}
                    <div className={`content${displayPlayIcon ? " no-overlay " : ""}`}>
                        <div className="publisher">
                            {publisherIcon && <Avatar shape="square" className="publisher-logo" size={24} src={publisherIcon} />}
                            <Text className="publisher-name">{publisherName}</Text>
                        </div>
                        <Text className="title">{title}</Text>
                    </div>
                </ImageLoader>
                {(footerLabel || publisherLabel || bias || datePublished) && (
                    <ContentFooter label={footerLabel || publisherLabel || bias || ""} datePublished={datePublished} />
                )}
            </Link>
        </div>
    );
};

export default memo(ContentCardV2);
