import { FC, memo, useCallback } from "react";
import { useMatomo }             from "@datapunt/matomo-tracker-react";
import Link                      from "@/designSystem/Link/Link";
import Text                      from "@/designSystem/Text/Text";
import { dash, toUSD }           from "@/helpers/index";
import "./ProductListing.less";

export interface ProductListingProps {
    title: string;
    price: number;
    brand: string;
    img: string;
    fallbackImg: string;
    alt?: string;
    href: string;
    size?: "default" | "small";
    newArrival?: boolean;
    fullWidth?: boolean;
}

const ProductListing: FC<ProductListingProps> = memo(({ title, price, brand, alt, img, fallbackImg, href, size = "default", newArrival = false }) => {
    const { trackEvent } = useMatomo();

    const onClick = useCallback(() => {
        // Track product click (product name only)
        trackEvent({
            category: "shop us",
            name: "product click",
            action: title,
        });

        // Track product click (brand name only)
        trackEvent({
            category: "shop us",
            name: "brand click",
            action: brand,
        });
    }, [trackEvent, title, brand]);

    return (
        <Link onClick={onClick} href={href} className={dash("ProductListing", size)}>
            <div className={dash("cover", size)}>
                <img src={img} alt={alt || title} className="image" onError={e => (e.currentTarget.src = fallbackImg)} />
            </div>
            <div className={dash("body", size)}>
                <div className="col">
                    <Text className={dash("title", size)}>{title}</Text>
                    <Text className="brand">{brand}</Text>
                </div>
                <div className="col">
                    <Text className={dash("price", size)}>{toUSD(price)}</Text>
                </div>
            </div>
            {newArrival && (
                <div className={dash("new-arrival", size)}>
                    <Text>New Arrival</Text>
                </div>
            )}
        </Link>
    );
});

export default ProductListing;
