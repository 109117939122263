import React, { FC, useCallback, useMemo } from "react";
import { useMatomo }                       from "@datapunt/matomo-tracker-react";
import useBreakpoint                       from "antd/lib/grid/hooks/useBreakpoint";
import { useLocation }                     from "react-router";
import { getAnalyticsCategory }            from "@/analytics/analytics";
import { CensoredContent }                 from "@/api/useGetDeepDiveTopic";
import Divider                             from "@/designSystem/Divider/Divider";
import { Col, Row }                        from "@/designSystem/Grid/Grid";
import Link                                from "@/designSystem/Link/Link";
import Paragraph                           from "@/designSystem/Paragraph/Paragraph";
import Title                               from "@/designSystem/Title/Title";
import HorizontalItem                      from "@/molecules/HorizontalItem/HorizontalItem";
import ImageArticle                        from "@/molecules/ImageArticle/ImageArticle";
import "./AnsoContainer.less";

interface AnsoContainerProps {
    articles: CensoredContent[];
}

const AnsoContainer: FC<AnsoContainerProps> = ({ articles }) => {
    const { xs, sm, md, lg } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const location = useLocation();

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const reportClickOnViewAll = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            name: "censored stories",
            action: "topic 1 censored carousel view all",
        });
    }, [analyticsMeta.category, trackEvent]);

    const reportClickOnCensoredContent = useCallback(
        (url: string, name: string) => {
            trackEvent({
                category: analyticsMeta.category,
                name: name,
                action: "topic 1 censored carousel article click",
            });
        },
        [analyticsMeta.category, trackEvent]
    );

    return (
        <div className="AnsoContainer">
            {lg && (
                <>
                    <Title className="AnsoContainerTitle" level={4}>
                        Censored Stories
                        <Link to="/category/censored-stories?page=1" onClick={reportClickOnViewAll}>
                            View All
                        </Link>
                    </Title>
                    <Paragraph className="AnsoParagraph">Stories censored by the media</Paragraph>
                </>
            )}
            <div className="anso-articles">
                {articles.slice(0, 3).map((article, i) => {
                    if ((xs || sm || md) && !lg) {
                        return (
                            <HorizontalItem
                                key={`anso-article-${i}`}
                                type="article"
                                url={article.url}
                                imageUrl={article?.images[0]}
                                itemId={article.id}
                                name={article.title}
                                publisher={new URL(article.url).hostname}
                                date={""}
                                specialTag={article.subcategory}
                                bias="right"
                                reportClick={reportClickOnCensoredContent}
                            />
                        );
                    } else {
                        return (
                            <Row key={`anso-article-${i}`} wrap={true} gutter={[0, 24]}>
                                <Col className="AnsoImageArticle" span={24}>
                                    <ImageArticle
                                        url={article.url}
                                        images={article.images}
                                        title={article.title}
                                        bias={"right"}
                                        specialTag={article.subcategory}
                                        date={article.datePublished}
                                        publisher={new URL(article.url).hostname}
                                        reportClick={reportClickOnCensoredContent}
                                    />
                                </Col>
                            </Row>
                        );
                    }
                })}
            </div>
            {(xs || sm || md) && !lg && (
                <>
                    <Link className="AnsoMobileLink" to="/category/censored-stories?page=1" onClick={reportClickOnViewAll}>
                        View Censored Stories Timeline
                    </Link>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default AnsoContainer;
