import axios                from "axios";
import { stringify }        from "query-string";
import useSWRImmutable      from "swr/immutable";
import { useBackendAccess } from "@/api/useBackendAccess";
import { BACKEND_URL }      from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

interface useGetSpecialTopicSubcategoriesResponse {
    data: string[];
}

const useGetSpecialTopicSubcategories = (route: "censored-stories" | "best-of-the-web") => {
    const { authHeaders } = useBackendAccess();
    const fetcher = (URL: string) => axios.get(URL, { headers: authHeaders }).then(res => res.data);
    const tag = route === "censored-stories" ? "censored" : "fun";
    const url = `${BACKEND_URL}/topic/subcategory/list?${stringify({ tag })}`;

    const { data, error, isValidating, mutate } = useSWRImmutable<useGetSpecialTopicSubcategoriesResponse>(authHeaders ? url : null, fetcher, config);

    return {
        data: data?.data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
};

export default useGetSpecialTopicSubcategories;
