import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { useMatomo }                                          from "@datapunt/matomo-tracker-react";
import useBreakpoint                                          from "antd/lib/grid/hooks/useBreakpoint";
import Text                                                   from "antd/lib/typography/Text";
import { useHistory, useLocation }                            from "react-router-dom";
import { useSearchImagesParams }                              from "@/api/useSearchImages";
import Button                                                 from "@/designSystem/Button/Button";
import Dropdown                                               from "@/designSystem/Dropdown/Dropdown";
import Menu                                                   from "@/designSystem/Menu/Menu";
import Paragraph                                              from "@/designSystem/Paragraph/Paragraph";
import useIsMounted                                           from "@/hooks/useIsMounted";
import useWindowSize                                          from "@/hooks/useWindowSize";
import { ReactComponent as CloseIcon }                        from "@/static/icons/close-circle-x.svg";
import { ReactComponent as SettingsIcon }                     from "@/static/icons/settings.svg";
import "./ImageSearchFilter.less";

type TSettingsData = {
    id: string;
    label: string;
    values: {
        displayValue: string;
        apiValue: string;
    }[];
}[];

const settingsData: TSettingsData = [
    {
        id: "freshness",
        label: "Time",
        values: [
            // "all" fetch as no query
            {
                displayValue: "All",
                apiValue: "",
            },
            {
                displayValue: "Day",
                apiValue: "Day",
            },
            {
                displayValue: "Week",
                apiValue: "Week",
            },
            {
                displayValue: "Month",
                apiValue: "Month",
            },
        ],
    },
    {
        id: "imageType",
        label: "Image Type",
        values: [
            // "all" fetch as no query
            {
                displayValue: "All",
                apiValue: "",
            },
            {
                displayValue: "Animated Gif",
                apiValue: "AnimatedGif",
            },
            {
                displayValue: "Clip Art",
                apiValue: "Clipart",
            },
            {
                displayValue: "Line",
                apiValue: "Line",
            },
            {
                displayValue: "Photo",
                apiValue: "Photo",
            },
            {
                displayValue: "Shopping",
                apiValue: "Shopping",
            },
            {
                displayValue: "Transparent",
                apiValue: "Transparent",
            },
        ],
    },
    {
        id: "size",
        label: "Image Size",
        values: [
            {
                displayValue: "All",
                apiValue: "All",
            },
            {
                displayValue: "Small",
                apiValue: "Small",
            },
            {
                displayValue: "Medium",
                apiValue: "Medium",
            },
            {
                displayValue: "Large",
                apiValue: "Large",
            },
            {
                displayValue: "Wallpaper",
                apiValue: "Wallpaper",
            },
        ],
    },
    {
        id: "aspect",
        label: "Aspect",
        values: [
            {
                displayValue: "All",
                apiValue: "All",
            },
            {
                displayValue: "Square",
                apiValue: "Square",
            },
            {
                displayValue: "Wide",
                apiValue: "Wide",
            },
            {
                displayValue: "Tall",
                apiValue: "Tall",
            },
        ],
    },
    {
        id: "safeSearch",
        label: "Image Safe Search",
        values: [
            // "all" fetch as no query
            {
                displayValue: "On",
                apiValue: "Strict",
            },
            {
                displayValue: "Off",
                apiValue: "Off",
            },
        ],
    },
    {
        id: "color",
        label: "Image Color",
        values: [
            // "all" fetch as no query
            {
                displayValue: "All",
                apiValue: "",
            },
            {
                displayValue: "Color Only",
                apiValue: "ColorOnly",
            },
            {
                displayValue: "Monochrome",
                apiValue: "Monochrome",
            },
            {
                displayValue: "Black",
                apiValue: "Black",
            },
            {
                displayValue: "Blue",
                apiValue: "Blue",
            },
            {
                displayValue: "Brown",
                apiValue: "Brown",
            },
            {
                displayValue: "Gray",
                apiValue: "Gray",
            },
            {
                displayValue: "Green",
                apiValue: "Green",
            },
            {
                displayValue: "Orange",
                apiValue: "Orange",
            },
            {
                displayValue: "Pink",
                apiValue: "Pink",
            },
            {
                displayValue: "Purple",
                apiValue: "Purple",
            },
            {
                displayValue: "Red",
                apiValue: "Red",
            },
            {
                displayValue: "Teal",
                apiValue: "Teal",
            },
            {
                displayValue: "White",
                apiValue: "White",
            },
            {
                displayValue: "Yellow",
                apiValue: "Yellow",
            },
        ],
    },
    {
        id: "imageContent",
        label: "People Search",
        values: [
            // "all" fetch as no query
            {
                displayValue: "All",
                apiValue: "",
            },
            {
                displayValue: "Face",
                apiValue: "Face",
            },
            {
                displayValue: "Portrait",
                apiValue: "Portrait",
            },
        ],
    },
    {
        id: "license",
        label: "License",
        values: [
            // "all" fetch as no query
            {
                displayValue: "All",
                apiValue: "",
            },
            {
                displayValue: "Any",
                apiValue: "Any",
            },
            {
                displayValue: "Public",
                apiValue: "Public",
            },
            {
                displayValue: "Share",
                apiValue: "Share",
            },
            {
                displayValue: "Share Commercially",
                apiValue: "ShareCommercially",
            },
            {
                displayValue: "Modify",
                apiValue: "Modify",
            },
            {
                displayValue: "Modify Commercially",
                apiValue: "ModifyCommercially",
            },
        ],
    },
];

export interface ImageSearchFilterProps {}

const ImageSearchFilter: FC<ImageSearchFilterProps> = () => {
    const location = useLocation();
    const history = useHistory();
    const { lg } = useBreakpoint();
    const { width } = useWindowSize();
    const isMounted = useIsMounted();
    const hasBeenUsed = useRef<boolean>(false);

    const [isVisible, setIsVisible] = useState(false);
    // const [isSafeSearchChecked, setIsSafeSearchChecked] = useState(true);
    const { trackEvent } = useMatomo();

    // a function that maps URL query params to the filter internal state
    const syncParams = useCallback(() => {
        const urlParams = new URLSearchParams(location.search);
        return {
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page") || "1"),
            freshness: urlParams.get("freshness") || settingsData.find(item => item.id === "freshness")?.values[0].apiValue,
            imageType: urlParams.get("imageType") || settingsData.find(item => item.id === "imageType")?.values[0].apiValue,
            size: urlParams.get("size") || settingsData.find(item => item.id === "size")?.values[0].apiValue,
            aspect: urlParams.get("aspect") || settingsData.find(item => item.id === "aspect")?.values[0].apiValue,
            safeSearch: urlParams.get("safeSearch") || settingsData.find(item => item.id === "safeSearch")?.values[0].apiValue,
            color: urlParams.get("color") || settingsData.find(item => item.id === "color")?.values[0].apiValue,
            imageContent: urlParams.get("imageContent") || settingsData.find(item => item.id === "imageContent")?.values[0].apiValue,
            license: urlParams.get("license") || settingsData.find(item => item.id === "license")?.values[0].apiValue,
            id: urlParams.get("id") || "",
        } as useSearchImagesParams;
    }, [location.search]);

    const [urlParams, setUrlParams] = useState<useSearchImagesParams>(syncParams);

    const handleSelectChange = useCallback(e => {
        if (!hasBeenUsed.current) {
            hasBeenUsed.current = true;
        }
        setUrlParams(currentParams => ({ ...currentParams, [e.target.name]: e.target.value }));
    }, []);

    // const handleSwitchToggle = useCallback((isChecked: boolean) => {
    //     if (!hasBeenUsed.current) {
    //         hasBeenUsed.current = true;
    //     }
    //     setIsSafeSearchChecked(isChecked);
    //     setUrlParams(currentParams => ({ ...currentParams, safeSearch: isChecked ? "Strict" : "Off" }));
    // }, []);

    const updateUrlParams = useCallback(() => {
        if (isMounted()) {
            const newUrlParams = new URLSearchParams();

            Object.keys(urlParams).forEach(key => {
                if (urlParams[key] !== "") {
                    // if (urlParams[key] !== "") {
                    newUrlParams.append(key, urlParams[key]);
                }
            });

            // This is to prevent page and id reset on initial page load
            if (hasBeenUsed.current) {
                // Sets page to 1 on filter change
                newUrlParams.set("page", "1");

                // Hides "Image detail modal" on filter change
                newUrlParams.delete("id");
            }

            history.push({
                pathname: location.pathname,
                search: `?${newUrlParams}`,
            });
        }
    }, [history, isMounted, location.pathname, urlParams]);

    // Anytime user opens this filter we sync URL query params with filter internal params
    useEffect(() => {
        if (isVisible) {
            setUrlParams(syncParams());
        }
    }, [isVisible, syncParams]);

    useEffect(() => {
        if (isMounted()) {
            if (lg) {
                updateUrlParams();
            }
        }
    }, [isMounted, lg, updateUrlParams]);

    const handleCloseButtonClick = useCallback(() => {
        setIsVisible(false);
        if (!lg) {
            updateUrlParams();
        }
    }, [lg, updateUrlParams]);

    const handleDropdownButtonClick = useCallback(() => {
        trackEvent({
            category: "search results page",
            name: "open filter settings",
            action: "click",
        });

        setIsVisible(!isVisible);
    }, [isVisible, trackEvent]);

    // NOTE: This will not get called when user clicks "X" button
    const handleOnVisibleChange = useCallback(
        (visible: boolean) => {
            setIsVisible(visible);

            if (!lg) {
                updateUrlParams();
            }
        },
        [lg, updateUrlParams]
    );

    const renderLicenseDescription = useCallback(() => {
        let description: string;

        switch (urlParams.license) {
            case "All":
                description = "Do not filter by license type.";
                break;
            case "Any":
                description = "Images that are under any license type.";
                break;
            case "Public":
                description = "Images where the creator has waived their exclusive rights, to the fullest extent allowed by law.";
                break;
            case "Share":
                description = "Images that may be shared with others.";
                break;
            case "ShareCommercially":
                description = "Images that may be shared with others for personal or commercial purposes.";
                break;
            case "Modify":
                description = "Images that may be modified, shared, and used.";
                break;
            case "ModifyCommercially":
                description = "Images that may be modified, shared, and used for personal or commercial purposes.";
                break;
            default:
                description = "Do not filter by license type.";
                break;
        }

        return (
            <Paragraph className="description-text">
                <Text className="description-title">
                    {urlParams.license ? urlParams.license.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1") : "All"}
                </Text>
                {` - ${description}`}
            </Paragraph>
        );
    }, [urlParams.license]);

    const renderFormFields = useCallback(
        () =>
            settingsData.map(setting => {
                if (setting.id !== "safeSearch") {
                    const forColorSelect = setting.id === "color" && urlParams.color;

                    return (
                        <Menu.Item key={setting.id} className="menu-item">
                            <div className="settings-field">
                                <div className="flex-container">
                                    <label htmlFor={`${setting.id}-select`}>{setting.label}:</label>
                                    <div className="select-container">
                                        <select
                                            className={`${forColorSelect ? "color-select" : ""}`}
                                            id={`${setting.id}-select`}
                                            name={setting.id}
                                            onChange={handleSelectChange}
                                            defaultValue={urlParams[setting.id]}
                                        >
                                            {setting.values.map(value => (
                                                <option key={value.displayValue} value={value.apiValue || ""}>
                                                    {value.displayValue}
                                                </option>
                                            ))}
                                        </select>
                                        {setting.id === "color" && urlParams.color && (
                                            <span className={`color-indicator ${urlParams.color ? urlParams.color.toLowerCase() : ""}`} />
                                        )}
                                    </div>
                                </div>
                                {setting.id === "license" && renderLicenseDescription()}
                            </div>
                        </Menu.Item>
                    );
                }
                return null;
            }),
        [handleSelectChange, renderLicenseDescription, urlParams]
    );

    const menu = () => (
        <Menu>
            <Menu.Item key="closeButton" className="close-button-container">
                <button className="close-button" onClick={handleCloseButtonClick}>
                    <CloseIcon />
                </button>
            </Menu.Item>
            <Menu.ItemGroup key="itemGroup">{renderFormFields()}</Menu.ItemGroup>
        </Menu>
    );

    return (
        <div className="ImageSearchFilter">
            <Dropdown
                overlayClassName="SettingsDropdown"
                overlay={menu}
                trigger={["click"]}
                visible={isVisible}
                onVisibleChange={handleOnVisibleChange}
            >
                <Button className="settings-button" onClick={handleDropdownButtonClick}>
                    <SettingsIcon className="settings-icon" />
                    {width && width > 400 && <Text>Settings</Text>}
                </Button>
            </Dropdown>
        </div>
    );
};

export default memo(ImageSearchFilter);
