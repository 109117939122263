import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useMatomo }                                           from "@datapunt/matomo-tracker-react";
import clsx                                                    from "clsx";
import { useLocation }                                         from "react-router-dom";
import { animated, config, useSpring }                         from "react-spring";
import { AnalyticsMeta }                                       from "@/@types/custom";
import { getAnalyticsCategory }                                from "@/analytics/analytics";
import { ISimilarity }                                         from "@/api/useGetStoryById";
import Button                                                  from "@/designSystem/Button/Button";
import Link                                                    from "@/designSystem/Link/Link";
import List                                                    from "@/designSystem/List/List";
import Title                                                   from "@/designSystem/Title/Title";
import { useHeight }                                           from "@/hooks/useHeight";
import { ReactComponent as SimilarityCardLeftIcon }            from "@/static/icons/summary-card-left-icon.svg";
import { ReactComponent as SimilarityCardMiddleIcon }          from "@/static/icons/summary-card-middle-icon.svg";
import { ReactComponent as SimilarityCardRightIcon }           from "@/static/icons/summary-card-right-icon.svg";
import "./SimilarityCard.less";

export interface SimilarityCardProps {
    bias?: "left" | "right" | "middle"; // the card header color and icon will be different depending on the bias
    similarityItems: ISimilarity[];
    rows: number; // number of lines of text to display before it gets cut off.
    analyticsMeta: AnalyticsMeta;
    onSizeChange: (isExpanded: boolean) => void; // used to tell parent component to force other siblings to expand
    forceExpand?: boolean;
}

const SimilarityCard: FC<SimilarityCardProps> = memo(({ similarityItems, bias, rows, analyticsMeta, onSizeChange, forceExpand }) => {
    const location = useLocation();
    const { trackEvent } = useMatomo();
    const [isExpanded, setIsExpanded] = useState(!!forceExpand);
    const [heightRef, height] = useHeight();
    const [initialHeight, setInitialHeight] = useState(0);

    useEffect(() => {
        if (height && height !== 0 && !initialHeight) {
            setInitialHeight(height);
        }
    }, [height, initialHeight]);

    // Parent requires to expand the card
    useEffect(() => {
        setIsExpanded(!!forceExpand);
    }, [forceExpand]);

    // Get analytics category name - 'home', 'freespoke topic' etc
    const analytics = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
            name: analyticsMeta.name,
        }),
        [location.pathname, analyticsMeta]
    );

    const reportBiasClick = useCallback(() => {
        trackEvent({
            category: analytics.category,
            name: analyticsMeta.name,
            action: `story summary publisher click - ${bias}`,
        });
    }, [analytics.category, analyticsMeta.name, bias, trackEvent]);

    /* Possible unnecessary method */
    // const reportArticleClick = useCallback(
    //     (url: string) => {
    //         trackEvent({
    //             category: analytics.category,
    //             name: "bias highlight article click",
    //             action: url,
    //         });
    //     },
    //     [analytics.category, trackEvent]
    // );

    const onSourceClick = useCallback(
        (url: string) => () => {
            reportBiasClick();
            // reportArticleClick(url); // Possible unnecessary method
        },
        [reportBiasClick]
    );

    const reportShowMoreClick = useCallback(() => {
        trackEvent({
            category: analytics.category,
            name: `bias highlight show more - ${bias}`,
            action: analytics.name || "unknown action",
        });
    }, [analytics, bias, trackEvent]);

    const handleShowMore = useCallback(
        e => {
            e.preventDefault();
            setIsExpanded(!isExpanded);
            onSizeChange(!isExpanded);

            if (!isExpanded) {
                reportShowMoreClick();
            }
        },
        [isExpanded, onSizeChange, reportShowMoreClick]
    );

    const slideInStyles = useSpring({
        config: { ...config.default },
        from: {
            height: isExpanded ? initialHeight : height,
        },
        to: {
            height: isExpanded ? height : initialHeight,
        },
    });

    return (
        <div className="SimilarityContainer">
            <animated.div style={slideInStyles} className="SimilarityCard">
                <div ref={heightRef}>
                    <List
                        className={clsx("list", bias)}
                        header={
                            <div className="header-container">
                                <Title level={1} className="title">
                                    {bias === "left" && "FROM THE LEFT"}
                                    {bias === "middle" && "BOTH SIDES AGREE ON"}
                                    {bias === "right" && "FROM THE RIGHT"}
                                </Title>
                                <div className="icon-container">
                                    {bias === "left" && <SimilarityCardLeftIcon />}
                                    {bias === "middle" && <SimilarityCardMiddleIcon />}
                                    {bias === "right" && <SimilarityCardRightIcon />}
                                </div>
                            </div>
                        }
                        dataSource={isExpanded ? similarityItems : similarityItems.slice(0, rows)}
                        renderItem={(item, i) => (
                            <List.Item className="item" key={item.id || `item-${i}`}>
                                {item.source_url ? (
                                    <Link className="source-name" href={item.source_url || ""} onClick={onSourceClick(item.source_url || "")}>
                                        {item.source_name}: &nbsp;
                                    </Link>
                                ) : (
                                    <span className="source-name">{item.source_name}: &nbsp;</span>
                                )}
                                {item.sentence}
                            </List.Item>
                        )}
                    />
                </div>
            </animated.div>
            {similarityItems.length > rows && (
                <div>
                    <Button type="link" onClick={handleShowMore} className={clsx("show-btn", isExpanded && "show-less-btn")}>
                        {isExpanded ? "Show Less" : "Show More"}
                    </Button>
                </div>
            )}
        </div>
    );
});

export default SimilarityCard;
