import { useCallback, useEffect, useMemo, useState } from "react";
import { useMatomo }                                 from "@datapunt/matomo-tracker-react";
import { CarouselProps }                             from "antd/lib/carousel";
import useBreakpoint                                 from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet }                                    from "react-helmet-async";
import { useHistory, useLocation }                   from "react-router-dom";
import { getAnalyticsCategory }                      from "@/analytics/analytics";
import useSearchWeb                                  from "@/api/useSearchWeb";
import Divider                                       from "@/designSystem/Divider/Divider";
import { Col, Row }                                  from "@/designSystem/Grid/Grid";
import ChevronRight                                  from "@/designSystem/Icons/ChevronRight";
import Link                                          from "@/designSystem/Link/Link";
import AntPagination                                 from "@/designSystem/Pagination/Pagination";
import Text                                          from "@/designSystem/Text/Text";
import Title                                         from "@/designSystem/Title/Title";
import ContentCard                                   from "@/molecules/ContentCard/ContentCard";
import ErrorResult                                   from "@/molecules/ErrorResult/ErrorResult";
import FreespokeLoader                               from "@/molecules/FreespokeLoader/FreespokeLoader";
import NewsSearchResult                              from "@/molecules/NewsSearchResult/NewsSearchResult";
import ScrollableArticles                            from "@/molecules/ScrollableArticles/ScrollableArticles";
import StoryCard                                     from "@/molecules/StoryCard/StoryCard";
import SuggestionText                                from "@/molecules/SuggestionText/SuggestionText";
import Tweet                                         from "@/molecules/Tweet/Tweet";
import WebSearchResult                               from "@/molecules/WebSearchResult/WebSearchResult";
import AppDownloadBanner                             from "@/organisms/AppDownloadBanner/AppDownloadBanner";
import BusinessPlace                                 from "@/organisms/BusinessPlace/BusinessPlace";
import ExpandableContainer                           from "@/organisms/ExpandableContainer/ExpandableContainer";
import TopicsCard                                    from "@/organisms/TopicsCard/TopicsCard";
import WikiCard                                      from "@/organisms/WikiCard/WikiCard";
import "./WebSearch.less";

const PER_PAGE = 10;

const CAROUSEL_SETTINGS: CarouselProps = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: true,
    slidesToShow: 2,
    rows: 1,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 1120,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 9999,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
    ],
};

export default function WebSearch() {
    const location = useLocation();
    const history = useHistory();
    const { trackPageView, trackEvent } = useMatomo();
    const { xs, lg } = useBreakpoint();
    const [params, setParams] = useState(() => {
        const urlParams = new URLSearchParams(location.search);
        return {
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page") || "1"),
        };
    });

    const { data, isLoading, error } = useSearchWeb({
        q: params.q,
        count: PER_PAGE,
        offset: (params.page - 1) * PER_PAGE,
        includeTopics: true,
        includeArticles: true,
        includeVideos: true,
        includeStories: true,
    });

    const screenSize = xs ? "small" : "large";

    useEffect(() => {
        // @ts-ignore
        trackPageView();

        const urlParams = new URLSearchParams(location.search);
        setParams({
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page") || "1"),
        });
    }, [trackPageView, location.search]);

    const onPaginationChange = useCallback(
        (pageNum: number) => {
            history.push({ pathname: location.pathname, search: `?${new URLSearchParams({ ...params, page: pageNum.toString() })}` });
            trackEvent({
                category: "search results page",
                name: "pagination",
                action: pageNum.toString(),
            });
        },
        [history, location.pathname, params, trackEvent]
    );

    const reportClickOnNewsCarouselItem = useCallback(
        (url: string, _title: string) => {
            trackEvent({
                category: "search results page",
                name: "top stories carousel article click",
                action: url,
            });
        },
        [trackEvent]
    );

    const reportClickOnVideosCarouselItem = useCallback(
        (url: string, _title: string) => {
            trackEvent({
                category: "search results page",
                name: "recent video click",
                action: url,
            });
        },
        [trackEvent]
    );

    const reportClickOnTweetsCarouselItem = useCallback(
        (url: string, _title: string) => {
            trackEvent({
                category: "search results page",
                name: "top stories carousel tweet click",
                action: url,
            });
        },
        [trackEvent]
    );

    const reportCarouselScroll = useCallback(
        (type: "article" | "tweet" | "video", name?: string) => {
            trackEvent({
                category: "search results page",
                name: name ?? `top stories carousel ${type} scroll`,
                action: "click",
            });
        },
        [trackEvent]
    );

    const reportTweetsCarouselScroll = useCallback(() => reportCarouselScroll("tweet"), [reportCarouselScroll]);
    const reportNewsCarouselScroll = useCallback(() => reportCarouselScroll("article"), [reportCarouselScroll]);
    const reportVideosCarouselScroll = useCallback(() => reportCarouselScroll("video", "recent videos video scroll"), [reportCarouselScroll]);

    const reportClickOnWebResult = useCallback(
        (url: string) => {
            trackEvent({
                category: getAnalyticsCategory(location.pathname),
                name: "search result page",
                action: url,
            });
        },
        [location.pathname, trackEvent]
    );

    const newsTabURL = useMemo(() => {
        const pageParams = new URLSearchParams(location.search);
        return `/search/news?q=${pageParams.get("q") || ""}`;
    }, [location.search]);

    const canonicalLinkParams = useMemo(() => (!params.q ? "" : `?${new URLSearchParams({ ...params, page: params.page.toString() })}`), [params]);
    const hasWebPages = !!(data && data.web_pages && Array.isArray(data.web_pages.data) && data.web_pages.data.length > 0);
    const hasBusinesses = !!(data?.businesses?.list && Array.isArray(data.businesses.list) && data.businesses.list.length > 0);
    const hasNavigationalPages = !!(data?.navigational_pages && Array.isArray(data.navigational_pages) && data.navigational_pages.length > 0);
    const hasNews = !!(data?.news && Array.isArray(data.news) && data.news.length > 0);
    const hasStories = !!(data?.stories && Array.isArray(data.stories) && data.stories.length > 0);
    const hasSuggestedTopics = !!(data?.suggested_topics && Array.isArray(data.suggested_topics) && data.suggested_topics.length > 0);
    const hasTopHits = !!(data?.top_hits && Array.isArray(data.top_hits) && data.top_hits.length > 0);
    const hasTweets = !!(data?.tweets && Array.isArray(data.tweets) && data.tweets.length > 0);
    const hasVideos = !!(data?.videos && Array.isArray(data.videos) && data.videos.length > 0);
    const hasWebEntities = data?.web_entities && Array.isArray(data.web_entities) && data.web_entities.length > 0;

    const hasAtLeastOneResult =
        hasWebPages ||
        hasNavigationalPages ||
        hasBusinesses ||
        hasNews ||
        hasStories ||
        hasSuggestedTopics ||
        hasTopHits ||
        hasTweets ||
        hasVideos ||
        hasWebEntities;

    if (error) {
        return <ErrorResult error={error} containerClassName="SearchPage" />;
    }

    const shouldDisplayNavPageResult =
        data?.navigational_pages &&
        Array.isArray(data.navigational_pages) &&
        data.navigational_pages.length > 0 &&
        (hasBusinesses || hasNews || hasStories || hasTopHits || hasTweets || hasVideos || (hasSuggestedTopics && !lg));

    return (
        <div className="WebSearch">
            <Helmet>
                <link rel="canonical" href={`https://freespoke.com/search/web${canonicalLinkParams}`} />
                <title>Search - Freespoke</title>
                <meta property="og:title" content={`${params.q} - Freespoke Search`} />
                <meta property="og:url" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="twitter:title" content={`${params.q} - Freespoke Search`} />
                <meta property="twitter:image" content="" />
            </Helmet>
            {isLoading && <FreespokeLoader />}
            {!isLoading && data && (
                <Row
                    gutter={[
                        { sm: 12, md: 32, lg: 32 },
                        { xs: 16, sm: 16, md: 24 },
                    ]}
                >
                    {data.web_pages?.total && typeof data.web_pages.total === "number" && (
                        <Col xs={24}>
                            <Title className="result-title" level={4}>
                                About <span>{data.web_pages.total.toLocaleString()}</span> results
                            </Title>
                        </Col>
                    )}
                    {data?.spellcheck?.suggest && (
                        <Col xs={24} className="suggestion-col">
                            <SuggestionText originalText={params.q} spellcheck={data.spellcheck} pathname={location.pathname} />
                        </Col>
                    )}
                    <Col xs={24} md={24} lg={16}>
                        <Row gutter={[0, { xs: 24, sm: 24, md: 24 }]}>
                            {data?.navigational_pages && shouldDisplayNavPageResult && (
                                <WebSearchResult
                                    href={data.navigational_pages[0].url || "/"}
                                    title={data.navigational_pages[0].title || "Missing title"}
                                    description={data.navigational_pages[0].description || "Missing description"}
                                    displayUrl={data.navigational_pages[0].url || "Missing URL"}
                                    key={data.navigational_pages[0].id}
                                />
                            )}
                            {data?.businesses?.total > 0 && data?.businesses?.list?.length > 0 && (
                                <ExpandableContainer
                                    className="business-places"
                                    panelKey="business-places"
                                    compactSize={3}
                                    compactBtnText="Show more"
                                    expandedBtnText="Show less"
                                >
                                    {data?.businesses?.list.map((item, i) => (
                                        <BusinessPlace className="business-place" key={item.id || i} data={item} />
                                    ))}
                                </ExpandableContainer>
                            )}
                            {Array.isArray(data.top_hits) &&
                                data.top_hits.length > 0 &&
                                data.top_hits.map((data, i) => (
                                    <Col className="component-col" key={data?.id || i}>
                                        <NewsSearchResult article={data} size={screenSize} showDescription showUrl />
                                        <Divider className="divider" />
                                    </Col>
                                ))}
                            {Array.isArray(data.news) && data.news.length > 0 && (
                                <Col className="component-col">
                                    <Title level={4} className="component-headline">
                                        Recent News{" "}
                                        <Link className="link-to-news" to={newsTabURL}>
                                            See all News <ChevronRight />
                                        </Link>
                                    </Title>
                                    <ScrollableArticles
                                        settings={CAROUSEL_SETTINGS}
                                        items={data.news}
                                        onScroll={reportNewsCarouselScroll}
                                        render={news =>
                                            news.map(newsArticle => (
                                                <ContentCard
                                                    key={newsArticle.id}
                                                    title={newsArticle.title}
                                                    bias={newsArticle.bias}
                                                    datePublished={newsArticle.datePublished}
                                                    publisherName={newsArticle.publisherName}
                                                    publisherIcon={newsArticle.publisherIcon}
                                                    publisherTypes={newsArticle.publisherTypes}
                                                    images={newsArticle.images}
                                                    url={newsArticle.url}
                                                    reportClick={reportClickOnNewsCarouselItem}
                                                />
                                            ))
                                        }
                                    />
                                </Col>
                            )}
                            {!lg && data?.web_entities && data.web_entities.length > 0 && (
                                <WikiCard
                                    title={data.web_entities[0].title}
                                    description={data.web_entities[0].description}
                                    image={data.web_entities[0].image}
                                    url={data.web_entities[0].url}
                                    source={data.web_entities[0].source}
                                />
                            )}
                            {data?.stories?.length && (
                                <Col className="component-col">
                                    <StoryCard id={data.stories[0].id} title={data.stories[0].title} updatedAt={data.stories[0].updatedAt} />
                                </Col>
                            )}
                            {Array.isArray(data?.videos) && data.videos.length && (
                                <Col className="component-col">
                                    <Title level={4} className="component-headline">
                                        Recent Videos
                                    </Title>
                                    <ScrollableArticles
                                        settings={CAROUSEL_SETTINGS}
                                        items={data.videos}
                                        onScroll={reportVideosCarouselScroll}
                                        render={videos =>
                                            videos.map(video => (
                                                <ContentCard
                                                    key={video.id}
                                                    title={video.title}
                                                    datePublished={video.datePublished}
                                                    publisherName={video.publisherName}
                                                    images={[video.thumbnailURL]}
                                                    url={video.url}
                                                    reportClick={reportClickOnVideosCarouselItem}
                                                    displayPlayIcon
                                                    footerLabel="video"
                                                />
                                            ))
                                        }
                                    />
                                </Col>
                            )}
                            {Array.isArray(data.tweets) && data.tweets.length > 0 && (
                                <Col className="component-col">
                                    <Title level={4} className="component-headline">
                                        What's Everyone Saying?
                                    </Title>
                                    <ScrollableArticles
                                        settings={CAROUSEL_SETTINGS}
                                        items={data.tweets}
                                        onScroll={reportTweetsCarouselScroll}
                                        render={tweets =>
                                            tweets.map(tweetArticle => (
                                                <Tweet
                                                    data={{
                                                        id: tweetArticle.id || "",
                                                        tweet_id: tweetArticle.tweet_id || "",
                                                        author: {
                                                            name: tweetArticle.author.name || "",
                                                            username: tweetArticle.author.username || "",
                                                            profile_image_url: tweetArticle.author.profile_image_url || "",
                                                            image_url: tweetArticle.author.image_url,
                                                        },
                                                        text: tweetArticle.text || "",
                                                        datePublished: tweetArticle.datePublished || "",
                                                        url: tweetArticle.url || "",
                                                    }}
                                                    key={tweetArticle.id}
                                                    maxLines={6}
                                                    reportClick={reportClickOnTweetsCarouselItem}
                                                />
                                            ))
                                        }
                                    />
                                </Col>
                            )}
                            {!lg && data?.suggested_topics && data.suggested_topics.length > 0 && <TopicsCard data={data.suggested_topics} />}
                            {hasWebPages &&
                                data.web_pages?.data.map(result => (
                                    <WebSearchResult
                                        dateLastCrawled={result.dateLastCrawled}
                                        href={result.url}
                                        title={result.name}
                                        description={result.snippet}
                                        displayUrl={result.displayUrl}
                                        deepLinks={result.deepLinks}
                                        showDate={true}
                                        key={result.url}
                                        reportClick={reportClickOnWebResult}
                                    />
                                ))}
                            {hasWebPages && (
                                <div className="search-provider-credit">
                                    <Text>Search results powered by Microsoft.</Text>
                                    <Link href="https://privacy.microsoft.com/en-us/privacystatement" rel="noopener">
                                        Privacy Policy
                                    </Link>
                                </div>
                            )}
                        </Row>
                    </Col>
                    <Col xs={24} md={8}>
                        <Row gutter={[0, { xs: 16, sm: 16, md: 16 }]}>
                            {lg && data?.web_entities && data.web_entities.length > 0 && (
                                <WikiCard
                                    title={data.web_entities[0].title}
                                    description={data.web_entities[0].description}
                                    image={data.web_entities[0].image}
                                    url={data.web_entities[0].url}
                                    source={data.web_entities[0].source}
                                />
                            )}
                            {lg && data?.suggested_topics && data.suggested_topics.length > 0 && <TopicsCard data={data.suggested_topics} />}
                        </Row>
                    </Col>
                </Row>
            )}
            {!isLoading && data && !hasAtLeastOneResult && (
                <Row gutter={[{ sm: 24, md: 40 }, 0]}>
                    <Col xs={24}>
                        <Title level={4}>No results found</Title>
                    </Col>
                </Row>
            )}
            {!isLoading && data && data.web_pages && data.web_pages.total > 0 ? (
                <Row justify="center" className="WebPagination">
                    <AntPagination
                        showSizeChanger={false}
                        current={params.page}
                        defaultPageSize={PER_PAGE}
                        total={data.web_pages.total > 500 ? 500 * PER_PAGE : data.web_pages.total}
                        onChange={onPaginationChange}
                    />
                </Row>
            ) : null}

            {!isLoading && data && data.web_pages && data.web_pages.total > 0 && <AppDownloadBanner className="app-banner" />}
        </div>
    );
}
