import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useMatomo }                                                      from "@datapunt/matomo-tracker-react";
import { useHistory, useLocation }                                        from "react-router-dom";
import { animated, config, useTransition }                                from "react-spring";
import useGetSuggestions                                                  from "@/api/useGetSearchTopics";
import Card                                                               from "@/designSystem/Card/Card";
import CustomSearch                                                       from "@/designSystem/Icons/CustomSearch";
import CustomSubmit                                                       from "@/designSystem/Icons/CustomSubmit";
import Input                                                              from "@/designSystem/Input/Input";
import Link                                                               from "@/designSystem/Link/Link";
import Title                                                              from "@/designSystem/Title/Title";
import { getQueryParamsFromUrl }                                          from "@/helpers/index";
import { useAppBar }                                                      from "@/hooks/useAppBar";
import SearchBarItem                                                      from "@/molecules/SearchBarItem/SearchBarItem";
import { ReactComponent as SearchIcon }                                   from "@/static/icons/search.svg";
import "./SearchBar.less";

interface SearchBarProps {
    dropdown?: boolean;
    placeholder?: string;
    parent: string;
    analyticsMeta?: {
        name: string;
        action: string;
    };
}

const MAX_SUGGESTED_TOPICS = 10;
const MAX_POPULAR_SEARCH_TERMS = 5;

const SearchBar: FC<SearchBarProps> = ({ dropdown = true, placeholder = "Try a search...", parent, analyticsMeta }) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const inputRef = useRef() as RefObject<HTMLDivElement>;
    const dropdownRef = useRef() as RefObject<HTMLDivElement>;
    const history = useHistory();
    const location = useLocation();
    const { trackEvent } = useMatomo();
    const { data } = useGetSuggestions({ topicsLimit: MAX_SUGGESTED_TOPICS, searchTermsLimit: MAX_POPULAR_SEARCH_TERMS });
    const { closeHamburger } = useAppBar();

    const transitions = useTransition(showSuggestions, {
        from: { opacity: 0, transform: "scale(0)" },
        enter: { opacity: 1, transform: "scale(1)" },
        leave: { opacity: 0, transform: "scale(0)" },
        config: config.default,
    });

    useEffect(() => {
        const params = getQueryParamsFromUrl(location.search);
        if (params.q && params.q !== searchValue) {
            setSearchValue(params.q);
        } else if (!params.q) {
            setSearchValue("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    useEffect(() => {
        // Close dropdown if user clicks outside of search bar or dropdown div
        const handleClickOutside = (event: any) => {
            if (inputRef.current && !inputRef.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener("click", handleClickOutside, false);

        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    const onSubmit = useCallback(
        (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (searchValue !== "") {
                const trimmed = searchValue.trim();
                const queryParamsObj = new URLSearchParams({ q: trimmed });

                trackEvent({
                    category: "search bar",
                    name: analyticsMeta?.name || location.pathname,
                    action: analyticsMeta?.action || parent,
                });
                // Route to query
                if (location.pathname.includes("/search/news")) {
                    history.push(`/search/news?${queryParamsObj}`);
                } else if (location.pathname.includes("/search/images")) {
                    history.push(`/search/images?${queryParamsObj}`);
                } else {
                    history.push(`/search/web?${queryParamsObj}`);
                }
                setShowSuggestions(false);
                closeHamburger && closeHamburger();
            }
        },
        [analyticsMeta?.action, analyticsMeta?.name, closeHamburger, history, location.pathname, parent, searchValue, trackEvent]
    );

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }, []);

    const clearSearchQuery = useCallback(() => setSearchValue(""), []);

    const onFocus = useCallback(() => setShowSuggestions(true), []);
    // const onBlur = useCallback(() => setShowTopics(false), []);

    const hasTopics = !!data?.topics?.length;
    const hasPopularSearchTerms = !!data?.popular_search_terms?.length;

    return (
        <div className="SearchBar" ref={inputRef}>
            <form onSubmit={onSubmit}>
                <Input
                    type="search"
                    className="search-bar"
                    placeholder={placeholder}
                    size="large"
                    name="searchBar"
                    prefix={
                        <CustomSearch
                            style={{
                                visibility: searchValue ? "hidden" : "visible",
                                opacity: searchValue ? 0 : 1,
                                transition: "visibility 0.3s linear,opacity 0.3s linear",
                            }}
                        />
                    }
                    suffix={
                        <CustomSubmit
                            style={{
                                visibility: searchValue ? "visible" : "hidden",
                                opacity: searchValue ? 1 : 0,
                                transition: "visibility 0.3s linear,opacity 0.3s linear",
                            }}
                            onSubmit={onSubmit}
                            onClose={clearSearchQuery}
                            classes={["closeIcon", "searchButton"]}
                        />
                    }
                    onFocus={onFocus}
                    // onBlur={onBlur} // TODO: figure out why this block click event on the SearchBarItem (Firefox only)
                    autoComplete="off"
                    value={searchValue}
                    onChange={handleInputChange}
                />
            </form>
            {dropdown &&
                (hasTopics || hasPopularSearchTerms) &&
                transitions(
                    (styles, item) =>
                        item && (
                            <animated.div className="dropdown" style={styles}>
                                <Card>
                                    <div ref={dropdownRef}>
                                        {hasPopularSearchTerms && (
                                            <>
                                                <Title className="title" level={3}>
                                                    Popular Searches
                                                </Title>
                                                {data?.popular_search_terms?.map((searchTerm, i) => (
                                                    <Link
                                                        to={`/search/web?${new URLSearchParams({ q: searchTerm.value || "" })}`}
                                                        key={searchTerm.value}
                                                        className="popular-search-term"
                                                        onClick={() => {
                                                            // click popular search term from search bar drop down menu, action is the search term
                                                            trackEvent({
                                                                category: "search bar",
                                                                name: "popular searches",
                                                                action: searchTerm.value || "missing search term",
                                                            });
                                                            closeHamburger && closeHamburger();
                                                            setShowSuggestions(false);
                                                        }}
                                                    >
                                                        <span>
                                                            <span>
                                                                <SearchIcon />
                                                            </span>
                                                            <span>{searchTerm.value}</span>
                                                        </span>
                                                    </Link>
                                                ))}
                                            </>
                                        )}

                                        {hasTopics && (
                                            <>
                                                <Title className="title" level={3}>
                                                    Popular Topics
                                                </Title>
                                                <div className="topics">
                                                    {data?.topics?.map(({ id, name, image_url }, i) => (
                                                        <SearchBarItem
                                                            key={id || i}
                                                            avatarSrc={image_url || ""}
                                                            topicName={name || "Missing title"}
                                                            id={id || ""}
                                                            onClick={() => {
                                                                // click topic from search bar drop down menu, action is topic name
                                                                trackEvent({
                                                                    category: "search bar",
                                                                    name: "popular topic",
                                                                    action: name || "missing name",
                                                                });
                                                                closeHamburger && closeHamburger();
                                                                setShowSuggestions(false);
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Card>
                            </animated.div>
                        )
                )}
        </div>
    );
};

export default SearchBar;
