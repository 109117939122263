import { FC, useCallback } from "react";
import Card                from "@/designSystem/Card/Card";
import Link                from "@/designSystem/Link/Link";
import Text                from "@/designSystem/Text/Text";
import { toTimeAgo }       from "@/helpers/index";
import "./FeaturePreviewItem.less";

interface FeaturePreviewItemProps {
    item: {
        id: string;
        title: string;
        url: string;
        subcategory: string;
        images: string[];
        createdAt: string;
    };
    onClick: Function;
}

const FeaturePreviewItem: FC<FeaturePreviewItemProps> = ({ item, onClick }) => {
    const onItemClick = useCallback(() => {
        onClick(item.url);
    }, [item.url, onClick]);

    return (
        <Card
            className="FeaturePreviewItem"
            cover={
                <Link onClick={onItemClick} href={item.url}>
                    <img alt="example" src={(Array.isArray(item.images) && item.images[0]) || ""} />
                </Link>
            }
        >
            <Text className="subtitle">{item.subcategory}</Text>
            <Text className="title">
                <Link onClick={onItemClick} href={item.url}>
                    {item.title}
                </Link>
            </Text>
            <div className="foot">
                <Text>{toTimeAgo(item.createdAt)}</Text>
            </div>
        </Card>
    );
};

export default FeaturePreviewItem;
