import { FC, useCallback, useState }   from "react";
import { useMatomo }                   from "@datapunt/matomo-tracker-react";
import { Collapse }                    from "antd";
import useBreakpoint                   from "antd/lib/grid/hooks/useBreakpoint";
import { useHistory, useParams }       from "react-router-dom";
import Avatar                          from "@/designSystem/Avatar/Avatar";
import Divider                         from "@/designSystem/Divider/Divider";
import { Col, Row }                    from "@/designSystem/Grid/Grid";
import CustomSearch                    from "@/designSystem/Icons/CustomSearch";
import Link                            from "@/designSystem/Link/Link";
import Text                            from "@/designSystem/Text/Text";
import Title                           from "@/designSystem/Title/Title";
import ContentCard                     from "@/molecules/ContentCard/ContentCard";
import ErrorResult                     from "@/molecules/ErrorResult/ErrorResult";
import JoinTheNewsLetterBanner         from "@/molecules/JoinTheNewsLetterBanner/JoinTheNewsLetterBanner";
import NewsSearchResult                from "@/molecules/NewsSearchResult/NewsSearchResult";
import StoryHeader                     from "@/molecules/StoryHeader/StoryHeader";
import StoryPreviewCard                from "@/molecules/StoryPreviewCard/StoryPreviewCard";
import TopicPreviewCard                from "@/molecules/TopicPreviewCard/TopicPreviewCard";
import TweetCard                       from "@/molecules/TweetCard/TweetCard";
import AppDownloadBanner               from "@/organisms/AppDownloadBanner/AppDownloadBanner";
import { ReactComponent as TorchIcon } from "@/static/icons/fire-torch.svg";
import "./TopicsV2.less";
import { TopicVersions }               from "./TopicsV2Data";

interface TopicV2Param {
    topic_id: string;
}

const TopicsV2: FC = () => {
    const { xs } = useBreakpoint();
    const screenSize = xs ? "small" : "large";
    const history = useHistory();
    const { topic_id } = useParams<TopicV2Param>();
    const topicVersionIndex = parseInt(topic_id) - 1;
    const { Panel } = Collapse;
    const [isSummaryExpanded, setSummaryExpanded] = useState(false);
    // const [isDescExpanded, setIsDescExpanded] = useState(false);
    const [indexes, setIndexes] = useState<number[]>([]);

    const { trackEvent } = useMatomo();

    const reportStoryItemClick = useCallback(
        (topic: string, type: string) => () => {
            trackEvent({
                category: "freespoke topic 2.0",
                name: topic,
                action: type,
            });
        },
        [trackEvent]
    );

    const displayTweet = useCallback(
        (tweet, i) => {
            return (
                <TweetCard
                    key={`tweet-${tweet.id}-${i}`}
                    data={{
                        id: tweet.id,
                        author: {
                            name: tweet.author.name,
                            username: tweet.author.username,
                            profile_image_url: tweet.author.profile_image_url,
                        },
                        text: tweet.text,
                        datePublished: tweet.datePublished,
                        url: tweet.url,
                        bias: tweet.bias,
                    }}
                    maxLines={7}
                    reportClick={reportStoryItemClick(tweet.text, "topic 2 Response highlights tweet click")}
                />
            );
        },
        [reportStoryItemClick]
    );

    const displayArticle = useCallback(
        (article, i) => {
            return article.tweet_id ? (
                displayTweet(article, i)
            ) : (
                <ContentCard
                    key={`left-${article.id}-${i}`}
                    url={article.url}
                    datePublished={article.datePublished}
                    publisherName={article.publisherName}
                    publisherIcon={article.publisherIcon}
                    images={article.images}
                    bias={article.bias}
                    title={article.title}
                    reportClick={reportStoryItemClick(article.title, `topic 2 Response highlights ${article.footerLabel ?? "article"} click`)}
                    boldHeadline
                    displayPlayIcon
                    footerLabel={article.footerLabel}
                />
            );
        },
        [displayTweet, reportStoryItemClick]
    );

    const onSearchTopic = (topic: string) => {
        const queryParamsObj = new URLSearchParams({ q: topic });
        history.push(`/search/web?${queryParamsObj}`);
    };

    const handleClick = val => {
        setSummaryExpanded(!isSummaryExpanded);
        reportStoryItemClick("topic summary", "topic 2 read more - summary ")();
    };

    const handleArticleIndex = (i, topic: string) => {
        if (indexes.includes(i)) {
            setIndexes(indexes.filter(num => num !== i));
        } else {
            setIndexes([...indexes, i]);
            reportStoryItemClick(topic, "topic 2 Show more results")();
        }
    };

    if (!TopicVersions[topicVersionIndex]) {
        return <ErrorResult error="404" containerClassName="topic-error" />;
    }

    return (
        <div className="TopicsV2">
            <StoryHeader
                category={""}
                name={"The Battle for Free Speech"}
                images={["/images/TopicsV2/battle-free-speech.png"]}
                updated_at={""}
                page="topic 2 share"
            >
                <div className="topic-summary">
                    <span className="title-container">
                        <img src={TopicVersions[topicVersionIndex].topicSummary.icon} alt="summary" />
                        <Text className="topic-title">{TopicVersions[topicVersionIndex].topicSummary.title}</Text>
                    </span>
                    <p className={`para ${isSummaryExpanded ? "" : "summary-length"}`}>
                        <span>{TopicVersions[topicVersionIndex].topicSummary.para1.source}</span>
                        {TopicVersions[topicVersionIndex].topicSummary.para1.description}
                    </p>
                    <Collapse className="show-more" destroyInactivePanel={true} ghost expandIconPosition="left" onChange={handleClick}>
                        <Panel showArrow={false} header={`${isSummaryExpanded ? "Show less.." : "Read more.."}`} key="1">
                            <p className="para">
                                <span>{TopicVersions[topicVersionIndex].topicSummary.para2.source}</span>
                                {TopicVersions[topicVersionIndex].topicSummary.para2.description}
                            </p>
                        </Panel>
                    </Collapse>
                </div>
            </StoryHeader>
            {!xs ? (
                <div className="deep-dives">
                    <div>
                        <TorchIcon className="torch" />
                        <Text className="title">
                            Freespoke finds the inside scoop to important topics and cut through big tech to deliver clear stories and deep dives.
                        </Text>
                    </div>
                    <div className="stories">
                        <div className="story" onClick={() => onSearchTopic("The Origin of Cancel Culture")}>
                            The Origin of Cancel Culture
                        </div>
                        <span className="dot"></span>
                        <div className="story" onClick={() => onSearchTopic("What is Free Speech?")}>
                            What is Free Speech?
                        </div>
                        <span className="dot"></span>
                        <div className="story" onClick={() => onSearchTopic("Does Big Tech Censor Me?")}>
                            Does Big Tech Censor Me?
                        </div>
                    </div>
                </div>
            ) : null}
            <>
                {TopicVersions[topicVersionIndex].topicHighlights.map((highlight, i) => (
                    <div key={highlight.title}>
                        <div className="topic-highlights">
                            <Row
                                gutter={[
                                    { xs: 32, sm: 72, md: 72, lg: 72 },
                                    { xs: 32, sm: 72, md: 72, lg: 72 },
                                ]}
                            >
                                <Col xs={24} sm={24} md={24} lg={17}>
                                    <div className="highlights-title">
                                        <div className="title-container">
                                            <Title className="title">{highlight.title}</Title>
                                            <Link
                                                className="search-topic"
                                                to={`/search/web?${new URLSearchParams({ q: highlight.title })}`}
                                                onClick={reportStoryItemClick(highlight.title, "topic 2 see search results ")}
                                            >
                                                <CustomSearch /> See Search Results
                                            </Link>
                                        </div>
                                        <div className="publisherDetails">
                                            {highlight.publisherIcon && <Avatar className="publisherIcon" size={20} src={highlight.publisherIcon} />}
                                            <Text className="publisherName">{highlight.publisherName}</Text>
                                        </div>
                                        <Text className={`description`}>{highlight.description}</Text>
                                        {/* <Text className={`description ${isDescExpanded ? "" : "desc-lines"}`}>{highlight.description}</Text>
                                    <div className="show-description" onClick={() => setIsDescExpanded(!isDescExpanded)}>
                                        {isDescExpanded ? "Show less.." : "Read more.."}
                                    </div> */}
                                    </div>
                                    <Divider className="divider" />
                                    <Title level={3} className="">
                                        Response Highlights
                                    </Title>
                                    <div className="bias-container">{highlight.articles?.map(displayArticle)}</div>
                                    <Divider className="divider" />
                                    {highlight.newsArticles.slice(0, 2).map(result => (
                                        <div key={result.id}>
                                            <NewsSearchResult
                                                article={result}
                                                size={screenSize}
                                                showUrl
                                                analyticsMeta={{ action: "topic 2 Response highlights click" }}
                                            />
                                            <Divider className="divider" />
                                        </div>
                                    ))}
                                    <Collapse
                                        className="show-more"
                                        destroyInactivePanel={true}
                                        ghost
                                        expandIconPosition="left"
                                        onChange={() => handleArticleIndex(i, highlight.title)}
                                    >
                                        <Panel
                                            showArrow={false}
                                            header={`${indexes.some(num => num === i) ? "Show less results.." : "Show more results.."}`}
                                            key={i}
                                        >
                                            {highlight.newsArticles.slice(2).map(result => (
                                                <div key={result.id}>
                                                    <NewsSearchResult
                                                        article={result}
                                                        size={screenSize}
                                                        showUrl
                                                        analyticsMeta={{ action: "topic 2 Response highlights click" }}
                                                    />
                                                    <Divider className="divider" />
                                                </div>
                                            ))}
                                        </Panel>
                                    </Collapse>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={7} className="by-numbers">
                                    {highlight?.byNumbers && (
                                        <>
                                            <Text className="title">BY THE NUMBERS</Text>
                                            <div className="container">
                                                <Text className="percent">{highlight.byNumbers.percent}</Text>
                                                <Text className="opinion">{highlight.byNumbers.opinion}</Text>
                                            </div>
                                        </>
                                    )}
                                    {highlight.topStories.length > 0 && (
                                        <div className="top-stories">
                                            <Title level={4} className="title">
                                                Top Stories
                                            </Title>
                                            <Divider className="divider" />
                                            {highlight.topStories.map((topic, i) => (
                                                <div key={topic.url}>
                                                    <Link
                                                        href={topic.url}
                                                        className="topic"
                                                        onClick={reportStoryItemClick(topic.name, "topic 2 top stories")}
                                                    >
                                                        <h6 className="circle">{i + 1}</h6>
                                                        <Title level={4} ellipsis={{ rows: 3 }} className="title topic-name">
                                                            {topic.name}
                                                        </Title>
                                                    </Link>
                                                    <Divider className="divider" key={topic.name} />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        {(i === 0 || i === 3) && <AppDownloadBanner key={highlight.description} />}
                        {!(i === 0 || i === 3 || i === 5) && <Divider key={highlight.publisherName} />}
                    </div>
                ))}
            </>
            <div className="related">
                <div className="max-width-related">
                    <>
                        <div className="title-container">
                            <Title className="related-title">Related Stories</Title>
                        </div>

                        <div className="related-stories-container">
                            {TopicVersions[topicVersionIndex].relatedStories.map((story, i) => (
                                <StoryPreviewCard
                                    id={story.id!}
                                    key={story.id || i}
                                    title={story.title || "Missing title"}
                                    leftBiasCount={story.leftBiasCount}
                                    rightBiasCount={story.rightBiasCount}
                                    content={story.content}
                                    updatedAt={story.updatedAt}
                                    analyticsMeta={{ action: "topic 2 related story click" }}
                                />
                            ))}
                        </div>
                    </>
                    <div className="non-scroll">
                        <Divider className="related-divider" />
                        <div className="title-container">
                            <Title className="related-title">Related Topics</Title>
                            <Link
                                onClick={reportStoryItemClick("related topics", "topic 2 related topics view all")}
                                to="/search/categories"
                                className="view-all"
                            >
                                View All
                            </Link>
                        </div>
                        <Row
                            gutter={[
                                { xs: 20, sm: 20, md: 20, lg: 30 },
                                { xs: 20, sm: 20, md: 20, lg: 30 },
                            ]}
                        >
                            {TopicVersions[topicVersionIndex].relatedTopics.map(topic => (
                                <Col span={24} md={{ span: 12 }} key={topic.id}>
                                    <TopicPreviewCard
                                        id={topic.id!}
                                        image={topic.image}
                                        content={topic.content}
                                        title={topic.title || "Missing title"}
                                        key={topic.id}
                                        analyticsMeta={{ action: "topic 2 related topic click" }}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <JoinTheNewsLetterBanner />
        </div>
    );
};

export default TopicsV2;
