import { FC, useCallback, useMemo, useState } from "react";
import { useMatomo }                          from "@datapunt/matomo-tracker-react";
import { animated }                           from "@react-spring/web";
import { useLocation }                        from "react-router";
import { config, useTransition }              from "react-spring";
import { signUpForANewsletter }               from "@/api/newsletterAPI";
import { useBackendAccess }                   from "@/api/useBackendAccess";
import Button                                 from "@/designSystem/Button/Button";
import Form                                   from "@/designSystem/Form/Form";
import MailOutlined                           from "@/designSystem/Icons/MailOutlined";
import RightArrow                             from "@/designSystem/Icons/RightArrow";
import Input                                  from "@/designSystem/Input/Input";
import Text                                   from "@/designSystem/Text/Text";
import AcceptTermsCheckbox                    from "@/molecules/AcceptTermsCheckbox/AcceptTermsCheckbox";
import { getAnalyticsCategory }               from "@/src/analytics/analytics";
import FreespokeLoader                        from "../FreespokeLoader/FreespokeLoader";
import "./JoinTheNewsLetterBanner.less";

const JoinTheNewsLetterBanner: FC = () => {
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const { authHeaders } = useBackendAccess();
    const location = useLocation();
    const { trackEvent } = useMatomo();

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const onFinish = useCallback(
        values => {
            // Prevent additional calls while in loading or post-success state
            if (!loading || !success) {
                setLoading(true);

                signUpForANewsletter(values.email, authHeaders)
                    .then(() => setSuccess(true))
                    .catch(err => form.setFields([{ name: "email", errors: [err] }]))
                    .finally(() => setLoading(false));

                // Analytics
                trackEvent({
                    category: analyticsMeta.category,
                    name: "submit",
                    action: "join the movement",
                });
            }
        },
        [analyticsMeta.category, authHeaders, form, loading, success, trackEvent]
    );

    const transitions = useTransition(success, {
        from: { opacity: 0, transform: "translateX(-100%)" },
        enter: { opacity: 1, transform: "translateX(0)" },
        config: config.default,
    });

    return (
        <div className="JoinTheNewsLetterBanner">
            {transitions(
                (styles, item) =>
                    item && (
                        <animated.div className="success" style={styles}>
                            <Text className="title">Thank you!</Text>
                            <Text className="tagline">Your message has been successfully sent.</Text>
                        </animated.div>
                    )
            )}
            {!success && (
                <>
                    <Text className="title">Join the Movement</Text>
                    <Text className="tagline">Get a daily dose of Freespoke news coverage in your inbox.</Text>

                    <Form onFinish={onFinish} form={form} validateTrigger="onSubmit">
                        <div className="controls-row">
                            <Form.Item
                                className="email-input-container"
                                validateFirst
                                name="email"
                                rules={[
                                    { required: true, message: "" },
                                    { pattern: /^.+@.+$/, message: "Invalid email address" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("accepted_privacy_policy_and_terms") === true) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("You must agree to our Privacy Policy and Terms"));
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="yourname@company.com"
                                    autoComplete="email"
                                    prefix={<MailOutlined className="mail-icon" />}
                                    disabled={loading || success}
                                />
                            </Form.Item>
                            <Button className="submit-button" type="primary" size="large" onClick={() => form.submit()}>
                                {loading ? (
                                    <FreespokeLoader />
                                ) : (
                                    <>
                                        <RightArrow className="icon" />
                                        <Text className="text">Submit</Text>
                                    </>
                                )}
                            </Button>
                        </div>
                        <AcceptTermsCheckbox name="accepted_privacy_policy_and_terms" />
                    </Form>
                    <Text className="data-info">We don't sell your data.</Text>
                </>
            )}
        </div>
    );
};

export default JoinTheNewsLetterBanner;
