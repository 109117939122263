import { FC, useCallback, useMemo, useState } from "react";
import { useMatomo }                          from "@datapunt/matomo-tracker-react";
import { animated }                           from "@react-spring/web";
import clsx                                   from "clsx";
import { useLocation }                        from "react-router";
import { config, useTransition }              from "react-spring";
import { getAnalyticsCategory }               from "@/analytics/analytics";
import { signUpForANewsletter }               from "@/api/newsletterAPI";
import { useBackendAccess }                   from "@/api/useBackendAccess";
import Form                                   from "@/designSystem/Form/Form";
import RightArrow                             from "@/designSystem/Icons/RightArrow";
import Input                                  from "@/designSystem/Input/Input";
import Text                                   from "@/designSystem/Text/Text";
import Title                                  from "@/designSystem/Title/Title";
import AcceptTermsCheckbox                    from "@/molecules/AcceptTermsCheckbox/AcceptTermsCheckbox";
import FreespokeLoader                        from "../FreespokeLoader/FreespokeLoader";
import "./EmailCapture.less";

interface EmailCaptureProps {
    size?: "medium" | "small";
}

const EmailCapture: FC<EmailCaptureProps> = ({ size = "medium" }) => {
    const { trackEvent } = useMatomo();
    const location = useLocation();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const { authHeaders } = useBackendAccess();

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const onFinish = useCallback(
        values => {
            // Prevent additional calls while in loading or post-success state
            if (!loading || !success) {
                setLoading(true);

                signUpForANewsletter(values.email, authHeaders)
                    .then(() => setSuccess(true))
                    .catch(err => form.setFields([{ name: "email", errors: [err] }]))
                    .finally(() => setLoading(false));

                // Analytics
                trackEvent({
                    category: analyticsMeta.category,
                    name: "newsletter sign up",
                    action: "submit",
                });
            }
        },
        [analyticsMeta.category, authHeaders, form, loading, success, trackEvent]
    );

    const transitions = useTransition(success, {
        from: { opacity: 0, transform: "translateX(-100%)" },
        enter: { opacity: 1, transform: "translateX(0)" },
        config: config.default,
    });

    return (
        <div className="EmailCapture">
            <Title className={clsx("title text-white", size === "small" && "text-center title-small")}>
                {size === "small" ? "A" : "We’re building a"} search engine for <span>folks like us.</span>
            </Title>
            <Text className={clsx("subtitle text-white", size === "small" && "text-center subtitle-small")}>
                Sign up for curated, transparent news for free.
            </Text>
            <div className="action-area">
                {transitions(
                    (styles, item) =>
                        item && (
                            <animated.div className="success" style={styles}>
                                <Text>Success!</Text>
                            </animated.div>
                        )
                )}
                <Form onFinish={onFinish} form={form} validateTrigger="onSubmit">
                    <Form.Item
                        validateFirst
                        name="email"
                        rules={[
                            { required: true, message: "" },
                            { pattern: /^.+@.+$/, message: "Invalid email address" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("accepted_privacy_policy_and_terms") === true) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error("You must agree to our Privacy Policy and Terms"));
                                },
                            }),
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="name@company.com"
                            autoComplete="email"
                            suffix={loading ? <FreespokeLoader /> : <RightArrow onClick={() => form.submit()} />}
                            disabled={loading || success}
                        />
                    </Form.Item>
                    <AcceptTermsCheckbox name="accepted_privacy_policy_and_terms" />
                </Form>
            </div>
        </div>
    );
};

export default EmailCapture;
