import { FC, useEffect, useMemo, useState } from "react";
import { useMatomo }                        from "@datapunt/matomo-tracker-react";
import useBreakpoint                        from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet }                           from "react-helmet-async";
import useGetCategoriesAndTopics            from "@/api/useGetCategoriesAndTopics";
import { Col, Row }                         from "@/designSystem/Grid/Grid";
import Paragraph                            from "@/designSystem/Paragraph/Paragraph";
import Title                                from "@/designSystem/Title/Title";
import ErrorResult                          from "@/molecules/ErrorResult/ErrorResult";
import FreespokeLoader                      from "@/molecules/FreespokeLoader/FreespokeLoader";
import CategoryCard                         from "@/organisms/CategoryCard/CategoryCard";
import "./CategoriesSearch.less";

interface CategoriesSearchProps {}

const CategoriesSearch: FC<CategoriesSearchProps> = () => {
    const { xs, sm, md, lg } = useBreakpoint();
    const { data, isLoading, error } = useGetCategoriesAndTopics();
    const { trackPageView } = useMatomo();
    const [stackedCols, setStackedCols] = useState<any[]>();

    useEffect(() => {
        // @ts-ignore
        trackPageView();
    }, [trackPageView]);

    useMemo(() => {
        if (data && data.categories) {
            const chonkAmt = lg || md ? 3 : sm ? 2 : 1;
            const newData = data.categories.reduce((resultChonk: any[], item, index) => {
                const chonk = Math.floor(index % chonkAmt);
                if (!resultChonk[chonk]) {
                    resultChonk[chonk] = [];
                }
                resultChonk[chonk].push(item);
                return resultChonk;
            }, []);
            setStackedCols(newData);
        }
    }, [sm, md, lg, data, setStackedCols]);

    if (error) {
        return <ErrorResult error={error} containerClassName="CategoriesSearch" />;
    }

    return (
        <div className="CategoriesSearch">
            <Helmet>
                <link rel="canonical" href="https://freespoke.com/search/categories" />
                <title>Freespoke Search Topics</title>
                <meta name="description" content="Freespoke is “the easy button” for fresh perspective on controversial topics"></meta>
            </Helmet>
            <div className="CategoriesInner">
                {!xs && (
                    <div className="categories-header">
                        <Title level={1}>Freespoke Topics</Title>
                        <Paragraph>New “hot” topics every day. Form your own opinion with our full coverage results.</Paragraph>
                    </div>
                )}
                {isLoading ? (
                    <FreespokeLoader />
                ) : (
                    <Row gutter={[24, 0]}>
                        {!isLoading &&
                            data &&
                            data.categories &&
                            stackedCols &&
                            stackedCols.length > 0 &&
                            stackedCols.map((col, i) => {
                                return (
                                    <Col md={8} sm={12} xs={24} key={i} className="category-col">
                                        {col.map(category => (
                                            <CategoryCard key={category.id} isSmallScreen={!!xs} category={category} />
                                        ))}
                                    </Col>
                                );
                            })}
                    </Row>
                )}
            </div>
        </div>
    );
};

export default CategoriesSearch;
