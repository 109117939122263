import axios                    from "axios";
import { stringify }            from "query-string";
import useSWR, { KeyedMutator } from "swr";
import { IArticle, ITweet }     from "@/@types/custom";
import { useBackendAccess }     from "@/api/useBackendAccess";
import { BACKEND_URL }          from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export type ContentList = (IArticle & ITweet)[];

export interface useGetHeroStoryResponse {
    id: string;
    images: string[];
    shortTitle: string;
    title: string;
    updatedAt: string;
}

interface useGetHeroStoryReturnType {
    data: useGetHeroStoryResponse | undefined;
    isLoading: boolean;
    error: any;
    refresh: KeyedMutator<useGetHeroStoryResponse>;
}

export interface useGetHeroStoryParams {
    width: number;
    height?: number;
}

function useGetHeroStory(params: useGetHeroStoryParams): useGetHeroStoryReturnType {
    const { authHeaders } = useBackendAccess();
    const url = `${BACKEND_URL}/story/list/hero?${stringify(params)}`;
    const fetcher = () => axios.get(url, { headers: authHeaders }).then(res => res.data);

    const { data, error, isValidating, mutate } = useSWR<useGetHeroStoryResponse>(authHeaders ? url : null, fetcher, config);

    return {
        data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
}

export default useGetHeroStory;
