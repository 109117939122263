import { Carousel }      from "antd";
import { CarouselProps } from "antd/lib/carousel";
import useBreakpoint     from "antd/lib/grid/hooks/useBreakpoint";
import "./ScrollableArticles.less";

const DEFAULT_SETTINGS = {
    dots: false,
    arrows: true,
    variableWidth: false,
    draggable: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                dots: false,
                arrows: true,
                variableWidth: false,
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
    ],
};

interface ScrollableArticlesProps<T> {
    render: (items: Array<T>) => JSX.Element[] | undefined;
    items: Array<T>;
    settings?: CarouselProps;
    onScroll?: (currentSlide: number) => void;
}

const ScrollableArticles: <T extends unknown>({ render, items, settings, onScroll }: ScrollableArticlesProps<T>) => JSX.Element = ({
    render,
    items,
    settings,
    onScroll,
}) => {
    const { xs, sm, md } = useBreakpoint();

    return (
        <div className="ScrollableArticles">
            {md && items && items.length > 0 && (
                <div className="desktop-carousel">
                    <Carousel {...(settings || DEFAULT_SETTINGS)} lazyLoad="ondemand" afterChange={onScroll}>
                        {render(items)}
                    </Carousel>
                </div>
            )}
            {(xs || sm) && !md && items && items.length > 0 && <div className="mobile-carousel">{render(items)}</div>}
        </div>
    );
};

export default ScrollableArticles;
