import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMatomo }                                     from "@datapunt/matomo-tracker-react";
import useBreakpoint                                     from "antd/lib/grid/hooks/useBreakpoint";
import Select                                            from "antd/lib/select";
import clsx                                              from "clsx";
import { useLocation }                                   from "react-router";
import { getAnalyticsCategory }                          from "@/analytics/analytics";
import "./CategorySelect.less";

const Pill: FC<{ active?: boolean } & React.HTMLAttributes<HTMLDivElement>> = ({ children, active = false, ...rest }) => (
    <div {...rest} className={clsx("Pill", active && "Pill-Active")}>
        {children}
    </div>
);

interface CategorySelectProps {
    categories: { label: string; value: string }[];
    value?: string;
    onChange: (value: string) => any;
}

const CategorySelect: FC<CategorySelectProps> = ({ categories, value, onChange }) => {
    const location = useLocation();
    const { md } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const [selected, setSelected] = useState<string>(value || categories[0].value);

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    // Keep value in sync with the internal 'selected' state
    useEffect(() => {
        setSelected(value || categories[0].value);
    }, [categories, value]);

    const reportClickOnItem = useCallback(
        (topicName: string) => {
            trackEvent({
                category: analyticsMeta.category,
                name: "sub topic name",
                action: topicName,
            });
        },
        [analyticsMeta.category, trackEvent]
    );

    const changeEvent = (value: string, { label }: { label: string } & any) => {
        setSelected(value);
        onChange(value);
        reportClickOnItem(label);
    };

    return (
        <div className="CategorySelect">
            {md ? (
                categories.map(({ label, value }, key) => (
                    <Pill key={key} active={value === selected} onClick={() => changeEvent(value, { label })}>
                        {label}
                    </Pill>
                ))
            ) : (
                <Select virtual={false} value={selected} options={categories} onChange={changeEvent} size="large"></Select>
            )}
        </div>
    );
};

export default CategorySelect;
