import { FC, useState } from "react";
import { Collapse }     from "antd";
import ImageLoader      from "@/components/utils/ImageLoader";
import { slugify }      from "@/components/utils/slugify";
import Card             from "@/designSystem/Card/Card";
import Link             from "@/designSystem/Link/Link";
import "./CategoryCard.less";

const { Panel } = Collapse; //for showing/hiding topic links
const { Meta } = Card; //for category info

interface CategoryPanelProps {
    topics?: Array<{
        id: string;
        name: string;
    }>;
    isSmallScreen: boolean;
}

const CategoryPanel: FC<CategoryPanelProps> = ({ topics, isSmallScreen }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    if (!topics) {
        return null;
    }

    const mapTopics = (type: string) => {
        const sliceAmt = isSmallScreen ? topics.length : 10;
        if (type === "top") {
            return topics.slice(0, sliceAmt).map((item, i) => (
                <Link key={i} to={`/search/topic/${item.id}/${slugify(item.name)}`} className="TopicLink">
                    {item.name}
                </Link>
            ));
        } else {
            return topics.slice(sliceAmt).map((item, i) => (
                <Link key={i} to={`/search/topic/${item.id}/${slugify(item.name)}`} className="TopicLink">
                    {item.name}
                </Link>
            ));
        }
    };
    return (
        <>
            {mapTopics("top")}
            {!isSmallScreen && topics.length > 10 && (
                <Collapse className="show-more" destroyInactivePanel={true} accordion={true} ghost onChange={handleClick}>
                    <Panel header={`View ${isExpanded ? "Fewer" : "More"}`} showArrow={false} key="1">
                        {mapTopics("pannel")}
                    </Panel>
                </Collapse>
            )}
        </>
    );
};

interface CategoryCardProps {
    category: {
        name: string;
        images: string[];
        topics?: Array<{
            id: string;
            name: string;
        }>;
    };
    isSmallScreen: boolean;
}

const CategoryCard: FC<CategoryCardProps> = ({ category, isSmallScreen }) => {
    if (!category) {
        return null;
    }

    return (
        <Card className="CategoryCard" cover={<ImageLoader className="category-img" alt={category.name} srcList={category.images} />}>
            <Meta title={category.name} description={<CategoryPanel isSmallScreen={isSmallScreen} topics={category.topics} />} />
        </Card>
    );
};

export default CategoryCard;
