import { FC }      from "react";
import EmailHeader from "@/molecules/EmailHeader/EmailHeader";
import "./CensoredHeader.less";

interface CensoredHeaderProps {}

const CensoredHeader: FC<CensoredHeaderProps> = () => (
    <EmailHeader
        className="CensoredHeader"
        title="Censored Stories"
        subtitle="Timeline of Cancel Culture Attacks, the Main Stories the Media Doesn’t Want You to See. Sign up for the best stories in your inbox every week!"
    />
);

export default CensoredHeader;
