import { FC, useMemo, useState } from "react";
import { useMatomo }             from "@datapunt/matomo-tracker-react";
import { useSpring }             from "@react-spring/core";
import { animated }              from "@react-spring/web";
import clsx                      from "clsx";
import { useLocation }           from "react-router";
import { getAnalyticsCategory }  from "@/analytics/analytics";
import { signUpForANewsletter }  from "@/api/newsletterAPI";
import { useBackendAccess }      from "@/api/useBackendAccess";
import Button                    from "@/designSystem/Button/Button";
import Form                      from "@/designSystem/Form/Form";
import RightArrow                from "@/designSystem/Icons/RightArrow";
import Input                     from "@/designSystem/Input/Input";
import Text                      from "@/designSystem/Text/Text";
import Title                     from "@/designSystem/Title/Title";
import FreespokeLoader           from "../FreespokeLoader/FreespokeLoader";
import "./EmailHeader.less";

interface EmailHeaderProps {
    title: string;
    subtitle: string;
    className?: string;
}

const EmailHeader: FC<EmailHeaderProps> = ({ title, subtitle, className }) => {
    const { trackEvent } = useMatomo();
    const location = useLocation();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { authHeaders } = useBackendAccess();

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const onFinish: (values: { email: string }) => void = values => {
        // Prevent additional calls while in loading or post-success state
        if (!loading || !success) {
            setLoading(true);

            signUpForANewsletter(values.email, authHeaders)
                .then(() => setSuccess(true))
                .catch(err => form.setFields([{ name: "email", errors: [err] }]))
                .finally(() => setLoading(false));

            // Analytics
            trackEvent({
                category: analyticsMeta.category,
                name: "newsletter sign up",
                action: "submit",
            });
        }
    };

    const spring = useSpring(
        success
            ? {
                  transform: "translateX(0%)",
                  opacity: 1,
              }
            : {
                  transform: "translateX(-100%)",
                  opacity: 0,
              }
    );

    return (
        <div className={clsx("EmailHeader", className && className)}>
            <div className="max-wrap">
                <Title level={1} className="title">
                    {title}
                </Title>
                <Text className="subtitle">{subtitle}</Text>
                <Form className="collection-form" onFinish={onFinish} form={form} validateTrigger="onSubmit">
                    <Form.Item
                        className="email-form-item"
                        validateFirst
                        name="email"
                        rules={[
                            { required: true, message: "" },
                            { pattern: /^.+@.+$/, message: "Invalid email address" },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="name@company.com"
                            autoComplete="email"
                            suffix={loading ? <FreespokeLoader /> : <RightArrow onClick={() => form.submit()} />}
                            disabled={loading || success}
                        />
                    </Form.Item>
                    <Form.Item className="submit-form-item">
                        <Button type="primary" htmlType="submit" size="large" disabled={loading || success}>
                            Submit
                        </Button>
                    </Form.Item>
                    <animated.div className="sign-up-success" style={spring}>
                        <Text>Success!</Text>
                    </animated.div>
                </Form>
            </div>
        </div>
    );
};

export default EmailHeader;
