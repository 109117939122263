import { FC, useCallback, useEffect } from "react";
import { AnalyticsMeta }              from "@/@types/custom";
import Button                         from "@/designSystem/Button/Button";
import CustomClose                    from "@/designSystem/Icons/CustomClose";
import Link                           from "@/designSystem/Link/Link";
import AntModal                       from "@/designSystem/Modal/Modal";
import Title                          from "@/designSystem/Title/Title";
import { isMobileWidth }              from "@/helpers/index";
import SpangldTake                    from "@/molecules/SpangldTake/SpangldTake";
import ImageLoader                    from "@/utils/ImageLoader";
import "./SpangldPreview.less";

interface SpangldPreviewProps {
    data: {
        id: string;
        title: string;
        images: string[];
        rawHTML: string | undefined;
        image?: string;
        link?: string;
        url?: string;
    };
    analyticsMeta: AnalyticsMeta;
    onViewSummaryClick?: () => void;
}

const SpangldPreview: FC<SpangldPreviewProps> = ({ data, analyticsMeta, onViewSummaryClick }) => {
    // No idea if this is the
    useEffect(() => {
        const handleScroll = () => {
            const body = Array.from(document.getElementsByClassName("ant-modal-body") as HTMLCollectionOf<HTMLElement>)[0];
            const header = Array.from(document.getElementsByClassName("ant-modal-header") as HTMLCollectionOf<HTMLElement>)[0];
            if (body && header) {
                if (body.scrollTop === 0) {
                    header.style.boxShadow = "none";
                } else {
                    header.style.boxShadow = "0px 0px 16px rgba(0, 0, 0, 0.16)";
                }
            }
        };
        document.getElementsByClassName("ant-modal-body ")[0]?.addEventListener("scroll", handleScroll);
        return () => document.getElementsByClassName("ant-modal-body ")[0]?.removeEventListener("resize", handleScroll);
    }, []);

    const onPreviewClose = useCallback(() => {
        if (typeof onViewSummaryClick === "function") {
            onViewSummaryClick();
        }
    }, [onViewSummaryClick]);

    return (
        <AntModal
            className="SpangldModal"
            width={isMobileWidth() ? 600 : "100%"}
            maskStyle={{ background: "rgba(59,67,85,0.8)" }}
            centered={true}
            title={<SpangldTake url={data.link || data.url || "spangld.com"} analyticsMeta={analyticsMeta} />}
            visible={true}
            onCancel={onPreviewClose}
            closeIcon={<CustomClose />}
            footer={null}
        >
            <div className="SpangldPreview">
                <div className="SpangldTop">
                    <div className="SpangldImage">
                        <ImageLoader key={`spangld-preview-${data.id}`} srcList={data?.images} alt="Spangld Preview" />
                    </div>
                    <div className="SpangldInfo">
                        <Title className="SpangldTitle" level={2}>
                            {data.title}
                        </Title>
                        <div className="SpangldLink">
                            <Link href={data.link || data.url || "spangld.com"}>
                                <Button type="primary">Read Full Article</Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="SpangldBody">
                    <div className="SpangldSummaryList" dangerouslySetInnerHTML={{ __html: data.rawHTML || "" }}></div>
                </div>
            </div>
        </AntModal>
    );
};

export default SpangldPreview;
