import { FC, memo, useCallback, useMemo } from "react";
import { useMatomo }                      from "@datapunt/matomo-tracker-react";
import { useLocation }                    from "react-router-dom";
import { AnalyticsMeta }                  from "src/@types/custom";
import { getAnalyticsCategory }           from "@/analytics/analytics";
import { slugify }                        from "@/components/utils/slugify";
import Avatar                             from "@/designSystem/Avatar/Avatar";
import Card                               from "@/designSystem/Card/Card";
import Divider                            from "@/designSystem/Divider/Divider";
import RightArrow                         from "@/designSystem/Icons/RightArrow";
import Link                               from "@/designSystem/Link/Link";
import Text                               from "@/designSystem/Text/Text";
import Title                              from "@/designSystem/Title/Title";
import "./TopicPreviewCard.less";

export interface TopicPreviewCardProps {
    id: string; // topic id, used to construct the URL where users will be navigated if they click the topic preview card
    title: string;
    image: string | undefined;
    content:
        | {
              title: string;
          }[]
        | undefined;
    analyticsMeta?: AnalyticsMeta;
}

const TopicPreviewCard: FC<TopicPreviewCardProps> = memo(({ id, title, image, content, analyticsMeta }) => {
    const location = useLocation();
    const { trackEvent } = useMatomo();

    // Get analytics category name - 'home', 'freespoke topic' etc
    const analytics = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const reportClick = useCallback(() => {
        trackEvent({
            category: analytics.category,
            name: title,
            action: analyticsMeta?.action || "related topics suggestion",
        });
    }, [analytics.category, analyticsMeta?.action, title, trackEvent]);

    const url = useMemo(() => `/search/topic/${id}/${slugify(title)}`, [id, title]);

    return (
        <Link to={url} className="TopicPreviewCard" onClick={reportClick}>
            <Card className="card-container">
                <div className="titleContainer">
                    {image && <Avatar src={image} alt={"topic image"} size={40} className="image" />}
                    <Title level={3} className="title">
                        {title}
                    </Title>
                    <RightArrow className="right-arrow" />
                </div>
                {Array.isArray(content) && content.length > 0 && (
                    <>
                        <Divider className="divider" />
                        {content.map((item, i) => (
                            <Text key={i} className="text">
                                {item.title}
                            </Text>
                        ))}
                    </>
                )}
            </Card>
        </Link>
    );
});

export default TopicPreviewCard;
