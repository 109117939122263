import useBreakpoint     from "antd/lib/grid/hooks/useBreakpoint";
import { AnalyticsMeta } from "@/@types/custom";
import useGetHomepage    from "@/api/useGetHomepage";
import Title             from "@/designSystem/Title/Title";
import FreespokeLoader   from "@/molecules/FreespokeLoader/FreespokeLoader";
import ContentHolder     from "@/organisms/ContentHolder/ContentHolder";
import "./StoryPageNewsFeed.less";

interface StoryPageNewsFeedProps {
    analyticsMeta: Omit<AnalyticsMeta, "action">;
}
const StoryPageNewsFeed = ({ analyticsMeta }: StoryPageNewsFeedProps) => {
    const { data, isLoading } = useGetHomepage({ include: ["featured_stories", "top_stories"] });
    const { lg } = useBreakpoint();

    return (
        <div className="StoryPageNewsFeed">
            <Title className="title" level={3}>
                Scroll for Freespoke Newsfeed
            </Title>
            {!isLoading && data && (
                <ContentHolder
                    isMobile={lg ? false : true}
                    spangld={data.spangld}
                    stories={data.stories}
                    best_of_web={data.best_of_web}
                    censored_stories={data.censored_stories}
                    featured_stories={data.featured_stories}
                    analyticsMeta={analyticsMeta}
                />
            )}
            {isLoading && <FreespokeLoader />}
        </div>
    );
};

export default StoryPageNewsFeed;
