import { FC, useCallback, useMemo, useState } from "react";
import { useMatomo }                          from "@datapunt/matomo-tracker-react";
import { AnalyticsMeta }                      from "src/@types/custom";
import Link                                   from "@/designSystem/Link/Link";
import Paragraph                              from "@/designSystem/Paragraph/Paragraph";
import Text                                   from "@/designSystem/Text/Text";
import Title                                  from "@/designSystem/Title/Title";
import { stripHTML }                          from "@/helpers/index";
import SpangldPreview                         from "@/molecules/SpangldPreview/SpangldPreview";
import SpangldLogo                            from "@/static/images/spangld_logo.png";
import "./SpangldCTA.less";

interface SpangldCTAProps {
    id: string;
    images: string[];
    url: string;
    title: string;
    textSummary: string;
    rawHTML: string;
    analyticsMeta: Omit<AnalyticsMeta, "action">;
}

const SpangldCTA: FC<SpangldCTAProps> = ({ images, url, title, textSummary, rawHTML, id, analyticsMeta }) => {
    const { trackEvent } = useMatomo();
    const [showSpangldPreview, setShowSpangldPreview] = useState(false);

    // Since we can't pass variables to Less directly, generate background img/gradient via React CSS props
    const background: React.CSSProperties = {
        background: `linear-gradient(360deg, #07172e 8.85%, rgba(8, 26, 51, 0) 100%) ${images?.length ? `, url(${images[0]}` : ""})`,
    };

    const reportViewSummaryClick = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            action: title,
            name: "spangld view summary",
        });
    }, [trackEvent, analyticsMeta.category, title]);

    const reportClickOnSpangldTake = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            action: url,
            name: "view the spangld take ",
        });
    }, [trackEvent, analyticsMeta.category, url]);

    const toggleShowPreview = useCallback(() => {
        if (!showSpangldPreview) {
            reportViewSummaryClick();
        }
        setShowSpangldPreview(!showSpangldPreview);
    }, [reportViewSummaryClick, showSpangldPreview]);

    const textSummaryWithoutHTML = useMemo(() => stripHTML(textSummary), [textSummary]);

    return (
        <div className="SpangldCTA" style={background}>
            <div className="Branding">
                <Link href={url} onClick={reportClickOnSpangldTake}>
                    <img src={SpangldLogo} alt="Spangld Logo" />
                    <Text>The Spangld Take</Text>
                </Link>
            </div>
            <Title level={2} ellipsis={{ rows: 10 }}>
                {title}
            </Title>
            <Paragraph className="summary" ellipsis={{ rows: 15 }}>
                {textSummaryWithoutHTML}
            </Paragraph>
            {rawHTML && (
                <span className="SpangldView" onClick={toggleShowPreview}>
                    View Summary
                </span>
            )}
            {showSpangldPreview && (
                <SpangldPreview data={{ images, url, title, rawHTML, id }} onViewSummaryClick={toggleShowPreview} analyticsMeta={analyticsMeta} />
            )}
        </div>
    );
};

export default SpangldCTA;
