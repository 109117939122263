import { FC }      from "react";
import { slugify } from "@/components/utils/slugify";
import Avatar      from "@/designSystem/Avatar/Avatar";
import Link        from "@/designSystem/Link/Link";
import "./SearchBarItem.less";

interface SearchBarItemProps {
    avatarSrc: string;
    topicName: string;
    id: string;
    onClick: VoidFunction;
}

const SearchBarItem: FC<SearchBarItemProps> = ({ avatarSrc, topicName, id, onClick }) => {
    return (
        <Link className="SearchBarItem" to={`/search/topic/${id}/${slugify(topicName as string)}`} onClick={onClick}>
            <Avatar src={avatarSrc} />
            <span>{topicName}</span>
        </Link>
    );
};

export default SearchBarItem;
