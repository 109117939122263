import { useEffect } from "react";

/**
 * Use App Height
 *
 * Calculates the viewport height and assigns it to a css variable (--app-height).
 *
 * The purpose of this hook is to create a variable that can replace '100vh'. When
 * rendering on mobile, often times 100vh will be incorrect because the mobile
 * browser doesn't take the browser toolbar into consideration when running its
 * calculations view height calculations.
 */
const useAppHeight = () => {
    const calculateViewportHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    useEffect(() => {
        window.addEventListener("resize", calculateViewportHeight);
        window.addEventListener("load", calculateViewportHeight);
        return () => {
            window.removeEventListener("resize", calculateViewportHeight);
            window.removeEventListener("load", calculateViewportHeight);
        };
    }, []);
};

export default useAppHeight;
