import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMatomo }                                        from "@datapunt/matomo-tracker-react";
import { CarouselProps, Divider }                           from "antd";
import useBreakpoint                                        from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet }                                           from "react-helmet-async";
import { useHistory, useLocation }                          from "react-router-dom";
import { getAnalyticsCategory }                             from "@/analytics/analytics";
import useSearchArticles                                    from "@/api/useSearchArticles";
import { Col, Row }                                         from "@/designSystem/Grid/Grid";
import AntPagination                                        from "@/designSystem/Pagination/Pagination";
import Title                                                from "@/designSystem/Title/Title";
import NewsSearchResult                                     from "@/molecules//NewsSearchResult/NewsSearchResult";
import ContentCard                                          from "@/molecules/ContentCard/ContentCard";
import ErrorResult                                          from "@/molecules/ErrorResult/ErrorResult";
import FreespokeLoader                                      from "@/molecules/FreespokeLoader/FreespokeLoader";
import GenericContentCard                                   from "@/molecules/GenericContentCard/GenericContentCard";
import ScrollableArticles                                   from "@/molecules/ScrollableArticles/ScrollableArticles";
import SuggestionText                                       from "@/molecules/SuggestionText/SuggestionText";
import Timeline                                             from "@/molecules/Timeline/Timeline";
import TopicsCard                                           from "@/organisms/TopicsCard/TopicsCard";
import "./NewsSearch.less";

const PER_PAGE = 12;

namespace NewsSearchParams {
    export type Params = {
        q: string;
        page: number;
    };
}

const CAROUSEL_SETTINGS: CarouselProps = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: true,
    slidesToShow: 2,
    rows: 1,
    slidesToScroll: 2,
};

export default function NewsSearch() {
    const { xs } = useBreakpoint();
    const location = useLocation();
    const history = useHistory();
    const { trackEvent } = useMatomo();

    const [params, setParams] = useState<NewsSearchParams.Params>(() => {
        const urlParams = new URLSearchParams(location.search);
        return {
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page") || "1"),
        };
    });

    const { data, isLoading, error } = useSearchArticles(params.q, {
        page: params.page,
        perPage: PER_PAGE,
        include: ["timeline", "suggested_topics", "censored"],
    });

    const screenSize = xs ? "small" : "large";

    /* Scroll to top anytime user changes the page and trigger refetch if "page" or "q" changes */
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        setParams({
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page") || "1"),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const onPaginationChange = useCallback(
        (pageNum: number) => {
            history.push({ pathname: location.pathname, search: `?${new URLSearchParams({ ...params, page: pageNum.toString() })}` });
        },
        [history, location.pathname, params]
    );

    const canonicalLinkParams = useMemo(() => (!params.q ? "" : `?${new URLSearchParams({ ...params, page: params.page.toString() })}`), [params]);

    const reportNewsSearchContentClick = useCallback(
        (url: string) => {
            trackEvent({
                category: getAnalyticsCategory(location.pathname),
                name: "news tab content click",
                action: url,
            });
        },
        [location.pathname, trackEvent]
    );

    if (error) {
        return <ErrorResult error={error} containerClassName="SearchPage" />;
    }

    return (
        <div className="NewsSearch">
            <Helmet>
                <link rel="canonical" href={`https://freespoke.com/search/news${canonicalLinkParams}`} />
                <title>News Search - Freespoke</title>
                <meta property="og:title" content={`${params.q} - Freespoke Search`} />
                <meta property="og:url" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="twitter:title" content={`${params.q} - Freespoke Search`} />
                <meta property="twitter:image" content="" />
            </Helmet>
            {isLoading && <FreespokeLoader />}
            {!isLoading && data?.spellcheck?.suggest && (
                <Row>
                    <Col xs={24} className="suggestion-col">
                        <SuggestionText originalText={params.q} spellcheck={data.spellcheck} pathname={location.pathname} />
                    </Col>
                </Row>
            )}
            {!isLoading && !!data?.news?.data?.length ? (
                <Row
                    gutter={[
                        { xs: 12, sm: 12, md: 32, lg: 32 },
                        { xs: 32, sm: 72, md: 72, lg: 72 },
                    ]}
                >
                    <Col xs={24} lg={16}>
                        <Title level={4} className="component-headline">
                            Recent News
                        </Title>
                        <Row
                            gutter={[
                                { xs: 12, sm: 12, md: 32, lg: 32 },
                                { xs: 32, sm: 40, md: 40, lg: 40 },
                            ]}
                        >
                            {params.page === 1 &&
                                data.news.data.slice(0, 6).map((result, i) => (
                                    <Col key={result.id || i} xs={24} sm={12} md={12} lg={12}>
                                        <ContentCard
                                            title={result.title}
                                            bias={result.bias!}
                                            datePublished={result.datePublished}
                                            publisherName={result.publisherName}
                                            publisherIcon={result.publisherIcon}
                                            publisherTypes={result.publisherTypes}
                                            images={result.images}
                                            url={result.url}
                                            reportNewsSearchClick={reportNewsSearchContentClick}
                                        />
                                    </Col>
                                ))}
                            {data.timeline?.length && (
                                <Col key="timeline" xs={24} sm={24} md={24} lg={24}>
                                    <Title level={4} className="component-headline">
                                        Timeline
                                    </Title>
                                    <Timeline data={data?.timeline} />
                                </Col>
                            )}
                            {!!data.censored?.length && (
                                <Col key="censored" xs={24}>
                                    <Title level={4} className="component-headline">
                                        Censored
                                    </Title>
                                    <ScrollableArticles
                                        settings={CAROUSEL_SETTINGS}
                                        items={data.censored || []}
                                        render={censored =>
                                            censored.map((item, itemIndex) => (
                                                <div key={itemIndex} style={{ width: "350px" }}>
                                                    <GenericContentCard title={item.title || ""} images={item.images} url={item.url || ""} />
                                                </div>
                                            ))
                                        }
                                    />
                                </Col>
                            )}
                            {params.page === 1 &&
                                data.news.data.slice(6).map((result, i) => (
                                    <React.Fragment key={result.id || i}>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <NewsSearchResult article={result} size={screenSize} reportClick={reportNewsSearchContentClick} />
                                        </Col>
                                        <Divider className="divider" />
                                    </React.Fragment>
                                ))}
                            {params.page !== 1 &&
                                data.news.data.map((result, i) => (
                                    <React.Fragment key={result.id || i}>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <NewsSearchResult article={result} size={screenSize} reportClick={reportNewsSearchContentClick} />
                                        </Col>
                                        <Divider className="divider" />
                                    </React.Fragment>
                                ))}
                        </Row>
                    </Col>
                    {!!data?.suggested_topics?.length && (
                        <Col key="suggested-topics" xs={24} lg={8}>
                            <TopicsCard data={data.suggested_topics} />
                        </Col>
                    )}
                </Row>
            ) : null}
            {!isLoading && !data?.news?.data?.length && (
                <Row gutter={[{ sm: 24, md: 40 }, 0]}>
                    <Col xs={24}>
                        <Title level={4}>No results found</Title>
                    </Col>
                </Row>
            )}
            {!isLoading && !!data?.news?.data?.length ? (
                <Row justify="center" className="news-pagination">
                    <AntPagination
                        showSizeChanger={false}
                        current={params.page}
                        defaultPageSize={PER_PAGE}
                        total={Math.min(data?.news?.total!, 1000)} // cap results at 1,000, due to "Deep Paging in Distributed Systems" issue (ES)
                        onChange={onPaginationChange}
                    />
                </Row>
            ) : null}
        </div>
    );
}
