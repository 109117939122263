import axios                     from "axios";
import { stringify }             from "query-string";
import { IArticle, ISpellcheck } from "src/@types/custom";
import useSWR                    from "swr";
import { useBackendAccess }      from "@/api/useBackendAccess";
import { BACKEND_URL }           from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };
export interface TimelineItem {
    id?: string;
    title?: string;
    datePublished?: string;
}

export interface ICensoredContent {
    id?: string;
    title?: string;
    images?: string[];
    url?: string;
}

export interface ISuggestedTopic {
    id?: string;
    name?: string;
}

export interface useSearchArticlesParams {
    page: number;
    perPage: number;
    include?: ("timeline" | "suggested_topics" | "censored")[];
}

export type UseSearchArticlesResponse = {
    news?: { data?: IArticle[]; total?: number };
    timeline?: TimelineItem[];
    suggested_topics?: ISuggestedTopic[];
    censored?: ICensoredContent[];
    spellcheck?: ISpellcheck;
};

const useSearchArticles = (q: string, params: useSearchArticlesParams) => {
    const { authHeaders } = useBackendAccess();
    const fetcher = (URL: string) => axios.get(URL, { headers: authHeaders }).then(res => res.data);
    const url = `${BACKEND_URL}/v2/search/news?${stringify({ q, ...params })}`;

    const { data, error, isValidating, mutate } = useSWR<UseSearchArticlesResponse>(authHeaders && q ? url : null, fetcher, config);

    return {
        data: data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
};

export default useSearchArticles;
