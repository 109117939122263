import { FC }            from "react";
import { useMatomo }     from "@datapunt/matomo-tracker-react";
import { AnalyticsMeta } from "@/@types/custom";
import Link              from "@/designSystem/Link/Link";
import Title             from "@/designSystem/Title/Title";
import SpangldLogo       from "@/static/images/spangld_logo.png";
import "./SpangldTake.less";

interface SpangldTakeProps {
    url: string;
    analyticsMeta: AnalyticsMeta;
}

const SpangldTake: FC<SpangldTakeProps> = ({ url, analyticsMeta }) => {
    const { trackEvent } = useMatomo();

    const reportSpangldTake = () => {
        trackEvent({
            category: analyticsMeta.category,
            name: analyticsMeta.name || url,
            action: analyticsMeta.action || "spangld take",
        });
    };

    return (
        <Link className="SpangldTake" href={url} onClick={reportSpangldTake}>
            <Title className="spangld-take-header" level={4}>
                <img src={SpangldLogo} alt="spangld" />
                The Spangld Take
            </Title>
        </Link>
    );
};

export default SpangldTake;
