import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMatomo }                                         from "@datapunt/matomo-tracker-react";
import useBreakpoint                                         from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet }                                            from "react-helmet-async";
import { useHistory, useLocation }                           from "react-router-dom";
import { IImageSearchResult }                                from "@/@types/custom";
import useSearchImages, { useSearchImagesParams }            from "@/api/useSearchImages";
import { Col, Row }                                          from "@/designSystem/Grid/Grid";
import AntPagination                                         from "@/designSystem/Pagination/Pagination";
import Title                                                 from "@/designSystem/Title/Title";
import useIsMounted                                          from "@/hooks/useIsMounted";
import ErrorResult                                           from "@/molecules/ErrorResult/ErrorResult";
import FreespokeLoader                                       from "@/molecules/FreespokeLoader/FreespokeLoader";
import ImageSearchResult                                     from "@/molecules/ImageSearchResult/ImageSearchResult";
import ImageDetailsPage                                      from "../ImageDetailsPage/ImageDetailsPage";
import "./ImagesSearch.less";

const PER_PAGE = 10;

interface ImagesSearchParams extends Omit<useSearchImagesParams, "perPage"> {} // we don't want perPage to be in the URL

export default function ImageSearch() {
    const { xs, sm, md, lg } = useBreakpoint();
    const isMounted = useIsMounted();

    // Decodes how many image columns to display on different screens
    const responsiveSettings: { columns: number; perPage: number } = useMemo(() => {
        if (lg) return { columns: 5, perPage: 25 };
        if (md) return { columns: 4, perPage: 16 };
        if (sm) return { columns: 3, perPage: 12 };
        if (xs) return { columns: 2, perPage: 10 };
        else return { columns: 5, perPage: 25 };
    }, [lg, md, sm, xs]);

    const location = useLocation();
    const history = useHistory();
    const { trackPageView } = useMatomo();
    const columnsRef = useRef<Array<HTMLDivElement | null>>([]);

    const [params, setParams] = useState<ImagesSearchParams>(() => {
        const urlParams = new URLSearchParams(location.search);

        return {
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page") || "1"),
            id: urlParams.get("id") || "",
        };
    });

    const { data, isLoading, error } = useSearchImages({
        perPage: responsiveSettings.perPage,
        ...params,
        ...(isMounted() && { id: undefined }), // the only time we need to pass ID to the API is when "image detail page" is user's initial landing page
    });

    const onPaginationChange = useCallback(
        (pageNum: number) => {
            history.push({ pathname: location.pathname, search: `?${new URLSearchParams({ ...params, page: pageNum.toString() })}` });
        },
        [history, location.pathname, params]
    );

    useEffect(() => {
        // @ts-ignore
        trackPageView();
        const urlParams = new URLSearchParams(location.search);

        setParams({
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page") || "1"),
            freshness: urlParams.get("freshness") || "",
            imageType: urlParams.get("imageType") || "",
            size: urlParams.get("size") || "",
            aspect: urlParams.get("aspect") || "",
            safeSearch: urlParams.get("safeSearch") || "",
            color: urlParams.get("color") || "",
            imageContent: urlParams.get("imageContent") || "",
            license: urlParams.get("license") || "",
            id: urlParams.get("id") || "",
        } as ImagesSearchParams);
    }, [trackPageView, location.search]);

    const canonicalLinkParams = useMemo(() => (!params.q ? "" : `?${new URLSearchParams({ ...(params as any) })}`), [params]);

    // TODO: need to add Matomo events
    // const reportNewsSearchContentClick = useCallback(
    //     (url: string) => {
    //         trackEvent({
    //             category: getAnalyticsCategory(location.pathname),
    //             name: "images tab content click",
    //             action: url,
    //         });
    //     },
    //     [location.pathname, trackEvent]
    // );

    // Splits single image list into columns list ( [] => [ [], [], []...] )
    // NOTE: this requires image to have "object-fit: contain"
    // NOTE: occasionally you can notice that the column balancing aren't working well,
    // the reason for that is that the API sometimes returns invalid "img.thumbnail.width or "img.thumbnail.height"
    const imagesSortedByColumns = useMemo(() => {
        const result: { images: IImageSearchResult[]; columnAverageAspectRatio: number; width?: number }[] = [];

        // Get images for each column
        data?.images &&
            Array.isArray(data?.images) &&
            data.images.length > 0 &&
            Array.from(Array(responsiveSettings.columns).keys()).forEach((_, currentColumn) => {
                const images =
                    data.images?.reduce((acc, item, reduceImgIndex) => {
                        const isTrue = reduceImgIndex === currentColumn + acc.length * responsiveSettings.columns;
                        if (isTrue) {
                            return [...acc, item];
                        }
                        return acc;
                    }, []) || [];

                const columnAspectRatio = images.reduce((acc, img) => {
                    return (img?.height || 0) / (img?.width || 0) + acc;
                }, 0);
                const columnAverageAspectRatio = columnAspectRatio / responsiveSettings.columns;

                result.push({ images, columnAverageAspectRatio });
            });

        // Get average column aspect ratio
        const averageAspectRatio = result.reduce((acc, imgObj) => imgObj.columnAverageAspectRatio + acc, 0) / responsiveSettings.columns;

        // Calculate how wide should be each column - so all columns end up balanced in height
        result.forEach(columnObj => {
            const balancedColumnAspectRatio = (averageAspectRatio / columnObj.columnAverageAspectRatio) * 100;
            columnObj.width = Math.round(Number(balancedColumnAspectRatio.toFixed(2)) * 10) / 10;
        });

        return result;
    }, [data?.images, responsiveSettings.columns]);

    if (error) {
        return <ErrorResult error={error} containerClassName="SearchPage" />;
    }

    return (
        <div className="ImagesSearch">
            <Helmet>
                <link rel="canonical" href={`https://freespoke.com/search/images${canonicalLinkParams}`} />
                <title>Images Search - Freespoke</title>
                <meta property="og:title" content={`${params.q} - Freespoke Images Search`} />
                <meta property="og:url" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="twitter:title" content={`${params.q} - Freespoke Images Search`} />
                <meta property="twitter:image" content="" />
            </Helmet>
            {isLoading && <FreespokeLoader />}

            {params.id && <ImageDetailsPage imageId={params.id} imageData={data?.images} isVisible={!!params.id} imageSearchTerm={params.q} />}

            <div className={!!params.id ? "d-none" : ""}>
                {!isLoading && data?.images && data.images.length > 0 && (
                    <Row
                        gutter={[
                            { xs: 12, sm: 12, md: 32, lg: 32 },
                            { xs: 32, sm: 72, md: 72, lg: 72 },
                        ]}
                    >
                        {imagesSortedByColumns.length > 0 && (
                            <div className="images-container">
                                {imagesSortedByColumns.map((columnObj, currentColumn) => {
                                    return (
                                        <div
                                            ref={el => (columnsRef.current[currentColumn] = el)}
                                            className="column"
                                            key={currentColumn}
                                            style={{ width: responsiveSettings.columns === 2 ? "50%" : `${columnObj.width}%` }}
                                        >
                                            {columnObj.images.map((img, imgIndex) => (
                                                <ImageSearchResult
                                                    type={sm ? "with-metadata-on-hover" : "with-metadata"}
                                                    data={img}
                                                    key={`${imgIndex}-${lg}`}
                                                />
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </Row>
                )}
                {!isLoading && !data?.images?.length && (
                    <Row gutter={[{ sm: 24, md: 40 }, 0]}>
                        <Col xs={24}>
                            <Title level={4}>No results found</Title>
                        </Col>
                    </Row>
                )}
                {!isLoading && data?.images && data.images.length > 0 && (
                    <Row justify="center" className="image-pagination">
                        <AntPagination
                            showSizeChanger={false}
                            current={params.page}
                            defaultPageSize={PER_PAGE}
                            total={Math.min(data?.total || 0, 1000)}
                            onChange={onPaginationChange}
                        />
                    </Row>
                )}
            </div>
        </div>
    );
}
