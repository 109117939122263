import { FC, memo }        from "react";
import Link, { LinkProps } from "@/designSystem/Link/Link";
import Text                from "@/designSystem/Text/Text";
import { dash }            from "@/helpers/index";
import "./ProductCard.less";

export type ProductCardProps = {
    title: string;
    subtitle?: string;
    img: string;
    alt?: string;
    small?: boolean;
    onCardClick: (title: string) => any;
} & LinkProps;

const ProductCard: FC<ProductCardProps> = memo(({ img, alt, title, subtitle, small, onCardClick, ...rest }) => (
    <div className="ProductCard">
        <Link
            {...rest}
            onClick={() => {
                onCardClick(title);
            }}
            className={dash("cover", small && "small")}
        >
            <img src={img} alt={alt || subtitle} className="image" />
            <Text className={dash("title", small && "small")}>{title}</Text>
        </Link>
        {!small && (
            <Link
                {...rest}
                onClick={() => {
                    onCardClick(title);
                }}
                className="subtitle"
            >
                {subtitle}
            </Link>
        )}
    </div>
));

export default ProductCard;
