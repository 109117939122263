import { FC, memo, useCallback, useMemo } from "react";
import { format }                         from "date-fns";
import Avatar                             from "@/designSystem/Avatar/Avatar";
import Divider                            from "@/designSystem/Divider/Divider";
import TwitterOutlined                    from "@/designSystem/Icons/TwitterOutlined";
import Link                               from "@/designSystem/Link/Link";
import Paragraph                          from "@/designSystem/Paragraph/Paragraph";
import Text                               from "@/designSystem/Text/Text";
import Tooltip                            from "@/designSystem/Tooltip/Tooltip";
import { removeTweetURLs, toTimeAgo }     from "@/helpers/index";
import "./Tweet.less";

export interface TweetProps {
    data: {
        id: string;
        tweet_id?: string;
        author: {
            name?: string;
            username?: string;
            profile_image_url?: string;
            image_url?: string;
        };
        datePublished: string;
        bias?: string;
        text: string;
        url: string;
    };
    reportClick: (url: string, publisher: string) => void;
    maxLines: number;
}

const Tweet: FC<TweetProps> = ({ data, maxLines, reportClick }) => {
    const datePublishedFormatted = useMemo(() => toTimeAgo(data.datePublished, true), [data.datePublished]);
    const datePublishedTitle = useMemo(() => format(new Date(data.datePublished), "hh:mm b - dd MMM yyyy"), [data.datePublished]);

    const reportClickOnTweetAuthor = useCallback(
        () => reportClick(`https://twitter.com/${data.author.username || ""}`, data.author.username || ""),
        [data.author.username, reportClick]
    );

    const reportClickOnTweetBody = useCallback(
        () => reportClick(data.url, data.author.username || ""),
        [data.author.username, data.url, reportClick]
    );

    return (
        <div className="Tweet">
            <div className="tweet-header">
                <Avatar size={48} srcSet={data.author.image_url} src={data.author.profile_image_url || ""} alt="Tweet author avatar" />
                <div className="tweet-author">
                    <Tooltip title={data.author.name}>
                        <span className="tweet-author-title">{data.author.name}</span>
                    </Tooltip>
                    <Link
                        className="username"
                        href={`https://twitter.com/${data.author.username || ""}`}
                        rel="noopener noreferrer"
                        onClick={reportClickOnTweetAuthor}
                    >
                        <Tooltip title={`@${data.author.username}`}>{`@${data.author.username}`}</Tooltip>
                    </Link>
                </div>
            </div>
            <div className="tweet-body">
                <Link href={data.url} title={data.text} rel="noopener noreferrer" onClick={reportClickOnTweetBody}>
                    <Paragraph className="tweet-body-text" ellipsis={{ rows: maxLines }}>
                        {removeTweetURLs(data.text)}
                    </Paragraph>
                </Link>
            </div>
            <Divider className="tweet-divider" />
            <div className="tweet-footer">
                <div className="twitter-logo">
                    <TwitterOutlined />
                    <Text className="text">TWITTER</Text>
                </div>
                <div className="tweet-context">{datePublishedFormatted && <span title={datePublishedTitle}>{datePublishedFormatted}</span>}</div>
            </div>
        </div>
    );
};

export default memo(Tweet);
