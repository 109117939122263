import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useMatomo }                                   from "@datapunt/matomo-tracker-react";
import { useLocation }                                 from "react-router";
import { AnalyticsMeta }                               from "@/@types/custom";
import { getAnalyticsCategory }                        from "@/analytics/analytics";
import { useScrollContext }                            from "@/context/useScrollContext";
import Card                                            from "@/designSystem/Card/Card";
import Col                                             from "@/designSystem/Grid/Col";
import Row                                             from "@/designSystem/Grid/Row";
import Link                                            from "@/designSystem/Link/Link";
import Text                                            from "@/designSystem/Text/Text";
import FeaturePreviewItem                              from "@/molecules/FeaturePreviewItem/FeaturePreviewItem";
import ShareCompressed                                 from "@/molecules/ShareCompressed/ShareCompressed";
import "./FeaturePreviewBox.less";

interface FeaturePreviewBoxProps {
    previewItems: {
        id: string;
        title: string;
        url: string;
        subcategory: string;
        images: string[];
        createdAt: string;
    }[];
    type: "Censored Stories" | "Best of the Web";
    analyticsMeta?: AnalyticsMeta;
}

const mapType = {
    "Censored Stories": {
        description: "Stories the media doesn’t want you to see",
        viewMoreText: "View Timeline",
        link: "/category/censored-stories",
        className: "censored-stories",
        analyticsMeta: {
            action: "censored stories",
        },
    },
    "Best of the Web": {
        description: "The best stories from around the internet",
        viewMoreText: "View All",
        link: "/category/best-of-the-web",
        className: "best-of-the-web",
        analyticsMeta: {
            action: "best of web",
        },
    },
};

const FeaturePreviewBox: FC<FeaturePreviewBoxProps> = ({ previewItems, type, analyticsMeta }) => {
    const { trackEvent } = useMatomo();
    const { itemToScroll, resetItemScroll } = useScrollContext();
    const location = useLocation();
    const shareUrl =
        type === "Best of the Web"
            ? `${window.location.origin}${window.location.pathname}#best-of-web`
            : `${window.location.origin}${window.location.pathname}#censored`;
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if ((type === "Best of the Web" && itemToScroll === "#best-of-web") || (type === "Censored Stories" && itemToScroll === "#censored")) {
            if (ref?.current) {
                const yOffset = -120;
                const element = ref.current;
                const y = element.getBoundingClientRect()?.top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: "smooth" });
                resetItemScroll();
            }
        }
    }, [itemToScroll, resetItemScroll, type]);

    // Get analytics category name - 'home', 'freespoke topic' etc
    const analytics = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
            name: analyticsMeta?.name,
            actionPrefix: getAnalyticsCategory(location.pathname) === "story page" ? "story newsfeed" : "",
            action: mapType[type].analyticsMeta.action,
        }),
        [analyticsMeta, location.pathname, type]
    );

    const reportClickOnViewAll = useCallback(() => {
        trackEvent({
            category: analytics.category,
            name: analyticsMeta?.name || "click",
            action: `${analytics.actionPrefix ? `${analytics.actionPrefix} ` : ""}${type.toLowerCase()} view all`,
        });
    }, [analytics, analyticsMeta, trackEvent, type]);

    const reportClickOnFeatureItem = useCallback(
        (url: string) => {
            trackEvent({
                category: analytics.category,
                name: analyticsMeta?.name || url,
                action: `${analytics.actionPrefix ? `${analytics.actionPrefix} ` : ""}${mapType[type].analyticsMeta.action} article click`,
            });
        },
        [analytics, analyticsMeta, trackEvent, type]
    );

    return previewItems && previewItems.length ? (
        <Card className={`FeaturePreviewBox ${mapType[type].className}`}>
            <div className="head" ref={ref}>
                <div className="about">
                    <Text>
                        <Link className="heading-link" to={mapType[type].link}>
                            {type}
                        </Link>
                    </Text>
                    <Text className="description">
                        <span className="description-text">{mapType[type].description}.</span>
                        <Link className="view-more-link" to={mapType[type].link} onClick={reportClickOnViewAll}>
                            View All
                        </Link>
                    </Text>
                </div>
                <ShareCompressed
                    url={shareUrl}
                    type="freespoke"
                    hasText
                    iconType="light"
                    analyticsMeta={{
                        ...analytics,
                        actionPrefix: analytics.category === "story page" ? "story newsfeed" : "",
                        action: `${analytics.action} share`,
                        name: analytics.name || `${type.toLowerCase()} share`,
                    }}
                />
            </div>
            <div className="scroll-area">
                <div className="scroll-area-inner-container">
                    <Row gutter={16}>
                        {previewItems.slice(0, 3).map(item => (
                            <Col span={8} key={item.id}>
                                <FeaturePreviewItem item={item} onClick={reportClickOnFeatureItem} />
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </Card>
    ) : null;
};

export default FeaturePreviewBox;
