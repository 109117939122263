import { FC } from "react";
import Tag    from "@/designSystem/Tag/Tag";
import "./AlertPill.less";

interface AlertPillProps {
    label: string;
    color?: string;
}

const AlertPill: FC<AlertPillProps> = ({ label, color = "#C43351" }) => {
    return (
        <Tag className="AlertPill" color={color}>
            {label}
        </Tag>
    );
};

export default AlertPill;
