import React, { FC, useCallback } from "react";
import TweetCard                  from "@/molecules/TweetCard/TweetCard";
import WebSearchResult            from "@/molecules/WebSearchResult/WebSearchResult";
import "./OtherContent.less";

interface OtherContentProps {
    articles: { [key: string]: any }[];
    onClick: (url: string, type: "article" | "tweet", title: string) => void;
}

const OtherContent: FC<OtherContentProps> = ({ articles, onClick }) => {
    const onArticleClick = useCallback(
        (url: string, _title: string) => {
            onClick(url, "article", _title);
        },
        [onClick]
    );

    const onTweetClick = useCallback(
        (url: string, _title: string) => {
            onClick(url, "tweet", _title);
        },
        [onClick]
    );

    return (
        <div className="OtherContent">
            {articles.map((data, index) => (
                <React.Fragment key={index}>
                    {data.type === "article" ? (
                        <WebSearchResult
                            className="navy-color-text"
                            title={data.article.title}
                            href={data.article.url}
                            description={data.article.description}
                            bias={data.article.bias}
                            showBias={true}
                            displayUrl={data.article.url}
                            reportClick={onArticleClick}
                        />
                    ) : (
                        <TweetCard data={data.tweet} maxLines={4} key={data.tweet.id} reportClick={onTweetClick} />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default OtherContent;
