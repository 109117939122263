import React, { FC, useCallback, useMemo } from "react";
import { useMatomo }                       from "@datapunt/matomo-tracker-react";
import { useLocation }                     from "react-router";
import { getAnalyticsCategory }            from "@/analytics/analytics";
import Card                                from "@/designSystem/Card/Card";
import Divider                             from "@/designSystem/Divider/Divider";
import Link                                from "@/designSystem/Link/Link";
import Skeleton                            from "@/designSystem/Skeleton/Skeleton";
import TopicsSidebarItem                   from "../TopicsSidebarItem/TopicsSidebarItem";
import "./TopicsSidebar.less";

interface TopicsSidebarProps {
    data: {
        image_url: string;
        name: string;
        id: string;
    }[];
}

const TopicsSidebar: FC<TopicsSidebarProps> = ({ data }) => {
    const location = useLocation();
    const { trackEvent } = useMatomo();

    // Get analytics category name - 'home', 'freespoke topic' etc
    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const reportViewAllClick = useCallback(() => {
        trackEvent({
            category: analyticsMeta.category,
            name: "dd topic list view all",
            action: "click",
        });
    }, [analyticsMeta.category, trackEvent]);

    const reportTopicItemClick = useCallback(
        (topicName: string) => {
            trackEvent({
                category: analyticsMeta.category,
                name: "dd topic list topic click",
                action: topicName,
            });
        },
        [analyticsMeta.category, trackEvent]
    );

    return (
        <Card
            className="TopicsSidebar"
            size="small"
            title="FREESPOKE TOPICS"
            extra={
                <Link to={"/search/categories"} onClick={reportViewAllClick}>
                    View All
                </Link>
            }
        >
            {data?.length ? (
                <div className="topics">
                    {data.map((topic: any, i: number) => (
                        <React.Fragment key={topic.id}>
                            <TopicsSidebarItem hot={i === 0} topic={topic} onClick={reportTopicItemClick} />
                            {i !== data.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                    <div className="topics-item-spacer" />
                </div>
            ) : (
                <Skeleton />
            )}
        </Card>
    );
};

export default TopicsSidebar;
