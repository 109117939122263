import React, { FC, useCallback, useMemo } from "react";
import useBreakpoint                       from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                from "clsx";
import Collapse                            from "@/designSystem/Collapse/Collapse";
import { Col, Row }                        from "@/designSystem/Grid/Grid";
import Link                                from "@/designSystem/Link/Link";
import Text                                from "@/designSystem/Text/Text";
import Title                               from "@/designSystem/Title/Title";
import { toTimeAgo }                       from "@/helpers/index";
import { ReactComponent as LeftIcon }      from "@/static/icons/democrat_flat.svg";
import { ReactComponent as RightIcon }     from "@/static/icons/republican_flat.svg";
import "./WebSearchResult.less";

const { Panel } = Collapse;

const handleReplaceURL = (url?: string) => url && url.replace(/(^\w+:|^)\/\//, "");
interface WebSearchResultProps {
    dateLastCrawled?: string;
    href: string;
    title: string;
    description: string;
    displayUrl: string;
    deepLinks?: {
        url?: string;
        name?: string;
        snippet?: string;
        deepLinks?: {
            name?: string;
            url?: string;
        }[];
    }[];
    bias?: "left" | "middle" | "right";
    showBias?: boolean;
    showDate?: boolean;
    reportClick?: (url: string, title: string) => void;
    className?: string;
}
const WebSearchResult: FC<WebSearchResultProps> = ({
    dateLastCrawled,
    href,
    title,
    description,
    displayUrl,
    deepLinks,
    bias,
    showBias,
    showDate,
    reportClick,
    className,
}) => {
    const onClick = useCallback(() => reportClick && reportClick(href, title), [reportClick, href, title]);
    const displayUrlSanitized = useMemo(() => handleReplaceURL(displayUrl), [displayUrl]);

    const distance = useMemo(() => {
        if (dateLastCrawled) {
            return toTimeAgo(dateLastCrawled, true);
        }
        return null;
    }, [dateLastCrawled]);
    const { lg, md } = useBreakpoint();

    const hasSubDeepLinksOrDescription = Array.isArray(deepLinks) && deepLinks.some(deepLink => deepLink?.snippet || deepLink?.deepLinks?.length);
    return (
        <div className={clsx("WebSearchResult", className && className)}>
            <Title level={5}>
                <Link onClick={onClick} href={href} className="searchResultTitleLink">
                    {title}
                </Link>
            </Title>
            <Text>{description}</Text>
            <div className="distance-source">
                {distance && showDate && <Text className="crawl-date">{distance}</Text>}
                {showBias && bias && (
                    <div className="bias">
                        {bias === "left" && <LeftIcon className="bias-icon" />}
                        {bias === "right" && <RightIcon className="bias-icon" />}
                        <Text className={`bias-label bias-label-${bias}`}>{bias}</Text>
                    </div>
                )}
                <Link onClick={onClick} href={href}>
                    {displayUrlSanitized}
                </Link>
            </div>
            {Array.isArray(deepLinks) ? (
                <Row className="deep-links" gutter={[0, { xs: 0, sm: 0, md: hasSubDeepLinksOrDescription ? 16 : 0 }]}>
                    {deepLinks.map((deepLink, index) => {
                        return (
                            <Col className="deep-link" key={deepLink.url} xs={24} md={11}>
                                {md && (
                                    <>
                                        <Link href={deepLink.url || "#"}>{deepLink.name}</Link>
                                        {deepLink.snippet ? <Text>{deepLink.snippet}</Text> : null}
                                        {lg && deepLink.deepLinks
                                            ? deepLink.deepLinks.map((link, idx) => {
                                                  if (Array.isArray(deepLink.deepLinks) && deepLink.deepLinks.length > 2 && idx === 0) {
                                                      return (
                                                          <Link className="sub-deep-link" key={link.url || "idx"} href={link.url || "#"}>
                                                              {link.name}&nbsp;·&nbsp;
                                                          </Link>
                                                      );
                                                  } else if (
                                                      (Array.isArray(deepLink.deepLinks) && deepLink.deepLinks.length > 2 && idx === 1) ||
                                                      (Array.isArray(deepLink.deepLinks) && deepLink.deepLinks.length < 2)
                                                  ) {
                                                      return (
                                                          <Link className="sub-deep-link" key={link.url || idx} href={link.url || "#"}>
                                                              {link.name}
                                                          </Link>
                                                      );
                                                  } else {
                                                      return null;
                                                  }
                                              })
                                            : null}
                                    </>
                                )}
                                {!md && (
                                    <Collapse bordered={false} expandIconPosition="right">
                                        <Panel
                                            className="deep-link"
                                            header={
                                                <div className="deep-link-header">
                                                    <Link onClick={event => event.stopPropagation()} href={deepLink.url || "#"}>
                                                        {deepLink.name}
                                                    </Link>
                                                </div>
                                            }
                                            showArrow={deepLink.deepLinks ? true : false}
                                            key={index}
                                        >
                                            <div className="links">
                                                {deepLink.deepLinks &&
                                                    deepLink.deepLinks.map(link => {
                                                        return (
                                                            <Link className="sub-deep-link" key={link.url} href={link.url || "#"}>
                                                                {link.name}
                                                            </Link>
                                                        );
                                                    })}
                                            </div>
                                        </Panel>
                                    </Collapse>
                                )}
                            </Col>
                        );
                    })}
                </Row>
            ) : null}
        </div>
    );
};
export default React.memo(WebSearchResult);
