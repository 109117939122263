import { FC, useEffect, useRef } from "react";
import useBreakpoint             from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet }                from "react-helmet-async";
import { useHistory }            from "react-router";
import DesktopLayout             from "@/components/layout/Desktop/DesktopLayout";
import MobileLayout              from "@/components/layout/Mobile/MobileLayout";
import { useScrollContext }      from "@/context/useScrollContext";
import SearchScreen              from "@/organisms/SearchScreen/SearchScreen";
import "./Homepage.less";

let interval;

const analyticsMeta = { category: "home" } as const;

interface HomepageProps {
    hide?: boolean;
}

const Homepage: FC<HomepageProps> = ({ hide }) => {
    const history = useHistory<{ autoScroll?: boolean }>();
    const childrenHasLoadedDataRef = useRef<boolean>(false);
    const reportDataFetchCompletion = (isCompleted: boolean) => (childrenHasLoadedDataRef.current = isCompleted);
    const { lg } = useBreakpoint();
    const { dispatchScrollToItem } = useScrollContext();

    useEffect(() => {
        if ((history.location.state?.autoScroll || history.location.hash) && !interval) {
            // prevents scrolling to news on browser 'refresh' button
            const state = { ...history.location.state };
            delete state.autoScroll;
            history.replace({ ...history.location, state });

            // creates an interval which checks if child component (MobileLayout or DesktopLayout) has loaded its data.
            // if data has been loaded it will scroll to the 'News' section and destroy the interval
            interval = setInterval(function () {
                if (childrenHasLoadedDataRef.current) {
                    interval = clearInterval(interval);
                    // give it another 1/2 second to account for delay between fetch completion and the actual rendering
                    setTimeout(function () {
                        dispatchScrollToItem(history.location.hash);
                    }, 500);
                }
            }, 500);
        }
    }, [dispatchScrollToItem, history, history.location]);

    return (
        <div className="Homepage" style={{ ...(hide && { display: "none" }) }}>
            <Helmet>
                <link rel="canonical" href="https://freespoke.com" />
            </Helmet>
            <SearchScreen />
            {lg ? (
                <DesktopLayout reportDataFetchCompletion={reportDataFetchCompletion} analyticsMeta={analyticsMeta} />
            ) : (
                <MobileLayout reportDataFetchCompletion={reportDataFetchCompletion} analyticsMeta={analyticsMeta} />
            )}
        </div>
    );
};

export default Homepage;
