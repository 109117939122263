import { useEffect }       from "react";
import { AnalyticsMeta }   from "@/@types/custom";
import useGetHomepage      from "@/api/useGetHomepage";
import { Col, Row }        from "@/designSystem/Grid/Grid";
import BreakingNewsSidebar from "@/molecules/BreakingNews/BreakingNews";
import FreespokeLoader     from "@/molecules/FreespokeLoader/FreespokeLoader";
import Notification        from "@/molecules/Notification/Notification";
import SpangldCTA          from "@/molecules/SpangldCTA/SpangldCTA";
import TopicSidebar        from "@/molecules/TopicsSidebar/TopicsSidebar";
import ContentHolder       from "@/organisms/ContentHolder/ContentHolder";
import TopStories          from "@/organisms/TopStories/TopStories";
import "./DesktopLayout.less";

const REFRESH_INTERVAL = 60 * 1000; // 1min

interface DesktopLayoutProps {
    reportDataFetchCompletion: Function;
    analyticsMeta: Omit<AnalyticsMeta, "name" | "action">;
}
const DesktopLayout = ({ reportDataFetchCompletion, analyticsMeta }: DesktopLayoutProps) => {
    const { data, isLoading, refresh, checkForNewData } = useGetHomepage({ include: ["featured_stories", "top_stories"] });

    // Start polling new data on interval
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isLoading) {
                checkForNewData();
            }
        }, REFRESH_INTERVAL);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    // reports to homepage when data has been fetched
    useEffect(() => {
        reportDataFetchCompletion(!isLoading && !!data);
    }, [data, reportDataFetchCompletion, isLoading]);

    return (
        <div className="DesktopLayout">
            {!isLoading && data && (
                <>
                    <Row style={{ marginBottom: 16 }} justify="end" gutter={[36, 24]}>
                        <Col sm={16} md={18} lg={18} xl={18}>
                            <Notification text="New Stories" newItemsCount={data.newStoriesAvailable} onClick={refresh} />
                        </Col>
                    </Row>
                    <Row gutter={[36, 24]}>
                        <Col sm={8} md={6} lg={6} xl={6}>
                            <Row gutter={[24, 24]}>
                                {Array.isArray(data.top_stories) && data.top_stories.length > 0 && <TopStories top_stories={data.top_stories} />}
                                <TopicSidebar data={data.suggested_topics} />
                                <BreakingNewsSidebar />
                                {Array.isArray(data.spangld) && data.spangld.length > 0 && (
                                    <SpangldCTA
                                        id={data.spangld[0].id}
                                        images={data.spangld[0].images}
                                        url={data.spangld[0].url}
                                        title={data.spangld[0].title}
                                        textSummary={data.spangld[0].textSummary}
                                        analyticsMeta={analyticsMeta}
                                        rawHTML={data.spangld[0].rawHTML}
                                    />
                                )}
                            </Row>
                        </Col>
                        <Col sm={16} md={18} lg={18} xl={18}>
                            <ContentHolder
                                stories={data.stories}
                                best_of_web={data.best_of_web}
                                censored_stories={data.censored_stories}
                                featured_stories={data.featured_stories}
                                analyticsMeta={analyticsMeta}
                            />
                        </Col>
                    </Row>
                </>
            )}
            {isLoading && <FreespokeLoader />}
        </div>
    );
};

export default DesktopLayout;
