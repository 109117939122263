import { FunctionComponent, useCallback, useMemo, useState } from "react";
import uniq                                                  from "lodash-es/uniq";
import Image                                                 from "@/designSystem/Image/Image";
import "./ImageLoader.less";

interface Props {
    className?: string;
    src?: string;
    srcList?: string[];
    placeholder?: boolean;
    alt: string;
    wrapperStyle?: React.CSSProperties;
    wrapperClassName?: string;
    imageLoadingType?: "eager" | "lazy";
    onClick?: () => void;
}

const ImageLoader: FunctionComponent<Props> = ({
    children,
    className,
    src,
    srcList,
    placeholder = true,
    alt,
    wrapperStyle,
    wrapperClassName,
    imageLoadingType,
    onClick,
}) => {
    const [currentSrcIndex, setCurrentSrcIndex] = useState<number>(0);
    const sources = useMemo<string[]>(() => (src && [src]) || (Array.isArray(srcList) && uniq(srcList)) || [""], [src, srcList]);
    const [altText, setAltText] = useState(alt);
    const onError = useCallback(() => {
        console.warn("Failed to load image: ", sources[currentSrcIndex]);

        if (sources[currentSrcIndex + 1]) {
            console.warn("Trying to load another image: ", sources[currentSrcIndex + 1]);
            setCurrentSrcIndex(currentSrcIndex + 1);
        } else {
            setAltText("");
        }
    }, [currentSrcIndex, sources]);

    return (
        <div onClick={onClick} className={`ImageLoader ${className ? className : ""}`}>
            <Image
                onError={onError}
                placeholder={placeholder}
                {...(wrapperStyle && { wrapperStyle })}
                {...(wrapperClassName && { wrapperClassName })}
                preview={false}
                loading={imageLoadingType || "lazy"}
                src={sources[currentSrcIndex] || ""}
                alt={altText}
            />
            {children}
        </div>
    );
};

export default ImageLoader;
