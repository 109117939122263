import { useCallback, useEffect, useRef, useState } from "react";
import { useMatomo }                                from "@datapunt/matomo-tracker-react";
import ErrorSVG                                     from "@/components/utils/Error";
import useScript                                    from "@/hooks/useScript";
import FreespokeLoader                              from "@/molecules/FreespokeLoader/FreespokeLoader";
import "./VideoPlayer.less";

function VideoPlayer({ id, parentId, url, thumbnailURL, onClose }) {
    const [isPlayerInitialized, setIsPlayerInitialized] = useState(false);
    const [error, setError] = useState("");
    const status = useScript("//amp.azure.net/libs/amp/2.3.10/azuremediaplayer.min.js");
    const videoPlayer = useRef(null);
    const { trackEvent } = useMatomo();

    // Matomo analytics
    const recordPlayAction = useCallback(
        e => {
            // eslint-disable-next-line no-undef
            const type = e.type;
            let action = "play";

            if (type === "firstquartile") {
                action = "Play-25%";
            } else if (type === "thirdquartile") {
                action = "Play-75%";
            }

            trackEvent({
                category: "Video",
                action: action,
            });
        },
        [trackEvent]
    );

    const setSource = useCallback(() => {
        if (!url) throw Error("Video URL is missing");
        videoPlayer.current.src([
            {
                src: url,
                type: "application/vnd.ms-sstr+xml",
            },
        ]);
    }, [videoPlayer, url]);

    const initializeVideoPlayer = useCallback(() => {
        // eslint-disable-next-line no-undef
        videoPlayer.current = amp(`${parentId}-${id}`, {
            techOrder: ["azureHtml5JS", "html5FairPlayHLS", "html5"], // tech order for the player to decide on which tech to use. Removed "flashSS", "silverlightSS" since they can cause issue on mobile
            nativeControlsForTouch: false, // nativeControlsForTouch must be false (default) to avoid "Object doesn't support property or method 'setControls'"
            autoplay: false,
            logo: { enabled: false },
            fluid: false, // set whether the video should follow the width of its container while keeping the video aspect ratio. Default is false.
            controls: true, // set whether the controls should be displayed. Default is false.
            /*
             * HighQuality:
             *   Profile that tries to play highest quality possible. It builds the buffer to limit potential buffering.
             *   It does not take the width and height of the player into account when switching bitrates.
             *   For live streams this profile has a backoff from the live edge to avoid potential buffering.
             * Hybrid:
             *   Profile that tries to balance quality and speed.
             *   It builds the buffer more than QuickStart but less than HighQuality.
             *   It takes the width and height of the player into account when switching bitrates.
             *   For live streams this profile tries to stay close to the live edge.
             *   This is the default profile.
             * LowLatency:
             *   Profile designed to work alongside Azure Media Services low latency feature for live streaming.
             *   If low latency is not enabled on the stream, this heuristic profile will not yield a latency improvement.
             * QuickStart:
             *   Profile that starts the playback as fast as possible.
             *   It also takes the width and height of the player into account when switching bitrates.
             *   For live streams this profile tries to stay close to the live edge.
             */
            heuristicProfile: "HighQuality",
            // width: "300",
            height: "auto",
            poster: thumbnailURL,
            playbackSpeed: {
                // configuration options for playback speed control
                enabled: true,
                initialSpeed: 1.0,
                speedLevels: [
                    { name: "2x", value: 2.0 },
                    { name: "1.5x", value: 1.5 },
                    { name: "normal", value: 1.0 },
                    { name: "0.5x", value: 0.5 },
                ],
            },
            hotKeys: {
                enableJogStyle: true, // flag to control whether seeking forth/back by 1 sec with up/down arrows is allowed
                seekStep: 5, // seek step in seconds
                enableMute: true,
            },
        });
        setIsPlayerInitialized(true);

        // Analytics
        videoPlayer.current.addEventListener("play", recordPlayAction);
        videoPlayer.current.addEventListener("firstquartile", recordPlayAction);
        videoPlayer.current.addEventListener("thirdquartile", recordPlayAction);

        // Bing a listener to the Player's ready state.
        videoPlayer.current.addEventListener("ready", () => {
            setSource();
        });
    }, [parentId, id, videoPlayer, setSource, recordPlayAction, thumbnailURL]);

    /* Initialize video player once the script is loaded or displayed error */
    useEffect(() => {
        if (status === "ready") {
            initializeVideoPlayer();
        } else if (status === "error") {
            setError("Failed to load media player");
        }
    }, [status, initializeVideoPlayer]);

    /* Dispose video player on component unmount */
    useEffect(() => {
        return () => {
            if (videoPlayer.current) {
                videoPlayer.current.dispose();
            }
        };
    }, []);

    const onClick = e => e.target.className.includes("VideoPlayer") && onClose();

    return (
        <div onClick={onClick} className="VideoPlayer">
            {!isPlayerInitialized && !error && (
                <div className="loader">
                    <FreespokeLoader />
                    <p onClick={onClose}>Go back</p>
                </div>
            )}
            {error && (
                <div className="error">
                    <ErrorSVG />
                    <h4>{error}</h4>
                    <p onClick={onClose}>Go Back</p>
                </div>
            )}
            <div className={`video-player-wrapper${isPlayerInitialized ? "" : " invisible"}`}>
                <video id={`${parentId}-${id}`} className="azuremediaplayer amp-default-skin amp-big-play-centered">
                    <p className="amp-no-js">
                        To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
                    </p>
                </video>
            </div>
        </div>
    );
}

export default VideoPlayer;
