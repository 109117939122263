import { FC, useCallback }                        from "react";
import { useMatomo }                              from "@datapunt/matomo-tracker-react";
import useBreakpoint                              from "antd/lib/grid/hooks/useBreakpoint";
import { useResizeDetector }                      from "react-resize-detector/build/withPolyfill";
import { useLocation }                            from "react-router";
import { animated, useSpring }                    from "react-spring";
import Link                                       from "@/designSystem/Link/Link";
import { useAppBar }                              from "@/hooks/useAppBar";
import Hamburger                                  from "@/molecules/Hamburger/Hamburger";
import SearchBar                                  from "@/molecules/SearchBar/SearchBar";
import { ReactComponent as FreespokeLogoDesktop } from "@/static/logo/logo.svg";
import { ReactComponent as FreespokeLogoMobile }  from "@/static/logo/logo_small.svg";
import "./AppBarHeader.less";

// Winter Theme
// import SnowflakesSketch from "../../seasonal/Snowflakes/Snowflakes";
// import { ReactComponent as FreespokeTorchWithLights } from "@/static/logo/seasonal/freespoke_torch_with_lights_cropped.svg";
// import { ReactComponent as FreespokeLogoWithLights } from "@/static/logo/seasonal/freespoke_logo_with_lights_cropped.svg";
// const ReactP5Wrapper = lazy(() =>
//     import("react-p5-wrapper").then(module => {
//         return { default: module.ReactP5Wrapper };
//     })
// );

interface AppBarHeaderProps {}

const AppBarHeader: FC<AppBarHeaderProps> = () => {
    const { lg } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const { showAppBar, closeHamburger } = useAppBar();
    const location = useLocation();

    const spring = useSpring(
        (location.pathname !== "/" || showAppBar) && !location.pathname.startsWith("/join")
            ? {
                  transform: "translateY(0%)",
                  opacity: 1,
              }
            : {
                  transform: "translateY(-100%)",
                  opacity: 0,
              }
    );

    const onResize = useCallback((_width: number, height: number) => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-header-height", `${height + 20}px`);
    }, []);

    // A Hook that listens on the outer HTML element height change
    const { ref } = useResizeDetector({
        handleHeight: true,
        handleWidth: false,
        refreshMode: "debounce",
        refreshRate: 300,
        onResize,
    });

    const recordEvent = useCallback(() => {
        // Matomo analytics click logo back to the homepage
        trackEvent({
            category: "nav bar",
            action: "logo click",
            name: "click",
        });
    }, [trackEvent]);

    return (
        <>
            <animated.header ref={ref} className="AppBarHeader" style={spring}>
                <div className="max-wrap">
                    <Link to="/" className="logo" onClick={closeHamburger}>
                        {lg ? <FreespokeLogoDesktop onClick={recordEvent} /> : <FreespokeLogoMobile onClick={recordEvent} />}
                    </Link>
                    <SearchBar parent="header" />
                    <Hamburger
                        analyticsMeta={{
                            category: "nav bar",
                            name: "hamburger menu open / close",
                            action: "click",
                        }}
                    />
                </div>
                {/* <Suspense fallback={<span></span>}>
                    <div
                        style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            visibility: showHamburger ? "hidden" : "visible",
                            overflow: "hidden",
                            top: "0",
                            zIndex: -1,
                        }}
                    >
                        <ReactP5Wrapper sketch={SnowflakesSketch} />
                    </div>
                    ˝
                </Suspense> */}
            </animated.header>
        </>
    );
};

export default AppBarHeader;
