import { FC }                  from "react";
import { Redirect, useParams } from "react-router-dom";
import Hero                    from "@/molecules/Hero/Hero";
import Feature                 from "@/pages/Feature/Feature";
import "./Category.less";

const Category: FC = () => {
    let { category_name } = useParams<{ category_name?: string }>();

    const isAnso = category_name === "anso" || category_name === "censored-stories";
    const isFun = category_name === "fun" || category_name === "make-me-laugh" || category_name === "best-of-the-web";

    if (!isAnso && !isFun) {
        return <Redirect to="/" />;
    }

    const route = isAnso ? "censored-stories" : "best-of-the-web";

    return (
        <>
            {category_name === "trending" && <Hero />}
            {(isFun || isAnso) && <Feature key={route} route={route} />}
        </>
    );
};

export default Category;
