import { VFC, useCallback, useEffect, useMemo, useRef, useState }         from "react";
import { useMatomo }                                                      from "@datapunt/matomo-tracker-react";
import { animated, useSpring }                                            from "@react-spring/web";
import { stringify }                                                      from "query-string";
import { useLocation }                                                    from "react-router";
import { LinkProps }                                                      from "react-router-dom";
import { getCategories, getFeaturedProducts, getSubCategoriesByCategory } from "@/data/shop-api";
import Breadcrumb                                                         from "@/designSystem/Breadcrumb/Breadcrumb";
import ChevronLeft                                                        from "@/designSystem/Icons/ChevronLeft";
import ChevronRight                                                       from "@/designSystem/Icons/ChevronRight";
import CloseOutlined                                                      from "@/designSystem/Icons/CloseOutlined";
import HamburgerIcon                                                      from "@/designSystem/Icons/HamburgerIcon";
import Link                                                               from "@/designSystem/Link/Link";
import Text                                                               from "@/designSystem/Text/Text";
import { toTitleCase }                                                    from "@/helpers/index";
import useLockBodyScroll                                                  from "@/hooks/useLockBodyScroll";
import Container                                                          from "@/molecules/Container/Container";
import FeaturedBrand, { FeaturedBrandProps }                              from "@/molecules/FeaturedBrand/FeaturedBrand";
import "./ShopUsaBreadcrumbs.less";
import "./ShopUsaMenu-desktop.less";
import "./ShopUsaMenu-mobile.less";

interface ShopUsaCategory {
    category: { label: string; to: LinkProps["to"]; description: string };
    subcategories: Array<{ label: string; to: LinkProps["to"] }>;
    featured: Array<Omit<FeaturedBrandProps, "layout"> & { id: string }>;
    products: [];
}

export interface ShopUsaMenuProps {
    mobile?: boolean;
}

const ShopUsaMenu: VFC<ShopUsaMenuProps> = ({ mobile = false }) => {
    const data: Array<ShopUsaCategory> = (getCategories().filter(category => !!category) as Array<string>).map(category => ({
        category: {
            label: category,
            to: {
                pathname: `/product`,
                search: `?${stringify({ category: category })}`,
                state: {
                    clearFilters: true,
                },
            },
            description: "",
        },
        subcategories: (getSubCategoriesByCategory(category).filter(category => !!category) as Array<string>).map(subCategory => ({
            label: subCategory,
            to: {
                pathname: `/product`,
                search: `?${stringify({ category, subCategory: toTitleCase(subCategory) })}`,
                state: {
                    clearFilters: true,
                },
            },
        })),
        featured: getFeaturedProducts(category),
        products: [],
    }));

    return mobile ? <Mobile data={data} /> : <Desktop data={data} />;
};

const ShopUsaBreadcrumbs: VFC = () => {
    const { trackEvent } = useMatomo();
    const location = useLocation<{ clearFilters?: boolean }>();
    const queryParams = useMemo(() => {
        const queryParamsObj = new URLSearchParams(location.search);
        const category = queryParamsObj.get("category") || "";
        const subCategory = queryParamsObj.get("subCategory") || "";
        const collection = queryParamsObj.get("collection") || "";

        return {
            category,
            subCategory,
            collection,
        };
    }, [location.search]);

    const onClick = useCallback(
        (itemName: string) => {
            // Track breadcrumb click
            trackEvent({
                category: "shop us",
                name: "breadcrumbs",
                action: itemName,
            });
        },
        [trackEvent]
    );

    if (!queryParams.category && !queryParams.subCategory && !queryParams.collection) {
        return null;
    }

    return (
        <Breadcrumb className="ShopUsaBreadcrumbs" separator=">">
            <Breadcrumb.Item className="breadcrumb-item">
                <Link onClick={() => onClick("Store")} to="/products">
                    Store
                </Link>
            </Breadcrumb.Item>
            {!!queryParams.category && (
                <Breadcrumb.Item className="breadcrumb-item">
                    {queryParams.subCategory || queryParams.collection ? (
                        <Link onClick={() => onClick(queryParams.category)} to={`/product?${stringify({ category: queryParams.category })}`}>
                            {queryParams.category}
                        </Link>
                    ) : (
                        queryParams.category
                    )}
                </Breadcrumb.Item>
            )}
            {!!queryParams.collection && (
                <Breadcrumb.Item className="breadcrumb-item">
                    {queryParams.subCategory ? (
                        <Link
                            onClick={() => onClick(queryParams.collection)}
                            to={`/product?${stringify({
                                category: queryParams.category,
                                collection: queryParams.collection,
                            })}`}
                        >
                            {queryParams.collection}
                        </Link>
                    ) : (
                        queryParams.collection
                    )}
                </Breadcrumb.Item>
            )}
            {!!queryParams.subCategory && <Breadcrumb.Item className="breadcrumb-item">{queryParams.subCategory}</Breadcrumb.Item>}
        </Breadcrumb>
    );
};

const Mobile: VFC<{ data: Array<Omit<ShopUsaCategory, "products">> }> = ({ data }) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<Omit<ShopUsaCategory, "products">>(data[0]);

    // prevents body from scrolling when menu is open
    useLockBodyScroll(dropdownOpen);

    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    }, [headerRef]);

    const dropdownSpring = useSpring({
        opacity: dropdownOpen ? 1 : 0,
        transform: dropdownOpen ? `scaleY(1)` : `scaleY(0)`,
    });

    const drawerSpring = useSpring({
        opacity: drawerOpen ? 1 : 0,
        transform: drawerOpen ? `translateX(0%)` : `translateX(200%)`,
    });

    const toggleDropdown = useCallback(() => {
        if (drawerOpen) {
            setDrawerOpen(false);
        }
        setDropdownOpen(current => !current);
    }, [setDropdownOpen, setDrawerOpen, drawerOpen]);

    const openDrawer = useCallback(
        (selected: string) => {
            setSelected(data.find(({ category: { label } }) => selected?.toLowerCase() === label?.toLowerCase()) || data[0]);
            setDrawerOpen(true);
        },
        [data, setSelected, setDrawerOpen]
    );

    const closeDrawer = useCallback(() => setDrawerOpen(false), [setDrawerOpen]);

    return (
        <div className="ShopUsaMenu-mobile">
            <div className="header" ref={headerRef}>
                <div className="header-text">browse our store</div>
                <div className="header-icon" onClick={toggleDropdown}>
                    {dropdownOpen ? <CloseOutlined /> : <HamburgerIcon />}
                </div>
            </div>
            <ShopUsaBreadcrumbs />
            <animated.div
                className="dropdown"
                style={{
                    top: headerHeight,
                    height: `calc(var(--app-height, 100vh) - ${headerRef.current?.getBoundingClientRect().bottom || 0}px)`,
                    ...dropdownSpring,
                }}
            >
                {data.map(({ category: { label } }, key) => (
                    <div key={key} onClick={() => openDrawer(label)}>
                        <Text className="drawer-option">{label.toUpperCase()}</Text>
                        <div className="chevron">
                            <ChevronRight />
                        </div>
                    </div>
                ))}
            </animated.div>
            {/* 69.59+80+56 */}
            <animated.div
                className="drawer"
                style={{
                    top: headerHeight,
                    height: `calc(var(--app-height, 100vh) - ${headerRef.current?.getBoundingClientRect().bottom || 0}px)`,
                    ...drawerSpring,
                }}
            >
                <div className="chevron" onClick={closeDrawer}>
                    <ChevronLeft />
                </div>
                <Text className="drawer-title">
                    {selected.category.label
                        .split("/")
                        .map(word => toTitleCase(word))
                        .join("/")}
                </Text>
                <div className="subcategories">
                    {selected.subcategories.map(({ label, to }, key) => (
                        <Link className="subcategory" key={key} to={to} onClick={toggleDropdown}>
                            {toTitleCase(label)}
                        </Link>
                    ))}
                </div>
                <div className="featured-products">
                    {selected.featured.map((brand, key) => (
                        <FeaturedBrand key={key} {...brand} layout="vertical" />
                    ))}
                </div>
            </animated.div>
        </div>
    );
};

const Desktop: VFC<{ data: Array<Omit<ShopUsaCategory, "products">> }> = ({ data }) => {
    const { trackEvent } = useMatomo();
    const headerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [offset, setOffset] = useState<number>(0);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<Omit<ShopUsaCategory, "products">>(data[0]);

    useEffect(() => {
        if (headerRef.current) {
            setOffset(headerRef.current.offsetHeight);
        }
    }, [headerRef]);

    const dropdownSpring = useSpring({
        opacity: dropdownOpen ? 1 : 0,
        transform: dropdownOpen ? `scaleY(1)` : `scaleY(0)`,
    });

    const openDropdown = useCallback(
        (selected: string) => {
            setSelected(data.find(({ category: { label } }) => selected === label) || data[0]);
            setDropdownOpen(true);
        },
        [data, setSelected, setDropdownOpen]
    );

    const closeDropdown = useCallback(() => setDropdownOpen(false), [setDropdownOpen]);

    const onLinkClick = useCallback(
        (label: string, type: "shop us top categories" | "shop us menu sub categories") => {
            // Track click on shop usa top categories
            trackEvent({
                category: "shop us",
                name: type,
                action: label,
            });
            closeDropdown();
        },
        [closeDropdown, trackEvent]
    );

    const focusContainer = () => {
        if (containerRef.current) {
            containerRef.current.focus();
        }
    };

    return (
        <div className="ShopUsaMenu-desktop" onMouseLeave={closeDropdown} onMouseEnter={focusContainer} ref={containerRef}>
            <Container>
                <div className="header" ref={headerRef}>
                    {data.map(({ category: { label, to } }, key) => (
                        <div key={key} onMouseEnter={() => openDropdown(label)}>
                            <Link className="header-link" to={to} onClick={() => onLinkClick(label, "shop us top categories")}>
                                {toTitleCase(label)}
                            </Link>
                        </div>
                    ))}
                </div>
                <ShopUsaBreadcrumbs />
            </Container>
            <animated.div className="dropdown" style={{ top: offset, ...dropdownSpring }}>
                <Container>
                    <div className="subcategories">
                        <Link to={selected.category.to} className="dropdown-title" onClick={closeDropdown}>
                            {selected.category.label}
                        </Link>
                        {selected.subcategories.map(({ label, to }, key) => (
                            <Link key={key} to={to} className="subcategory-link" onClick={() => onLinkClick(label, "shop us menu sub categories")}>
                                {toTitleCase(label)}
                            </Link>
                        ))}
                    </div>
                    <div className="features">
                        <Text className="dropdown-title">Featured</Text>
                        <div className="featured-products">
                            {selected.featured.map(brand => (
                                <FeaturedBrand {...brand} key={brand.id} layout="horizontal" />
                            ))}
                        </div>
                    </div>
                </Container>
            </animated.div>
        </div>
    );
};

export default ShopUsaMenu;
