import { FC, useCallback }                 from "react";
import { useMatomo }                       from "@datapunt/matomo-tracker-react";
import { useAppBar }                       from "@/hooks/useAppBar";
import { ReactComponent as HamburgerIcon } from "@/static/icons/hamburger_icon.svg";
import "./Hamburger.less";

interface HamburgerProps {
    analyticsMeta: {
        category: "home" | "nav bar";
        name: "menu open" | "hamburger menu open / close";
        action: "click";
    };
}

const Hamburger: FC<HamburgerProps> = ({ analyticsMeta }) => {
    const { openHamburger, closeHamburger, showHamburger } = useAppBar();
    const { trackEvent } = useMatomo();

    const onClick = useCallback(() => {
        // click to open hamburger menu
        trackEvent(analyticsMeta);
    }, [trackEvent, analyticsMeta]);

    return (
        <div className="Hamburger" onClick={showHamburger ? closeHamburger : openHamburger}>
            <HamburgerIcon className="icon" onClick={onClick} />
        </div>
    );
};

export default Hamburger;
