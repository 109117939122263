import { FC, useEffect, useState } from "react";
import Button                      from "@/designSystem/Button/Button";
import Result                      from "@/designSystem/Result/Result";

interface ErrorResultProps {
    error: any;
    containerClassName: string;
    subTitle?: string;
}

const ErrorResult: FC<ErrorResultProps> = ({ error, containerClassName }) => {
    const [errorCode, setErrorCode] = useState("");

    useEffect(() => {
        if (error) {
            // last 3 chars in error message usually is the error code
            setErrorCode(error.toString().substring(error.toString().length - 3));
        }
    }, [error]);

    return (
        <div className={`${containerClassName || "ErrorResult"}`}>
            {errorCode === "404" ? (
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={
                        <a href="/">
                            <Button type="primary">Back to Freespoke</Button>
                        </a>
                    }
                />
            ) : (
                <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                    extra={
                        <a href="/">
                            <Button type="primary">Back to Freespoke</Button>
                        </a>
                    }
                />
            )}
        </div>
    );
};
export default ErrorResult;
