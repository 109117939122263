import { FC, memo, useCallback } from "react";
import { useMatomo }             from "@datapunt/matomo-tracker-react";
import useBreakpoint             from "antd/lib/grid/hooks/useBreakpoint";
import { useLocation }           from "react-router-dom";
import { ITopStory }             from "@/@types/custom";
import { getAnalyticsCategory }  from "@/analytics/analytics";
import Link                      from "@/designSystem/Link/Link";
import Text                      from "@/designSystem/Text/Text";
import Title                     from "@/designSystem/Title/Title";
import { slugify }               from "@/utils/slugify";
import "./TopStories.less";

export interface TopStoriesProps {
    top_stories: ITopStory[];
}

const TopStories: FC<TopStoriesProps> = ({ top_stories }) => {
    const { lg } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const location = useLocation<{ clearFilters?: boolean }>();

    const recordStoryClickEvent = useCallback(
        storyTitle => {
            // Matomo analytics Story link click
            trackEvent({
                category: getAnalyticsCategory(location.pathname),
                name: "whats hot story click",
                action: storyTitle,
            });
        },
        [location.pathname, trackEvent]
    );

    const recordViewAllClickEvent = useCallback(() => {
        // Matomo analytics View All link click
        trackEvent({
            category: getAnalyticsCategory(location.pathname),
            name: "whats hot view all",
            action: "click",
        });
    }, [location.pathname, trackEvent]);

    const renderStory = (story: ITopStory, i: number) => {
        const { id, title } = story;
        const url = `/story/${id}/${slugify(title)}`;

        return (
            <div key={`top-story-${id}`} className="top-story-container">
                {lg ? (
                    <>
                        <div className="story-header">
                            <div className="item-number-block">
                                <Text>{i + 1}</Text>
                            </div>
                            <div className="title-block">
                                <Link to={url} className="story-title" onClick={() => recordStoryClickEvent(title)}>
                                    {title}
                                </Link>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="item-number-block">
                            <span>{i + 1}</span>
                        </div>
                        <div className="title-block">
                            <Link to={url} className="story-title" onClick={() => recordStoryClickEvent(title)}>
                                {title}
                            </Link>
                        </div>
                    </>
                )}
            </div>
        );
    };

    return (
        <div className="TopStories">
            <div className="header">
                <Title className="header-title">What's hot</Title>
                <Link to="/what-is-hot" className="header-link" onClick={recordViewAllClickEvent}>
                    View All
                </Link>
            </div>
            <div className="body">
                <ul className="stories-list">{top_stories.slice(0, 3).map((story, i) => renderStory(story, i))}</ul>
            </div>
        </div>
    );
};

export default memo(TopStories);
