import { FC }       from "react";
import AppBarHeader from "@/molecules/AppBarHeader/AppBarHeader";
import AppBarMenu   from "@/molecules/AppBarMenu/AppBarMenu";
import "./AppBar.less";

interface AppBarProps {}

const AppBar: FC<AppBarProps> = () => {
    return (
        <div className="AppBar">
            <AppBarHeader />
            <AppBarMenu />
        </div>
    );
};

export default AppBar;
