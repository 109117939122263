import CustomClose  from "./CustomClose";
import CustomSearch from "./CustomSearch";

const CustomSubmit = props => {
    return (
        <>
            <CustomClose onClick={props.onClose} className={props.classes[0]} {...props} />
            <CustomSearch onClick={props.onSubmit} className={props.classes[1]} {...props} />
        </>
    );
};
export default CustomSubmit;
