import { FC }                                  from "react";
import { useMatomo }                           from "@datapunt/matomo-tracker-react";
import Link                                    from "@/designSystem/Link/Link";
import Text                                    from "@/designSystem/Text/Text";
import ArticleContext, { ArticleContextProps } from "@/molecules/ArticleContext/ArticleContext";
import ImageLoader                             from "@/utils/ImageLoader";
import "./ImageArticle.less";

interface ImageArticleProps extends ArticleContextProps {
    title: string;
    images: string[];
    url: string;
    specialTag?: string;
    reportClick?: (url: string, title: string) => void;
}

const ImageArticle: FC<ImageArticleProps> = ({ images, publisher, title, url, specialTag, reportClick, ...rest }) => {
    const { trackLink } = useMatomo();
    const handleClick = () => {
        trackLink({ href: url, linkType: "link" });

        if (typeof reportClick === "function") {
            reportClick(url, title);
        } else {
            console.error("reportClick is not defined in ImageArticle component");
        }
    };
    return (
        <div className="ImageArticle">
            <Link href={url || "#"} onClick={handleClick}>
                <ImageLoader srcList={images} alt={publisher || "unknown publisher"} className="image-article-img" />
            </Link>
            <Link href={url || "#"} onClick={handleClick}>
                {specialTag && <Text className="SpecialTitle">{specialTag}</Text>}
                <Text className="ImageArticleTitle">{title}</Text>
            </Link>
            <ArticleContext publisher={publisher} {...rest} />
        </div>
    );
};

export default ImageArticle;
