import { FC, useCallback, useState } from "react";
import { AnalyticsMeta }             from "@/@types/custom";
import SpangldPreview                from "@/molecules/SpangldPreview/SpangldPreview";
import SpangldTake                   from "@/molecules/SpangldTake/SpangldTake";
import "./SpangldSummary.less";

interface SpangldSummaryProps {
    data: {
        id: string;
        title: string;
        images: string[];
        textSummary: string;
        rawHTML: string;
        link?: string;
        url?: string;
    };
    analyticsMeta: AnalyticsMeta;
    onViewSummaryClick?: (url: string, title: string) => void;
}
const SpangldSummary: FC<SpangldSummaryProps> = ({ data, onViewSummaryClick, analyticsMeta }) => {
    const [showSpangldPreview, setShowSpangldPreview] = useState(false);

    const toggleShowPreview = useCallback(() => {
        // analytics
        if (!showSpangldPreview && typeof onViewSummaryClick === "function") {
            onViewSummaryClick(data.link || data.url || "spangld.com", data.title);
        }
        setShowSpangldPreview(!showSpangldPreview);
    }, [data.link, data.url, onViewSummaryClick, showSpangldPreview, data.title]);

    return (
        <div className="SpangldSummary">
            <SpangldTake url={data.link || data.url || "spangld.com"} analyticsMeta={analyticsMeta} />
            <div className="SummaryWrap">
                <p className="Summary">{data.textSummary}</p>
                {data && (
                    <span className="SpangldView" onClick={toggleShowPreview}>
                        View Summary
                    </span>
                )}
            </div>
            {showSpangldPreview && <SpangldPreview data={data} onViewSummaryClick={toggleShowPreview} analyticsMeta={analyticsMeta} />}
        </div>
    );
};

export default SpangldSummary;
