import { FC, createContext, useContext } from "react";
import axios, { AxiosRequestHeaders }    from "axios";
import { User, useIdentityContext }      from "react-netlify-identity";
import useSWR                            from "swr";
import { GUEST_TOKEN_URL }               from "@/config";
import { getSessionId }                  from "@/helpers/index";

export interface AuthHeaders extends AxiosRequestHeaders {
    Authorization: string;
    "x-session-id": string;
}

type BackendAccess = {
    authHeaders?: AuthHeaders;
    error?: any;
};

const xSessionIdHeader = { "x-session-id": getSessionId() || "" };

const fetcher: (url: string, user: User) => any = async (url, user) => {
    const res = await axios.get(url, {
        headers: {
            ...xSessionIdHeader,
            Authorization: `Bearer ${user?.token?.access_token}`,
        },
    });
    return res.data;
};

const BackendAccessContext = createContext<BackendAccess>({ error: undefined, authHeaders: undefined });

export const BackendAccessProvider: FC = ({ children }) => {
    const { user } = useIdentityContext();
    const { data, error } = useSWR<{ [key: string]: any }>([`${GUEST_TOKEN_URL}/.netlify/functions/authorization`, user], fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    return (
        <BackendAccessContext.Provider
            value={{
                error: error,
                authHeaders: data
                    ? {
                          Authorization: `Bearer ${data.token}`,
                          "x-session-id": getSessionId() || "",
                      }
                    : undefined,
            }}
        >
            {children}
        </BackendAccessContext.Provider>
    );
};

export const useBackendAccess = () => {
    return useContext(BackendAccessContext);
};
