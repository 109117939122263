import { FC }        from "react";
import PrivacyPolicy from "@/components/legal/PrivacyPolicy";
import Checkbox      from "@/designSystem/Checkbox/Checkbox";
import Form          from "@/designSystem/Form/Form";
import Link          from "@/designSystem/Link/Link";
import "./AcceptTermsCheckbox.less";

interface AcceptTermsCheckboxProps {
    name?: string;
    rules?: object[];
}

const AcceptTermsCheckbox: FC<AcceptTermsCheckboxProps> = ({ name = "accepted_privacy_policy_and_terms", rules }) => {
    return (
        <Form.Item className="AcceptTermsCheckbox" shouldUpdate name={name} rules={rules} valuePropName="checked">
            <Checkbox className="form-checkbox">
                By signing up, you agree to our &nbsp;
                <PrivacyPolicy text="Privacy Policy" /> and <Link to="/terms-of-service">Terms of Use</Link>
            </Checkbox>
        </Form.Item>
    );
};

export default AcceptTermsCheckbox;
