import { FC, createContext, useCallback, useContext, useState } from "react";

interface IScrollContext {
    itemToScroll: string | undefined;
    dispatchScrollToItem: (itemName: string) => void;
    resetItemScroll: () => void;
}

const ScrollContext = createContext<IScrollContext>({ itemToScroll: undefined, dispatchScrollToItem: () => {}, resetItemScroll: () => {} });

export const ScrollContextProvider: FC = ({ children }) => {
    const [itemToScroll, setItemToScroll] = useState<string>("");

    const dispatchScrollToItem = useCallback((itemName: string) => {
        setItemToScroll(itemName);
    }, []);

    const resetItemScroll = useCallback(() => {
        setItemToScroll("");
    }, []);

    return (
        <ScrollContext.Provider
            value={{
                itemToScroll: itemToScroll,
                dispatchScrollToItem,
                resetItemScroll,
            }}
        >
            {children}
        </ScrollContext.Provider>
    );
};

export const useScrollContext = () => {
    return useContext(ScrollContext);
};
