import axios                                                                                                       from "axios";
import { stringify }                                                                                               from "query-string";
import useSWR                                                                                                      from "swr";
import { IArticle, IBusiness, ICoordinates, IFreespokeVideo, INavigationalPage, ISpellcheck, IStory, ITopHitItem } from "@/@types/custom";
import { useBackendAccess }                                                                                        from "@/api/useBackendAccess";
import { BACKEND_URL }                                                                                             from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

interface IWebPage {
    id: string;
    dateLastCrawled: string;
    displayUrl: string;
    name: string;
    snippet: string;
    url: string;
    deepLinks?: {
        url?: string;
        name?: string;
        snippet?: string;
        deepLinks?: {
            name?: string;
            url?: string;
        }[];
    }[];
}

interface useSearchWebResponse {
    headers: object;
    data: {
        news?: IArticle[];
        tweets?: {
            id: string;
            author: {
                name: string;
                username: string;
                profile_image_url: string;
                image_url?: string;
            };
            datePublished: string;
            text: string;
            tweet_id: string;
            url: string;
        }[];
        suggested_topics?: {
            id: string;
            name: string;
        }[];
        web_entities?: {
            description: string;
            image: string;
            title: string;
            url: string;
            source: string;
        }[];
        web_pages?: {
            data: IWebPage[];
            total: number;
        };
        navigational_pages?: INavigationalPage[];
        videos?: IFreespokeVideo[];
        stories?: Pick<IStory, "id" | "title" | "updatedAt">[];
        spellcheck?: ISpellcheck;
        top_hits?: ITopHitItem[];
        businesses: {
            total: number;
            list: IBusiness[];
            region: {
                center: ICoordinates; // user geo coordinates
            };
        };
    };
}

export interface useSearchWebParams {
    q: string;
    count: number;
    offset: number;
    includeTopics: boolean;
    includeArticles: boolean;
    includeVideos: boolean;
    includeStories: boolean;
}

const useSearchWeb = (params: useSearchWebParams) => {
    const { authHeaders } = useBackendAccess();

    const searchClientId = sessionStorage.getItem("x-search-client-id") || "";
    const url: string = `${BACKEND_URL}/search/web?${stringify(params)}`;

    const fetcher = (URL: string) =>
        axios.get(URL, { headers: { ...authHeaders, "x-search-client-id": searchClientId } }).then(res => {
            return {
                data: res.data,
                headers: res.headers,
            };
        });

    const { data, error, isValidating, mutate } = useSWR<useSearchWebResponse>(authHeaders && params.q ? url : null, fetcher, config);

    if (!searchClientId && data && data.headers && data.headers["x-search-client-id"]) {
        sessionStorage.setItem("x-search-client-id", data.headers["x-search-client-id"]);
    }

    return {
        data: data && data.data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
};

export default useSearchWeb;
