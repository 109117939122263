import { uniq } from "lodash-es";
import data     from "./shop-data.json";

// Define shape of a Shop USA product
interface Product {
    productName: string | null;
    company: string | null;
    companyUrl: string | null;
    productCategory: string | null;
    priceRange: { low: number; high: number };
    subCategory: string | null;
    productUrl: string | null;
    price: number | null;
    productImage: string | null;
    originalProductImage: string | null;
    collection: Array<string>;
    isHighlightedProduct: boolean | null;
    isFeaturedProduct: boolean | null;
    featuredProductDescription: string | null;
    isPopularProduct: boolean | null;
    productOrder: number | null;
}

// Attach interface to data from shop-data.json
const products: Array<Product> = data as Array<Product>;

// Describe the shape of the 'getProducts' filter
export type ProductFilter = Partial<{
    priceRange: Array<number>;
    category: string;
    subCategory: string;
    collection: string;
    page: number;
    perPage: number;
}>;

// Mock API for Shop USA Products
export const getProducts: (args?: ProductFilter) => { paginated: Array<Product>; products: Array<Product> } = args =>
    // IIFE: Paginate data after filtering
    (filtered => ({
        // All products
        // Includes all filtered products so we can properly populate the "product search" filters
        products: filtered,
        // Paginated products
        // Only includes a limited amount of filtered products depending on page size and number
        paginated:
            args?.page && args?.perPage
                ? // Paginate JSON array
                  filtered.slice((args.page - 1) * args.perPage, args.page * args.perPage)
                : // Otherwise allow all filtered products through
                  filtered,
    }))(
        // IIFE Input: Filter products before pagination
        products
            // Category filter
            .filter(({ productCategory }) =>
                args?.category
                    ? // If a category is not null...
                      productCategory
                        ? // Allow products through that match the given category
                          productCategory.toLowerCase() === args.category.toLowerCase()
                        : // Otherwise, don't allow the product through
                          false
                    : // If no category was given, allow all products through
                      true
            )
            // Sub-category filter
            .filter(({ subCategory }) =>
                args?.subCategory
                    ? // If a subcategory is not null...
                      subCategory
                        ? // Allow products through that match the given sub-category
                          subCategory.toLowerCase() === args.subCategory.toLowerCase()
                        : // Otherwise, don't allow the product through
                          false
                    : // If no sub-category was given, allow all products through
                      true
            )
            // Collection filter
            .filter(({ collection }) =>
                args?.collection
                    ? // If a collection is not null...
                      collection
                        ? // Allow products through that match the given collection
                          collection.map(element => element.toLowerCase()).indexOf(args.collection.toLowerCase()) !== -1
                        : // Otherwise, don't allow the product through
                          false
                    : // If no collection was given, allow all products through
                      true
            )
            // Price range filter
            .filter(({ price }) =>
                // If a price range is provided
                args?.priceRange && args.priceRange.length > 0
                    ? (priceLevels =>
                          // Only allow products through that have a price and fall within the min/max boundaries
                          (([minPrice, maxPrice]) => (price ? price > minPrice && price <= maxPrice : false))(
                              // Calculate the min/max values in the combined price levels
                              (combinedLevels => [Math.min(...combinedLevels), Math.max(...combinedLevels)])(
                                  // Filter out the price levels depending on the provided indexes and flatten array
                                  priceLevels.filter((_, i) => args.priceRange?.indexOf(i) !== -1).flat()
                              )
                          ))([
                          // $ - Level 1
                          [0, 50],
                          // $$ - Level 2
                          [50, 100],
                          // $$$ - Level 3
                          [100, 250],
                          // $$$$ - Level 4
                          [250, Number.MAX_VALUE],
                      ])
                    : // Otherwise let all products through
                      true
            )
    );

export const getAllCollections = (category?: string) =>
    uniq(
        getProducts({ category })
            .products.map(({ collection }) => collection)
            .flat()
    );

export const getAllSubCategories = (args?: ProductFilter) =>
    uniq(
        getProducts(args)
            .products.map(({ subCategory }) => subCategory)
            .flat()
    );

// Get an array of featured products for specific category
export const getFeaturedProducts = (category: string) =>
    products
        // Filter out non-featured products and ones that do not belong to this category
        .filter(({ isFeaturedProduct, productCategory }) => isFeaturedProduct && productCategory?.toLowerCase() === category.toLowerCase())
        .map((product, i) => ({
            id: product.productUrl || i.toString(),
            img: product.productImage || "",
            alt: "Product image",
            title: product.productName || "",
            description: product.featuredProductDescription || "",
            href: product.productUrl || "",
        }));

// Get an array of featured products for specific category
export const getPopularProducts = () =>
    products
        // Filter out non-featured products and ones that do not belong to this category
        .filter(({ isPopularProduct }) => isPopularProduct)
        .sort(productCustomSort)
        .map((product, i) => ({
            id: product.productUrl || i.toString(),
            brand: product.company,
            title: product.productName || "",
            price: product.price,
            img: product.productImage || "",
            fallbackImg: product.originalProductImage,
            alt: "Product image",
            href: product.productUrl || "",
        }));

// Get an array of featured products for specific category
export const getHighlightedProducts = () =>
    products
        // Filter out non-featured products and ones that do not belong to this category
        .filter(({ isHighlightedProduct }) => isHighlightedProduct)
        .sort(productCustomSort)
        .map((product, i) => ({
            id: product.productUrl || i.toString(),
            brand: product.company,
            title: product.productName || "",
            price: product.price,
            img: product.productImage || "",
            fallbackImg: product.originalProductImage,
            alt: "Product image",
            href: product.productUrl || "",
        }));

// Get an array of category names
export const getCategories = () =>
    products
        // Map all products to their product category
        .map(({ productCategory }) => productCategory)
        // Filter out all null/undefined results
        .filter(productCategory => !!productCategory)
        // Filter down to unique values only
        .filter((productCategory, index, arr) => arr.indexOf(productCategory) === index);

// Get an array of sub-category names by category
export const getSubCategoriesByCategory = (category: string) =>
    products
        // Filter products that match the provided category
        .filter(({ productCategory }) => productCategory?.toLowerCase() === category.toLowerCase())
        // Map matched products to their product subcategory
        .map(({ subCategory }) => subCategory?.toLowerCase())
        // Filter out all null/undefined results
        .filter(subCategory => !!subCategory)
        // Filter down to unique values only
        .filter((subCategory, index, arr) => arr.indexOf(subCategory) === index);

// Define default export
const shop = {
    getProducts,
    getCategories,
    getFeaturedProducts,
    getSubCategoriesByCategory,
};

const productCustomSort = (a, b) => {
    const fieldName = "productOrder";
    const ascending = true;
    // equal items sort equally
    if (a[fieldName] === b[fieldName]) {
        return 0;
    }
    // nulls sort after anything else
    else if (a[fieldName] === null) {
        return 1;
    } else if (b[fieldName] === null) {
        return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    else if (ascending) {
        return a[fieldName] < b[fieldName] ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
        return a[fieldName] < b[fieldName] ? 1 : -1;
    }
};

export default shop;
