import { FC, memo }                         from "react";
import Link                                 from "@/designSystem/Link/Link";
import Paragraph                            from "@/designSystem/Paragraph/Paragraph";
import Title                                from "@/designSystem/Title/Title";
import { ReactComponent as BookIcon }       from "@/static/icons/book-grey.svg";
import { ReactComponent as ArrowRightIcon } from "@/static/icons/right-arrow.svg";
import "./ViewStoryCard.less";

export interface ViewStoryCardProps {
    storyTitle: string;
    url: string;
}

const ViewStoryCard: FC<ViewStoryCardProps> = ({ storyTitle, url }) => {
    // TODO: implement matomo event on link click
    // const handleClick = useCallback(() => {
    // }, [url]);

    return (
        <Link rel="noopener" className="ViewStoryCard" href={url || "#"}>
            <div className="inner-container">
                <BookIcon className="view-story-icon" />
                <Title className="story-title" level={5} ellipsis={{ rows: 3 }}>
                    {storyTitle}
                </Title>
                <Paragraph className="view-story-text">
                    <span>View Story</span>
                    <ArrowRightIcon className="arrow-right-icon" />
                </Paragraph>
            </div>
        </Link>
    );
};

export default memo(ViewStoryCard);
