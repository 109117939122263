import { FC, memo }    from "react";
import { ISpellcheck } from "@/@types/custom";
import Link            from "@/designSystem/Link/Link";
import Text            from "@/designSystem/Text/Text";
import Title           from "@/designSystem/Title/Title";
import "./SuggestionText.less";

interface SuggestionTextProps {
    originalText: string;
    spellcheck: ISpellcheck;
    pathname: string;
}
const SuggestionText: FC<SuggestionTextProps> = ({ originalText, spellcheck, pathname }) => {
    return (
        <div className="SuggestionText">
            <Title level={2} className="search-query">
                {originalText}
            </Title>
            <Text className="did-you-mean">
                Did you mean:{" "}
                <Link
                    className="suggestion"
                    to={`${pathname}?${new URLSearchParams({ q: spellcheck.suggest })}`}
                    dangerouslySetInnerHTML={{ __html: spellcheck.highlight || "" }}
                />
            </Text>
        </div>
    );
};

export default memo(SuggestionText);
