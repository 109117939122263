export const TopicVersions = [
    {
        topicSummary: {
            title: "TOPIC SUMMARY",
            icon: "/images/TopicsV2/branded.svg",
            para1: {
                source: "American Bar Association: ",
                description:
                    "Freedom of speech, Supreme Court Justice Benjamin Cardozo declared more than 80 years ago, “is the matrix, the indispensable condition of nearly every other form of freedom.” Countless other justices, commentators, philosophers, and more have waxed eloquent for decades over the critically important role that freedom of speech plays in promoting and maintaining democracy.",
            },
            para2: {
                source: "New York Times: ",
                description:
                    "For all the tolerance and enlightenment that modern society claims, Americans are losing hold of a fundamental right as citizens of a free country: the right to speak their minds and voice their opinions in public without fear of being shamed or shunned.",
            },
        },
        topicHighlights: [
            {
                title: "The Free Speech Battleground ",
                description: "“In short, the free speech landscape now looks very different than it did even just five years ago.”",
                publisherIcon: "/images/TopicsV2/ipsos.svg",
                publisherName: "Ipsos",
                articles: [
                    {
                        id: "1",
                        images: ["/images/TopicsV2/rh1.svg"],
                        publisherIcon: "/images/TopicsV2/youtube.svg",
                        publisherName: "YouTube",
                        title: "The Battle Over Free Speech: Are Trigger Warnings, Safe Spaces & No-Platforming Harming Young Minds?",
                        url: "https://www.youtube.com/watch?v=wpGd5DZ_K5w&ab_channel=IntelligenceSquared",
                        footerLabel: "video",
                    },
                    {
                        id: "2",
                        images: ["/images/TopicsV2/rh2.svg"],
                        publisherIcon: "/images/TopicsV2/rumble.svg",
                        publisherName: "Rumble",
                        title: "The Fight For FREE SPEECH Against Big Tech Frauds",
                        url: "https://rumble.com/vta1j7-the-fight-for-free-speech-against-big-tech-frauds.html ",
                        footerLabel: "video",
                    },
                    {
                        id: "3",
                        images: ["/images/TopicsV2/rh3.svg"],
                        publisherIcon: "/images/TopicsV2/acast.svg",
                        publisherName: "Acast",
                        title: "Censorship: waging war on free speech",
                        url: "https://play.acast.com/s/historyextra/censorship-wagingwaronfreespeech",
                        footerLabel: "podcast",
                    },
                ],
                newsArticles: [
                    {
                        bias: "left",
                        description:
                            "America was built on the premise of free speech, but today's news is filled with examples of limiting expression, including book bans, social media suspensions, and laws restricting classroom discussions on race, sexual orientation and gender identity.",
                        id: "80Nzv05Q9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article1.svg"],
                        publisherIcon: "/images/TopicsV2/cbs.svg",
                        publisherName: "CBS News",
                        title: "How free speech is under attack in the U.S.",
                        url: "https://www.cbsnews.com/news/how-free-speech-is-under-attack-in-the-u-s/",
                    },
                    {
                        bias: "right",
                        description:
                            "The First Amendment to the U.S. Constitution begins: “Congress shall make no law … abridging the freedom of speech.” Commitment to this freedom, once universally considered fundamental, may be on the rebound. In reviving a lawsuit over the University of Michigan’s censorship code, the U.S. Court of Appeal...",
                        id: "80Nzv05Q9n5F9ap-LOf9i",
                        images: ["/images/TopicsV2/article2.svg"],
                        publisherIcon: "/images/TopicsV2/heritage.svg",
                        publisherName: "The Heritage Foundation",
                        title: "A Campus Free Speech Case Shows How Fragile Liberty Can Be",
                        url: "https://www.heritage.org/civil-society/commentary/campus-free-speech-case-shows-how-fragile-liberty-can-be",
                    },
                    {
                        bias: "left",
                        description:
                            "Between the rise of online harassment and alarmist warnings about political correctness, it’s hard to tell who’s speaking and who’s censoring.",
                        id: "80Nzv05Q9n5F9bp-LOf9i",
                        images: ["/images/TopicsV2/article3.svg"],
                        publisherIcon: "/images/TopicsV2/new-yorker.svg",
                        publisherName: "The New Yorker",
                        title: "Who’s Fighting Free Speech?",
                        url: "https://www.newyorker.com/magazine/2015/08/10/the-hell-you-say",
                    },
                    {
                        bias: "right",
                        description:
                            "There is another sort of free-speech problem, namely that of compelled speech.  That is, people (particularly faculty members) being required to say certain things.",
                        id: "80Nzv05Q9n5Fcjp-LOf9i",
                        images: ["/images/TopicsV2/article4.svg"],
                        publisherIcon: "/images/TopicsV2/nationalReview.svg",
                        publisherName: "National Review",
                        title: "A Different Sort of Free-Speech Problem on Campus | National Review",
                        url: "https://www.nationalreview.com/corner/a-different-sort-of-free-speech-problem-on-campus/",
                    },
                    {
                        bias: "left",
                        description:
                            "The study cited a lack of regulation in the domestic tech industry, and the rise of authoritarian agencies abroad",
                        id: "80Nzv05Q9n5F9dp-LOf9i",
                        images: ["/images/TopicsV2/article5.svg"],
                        publisherIcon: "/images/TopicsV2/guardian.svg",
                        publisherName: "the Guardian",
                        title: "Internet freedom on the decline in US and globally, study finds",
                        url: "https://www.theguardian.com/technology/2021/sep/21/internet-freedom-decline-free-speech-study",
                    },
                ],
                topStories: [
                    {
                        name: "The 21st Century Free Speech Act: Rep. Marjorie Taylor Greene Offers Bill to Abolish Section 230",
                        url: "https://freespoke.com/story/RAd-0crCD-iRWKllzq6O6/the-21st-century-free-speech-act-rep-marjorie-taylor-greene-offers-bill-to-abolish-section-230",
                    },
                    {
                        name: "Supreme Court Says That Boston City Hall Should Have Allowed Christian Flag on City Property",
                        url: "https://freespoke.com/story/swE7fG4XqXulb1HBGxUh7/supreme-court-says-that-boston-city-hall-should-have-allowed-christian-flag-on-city-property",
                    },
                    {
                        name: "Tulsi Gabbard Rails Against Cancel Culture and Censorship on Hannity",
                        url: "https://freespoke.com/story/61fe0094662fcf0013ea48b4/tulsi-gabbard-rails-against-cancel-culture-and-censorship-on-hannity",
                    },
                ],
            },
            {
                title: "Big Tech Censorship",
                description:
                    "“There is no doubt: today, big tech has an enormous impact on our lives. From what we buy, where we go, and even what we feel, almost everything we do is conditioned by what search engines and platforms are showing us. It even affects our democracy. Because if what we can and can’t say, read or watch online is limited by big tech censorship, it means we are less free.",
                publisherIcon: "/images/TopicsV2/liberties.svg",
                publisherName: "Liberties",
                articles: [
                    {
                        id: "4",
                        images: ["/images/TopicsV2/rh4.svg"],
                        publisherIcon: "/images/TopicsV2/rumble.svg",
                        publisherName: "Rumble",
                        title: "Bret Weinstein Talks The Problem With Big Tech Companies",
                        url: "https://rumble.com/vkhmvf-bret-weinstein-talks-the-problem-with-big-tech-companies.html ",
                        footerLabel: "video",
                    },
                    {
                        id: "5",
                        images: ["/images/TopicsV2/rh5.svg"],
                        publisherIcon: "/images/TopicsV2/youtube.svg",
                        publisherName: "YouTube",
                        title: "Yes, Big Tech is Censoring Speech: Now What?",
                        url: "https://www.youtube.com/watch?v=njvk_gSVZmw&ab_channel=InstituteforJustice",
                        footerLabel: "video",
                    },
                    {
                        bias: "right",
                        datePublished: "2022-03-27T21:50:00.000Z",
                        id: "6",
                        images: ["/images/TopicsV2/rh6.svg"],
                        publisherIcon: "/images/TopicsV2/breitbart.svg",
                        publisherName: "Breitbart",
                        title: "5 of Big Tech’s Most Serious Acts of Censorship",
                        url: "https://www.breitbart.com/tech/2021/05/10/5-of-big-techs-most-serious-acts-of-censorship/ ",
                    },
                ],
                newsArticles: [
                    {
                        bias: "middle",
                        description:
                            "Claiming that “right-wing voices are being censored,” Republican-led legislatures in Florida and Texas have introduced legislation to “end Big Tech censorship.” They’re right. Conservatives are being censored. But it’s not just conservatives who have their political speech blocked by social media giants.",
                        id: "80Nzv05Q9n5Fddp-LOf9i",
                        images: ["/images/TopicsV2/article6.svg"],
                        publisherIcon: "/images/TopicsV2/frontier.svg",
                        publisherName: "Electronic Frontier Foundation",
                        title: "Right or Left, You Should Be Worried About Big Tech Censorship",
                        url: "https://www.eff.org/deeplinks/2021/07/right-or-left-you-should-be-worried-about-big-tech-censorship ",
                    },
                    {
                        bias: "right",
                        description:
                            "The companies enjoy the privileges of common carriers without the responsibilities. State antidiscrimination laws are one promising remedy.",
                        id: "80Nzv05Q9n5F9ep-LOf9i",
                        images: ["/images/TopicsV2/article7.svg"],
                        publisherIcon: "/images/TopicsV2/wsj.svg",
                        publisherName: "WSJ",
                        title: "Opinion | The First Amendment Doesn’t Protect Big Tech’s Censorship",
                        url: "https://www.wsj.com/articles/big-tech-twitter-facebook-google-youtube-sec-230-common-carrier-11627656722 ",
                    },
                    {
                        bias: "left",
                        description: "The Tesla CEO has weighed in on the debate over Donald Trump's social media ban.",
                        id: "80Nzv05Q9n5F9fp-LOf9i",
                        images: ["/images/TopicsV2/article8.svg"],
                        publisherIcon: "/images/TopicsV2/newsweek.svg",
                        publisherName: "Newsweek",
                        title: "Elon Musk on the difference between Big Tech banning hate speech and hated speech",
                        url: "https://www.newsweek.com/elon-musk-big-tech-banning-hate-speech-hated-speech-1560873 ",
                    },
                    {
                        bias: "right",
                        description:
                            "The New York Times admitted that the information on Hunter Biden’s laptop was authenticated and relevant — 17 months after voters went to the polls, unaware of the Biden’s deals with the Chin…",
                        id: "80Nzv05Q9n5F9gp-LOf9i",
                        images: ["/images/TopicsV2/article9.svg"],
                        publisherIcon: "/images/TopicsV2/newyork.svg",
                        publisherName: "New York Post",
                        title: "The NYT finally discovers free speech — after years calling for Big Tech censorship",
                        url: "https://nypost.com/2022/03/21/the-nyt-finally-discovers-free-speech-after-years-calling-for-big-tech-censorship/ ",
                    },
                    {
                        bias: "right",
                        description:
                            "U.S. Senator Marco Rubio (R-FL) introduced legislation to halt Big Tech censorship of Americans, defend free speech on the internet, and level the playing field to remove unfair protections that shield massive Silicon Valley firms from accountability. The Disincentivizing Internet Service Censorship of Online User...",
                        id: "80Nzv05Q9n5F9hp-LOf9i",
                        images: ["/images/TopicsV2/article10.svg"],
                        publisherIcon: "",
                        publisherName: "U.S. Senator for Florida, Marco Rubio",
                        title: "Rubio Introduces Sec 230 Legislation to Crack Down on Big Tech Algorithms and Protect Free Speech",
                        url: "https://www.rubio.senate.gov/public/index.cfm/2021/6/rubio-introduces-sec-230-legislation-to-crack-down-on-big-tech-algorithms-and-protect-free-speech ",
                    },
                ],
                byNumbers: {
                    percent: "949",
                    opinion: "Censored stories discovered and published on Freespoke.com",
                },
                topStories: [
                    {
                        name: "Why Would Google Ban this Discussion on Election Integrity? Listen and Judge for Yourself",
                        url: "https://freespoke.com/story/5wD9mzshIR8kX4QVjZkv6/why-would-google-ban-this-discussion-on-election-integrity-listen-and-judge-for-yourself",
                    },
                    {
                        name: "Republicans Issue Grievance to the FEC against Google over Censoring Fundraising Emails",
                        url: "https://freespoke.com/story/bGThua_ghiGwpBfA2MKBn/republicans-issue-grievance-to-the-fec-against-google-over-censoring-fundraising-emails",
                    },
                    {
                        name: "'Incredibly Inappropriate': Elon Musk Slams Twitter for Censoring NY Post's Hunter Biden Laptop Story",
                        url: "https://freespoke.com/story/ags51deB7t93OTNA-KyRQ/incredibly-inappropriate-elon-musk-slams-twitter-for-censoring-ny-posts-hunter-biden-laptop-story",
                    },
                ],
            },
            {
                title: "Cancel Culture",
                description:
                    "“Cancel culture — the phenomenon of promoting the “canceling” of people, brands and even shows and movies due to what some consider to be offensive or problematic remarks or ideologies — isn’t all that new.”",
                publisherIcon: "/images/TopicsV2/newyork.svg",
                publisherName: "New York Post",
                articles: [
                    {
                        id: "7",
                        images: ["/images/TopicsV2/rh7.svg"],
                        publisherIcon: "/images/TopicsV2/youtube.svg",
                        publisherName: "YouTube",
                        title: "Canceling cancel culture with compassion",
                        url: "https://www.youtube.com/watch?v=pbihoXj0QwM&ab_channel=TEDxTalks",
                        footerLabel: "video",
                    },
                    {
                        author: {
                            name: "Seth Dillon",
                            profile_image_url: "/images/TopicsV2/sethdillon.svg",
                            username: "SethDillon",
                        },
                        bias: "right",
                        datePublished: "2022-05-10T05:00:00.000Z",
                        id: "yiEL7uiRbPMig6aALiRXJer",
                        text: "The aim of cancel culture is to raise the cost of speaking freely so high that few are willing to risk it. The pressure to conform 'or else' insulates the preferred narrative from criticism. When critics do rise up, Big Tech strikes them...",
                        tweet_id: "1508111817079341056",
                        url: "https://twitter.com/SethDillon/status/1524005973492387841",
                    },
                    {
                        id: "8",
                        images: ["/images/TopicsV2/rh8.svg"],
                        publisherIcon: "/images/TopicsV2/youtube.svg",
                        publisherName: "YouTube",
                        title: "Real Time with Bill Maher: Self Censorship vs. Free Speech",
                        url: "https://www.youtube.com/watch?v=ipu0ifyC-Xc&ab_channel=RealTimewithBillMaher",
                        footerLabel: "video",
                    },
                ],
                newsArticles: [
                    {
                        bias: "left",
                        description: "Is cancel culture a mob mentality, or a long overdue way of speaking truth to power?",
                        id: "80Nzv05a9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article11.svg"],
                        publisherIcon: "/images/TopicsV2/vox.svg",
                        publisherName: "Vox",
                        title: "Why we can’t stop fighting about cancel culture",
                        url: "https://www.vox.com/culture/2019/12/30/20879720/what-is-cancel-culture-explained-history-debate",
                    },
                    {
                        bias: "middle",
                        description:
                            "An overwhelming majority of voters said cancel culture has gone too far, a new Hill-HarrisX poll finds.Seventy-one percent of registered voters said they strongly or somewhat believe that cancel cu…",
                        id: "80Nzv05b9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article12.svg"],
                        publisherIcon: "/images/TopicsV2/hill.svg",
                        publisherName: "The Hill",
                        title: "Poll: Overwhelming majority say cancel culture has gone too far",
                        url: "https://thehill.com/hilltv/what-americas-thinking/580569-poll-overwhelming-majority-say-cancel-culture-has-gone-too-far/",
                    },
                    {
                        bias: "right",
                        description:
                            "Commentary Magazine · How Cancel Culture Tries to Cancel Free Expression   Today's podcast takes up the attack on a letter published by Harper's featuring dozens of prominent liberals and...",
                        id: "80Nzv05c9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article13.svg"],
                        publisherIcon: "/images/TopicsV2/commentary.svg",
                        publisherName: "Commentary Magazine",
                        title: "How Cancel Culture Tries to Cancel Free Expression",
                        url: "https://www.commentary.org/john-podhoretz/how-cancel-culture-tries-to-cancel-free-expression/ ",
                    },
                    {
                        bias: "middle",
                        description:
                            "Does any kind of public backlash or reputational damage count as being canceled, or does it need to rise to a certain level? Does it have to be permanent? Can you be canceled by just a few people? Does cancellation have to involve an organized campaign, or can the term just apply to widespread, uncoordina...",
                        id: "80Nzv05d9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article14.svg"],
                        publisherIcon: "/images/TopicsV2/freedom.svg",
                        publisherName: "Freedom Forum",
                        title: "PERSPECTIVE: UNPACKING CANCEL CULTURE: IS IT CENSORSHIP, CIVIL RIGHT OR SOMETHING ELSE?",
                        url: "https://www.freedomforum.org/2022/02/09/perspective-unpacking-cancel-culture-is-it-censorship-civil-right-or-something-else/ ",
                    },
                    {
                        bias: "middle",
                        description:
                            "Here’s a look at how adults in the United States see cancel culture, political correctness and related issues, based on the Center’s surveys.",
                        id: "80Nzv05e9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article15.svg"],
                        publisherIcon: "/images/TopicsV2/pew.svg",
                        publisherName: "Pew Research Center",
                        title: "How Americans feel about ‘cancel culture’ and offensive speech in 6 charts",
                        url: "https://www.pewresearch.org/fact-tank/2021/08/17/how-americans-feel-about-cancel-culture-and-offensive-speech-in-6-charts/ ",
                    },
                ],
                byNumbers: {
                    percent: "71%",
                    opinion: "of Americans think Cancel Culture has gone too far",
                },
                topStories: [
                    {
                        name: "Roseanne Barr, Cancelled Over Valerie Jarrett Tweet in 2018, Gives Rare Interview to Blast 'Witch Burning' Culture",
                        url: "https://freespoke.com/story/mGKUcJ3HyaXY0QePPEXaD/roseanne-barr-cancelled-over-valerie-jarrett-tweet-in-2018-gives-rare-interview-to-blast-witch-burning-culture",
                    },
                    {
                        name: "Vladimort: Putin Invokes J.K. Rowling's Cancellation in Lambasting Treatment of Russia",
                        url: "https://freespoke.com/story/2GVCb3Pzj6uLsQlR9B2wf/vladimort-putin-invokes-jk-rowlings-cancellation-in-lambasting-treatment-of-russia",
                    },
                    {
                        name: "Journalist Fired from Radio Station Over Joke About Kamala Harris' State of the Union Outfit",
                        url: "https://freespoke.com/story/mnai8rB5OwVpPwF0NFMbV/journalist-fired-from-radio-station-over-joke-about-kamala-harris-state-of-the-union-outfit",
                    },
                ],
            },
            {
                title: "Elon’s Twitter Takeover",
                description:
                    "“In a securities filing Friday, Musk accused Twitter of lying about the number of bots and spam accounts on the platform, as well as failing to provide material he asked for. That includes detailed data on the number of bot and spam accounts on Twitter, the company's methodology for calculating user numbers and backup materials detailing its financial valuation.”",
                publisherIcon: "/images/TopicsV2/cbs.svg",
                publisherName: "CBS News",
                articles: [
                    {
                        id: "10",
                        images: ["https://sp.rmbl.ws/s8/1/F/K/6/Y/FK6Ye.qR4e-small-Elon-Musk-deflects-Twitters.jpg"],
                        publisherIcon: "/images/TopicsV2/rumble.svg",
                        publisherName: "Rumble",
                        title: "Elon Musk deflects Twitter’s legal threat with humor",
                        url: "https://rumble.com/v1bwg7z-elon-musk-deflects-twitters-legal-threat-with-humor-just-the-news-now.html",
                        footerLabel: "video",
                    },
                    {
                        id: "11",
                        images: ["/images/TopicsV2/rh10.svg"],
                        publisherIcon: "/images/TopicsV2/youtube.svg",
                        publisherName: "YouTube",
                        title: "Monologue: Elon Musk's Twitter Takeover | Real Time with Bill Maher (HBO)",
                        url: "https://www.youtube.com/watch?v=QzzS4HdGkPc&ab_channel=RealTimewithBillMaher ",
                        footerLabel: "video",
                    },
                    {
                        id: "12",
                        images: ["https://i.ytimg.com/vi/E05so2YeMzs/maxresdefault.jpg"],
                        publisherIcon: "/images/TopicsV2/youtube.svg",
                        publisherName: "YouTube",
                        title: "Elon Musk Wants Out of Twitter Deal; What Comes Next?",
                        url: "https://www.youtube.com/watch?v=E05so2YeMzs&ab_channel=WallStreetJournal",
                        footerLabel: "video",
                    },
                ],
                newsArticles: [
                    {
                        bias: "left",
                        description: "'It was a business decision, it shouldn't have been.'",
                        id: "80azv05Q9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article16.svg"],
                        publisherIcon: "/images/TopicsV2/axios.svg",
                        publisherName: "Axios",
                        title: "Jack Dorsey says he agrees with reversing Trump's Twitter ban",
                        url: "https://www.axios.com/2022/05/10/jack-dorsey-trump-twitter-ban",
                    },
                    {
                        bias: "right",
                        description:
                            "Billionaire entrepreneur Elon Musk’s successful bid to take control of Twitter has inspired a mania among the nation’s most well-educated, highest-earning, left-of-center influencers—the very demographic that is most active on",
                        id: "80bzv05Q9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article17.svg"],
                        publisherIcon: "/images/TopicsV2/commentary.svg",
                        publisherName: "Commentary Magazine",
                        title: "Twitter Is Not the ‘Town Square’",
                        url: "https://www.commentary.org/noah-rothman/twitter-is-not-the-town-square/",
                    },
                    {
                        bias: "left",
                        description: "Twitter alleges Musk willingly offered and agreed to terms that were “as he touted, ‘seller friendly.’”",
                        id: "adad-LOf9i",
                        images: [
                            "https://image.cnbcfm.com/api/v1/image/107083077-1656593419933-gettyimages-1395062617-t_w16437_4934a878-972d-4bea-b6ef-b61f4ceeb787.jpeg?v=1656593537&w=1920&h=1080",
                        ],
                        publisherIcon: "https://logo.clearbit.com/cnbc.com",
                        publisherName: "CNBC",
                        title: "Here are Twitter’s biggest accusations against Musk in its lawsuit",
                        url: "https://www.cnbc.com/2022/07/13/twitters-biggest-accusations-against-musk-in-its-lawsuit.html",
                    },
                    {
                        bias: "right",
                        description:
                            "The Tesla CEO has said his vision is a private social-media company with less content moderation and an edit button.",
                        id: "80dzv05Q9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article19.svg"],
                        publisherIcon: "/images/TopicsV2/wsj.svg",
                        publisherName: "WSJ",
                        title: "What Elon Musk Would Do With Twitter",
                        url: "https://www.wsj.com/articles/what-elon-musk-would-do-with-twitter-11650200403 ",
                    },
                    {
                        bias: "left",
                        description:
                            "Elon Musk is set to take control of Twitter in a $44 billion deal that, at least judging by some of the reactions, feels like it could reshape the internet as we know it.",
                        id: "80ezv05Q9n5F9jp-LOf9i",
                        images: ["/images/TopicsV2/article20.svg"],
                        publisherIcon: "/images/TopicsV2/cnn.svg",
                        publisherName: "CNN",
                        title: "Why Elon Musk buying Twitter is such a big deal",
                        url: "https://www.cnn.com/2022/04/26/tech/importance-of-musk-buying-twitter/index.html#:~:text=More%20than%20two%2Dthirds%20of,the%20platform%20so%20potentially%20disruptive",
                    },
                ],
                topStories: [
                    {
                        name: "Elon Musk’s Twitter Buyout ‘Temporarily on Hold’",
                        url: "https://freespoke.com/story/e04E_t9lGwstEzX0UfaXZ/elon-musks-twitter-buyout-temporarily-on-hold",
                    },
                    {
                        name: "Musk Makes it Clear He Would Reverse Trump Twitter Ban ",
                        url: "https://freespoke.com/story/CQKdcWCAbunGDKjgQypeN/musk-makes-it-clear-he-would-reverse-trump-twitter-ban",
                    },
                    {
                        name: "Two Top Twitter Executives Set to Leave the Company Following Musk Takeover",
                        url: "https://freespoke.com/story/3jYWl5eVVTJReK5mhGgaj/two-top-twitter-executives-set-to-leave-the-company-following-musk-takeover",
                    },
                    {
                        name: "SEC Opens New Elon Musk Investigation for Issues with Disclosure of Twitter Stock Purchase",
                        url: "https://freespoke.com/story/Q6LtyEghZYlcpNXG-KvAU/sec-opens-new-elon-musk-investigation-for-issues-with-disclosure-of-twitter-stock-purchase",
                    },
                ],
            },
            {
                title: "Was the Hunter Biden Laptop Story Silenced?",
                description:
                    "“Which brings me to the Hunter Biden laptop story and the “discovery” by The New York Times that his laptop and its contents are real, after all. Not only did the Times and other major outlets and social media ignore the story, in some cases the story was deemed fraudulent and blocked on several platforms”",
                publisherIcon: "/images/TopicsV2/norfolk.svg",
                publisherName: "Norfolk Daily News ",
                articles: [
                    {
                        id: "13",
                        images: ["/images/TopicsV2/rh11.svg"],
                        publisherIcon: "/images/TopicsV2/rumble.svg",
                        publisherName: "Rumble",
                        title: "Joe Rogan and Mike Baker discuss the media's coverage of the Hunter Biden laptop story.",
                        url: "https://rumble.com/vxy5mt-joe-rogan-and-mike-baker-discuss-the-medias-coverage-of-the-hunter-biden-la.html",
                        footerLabel: "video",
                    },
                    {
                        author: {
                            name: "Elon Musk",
                            profile_image_url: "/images/TopicsV2/elon.svg",
                            username: "elonmusk",
                        },
                        bias: "middle",
                        datePublished: "2022-05-06T05:00:00.000Z",
                        id: "yiEL7uiRbPMig6aALiRXsdJ",
                        text: "Same org that covered up Hunter Biden laptop story, had Harvey Weinstein story early & killed it & built Matt Lauer his rape office. Lovely people.",
                        tweet_id: "1508111817079341056",
                        url: "https://twitter.com/Swiftstories/status/1522684803421818886",
                    },
                    {
                        id: "14",
                        images: ["/images/TopicsV2/rh12.svg"],
                        publisherIcon: "/images/TopicsV2/youtube.svg",
                        publisherName: "YouTube",
                        title: "Dorsey admits it was ‘wrong’ to censor Hunter Biden story, NY Post",
                        url: "https://www.youtube.com/watch?v=tWayExRuaYk&ab_channel=FoxNews",
                        footerLabel: "video",
                    },
                ],
                newsArticles: [
                    {
                        bias: "right",
                        description:
                            "First, The New York Times decides more than a year later that Hunter Biden’s business woes are worthy of a story. Then, deep in the piece, in passing, it notes that Hunter’s laptop is l…",
                        id: "80Nzv05Q9n5F9jp-aOf9i",
                        images: ["/images/TopicsV2/article21.svg"],
                        publisherIcon: "/images/TopicsV2/newyork.svg",
                        publisherName: "New York Post",
                        title: "Now that Joe Biden’s president, the Times finally admits: Hunter’s laptop is real",
                        url: "https://nypost.com/2022/03/17/the-times-finally-admits-hunter-bidens-laptop-is-real/",
                    },
                    {
                        bias: "left",
                        description: "Reports revive questions about social media companies restricting news outlets.",
                        id: "80Nzv05Q9n5F9jp-bOf9i",
                        images: ["/images/TopicsV2/article22.svg"],
                        publisherIcon: "/images/TopicsV2/washington.svg",
                        publisherName: "Washington Post",
                        title: "Analysis | Hunter Biden laptop findings renew scrutiny of Twitter, Facebook crackdowns",
                        url: "https://www.washingtonpost.com/politics/2022/03/31/hunter-biden-laptop-findings-renew-scrutiny-twitter-facebook-crackdowns/ ",
                    },
                    {
                        bias: "middle",
                        description: "Facebook and Twitter face backlash for limiting the spread of the New York Post article about Hunter Biden.",
                        id: "80Nzv05Q9n5F9jp-cOf9i",
                        images: ["/images/TopicsV2/article23.svg"],
                        publisherIcon: "/images/TopicsV2/deseret.svg",
                        publisherName: "Deseret News",
                        title: "Blocking content on social media may actually draw more attention to it",
                        url: "https://www.deseret.com/indepth/2020/10/22/21523584/censorship-fake-news-disinformation-conspiracy-twitter-facebook-social-media-hunter-biden-ny-post",
                    },
                    {
                        bias: "left",
                        description:
                            "On October 14, 2020, just weeks before the November 2020 presidential election, the New York Post published an article exposing damaging content from Hunter Biden’s personal laptop. The Post…",
                        id: "80Nzv05Q9n5F9jp-dOf9i",
                        images: ["/images/TopicsV2/article24.svg"],
                        publisherIcon: "/images/TopicsV2/politics.svg",
                        publisherName: "The Politics Society",
                        title: "The Saga of Hunter Biden’s Infamous Laptop, and Why Big Tech Censorship Fuels the Right",
                        url: "https://www.politicsatnyu.org/zeitgeist/2022/04/the-saga-of-hunter-bidens-infamous-laptop-and-why-big-tech-censorship-fuels-the-right/",
                    },
                    {
                        bias: "right",
                        description: "The press won’t claw back its credibility until it admits why it buried the story.",
                        id: "80Nzv05Q9n5F9jp-eOf9i",
                        images: ["/images/TopicsV2/article25.svg"],
                        publisherIcon: "/images/TopicsV2/wsj.svg",
                        publisherName: "WSJ",
                        title: "Opinion | Media Bias and Hunter’s Laptop",
                        url: "https://www.wsj.com/articles/media-spin-hunter-biden-laptop-news-trump-free-speech-censorship-stop-the-steal-big-lie-2020-presidential-election-steele-dossier-11649792758 ",
                    },
                ],
                topStories: [],
            },
        ],
        relatedStories: [
            {
                title: "Republicans Issue Grievance to the FEC against Google over Censoring Fundraising Emails",
                id: "bGThua_ghiGwpBfA2MKBn",
                leftBiasCount: 0,
                rightBiasCount: 4,
                content: [
                    {
                        publisherIcon: "/images/TopicsV2/hill.svg",
                        publisherName: "The Hill",
                        title: "Republicans file FEC complaint over alleged Google censorship of fundraising emails",
                        images: ["/images/TopicsV2/rt1.svg"],
                    },
                    {
                        publisherIcon: "/images/TopicsV2/newsmax.svg",
                        publisherName: "Newsmax",
                        title: "RNC Chair McDaniel to Newsmax: Gmail Treatment of GOP Emails 'Horrifying'",
                        images: ["/images/TopicsV2/rt2.svg"],
                    },
                    {
                        publisherIcon: "/images/TopicsV2/gop.svg",
                        publisherName: "GOP Presidential",
                        title: "Republicans Files FEC Complaint Against Google - GOP Presidential",
                        images: ["/images/TopicsV2/rt3.svg"],
                    },
                ],
                updatedAt: "2022-01-02T15:42:33.000Z",
            },
            {
                title: "The 21st Century Free Speech Act: Rep. Marjorie Taylor Greene Offers Bill to Abolish Section 230",
                id: "RAd-0crCD-iRWKllzq6O6",
                leftBiasCount: 6,
                rightBiasCount: 6,
                content: [
                    {
                        publisherIcon: "/images/TopicsV2/verge.svg",
                        publisherName: "The Verge Internet Services",
                        title: "Rep. Marjorie Taylor Greene throws support behind Section 230 repeal bill - The Verge",
                        images: ["/images/TopicsV2/rt4.svg"],
                    },
                    {
                        publisherIcon: "/images/TopicsV2/hill.svg",
                        publisherName: "The Hill",
                        title: "Greene offers bill to abolish Section 230",
                        images: ["/images/TopicsV2/rt5.svg"],
                    },
                    {
                        publisherIcon: "/images/TopicsV2/ussa.svg",
                        publisherName: "USSA News | The Tea Party's Front Page",
                        title: "MTG announces bill to reform Section 230, proposes roundtable with Elon Musk",
                        images: ["/images/TopicsV2/rt6.svg"],
                    },
                ],
                updatedAt: "2022-01-02T15:42:33.000Z",
            },
            {
                title: "Federal Appeals Court Revives Texas Law Prohibiting Social Media Companies from Banning Users Over Political Views",
                id: "IcktJ748ckfEo2yrlGC46",
                leftBiasCount: 6,
                rightBiasCount: 6,
                content: [
                    {
                        publisherIcon: "/images/TopicsV2/usa.svg",
                        publisherName: "USA Today",
                        title: "Texas law against Facebook, Twitter and YouTube prompted by Trump bans will take effect for now",
                        images: ["/images/TopicsV2/rt7.svg"],
                    },
                    {
                        publisherIcon: "/images/TopicsV2/kxa.svg",
                        publisherName: "KXAN 36 Austin",
                        title: "Texas law prohibiting social media companies from banning users over their viewpoints reinstated",
                        images: ["/images/TopicsV2/rt8.svg"],
                    },
                    {
                        publisherIcon: "/images/TopicsV2/wj.svg",
                        publisherName: "The Western Journal",
                        title: "Texas AG Celebrates 'Big Win Against Big Tech' as Appeals Court Makes Key Ruling",
                        images: ["/images/TopicsV2/rt9.svg"],
                    },
                ],
                updatedAt: "2022-01-02T15:42:33.000Z",
            },
        ],
        relatedTopics: [
            {
                title: "Elon Musk ",
                id: "w5kB5_CDrotyZy8NCYNrl",
                image: "/images/TopicsV2/elonmusk.svg",
                content: [
                    {
                        title: "Analysis: Why Black Twitter users are unnerved by Elon Musk’s ide..",
                    },
                    {
                        title: "MSNBC Host Frets Over Elon Musk Takeover Of Twitter And Accide...",
                    },
                    {
                        title: "With Elon Musk as an example, should we change eligibility for pr...",
                    },
                ],
            },
            {
                title: "Big Tech Censorship ",
                id: "60b7e55578ccb20016952f48",
                image: "/images/TopicsV2/censorship.svg",
                content: [
                    {
                        title: "MSNBC Host Frets Over Elon Musk Takeover Of Twitter And Accident...",
                    },
                    {
                        title: "The Heritage Foundation",
                    },
                    {
                        title: "Twitter Under Fire for Allowing Iranian Threats to Assassinate Presi...",
                    },
                ],
            },
            {
                title: "Social Media Misinformation",
                id: "60f72ea5c051380012a232a5",
                image: "/images/TopicsV2/social.svg",
                content: [
                    {
                        title: "Brit Hume: Border Patrol Agents Accused Of “Whipping” Migrants,...",
                    },
                    {
                        title: "After Nuking Hunter Biden Laptop Story, Corrupt Press Admit It W...",
                    },
                    {
                        title: "Opinion: People can spot fake news. So why do conspiracy theori...",
                    },
                ],
            },
        ],
    },
];
