import { FC }         from "react";
import { useMatomo }  from "@datapunt/matomo-tracker-react";
import { slugify }    from "@/components/utils/slugify";
import Link           from "@/designSystem/Link/Link";
import Text           from "@/designSystem/Title/Title";
import Title          from "@/designSystem/Title/Title";
import ArticleContext from "@/molecules/ArticleContext/ArticleContext";
import ImageLoader    from "@/utils/ImageLoader";
import "./HorizontalItem.less";

interface HorizontalItemProps {
    itemId: string;
    name: string;
    publisher?: string;
    publisherLogo?: string;
    imageUrl?: string;
    srcList?: string[];
    type?: string;
    url?: string;
    date?: string;
    specialTag?: string;
    bias: "left" | "middle" | "right";
    reportClick?: (url, title) => void;
}
// prettier-ignore
const HorizontalItem: FC<HorizontalItemProps> = ({ itemId, name, imageUrl, srcList, type = "topic", bias, publisher, publisherLogo, url, date, specialTag, reportClick }) => {
    const { trackLink } = useMatomo();
    const handleArticleClick = () => {
        trackLink({ href: url || "unknown url", linkType: 'link' });

        if (typeof reportClick === "function") {
            // click different topic from list on top of page 

            reportClick(url, name);
        } else {
            console.error("reportClick is not defined in HorizontalItem component");
        }
    }
    return (
        <div className="HorizontalItem">
            {type === "article" ? (
                <>
                    <Link href={url || "#"} onClick={handleArticleClick}>
                        <ImageLoader className="HorizontalItemImg" src={imageUrl} srcList={srcList} alt="Item Image" />
                        <div className="item-details">
                            {specialTag && <Text className="SpecialTag">{specialTag}</Text>}
                            <Title className="HorizontalItemTitle Article" level={5}>
                                {name}
                            </Title>
                            <ArticleContext publisher={publisher} publisherLogo={publisherLogo} bias={bias} showBias={false} date={date} />
                        </div>
                    </Link>
                </>
            ) : (
                <>
                    <Link to={`/search/topic/${itemId}/${slugify(name)}`}>
                        <ImageLoader className="HorizontalItemImg" src={imageUrl} srcList={srcList} alt="Item Image" />
                        <div className="item-details">
                            <Title className="HorizontalItemTitle Topic" level={5}>
                                {name}
                            </Title>
                        </div>
                    </Link>
                </>
            )}
        </div>
    );
};
export default HorizontalItem;
