import { useCallback }        from "react";
import axios                  from "axios";
import { stringify }          from "query-string";
import { Fetcher, KeyLoader } from "swr";
import useSWRInfinite         from "swr/infinite";
import { useBackendAccess }   from "@/api/useBackendAccess";
import { BACKEND_URL }        from "@/config";

interface QueryParams {
    perPage: number;
    page: number;
    subcategory: string;
    month: string;
    year: string;
}

type FetchedData = {
    data?: Array<{
        id: string;
        label: string;
        data?: Array<{
            id: string;
            title: string;
            url: string;
            images: Array<string>;
            datePublished: string;
            createdAt: string;
            subcategory: string;
        }>;
    }>;
    isLast: boolean;
    filter: string;
};

const useGetSpecialTopicContent = (
    route: "censored-stories" | "best-of-the-web",
    params: Partial<QueryParams> = { page: 1, perPage: 6, subcategory: "" }
) => {
    const { authHeaders } = useBackendAccess();

    const getKey: KeyLoader = useCallback(
        (index, previous) => {
            if (!authHeaders || (previous && (!previous?.data || previous.data.length === 0))) return null;
            const tag = route === "censored-stories" ? "censored" : "fun";
            return `${BACKEND_URL}/topic/list?${stringify(
                { page: index + 1, perPage: params.perPage, subcategory: params.subcategory, tag },
                { skipEmptyString: true }
            )}`;
        },
        [authHeaders, params.perPage, params.subcategory, route]
    );

    const fetcher: Fetcher<FetchedData> = (URL: string) => axios.get<FetchedData>(URL, { headers: authHeaders }).then(res => res.data);

    const { data, isValidating, error, size, mutate, setSize } = useSWRInfinite<FetchedData>(getKey, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateFirstPage: false,
    });

    const hasMore = useCallback(() => {
        const lastItem = data?.slice(-1)?.[0];
        if (lastItem?.data && lastItem.data.length > 0 && !lastItem.isLast) return true;
        else return false;
    }, [data]);

    const nextPage = useCallback(() => {
        if (hasMore()) {
            setSize(size + 1);
        }
    }, [hasMore, setSize, size]);

    return {
        data,
        isLoading: isValidating,
        error,
        hasMore,
        refresh: mutate,
        nextPage,
    };
};

export default useGetSpecialTopicContent;
