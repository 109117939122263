import { useEffect } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
// polyfill
import "intersection-observer";
import { IdentityContextProvider }                from "react-netlify-identity";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import smoothscroll                               from "smoothscroll-polyfill";
import { BackendAccessProvider }                  from "@/api/useBackendAccess";
import Root                                       from "@/components/pages/Root/Root";
import { AUTH_URL }                               from "@/config";
import Layout                                     from "@/designSystem/Layout/Layout";
import "./App.less";

/*
 * fixes "Failed to execute 'scrollTo' on 'Window': No function was found that matched the signature provided."
 * on older devices
 */
smoothscroll.polyfill(); // kick off the polyfill!

declare var fbq;

function App() {
    const { enableLinkTracking } = useMatomo();
    const { trackEvent } = useMatomo();

    useEffect(() => {
        // Matomo Analytics
        // The session storage is used to help prevent "Landed on" duplicate events anytime user refreshes the page
        // Note: the session storage item lives until the tab is closed
        const isSessionInitialized = sessionStorage.getItem("_ff_session_init") === "true";

        if (!isSessionInitialized) {
            sessionStorage.setItem("_ff_session_init", "true");
            trackEvent({
                category: "Landed on",
                action: `${window.location.hostname}${window.location.pathname}`,
            });
        }

        // FB Pixel
        // eslint-disable-next-line no-undef
        if (typeof fbq !== "undefined") {
            // check if the user has previously granted consent to be tracked by the Zuckerbeast
            if (localStorage.getItem("pixel_consent") === "true") {
                // eslint-disable-next-line no-undef
                fbq("consent", "grant");
                // eslint-disable-next-line no-undef
                fbq("track", "PageView");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // For properly tracking outlinks - this makes Matomo rescan DOM everytime this component rerenders
    enableLinkTracking();

    if (!AUTH_URL) return null;

    return (
        <Layout>
            <div className="App">
                <IdentityContextProvider url={AUTH_URL}>
                    <BackendAccessProvider>
                        <Router>
                            <Switch>
                                <Route path="/" component={Root} />
                            </Switch>
                        </Router>
                    </BackendAccessProvider>
                </IdentityContextProvider>
            </div>
        </Layout>
    );
}

export default App;
