import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PlusCircleOutlined }                                          from "@ant-design/icons";
import { useMatomo }                                                   from "@datapunt/matomo-tracker-react";
import { parse }                                                       from "query-string";
import { Helmet }                                                      from "react-helmet-async";
import { useHistory, useLocation }                                     from "react-router";
import { getAnalyticsCategory }                                        from "@/analytics/analytics";
import useGetSpecialTopicContent                                       from "@/api/useGetSpecialTopicContent";
import useGetSpecialTopicSubcategories                                 from "@/api/useGetSpecialTopicSubcategories";
import ImageLoader                                                     from "@/components/utils/ImageLoader";
import Button                                                          from "@/designSystem/Button/Button";
import Link                                                            from "@/designSystem/Link/Link";
import Text                                                            from "@/designSystem/Text/Text";
import { toTimeAgo }                                                   from "@/helpers/index";
import CategorySelect                                                  from "@/molecules/CategorySelect/CategorySelect";
import FreespokeLoader                                                 from "@/molecules/FreespokeLoader/FreespokeLoader";
import ScrollableArticles                                              from "@/molecules/ScrollableArticles/ScrollableArticles";
import AppDownloadBanner                                               from "@/organisms/AppDownloadBanner/AppDownloadBanner";
import CensoredHeader                                                  from "@/organisms/CensoredHeader/CensoredHeader";
import FunHeader                                                       from "@/organisms/FunHeader/FunHeader";
import "./Feature.less";

const defaultCategory = { label: "All Stories", value: "" };
const PER_PAGE = 5;
interface FeatureProps {
    route: "censored-stories" | "best-of-the-web";
}

interface StoryProps {
    title: string;
    images: Array<string>;
    subcategory: string;
    createdAt: string;
    route: FeatureProps["route"];
    url: string;
    onClick: (url: string) => void;
}

const SectionTitle: FC = ({ children }) => <Text className="SectionTitle">{children}</Text>;

const Story: FC<StoryProps> = memo(({ title, images, subcategory, createdAt, route, url, onClick }) => (
    <div className={`story story-${route}`}>
        <ImageLoader src={images[0]} alt={title} className="image" />
        <div className="info">
            <Text className="subtitle">{subcategory}</Text>
            <Link className="title" href={url} onClick={() => onClick(url)}>
                {title}
            </Link>
            <Text className="date">{toTimeAgo(createdAt, true)}</Text>
        </div>
    </div>
));

const Feature: FC<FeatureProps> = ({ route }) => {
    const history = useHistory();
    const location = useLocation();
    const { trackEvent } = useMatomo();
    const [subcategory, setSubcategory] = useState<string>(() => {
        const subcategoryParam = parse(location.search)?.subcategory || defaultCategory.value;
        return Array.isArray(subcategoryParam) ? subcategoryParam[0] || "" : subcategoryParam;
    });
    const [pageReady, setPageReady] = useState<boolean>(false);
    const {
        data: storyGroups,
        isLoading: isStoriesLoading,
        nextPage,
        hasMore,
    } = useGetSpecialTopicContent(route, { subcategory: subcategory, perPage: PER_PAGE });
    const { data: filters, isLoading: isFiltersLoading } = useGetSpecialTopicSubcategories(route);
    const containerRef = useRef<HTMLDivElement>(null);
    const prevHeight = useRef<number>(1);

    const isLoading: boolean = isStoriesLoading || isFiltersLoading;

    /*
     * Prevents vertical scroll position reset when the API hook starts fetching a new data, for example when user clicks on a filter
     * It accomplishes it by preventing the outer div element from downsizing it's height
     * If this idea works out well, we should move this logic into a reusable hook
     */
    useEffect(() => {
        const offset = 100; // approx. offset for the header, doesn't have to be exact
        const height = containerRef.current?.clientHeight;
        if (!isLoading && height && height > prevHeight.current) {
            containerRef.current.style.height = `${(height + offset).toString()}px`;
            containerRef.current.style.minHeight = `${(height + offset).toString()}px`;
            prevHeight.current = height + offset;
        }
        if (isLoading) {
            containerRef.current!.style!.height = "auto";
            containerRef.current!.style!.minHeight = `${window.innerHeight + window.scrollY}px`;
        }
    }, [isLoading]);

    // Keep internal state in sync with URL query parameters
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const subcategoryQueryParam = urlParams.get("subcategory") || "";

        if (subcategory !== subcategoryQueryParam) {
            setSubcategory(subcategoryQueryParam);
        }
    }, [history, location.search, subcategory]);

    useEffect(() => {
        if (!pageReady && !isLoading && storyGroups) {
            setPageReady(true);
        }
    }, [isLoading, storyGroups, pageReady]);

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    // Update URL when subcategory changes
    const onCategoryChange = useCallback(
        categoryName => {
            history.push({ ...(categoryName === "" ? {} : { search: `?${new URLSearchParams({ subcategory: categoryName })}` }) });
        },
        [history]
    );

    const onShowMoreBtnClick = useCallback(() => {
        nextPage();
        trackEvent({
            category: analyticsMeta.category,
            name: "show more stories",
            action: "click",
        });
    }, [analyticsMeta.category, nextPage, trackEvent]);

    const reportClickOnStory = useCallback(
        (url: string, type: "recent stories" | "timeline stories") => {
            trackEvent({
                category: analyticsMeta.category,
                name: `${type} article click`,
                action: url,
            });
        },
        [analyticsMeta.category, trackEvent]
    );

    const reportRecentStoryClick = useCallback((url: string) => reportClickOnStory(url, "recent stories"), [reportClickOnStory]);
    const reportTimelineStoryClick = useCallback((url: string) => reportClickOnStory(url, "timeline stories"), [reportClickOnStory]);

    const reportCarouselScroll = useCallback(
        (type: "recent stories" | "timeline stories") => {
            trackEvent({
                category: analyticsMeta.category,
                name: `${type} carousel scroll`,
                action: "click",
            });
        },
        [analyticsMeta.category, trackEvent]
    );

    const reportRecentStoriesScroll = useCallback(() => reportCarouselScroll("recent stories"), [reportCarouselScroll]);
    const reportTimelineStoriesScroll = useCallback(() => reportCarouselScroll("timeline stories"), [reportCarouselScroll]);

    const currentStory = useMemo(
        () => [
            storyGroups
                ?.map(group => group.data)
                .flat()
                .find(month => month?.label === "Current"),
        ],
        [storyGroups]
    );

    const previousStories = useMemo(
        () =>
            storyGroups
                ?.map(group => group.data)
                .flat()
                .filter(month => month?.label !== "Current"),
        [storyGroups]
    );

    const canonicalLinkParams = useMemo(() => (subcategory ? `?${new URLSearchParams({ subcategory })}` : ""), [subcategory]);

    return (
        <div ref={containerRef} className="Feature">
            <Helmet>
                <link rel="canonical" href={`https://freespoke.com/category/${route}${canonicalLinkParams}`} />
                <title>{route === "censored-stories" ? "Censored Stories" : "Best of the Web"}</title>
                {route === "censored-stories" && <meta name="description" content="Timeline of Cancel Culture Attacks"></meta>}
            </Helmet>
            {pageReady && (route === "censored-stories" ? <CensoredHeader /> : <FunHeader />)}
            <div className="max-wrap">
                {pageReady && filters && (
                    <CategorySelect
                        categories={[
                            defaultCategory,
                            ...filters.filter(String).map(filter => ({
                                label: filter.trim(),
                                value: filter.trim(),
                            })),
                        ]}
                        value={subcategory}
                        onChange={onCategoryChange}
                    />
                )}
                {!storyGroups && <FreespokeLoader />}
                {pageReady && (
                    <>
                        {currentStory.map(
                            current =>
                                current?.data &&
                                current.data.length > 0 && (
                                    <div key={current.id} className="recent">
                                        <SectionTitle>
                                            Recent
                                            {subcategory !== defaultCategory.value ? ` “${subcategory}” ` : " "}
                                            Stories
                                        </SectionTitle>
                                        <ScrollableArticles
                                            items={current.data}
                                            onScroll={reportRecentStoriesScroll}
                                            render={stories =>
                                                stories.map(story => (
                                                    <Story key={story.id} route={route} onClick={reportRecentStoryClick} {...story} />
                                                ))
                                            }
                                        />
                                        {storyGroups && <AppDownloadBanner className="app-banner" />}
                                    </div>
                                )
                        )}

                        {Array.isArray(previousStories) && previousStories.length > 0 && (
                            <Text className="timeline-title">
                                {route === "censored-stories" ? "Timeline of Censored Stories" : "Best of Web by Month"}
                            </Text>
                        )}
                        <div className="timeline">
                            {previousStories?.map(
                                month =>
                                    month?.data &&
                                    month.data.length > 0 && (
                                        <div key={month.id} className="past">
                                            <SectionTitle>
                                                {subcategory !== defaultCategory.value
                                                    ? ` “${subcategory}” in `
                                                    : route === "censored-stories"
                                                    ? "Censored in "
                                                    : "Best of "}
                                                {month.label}
                                            </SectionTitle>
                                            <ScrollableArticles
                                                items={month.data}
                                                onScroll={reportTimelineStoriesScroll}
                                                render={stories =>
                                                    stories.map(story => (
                                                        <Story key={story.id} route={route} onClick={reportTimelineStoryClick} {...story} />
                                                    ))
                                                }
                                            />
                                        </div>
                                    )
                            )}
                            {hasMore() && (
                                <Button
                                    size="large"
                                    icon={!isLoading && <PlusCircleOutlined />}
                                    onClick={onShowMoreBtnClick}
                                    disabled={isLoading}
                                    className="load-more-btn"
                                >
                                    {isLoading ? <FreespokeLoader /> : "Show More Stories"}
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Feature;
