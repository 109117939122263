import { FC, memo, useCallback }           from "react";
import { useMatomo }                       from "@datapunt/matomo-tracker-react";
import clsx                                from "clsx";
import { useLocation }                     from "react-router-dom";
import { IBusiness }                       from "@/@types/custom";
import { getAnalyticsCategory }            from "@/analytics/analytics";
import Link                                from "@/designSystem/Link/Link";
import Title                               from "@/designSystem/Title/Title";
import { ReactComponent as AddressIcon }   from "@/static/icons/location_address_blue.svg";
import { ReactComponent as PhoneIcon }     from "@/static/icons/location_phone_blue.svg";
import _0_stars_icon                       from "@/static/yelp/0_stars.png";
import _1_5_stars_icon                     from "@/static/yelp/1_5_stars.png";
import _1_stars_icon                       from "@/static/yelp/1_stars.png";
import _2_5_stars_icon                     from "@/static/yelp/2_5_stars.png";
import _2_stars_icon                       from "@/static/yelp/2_stars.png";
import _3_5_stars_icon                     from "@/static/yelp/3_5_stars.png";
import _3_stars_icon                       from "@/static/yelp/3_stars.png";
import _4_5_stars_icon                     from "@/static/yelp/4_5_stars.png";
import _4_stars_icon                       from "@/static/yelp/4_stars.png";
import _5_stars_icon                       from "@/static/yelp/5_stars.png";
import { ReactComponent as YelpShortLogo } from "@/static/yelp/yelp_burst.svg";
import ImageLoader                         from "@/utils/ImageLoader";
import "./BusinessPlace.less";

const REVIEW_ICONS_MAP = {
    0: _0_stars_icon,
    1: _1_stars_icon,
    1.5: _1_5_stars_icon,
    2: _2_stars_icon,
    2.5: _2_5_stars_icon,
    3: _3_stars_icon,
    3.5: _3_5_stars_icon,
    4: _4_stars_icon,
    4.5: _4_5_stars_icon,
    5: _5_stars_icon,
};
export interface BusinessPlaceProps {
    className?: string;
    data: IBusiness;
}

const BusinessPlace: FC<BusinessPlaceProps> = ({
    className,
    data: { name, image_url, url, display_phone, location, rating, review_count, price, categories },
}) => {
    const routerLocation = useLocation<{ clearFilters?: boolean }>();
    const { trackEvent } = useMatomo();

    const recordTitleClick = useCallback(() => {
        // Track click on location title
        trackEvent({
            category: getAnalyticsCategory(routerLocation.pathname),
            name: "location title click",
            action: "click",
        });
    }, [trackEvent, routerLocation.pathname]);

    const recordPhoneNumberClick = useCallback(() => {
        // Track click on location phone number
        trackEvent({
            category: getAnalyticsCategory(routerLocation.pathname),
            name: "location phone number click ",
            action: "click",
        });
    }, [trackEvent, routerLocation.pathname]);

    const recordRatingClick = useCallback(() => {
        // Track click on location ratings/reviews
        trackEvent({
            category: getAnalyticsCategory(routerLocation.pathname),
            name: "location review click",
            action: "click",
        });
    }, [trackEvent, routerLocation.pathname]);

    const recordImageClick = useCallback(() => {
        // Track click on image link
        trackEvent({
            category: getAnalyticsCategory(routerLocation.pathname),
            name: "location image click",
            action: "click",
        });
    }, [trackEvent, routerLocation.pathname]);

    const renderCTAs = () => {
        return (
            <div className="business-info">
                {location?.display_address && location?.display_address.length > 0 && (
                    <div className="business-info-item">
                        <AddressIcon />
                        <address className="business-info-item-text">{location?.display_address?.join(", ")}</address>
                    </div>
                )}
                {display_phone && (
                    <div className="business-info-item">
                        <PhoneIcon />
                        <a className="business-info-item-text" href="tel:123-456-7890" onClick={recordPhoneNumberClick}>
                            {display_phone}
                        </a>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={clsx("BusinessPlace", className)}>
            <Link href={url || "yelp.com"} target="blank" className="business-image-link" onClick={recordImageClick}>
                <ImageLoader className="business-image" src={image_url} alt="business" />
            </Link>
            <div className="business-body">
                <Link href={url || "yelp.com"} target="blank" onClick={recordTitleClick}>
                    <Title level={3} className="business-title">
                        {name}
                    </Title>
                </Link>
                <span>
                    <span className="business-price">{price}</span>{" "}
                    {`${price ? " - " : ""}${categories?.map(category => category.title).join(" | ")}`}
                </span>
                {renderCTAs()}
                <div className="business-footer">
                    {rating && REVIEW_ICONS_MAP[rating] && (
                        <Link href={url || "yelp.com"} target="blank" className="business-rating-link" onClick={recordRatingClick}>
                            <img className="business-rating-image" src={REVIEW_ICONS_MAP[rating]} alt="stars rating icon" />
                            {review_count && (
                                <span className="business-rating-text">{`Based on ${review_count} ${
                                    review_count === 1 ? "review" : "reviews"
                                }`}</span>
                            )}
                        </Link>
                    )}
                    <Link href={url || "yelp.com"} target="blank">
                        <YelpShortLogo className="business-yelp-logo" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default memo(BusinessPlace);
