import { useEffect, useState } from "react";
import copyToClipboard         from "copy-to-clipboard";
import CopyOutlined            from "@/designSystem/Icons/CopyOutlined";
import Message                 from "@/designSystem/Message/Message";

type CopyCallback = (text?: string) => void;
type UseClipboard = (
    options?: Partial<{
        defaultText: string;
        successDuration: number;
    }>
) => { isCopied: boolean; copy: CopyCallback };

/**
 * Helper function that copies a text value to the user's clipboard. We use this solution
 * to cover our bases with browser support. Unfortunately the following syntax isn't
 * covered by all browsers at this time...
 *
 * await navigator.clipboard.writeText(url);
 */
const useClipboard: UseClipboard = ({ defaultText = "", successDuration = 1200 } = {}) => {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (isCopied && successDuration) {
            const timeoutId = setTimeout(() => {
                setIsCopied(false);
            }, successDuration);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isCopied, successDuration]);

    /**
     * Perform a clipboard copy
     * @param text Optionally pass text to be copied. Will override 'defaultValue'.
     */
    const copy: CopyCallback = text => {
        const success = copyToClipboard(text || defaultText);
        Message.success({
            content: "Copied !",
            icon: <CopyOutlined />,
        });
        setIsCopied(success);
    };

    return { isCopied, copy };
};

export default useClipboard;
