/* eslint-disable no-multi-spaces */
// Note: react-scripts require env variables to be prefixed with REACT_APP
// prettier-config
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const GUEST_TOKEN_URL = process.env.REACT_APP_GUEST_TOKEN_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const ENABLE_ANALYTICS = process.env.REACT_APP_ENABLE_ANALYTICS === "true";
export const MATOMO_SITE_ID = parseInt(process.env.REACT_APP_MATOMO_SITE_ID);
export const MATOMO_TRACKER_URL = process.env.REACT_APP_MATOMO_TRACKER_URL;
export const MATOMO_SRC_URL = process.env.REACT_APP_MATOMO_SRC_URL;
export const PRIVATE_KEY = process.env.PRIVATE_KEY;
export const ENABLE_DATADOG = process.env.REACT_APP_ENABLE_DATADOG === "true";
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV || "not set";
