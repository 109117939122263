import { FC, memo, useCallback } from "react";
import { useMatomo }             from "@datapunt/matomo-tracker-react";
import { useLocation }           from "react-router-dom";
import { getAnalyticsCategory }  from "@/analytics/analytics";
import Link                      from "@/designSystem/Link/Link";
import app_play_badge_black      from "@/static/logo/app-store-badge-black.svg";
import app_play_badge_white      from "@/static/logo/app-store-badge-white.svg";
import google_play_badge         from "@/static/logo/google-play-badge.png";
import "./AppDownloadBadge.less";

export interface AppDownloadBadgeProps {
    type: "ios" | "ios-alt" | "android";
    className?: string;
}

const AppDownloadBadge: FC<AppDownloadBadgeProps> = ({ type, className }) => {
    const isIOS = type === "ios" || type === "ios-alt";
    const { trackEvent } = useMatomo();
    const { pathname } = useLocation<{ clearFilters?: boolean }>();

    const recordAppDownloadClickEvent = useCallback(() => {
        // Matomo analytics click App Download link
        const pageName = getAnalyticsCategory(pathname);

        trackEvent({
            category: "app download",
            name: `app download - ${pageName} ${isIOS ? "ios" : "android"}`,
            action: "app download click",
        });
        if (pageName === "home") {
            trackEvent({
                category: "home",
                name: "app cta click home",
                action: "click",
            });
        }
    }, [isIOS, pathname, trackEvent]);

    const renderBadge = () => {
        switch (type) {
            case "ios":
                return <img src={app_play_badge_black} alt="app store badge" />;
            case "ios-alt":
                return <img src={app_play_badge_white} alt="app store badge" />;
            case "android":
                return <img src={google_play_badge} alt="google play badge" />;
            default:
                break;
        }
    };

    return (
        <div className={`AppDownloadBadge ${className ? className : ""} ${isIOS ? "ios" : "android"}`}>
            <Link
                href={isIOS ? "https://search.freespoke.com/3NDyZ1o" : "https://search.freespoke.com/3MJ5KKF"}
                className="download-link"
                onClick={recordAppDownloadClickEvent}
            >
                {renderBadge()}
            </Link>
        </div>
    );
};

export default memo(AppDownloadBadge);
