import { FC, Suspense, lazy, useEffect, useState } from "react";
import { Route, Switch, useLocation }              from "react-router-dom";
import useAppHeight                                from "@/components/utils/useAppHeight";
import { ScrollContextProvider }                   from "@/context/useScrollContext";
import { scrollToTop }                             from "@/helpers/index";
import { AppBarProvider }                          from "@/hooks/useAppBar";
import Footer                                      from "@/layout/Footer/Footer";
import FreespokeLoader                             from "@/molecules/FreespokeLoader/FreespokeLoader";
import AppBar                                      from "@/organisms/AppBar/AppBar";
import CategoriesSearch                            from "@/pages/CategoriesSearch/CategoriesSearch";
import Category                                    from "@/pages/Category/Category";
import Homepage                                    from "@/pages/Homepage/Homepage";
import ProductSearch                               from "@/pages/ProductSearch/ProductSearch";
import Products                                    from "@/pages/Products/Products";
import SearchPage                                  from "@/pages/SearchPage/SearchPage";
import StoryPage                                   from "@/pages/StoryPage/StoryPage";
import TopStoriesPage                              from "@/pages/TopStoriesPage/TopStoriesPage";
import TopicSearch                                 from "@/pages/TopicSearch/TopicSearch";
import TopicsV2                                    from "@/pages/TopicsV2/TopicsV2";
import "./Root.less";

// import JoinMovementCTA                             from "@/molecules/JoinMovementCTA/JoinMovementCTA";

/*
 * We can assume that the below components won't be needed for 90% of users,
 * so let's not include them in main.script and main.css assets.
 * It will be only loaded after user navigates to the routes that use these components
 */
const About = lazy(() => import("../About/About"));
const NoticeOfCollection = lazy(() => import("@/pages/NoticeOfCollection/NoticeOfCollection"));
const PrivacyPolicy = lazy(() => import("@/pages/PrivacyPolicy/PrivacyPolicy"));
const ResetPassword = lazy(() => import("@/components/auth/ResetPassword/ResetPassword"));
const SignUp = lazy(() => import("@/pages/SignUp/SignUp"));
const TermsOfService = lazy(() => import("@/pages/TermsOfService/TermsOfService"));

const Root: FC = () => {
    const location = useLocation();
    const [hasVisitedHomepage, setHasVisitedHomepage] = useState<boolean>(false);
    useAppHeight();

    // Save for future use
    // const joinMovementVisibilityRegex = /^\/?$|^(\/join)/;

    // when someone lands on any page page other then homepage, we don't want to render 'Homepage' component
    useEffect(() => {
        if (location.pathname === "/" && !hasVisitedHomepage) {
            setHasVisitedHomepage(true);
        }
    }, [hasVisitedHomepage, location.pathname]);

    const isHomepageRoute = location.pathname === "/";

    // Scroll to top on navigation stack push (or replace)
    useEffect(() => {
        // do not scroll to top when user goes back to homepage
        if (location.pathname !== "/") {
            scrollToTop({ behavior: "auto" });
        }
    }, [location.pathname]);

    return (
        <AppBarProvider>
            <ScrollContextProvider>
                <div className="Root">
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    flex: "1 1",
                                    display: "flex",
                                    height: "100%",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <FreespokeLoader />
                            </div>
                        }>
                        {/* Save for future use */}
                        {/* {!joinMovementVisibilityRegex.test(location.pathname) && <JoinMovementCTA />} */}
                        <Switch>
                            <Route path="/story/:story_id/:story_slug?" component={StoryPage} />
                            <Route path="/category/:category_name/:topic_id?" component={Category} />
                            <Route path="/search/categories/:category_id?" component={CategoriesSearch} />
                            <Route path="/topic/:topic_id" component={TopicsV2} />
                            <Route path="/search/topic/:topic_id?/:topic_name?" component={TopicSearch} />
                            <Route path="/search" component={SearchPage} />
                            <Route path="/product/:product_category_name?" component={ProductSearch} />
                            <Route path="/products" component={Products} />
                            <Route path="/about" component={About} />
                            <Route path="/join" component={SignUp} />
                            <Route path="/extension-success" component={SignUp} />
                            <Route path="/what-is-hot" component={TopStoriesPage} />
                            <Route path="/reset-password" component={ResetPassword} />
                            <Route path="/notice-of-collection" component={NoticeOfCollection} />
                            <Route path="/privacy-policy" component={PrivacyPolicy} />
                            <Route path="/terms-of-service" component={TermsOfService} />
                        </Switch>
                        {hasVisitedHomepage && <Homepage hide={!isHomepageRoute} />}
                    </Suspense>
                    <Footer />
                </div>
                <AppBar />
            </ScrollContextProvider>
        </AppBarProvider>
    );
};

export default Root;
