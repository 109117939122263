import axios           from "axios";
import { AuthHeaders } from "@/api/useBackendAccess";
import { BACKEND_URL } from "../config";

export async function signUpForANewsletter(email: string, authHeaders?: AuthHeaders) {
    try {
        const { data } = await axios.post(`${BACKEND_URL}/forms/newsletter/register`, { email }, { headers: authHeaders });
        return data;
    } catch (e) {
        const errorMessage = e && e.response && e.response.data && e.response.data.message;
        throw errorMessage || e.message || e;
    }
}
