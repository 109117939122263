import { FC, useCallback, useMemo }         from "react";
import Text                                 from "@/designSystem/Text/Text";
import { toTimeAgo }                        from "@/helpers/index";
import { ReactComponent as LeftIcon }       from "@/static/icons/democrat_flat.svg";
import { ReactComponent as FreespokeVideo } from "@/static/icons/freespoke-video.svg";
import { ReactComponent as RightIcon }      from "@/static/icons/republican_flat.svg";
import "./ContentFooter.less";

interface ContentFooterProps {
    datePublished?: string;
    label: string;
}
// NOTE: if label equals to 'left' or 'right' a bias icon will be displayed before the label

const ContentFooter: FC<ContentFooterProps> = ({ datePublished, label }) => {
    const distance = useMemo(() => datePublished && toTimeAgo(datePublished ?? "", true), [datePublished]);

    const getFooterLabel = useCallback(() => {
        if (label === "left") {
            return (
                <>
                    <LeftIcon className="donkey" />
                    <Text className="bias left">LEFT</Text>
                </>
            );
        }
        if (label === "right") {
            return (
                <>
                    <RightIcon className="elephant" />
                    <Text className="bias right">RIGHT</Text>
                </>
            );
        }
        if (label === "video" || label === "freespoke video" || label === "podcast") {
            return (
                <span>
                    <FreespokeVideo />
                    <Text className="bias video">{label.toUpperCase()}</Text>
                </span>
            );
        }
        if (label) {
            return <Text className="bias generic">{label.toUpperCase()}</Text>;
        }
        return null;
    }, [label]);

    return (
        <div className="ContentFooter">
            {getFooterLabel()}
            {distance && <Text className="date">{distance}</Text>}
        </div>
    );
};

export default ContentFooter;
