import { RefObject, useEffect, useRef } from "react";
import { AnalyticsMeta }                from "@/@types/custom";
import useGetHomepage                   from "@/api/useGetHomepage";
import { Col, Row }                     from "@/designSystem/Grid/Grid";
import BreakingNewsSidebar              from "@/molecules/BreakingNews/BreakingNews";
import FreespokeLoader                  from "@/molecules/FreespokeLoader/FreespokeLoader";
import Notification                     from "@/molecules/Notification/Notification";
import TopicSidebar                     from "@/molecules/TopicsSidebar/TopicsSidebar";
import ContentHolder                    from "@/organisms/ContentHolder/ContentHolder";
import TopStories                       from "@/organisms/TopStories/TopStories";
import "./MobileLayout.less";

const REFRESH_INTERVAL = 60 * 1000; // 1min
interface MobileLayoutProps {
    reportDataFetchCompletion: Function;
    analyticsMeta: Omit<AnalyticsMeta, "name" | "action">;
}
const MobileLayout = ({ reportDataFetchCompletion, analyticsMeta }: MobileLayoutProps) => {
    const { data, isLoading, refresh, checkForNewData } = useGetHomepage({ include: ["featured_stories", "top_stories"] });
    const contentRef = useRef() as RefObject<HTMLDivElement>;

    // Start polling new data on interval
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isLoading) {
                checkForNewData();
            }
        }, REFRESH_INTERVAL);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    // reports to homepage when data has been fetched
    useEffect(() => {
        reportDataFetchCompletion(!isLoading && !!data);
    }, [data, reportDataFetchCompletion, isLoading]);

    // after refresh, scroll smoothly to the top story
    const onNotificationClick = () => {
        refresh().then(() => {
            if (contentRef.current) {
                contentRef.current.scrollIntoView({ behavior: "smooth" });
            }
        });
    };
    return (
        <div className="MobileLayout">
            {!isLoading && data && (
                <Row gutter={[0, 24]}>
                    <Notification sticky={true} text="New Stories" newItemsCount={data.newStoriesAvailable} onClick={onNotificationClick} />
                    {Array.isArray(data.top_stories) && data.top_stories.length > 0 && (
                        <Col span={24}>
                            <TopStories top_stories={data.top_stories} />
                        </Col>
                    )}
                    <Col span={24}>
                        <TopicSidebar data={data.suggested_topics} />
                    </Col>
                    <Col span={24}>
                        <BreakingNewsSidebar mobile />
                    </Col>
                    <Col>
                        <ContentHolder
                            customRef={contentRef}
                            spangld={data.spangld}
                            isMobile={true}
                            stories={data.stories}
                            censored_stories={data.censored_stories}
                            best_of_web={data.best_of_web}
                            featured_stories={data.featured_stories}
                            analyticsMeta={analyticsMeta}
                        />
                    </Col>
                </Row>
            )}
            {isLoading && <FreespokeLoader />}
        </div>
    );
};

export default MobileLayout;
