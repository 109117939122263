import { FC, useCallback, useEffect, useMemo } from "react";
import { useMatomo }                           from "@datapunt/matomo-tracker-react";
import clsx                                    from "clsx";
import { debounce }                            from "lodash-es";
import { useLocation }                         from "react-router";
import { getAnalyticsCategory }                from "@/analytics/analytics";
import useGetBreakingNews                      from "@/api/useGetBreakingNews";
import Button                                  from "@/designSystem/Button/Button";
import Card                                    from "@/designSystem/Card/Card";
import ReloadOutlined                          from "@/designSystem/Icons/ReloadOutlined";
import Skeleton                                from "@/designSystem/Skeleton/Skeleton";
import BreakingNewsItem                        from "../BreakingNewsItem/BreakingNewsItem";
import "./BreakingNews.less";

const PER_PAGE = 10;
const REFRESH_INTERVAL = 60 * 1000; // 1min

interface BreakingNewsProps {
    mobile?: boolean;
}

const BreakingNews: FC<BreakingNewsProps> = ({ mobile = false }) => {
    const location = useLocation();
    const { trackEvent } = useMatomo();
    const { data: { data, newContentAvailable } = {}, isLoading, checkForNewData, refresh } = useGetBreakingNews({ page: 1, perPage: PER_PAGE });

    // Get analytics category name - 'home', 'freespoke topic' etc
    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const reportBreakingNewsContentClick = useCallback(
        (url: string) => {
            trackEvent({
                category: analyticsMeta.category,
                name: "breaking news content click",
                action: url,
            });
        },
        [analyticsMeta.category, trackEvent]
    );

    // Report Breaking News User Scroll - reports only once per 2 seconds
    const reportBreakingNewsScroll = useMemo(
        () =>
            debounce(() => {
                trackEvent({
                    category: analyticsMeta.category,
                    name: "breaking news scroll",
                    action: "scroll",
                });
            }, 2000),
        [analyticsMeta.category, trackEvent]
    );

    // Start polling new data on interval
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isLoading) {
                checkForNewData();
            }
        }, REFRESH_INTERVAL);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <Card
            className={clsx("BreakingNews", mobile && "BreakingNewsMobile")}
            size="small"
            title={`BREAKING NEWS${newContentAvailable ? ` (${newContentAvailable})` : ""}`}
            extra={newContentAvailable ? <Button icon={<ReloadOutlined onClick={refresh} />} type="primary"></Button> : undefined}
        >
            {isLoading ? (
                [...Array(PER_PAGE)].map((_, i) => <Skeleton key={i} />)
            ) : (
                <div className="body" onScroll={reportBreakingNewsScroll}>
                    {data?.map((content, i) => (
                        <BreakingNewsItem content={content} key={i} onClick={reportBreakingNewsContentClick} />
                    ))}
                    {mobile && <div className="breaking-news-item-spacer" />}
                </div>
            )}
        </Card>
    );
};

export default BreakingNews;
