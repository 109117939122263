import React, { FC, useCallback }  from "react";
import { useMatomo }               from "@datapunt/matomo-tracker-react";
import clsx                        from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { IImageSearchResult }      from "@/@types/custom";
import { getAnalyticsCategory }    from "@/analytics/analytics";
import Link                        from "@/designSystem/Link/Link";
import "./ImageSearchResult.less";

interface ImageSearchResultProps {
    data: IImageSearchResult;
    type: "with-metadata" | "with-metadata-on-hover";
}
const ImageSearchResult: FC<ImageSearchResultProps> = ({ data, type }) => {
    const location = useLocation();
    const history = useHistory();
    const { trackEvent } = useMatomo();

    const handleImageClick = useCallback(() => {
        trackEvent({
            category: getAnalyticsCategory(location.pathname),
            name: "images tab image click",
            action: "click",
        });

        const urlParams = new URLSearchParams(location.search);

        if (urlParams.has("id")) {
            urlParams.delete("id");
        }
        urlParams.append("id", `${data.id}`);

        history.push({
            pathname: location.pathname,
            search: `?${urlParams}`,
        });
    }, [data.id, history, location.pathname, location.search, trackEvent]);

    return (
        <div className={clsx("ImageSearchResult", type && type)}>
            <div className="image-container">
                <button className="image-button" onClick={handleImageClick}>
                    <img className="image" width={data?.width} height={data?.height} src={data.thumbnailUrl} alt={data.name} />
                </button>
            </div>
            <div className="metadata-container">
                <div className="metadata-wrapper">
                    {data.name && (
                        <h3 onClick={handleImageClick} title={data.name} className="name text">
                            {data.name}
                        </h3>
                    )}
                    {data.hostPageDomainFriendlyName && (
                        <Link href={data.hostPageUrl || "/"}>
                            <span className="meta host text">{data.hostPageDomainFriendlyName}</span>
                        </Link>
                    )}
                    {data.hostPageDomainFriendlyName && data.width && data.height && <span className="meta bullet-point text">&#x2022;</span>}
                    {data.width && data.height && (
                        <span className="meta dimensions text">
                            {data.width} x {data.height}
                        </span>
                    )}
                    {data.encodingFormat && <span className="meta text"> {data.encodingFormat}</span>}
                </div>
            </div>
        </div>
    );
};
export default React.memo(ImageSearchResult);
