import { FC }                                               from "react";
import useBreakpoint                                        from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                                 from "clsx";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

export type LinkProps = RequireOnlyOne<RouterLinkProps, "to" | "href">;

/**
 * This component will either work as a normal React Router Link
 * or a standard HTMLAnchorElement. You have to either pass "to"
 * or "href" as a prop. If you pass "to", this component will function
 * as a normal React Router Link. If you pass "href", this component
 * will function as a normal HTMLAnchorElement. Both options will
 * include styling from Ant Design.
 *
 * Why? Because we need a component that will handle both internal
 * and external routing. This solution accomplishes both use cases
 * from a single component.
 *
 * NOTE: On "lg" screens the default behavior for external links is to open them in a new tab
 */
const Link: FC<LinkProps> = ({ children, to, replace, innerRef, className, onClick, rel = "noopener", target, ...rest }) => {
    const { lg } = useBreakpoint();

    return to ? (
        <RouterLink
            {...rest}
            className={clsx("ant-typography", className && className)}
            to={to}
            replace={replace}
            onClick={onClick}
            innerRef={innerRef}
        >
            {children}
        </RouterLink>
    ) : (
        <a {...rest} className={clsx("ant-typography", className && className)} onClick={onClick} target={target || (lg ? "_blank" : "")} rel={rel}>
            {children}
        </a>
    );
};

export default Link;
