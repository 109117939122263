import axios                from "axios";
import useSWRImmutable      from "swr/immutable";
import { useBackendAccess } from "@/api/useBackendAccess";
import { BACKEND_URL }      from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export interface IUseGetSuggestionsResponse {
    topics?: {
        id: string | undefined;
        name: string | undefined;
        image_url: string | undefined;
    }[];
    popular_search_terms?: {
        value: string | undefined;
    }[];
}

export interface IUseGetSuggestionsParams {
    topicsLimit: number;
    searchTermsLimit: number;
}

const useGetSuggestions = (params: IUseGetSuggestionsParams) => {
    const { authHeaders } = useBackendAccess();
    const url = `${BACKEND_URL}/suggestions/list`;
    const fetcher = () => axios.get(url, { headers: authHeaders, params }).then(res => res.data);

    const { data, error, isValidating, mutate } = useSWRImmutable<IUseGetSuggestionsResponse>(authHeaders ? url : null, fetcher, config);

    return {
        data,
        isLoading: isValidating,
        error,
        refresh: mutate,
    };
};

export default useGetSuggestions;
