import { FC, useCallback } from "react";
import { slugify }         from "@/components/utils/slugify";
import Avatar              from "@/designSystem/Avatar/Avatar";
import Link                from "@/designSystem/Link/Link";
import AlertPill           from "@/molecules/AlertPill/AlertPill";
import "./TopicsSidebarItem.less";

interface TopicsSidebarItemProps {
    hot?: boolean;
    topic: Record<string, any>;
    onClick: Function;
}

const TopicsSidebarItem: FC<TopicsSidebarItemProps> = ({ hot, topic, onClick }) => {
    const onTopicItemClick = useCallback(() => {
        onClick(topic.name);
    }, [onClick, topic.name]);

    return (
        <div className="TopicsSidebarItem">
            <div>
                <Avatar src={topic.image_url} />
            </div>
            <div className="title">
                <div>
                    <Link to={`/search/topic/${topic.id}/${slugify(topic.name as string)}`} onClick={onTopicItemClick}>
                        {topic.name}
                    </Link>
                </div>
                {hot && <AlertPill color={"#C43351"} label="hot" />}
            </div>
        </div>
    );
};

export default TopicsSidebarItem;
