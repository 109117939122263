import { FunctionComponent } from "react";
import Link                  from "@/designSystem/Link/Link";

interface NoticeOfCollectionTypes {
    text?: string;
    className?: string;
    onClick?: () => void;
}

const NoticeOfCollection: FunctionComponent<NoticeOfCollectionTypes> = ({ text, className, onClick }) => {
    return (
        <Link {...{ className, onClick }} title={text} to="/notice-of-collection">
            {text ? text : "Notice of Collection"}
        </Link>
    );
};

export default NoticeOfCollection;
