import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMatomo }                                     from "@datapunt/matomo-tracker-react";
import useBreakpoint                                     from "antd/lib/grid/hooks/useBreakpoint";
import { useHistory, useLocation }                       from "react-router-dom";
import { IImageSearchResult }                            from "@/@types/custom";
import { getAnalyticsCategory }                          from "@/analytics/analytics";
import Button                                            from "@/designSystem/Button/Button";
import Link                                              from "@/designSystem/Link/Link";
import Title                                             from "@/designSystem/Title/Title";
import { scrollToTop }                                   from "@/helpers/index";
import { ReactComponent as ArrowLeft }                   from "@/static/icons/arrow-left.svg";
import "./ImageDetailsPage.less";

export interface IImageDetailsPage {
    imageSearchTerm?: string;
    imageData: IImageSearchResult[] | undefined;
    isVisible: boolean;
    imageId: string;
}

const ImageDetailsPage: FC<IImageDetailsPage> = ({ imageId, imageSearchTerm, imageData, isVisible }) => {
    const location = useLocation();
    const history = useHistory();
    const { lg } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const [displayMetaInfo, setDisplayMetaInfo] = useState<boolean>(false); // prevent layout shift

    const imageDetails = useMemo(() => imageData && imageData.find(data => data.id === imageId), [imageData, imageId]);

    const relatedImageDetails = useMemo(() => {
        let mainImageIndex = imageData && imageData.findIndex(data => data.id === imageId);

        if (typeof mainImageIndex === "number" && imageData && mainImageIndex + 5 > imageData?.length) {
            mainImageIndex = 1;
        }

        return imageData && typeof mainImageIndex === "number" && imageData.slice(mainImageIndex + 1, mainImageIndex + 6);
    }, [imageData, imageId]);

    // Scroll to the top when user visits a new "Image details" page
    useEffect(() => {
        if (imageId) {
            scrollToTop({ behavior: "auto" });
        }
        // reset display meta info state
        setDisplayMetaInfo(false);
    }, [imageId]);

    const clearIdFromUrl = useCallback(() => {
        const urlParams = new URLSearchParams(location.search);

        if (urlParams.has("id")) {
            urlParams.delete("id");

            history.push({
                pathname: location.pathname,
                search: `?${urlParams}`,
            });
        }
    }, [history, location.pathname, location.search]);

    const handleBackButtonClick = useCallback(() => {
        clearIdFromUrl();
    }, [clearIdFromUrl]);

    const handleRelatedClick = useCallback(
        id => {
            trackEvent({
                category: getAnalyticsCategory(location.pathname),
                name: "images tab related image click",
                action: "click",
            });

            const urlParams = new URLSearchParams(location.search);

            if (urlParams.has("id")) {
                urlParams.delete("id");
                urlParams.append("id", id);

                history.push({
                    pathname: location.pathname,
                    search: `?${urlParams}`,
                });
            }
        },
        [history, location.pathname, location.search, trackEvent]
    );

    const renderRelatedImages = useCallback(
        () =>
            relatedImageDetails &&
            relatedImageDetails.map(relatedImage => (
                <div className="related-content-card" key={relatedImage.id || relatedImage.thumbnailUrl}>
                    <Button className="related-content-button" onClick={() => handleRelatedClick(relatedImage.id)}>
                        <img className="related-img" src={relatedImage.thumbnailUrl} alt={relatedImage.name} />
                        <Title className="related-img-title" level={4}>
                            {relatedImage.name}
                        </Title>
                    </Button>
                </div>
            )),
        [handleRelatedClick, relatedImageDetails]
    );

    // TODO: Add Matomo events

    if (!imageData) {
        return null;
    }

    return (
        <div className={`ImageDetailsPage ${isVisible ? "" : "d-none"}`}>
            <div className="container">
                <div className="inner-container">
                    <div className="header">
                        <Button className="back-button" onClick={handleBackButtonClick}>
                            <ArrowLeft />
                            <Title className="header-title" level={1}>
                                {`Image: ${imageSearchTerm}`}
                            </Title>
                        </Button>
                    </div>
                    <div className="image-block">
                        {imageDetails && (
                            <img
                                key={imageDetails.thumbnailUrl}
                                className="main-img"
                                src={imageDetails.thumbnailUrl}
                                alt={imageDetails.name}
                                width={imageDetails.thumbnail?.width}
                                height={imageDetails.thumbnail?.height}
                                onLoad={() => setDisplayMetaInfo(true)}
                                onError={() => setDisplayMetaInfo(true)}
                            />
                        )}
                        {/* wait until the image has been loaded before showing related content */}
                        {displayMetaInfo && (
                            <div className="image-info">
                                <Title className="image-title" level={2}>
                                    {imageDetails && imageDetails.name}
                                </Title>
                                <div className="copyright-container">
                                    <span className="info-text">Image may be subject to copyright.</span>
                                    <Link className="info-link" href={(imageDetails && imageDetails.hostPageUrl) || ""}>
                                        {(imageDetails && imageDetails.hostPageDomainFriendlyName) || "Source"}
                                    </Link>
                                    <span className="bullet">&#8226;</span>
                                    <span className="info-text">
                                        <span className="info-size">
                                            {imageDetails?.width} x {imageDetails?.height} {imageDetails?.encodingFormat}
                                        </span>
                                    </span>
                                </div>
                                <div className="info-container">
                                    {imageDetails?.contentUrl && (
                                        <Link className="save-button" href={imageDetails.contentUrl}>
                                            <span>Original Image</span>
                                        </Link>
                                    )}
                                    <span className="bullet">&#8226;</span>
                                    <Link className="info-link" href={(imageDetails && imageDetails.hostPageUrl) || ""}>
                                        View Page
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* on smaller screen wait until the image has been loaded before showing related content */}
                {(lg || displayMetaInfo) && (
                    <div className="related-block">
                        <Title className="related-title" level={3}>
                            Related Content
                        </Title>
                        <div className="content-container">{renderRelatedImages()}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageDetailsPage;
