import { FC, memo, useCallback } from "react";
import { useMatomo }             from "@datapunt/matomo-tracker-react";
import useBreakpoint             from "antd/lib/grid/hooks/useBreakpoint";
import { useLocation }           from "react-router-dom";
import { getAnalyticsCategory }  from "@/analytics/analytics";
import Link                      from "@/designSystem/Link/Link";
import Paragraph                 from "@/designSystem/Paragraph/Paragraph";
import Title                     from "@/designSystem/Title/Title";
import freespoke_torch           from "@/static/icons/freespoke_torch.svg";
import quill                     from "@/static/icons/quill.svg";
import "./FeaturedStory.less";

export interface FeaturedStoryProps {
    url: string;
    title: string;
    description: string;
    image: string;
    alt: string;
}

const FeaturedStory: FC<FeaturedStoryProps> = ({ title, description, image, alt, url }) => {
    const { lg } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const location = useLocation<{ clearFilters?: boolean }>();

    const titleMaxLines = lg ? 3 : 10;
    const descriptionMaxLines = lg ? 6 : 10;

    const reportClick = useCallback(() => {
        trackEvent({
            category: getAnalyticsCategory(location.pathname),
            name: "featured story click",
            action: title || "missing featured story title",
        });
    }, [location?.pathname, title, trackEvent]);

    return (
        <div className="FeaturedStory">
            <Link
                {...(url?.startsWith("https://freespoke.com") ? { to: url.replace("https://freespoke.com", "") } : { href: url })}
                className="featured--link"
                onClick={reportClick}
            >
                <div className="featured--container">
                    <div className="featured--title-block">
                        <img src={freespoke_torch} alt="freespoke torch" className="torch-icon" />
                        <Title className="featured--title" level={3}>
                            Featured Story
                        </Title>
                        <Paragraph className="featured--subtitle">Highlighting important stories you don't want to miss.</Paragraph>
                    </div>

                    <div className="featured--thumbnail-block">
                        <img className="featured--thumbnail-image" src={image} alt={alt} />
                    </div>

                    <div className="featured--story-block">
                        <div className="content-container">
                            <Title className="featured--story-title" level={4} ellipsis={{ rows: titleMaxLines }}>
                                {title}
                            </Title>
                            <Paragraph className="featured--story-description" ellipsis={{ rows: descriptionMaxLines }}>
                                <span dangerouslySetInnerHTML={{ __html: description }}></span>
                            </Paragraph>
                        </div>

                        <div className="featured--story-footer">
                            <div className="footer-container">
                                <img src={quill} alt="featured story quill" className="footer-icon" />
                                <Title className="footer-text" level={5}>
                                    Featured
                                </Title>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default memo(FeaturedStory);
