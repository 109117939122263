import { FC, memo, useMemo }           from "react";
import useBreakpoint                   from "antd/lib/grid/hooks/useBreakpoint";
import Card                            from "@/designSystem/Card/Card";
import Link                            from "@/designSystem/Link/Link";
import Text                            from "@/designSystem/Text/Text";
import Title                           from "@/designSystem/Title/Title";
import { toTimeAgo }                   from "@/helpers/index";
import { ReactComponent as TorchIcon } from "@/static/icons/fire-torch.svg";
import { slugify }                     from "@/utils/slugify";
import "./StoryCard.less";

export interface StoryCardProps {
    id: string;
    title: string;
    updatedAt: string;
}

const StoryCard: FC<StoryCardProps> = memo(({ id, title, updatedAt }) => {
    const { xs } = useBreakpoint();
    const titleMaxLines = xs ? 3 : 2;
    const url = useMemo(() => `/story/${id}/${slugify(title)}`, [id, title]);
    const datePublishedFormatted = useMemo(() => toTimeAgo(updatedAt, true), [updatedAt]);

    return (
        <Card className="StoryCard">
            <div className="header">
                <TorchIcon /> <Text>FREESPOKE STORY</Text>
            </div>
            <div className="link">
                <Link to={url}>
                    <Title level={3} ellipsis={{ rows: titleMaxLines }} className="title">
                        {title}
                    </Title>
                </Link>
                {!xs && (
                    <Link to={url} className="view-story-button">
                        View Story
                    </Link>
                )}
            </div>
            <div className="footer">
                {xs && (
                    <Link to={url} className="view-story-link">
                        View Story
                    </Link>
                )}
                <div className="updated-date">{datePublishedFormatted && <span>Updated: {datePublishedFormatted}</span>}</div>
            </div>
        </Card>
    );
});

export default StoryCard;
