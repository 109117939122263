import { FC, useCallback } from "react";
import useBreakpoint       from "antd/lib/grid/hooks/useBreakpoint";
import ContentCardV2       from "../ContentCardV2/ContentCardV2";
import TweetCard           from "../TweetCard/TweetCard";
import "./BreakingNewsItem.less";

interface BreakingNewsItemProps {
    content: Record<string, any>;
    onClick: Function;
}

const BreakingNewsItem: FC<BreakingNewsItemProps> = ({ content, onClick }) => {
    const onItemClick = useCallback(
        () => onClick(content?.article?.url || content?.tweet?.url),
        [content?.article?.url, content?.tweet?.url, onClick]
    );
    const { lg } = useBreakpoint();

    return (
        <div className="BreakingNewsItem">
            {content.type === "tweet" ? (
                <TweetCard
                    data={{
                        id: content.id,
                        tweet_id: content?.tweet?.tweet_id,
                        author: {
                            name: content?.tweet?.author?.name || content?.article?.full_info?.publisherName,
                            username: content?.tweet?.author?.username,
                            profile_image_url: content?.tweet?.author?.profile_image_url,
                            image_url: content?.tweet?.author?.image_url,
                        },
                        datePublished: content?.tweet?.datePublished,
                        bias: content?.tweet?.bias,
                        text: content?.tweet?.text,
                        url: content?.tweet?.url,
                    }}
                    reportClick={onItemClick}
                    maxLines={lg ? 6 : 5}
                />
            ) : (
                <ContentCardV2
                    publisherName={content?.article?.full_info?.publisherName}
                    title={content?.article?.full_info?.headline}
                    url={content?.article?.url}
                    images={content?.article?.full_info?.images}
                    datePublished={content?.article?.full_info?.datePublished}
                    publisherIcon={content?.article.full_info?.publisherIcon}
                    bias={content?.article.full_info?.bias}
                    reportClick={onItemClick}
                />
            )}
        </div>
    );
};

export default BreakingNewsItem;
