import { useRef }                from "react";
import axios                     from "axios";
import { get }                   from "lodash-es";
import { stringify }             from "query-string";
import useSWR                    from "swr";
import { BACKEND_URL }           from "../config";
import { normalizeBreakingNews } from "../data/normalizer";
import { useBackendAccess }      from "./useBackendAccess";

interface QueryParams {
    perPage: number;
    page: number;
}

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

const useGetBreakingNews = (params: Partial<QueryParams> = { page: 1, perPage: 5 }) => {
    /*
     *  Here we store the new fresh data fetched by 'checkForNewData' function
     *  Reason 1: we don't have to make another call when user clicks on 'refresh'
     *  Reason 2: if we just use mutate() useSWR destroys old data and all topic components will get demounted/mounted too.
     *  I think doing this way accomplish a better UX, no flickering, no loading icon.
     */
    const prefetchedData = useRef();

    const fetcher = url => axios.get(url, { headers: authHeaders }).then(res => normalizeBreakingNews(res.data));
    const url = `${BACKEND_URL}/breaking_news?${stringify(params)}`;

    // Check if there are new topics, if so adds 'newTopicsAvailable' variable to the hook response
    const _checkForNewData = async currentData => {
        const newData = await fetcher(url);

        if (get(newData, "data.length")) {
            const newContentAvailable = findChanges(currentData, newData);

            if (newContentAvailable) {
                prefetchedData.current = newData;
                return { ...currentData, newContentAvailable: newContentAvailable };
            }
            // In case the new content is no longer available (ex. admin switches it to OFF)
            else if (newContentAvailable === 0 && currentData.newContentAvailable > 0) {
                return { ...currentData, newContentAvailable: 0 };
            }
        }
        return currentData;
    };

    const { authHeaders } = useBackendAccess();
    const { data, error, isValidating, mutate } = useSWR<{
        [key: string]: any;
    }>(authHeaders ? url : null, fetcher, config);

    return {
        data,
        isLoading: isValidating,
        error,
        refresh: () => mutate(prefetchedData.current, false), // 'false' -> won't auto refresh data using API call
        checkForNewData: () => mutate(_checkForNewData, false),
        reset: () => mutate({ ...data, newContentAvailable: 0 }, false),
    };
};

// Finds if there is new content available for fetching and returns its quantity
function findChanges(currentData, freshData) {
    if (get(freshData, "data.length")) {
        const freshContentIDs = freshData && freshData.data.map(content => content.id || content._id);
        const currentContentIDs = freshData && currentData.data.map(content => content.id || content._id);

        // LEFT JOIN (new topics, old topics)
        return freshContentIDs.filter(contentId => !currentContentIDs.includes(contentId)).length;
    }
    return 0;
}

export default useGetBreakingNews;
