import slugifyPackage from "slugify";

const options = {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
    trim: true, // trim leading and trailing replacement chars, defaults to `true`
};

export function slugify(str: string) {
    return slugifyPackage(str, options);
}
