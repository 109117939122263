import { useRef }                    from "react";
import axios                         from "axios";
import useSWR                        from "swr";
import { IFeaturedStory, ITopStory } from "@/@types/custom";
import { useBackendAccess }          from "@/api/useBackendAccess";
import { BACKEND_URL }               from "@/config";

interface QueryParams {
    categories: string[];
    suggested_topics_count: number;
    stories_count: number;
    breaking_news_count: number;
    include?: ("featured_stories" | "top_stories")[];
}

interface useGetHomepageResponse {
    top_stories?: ITopStory[];
    featured_stories?: IFeaturedStory[];
}

const useGetHomepage = (params: Partial<QueryParams> = {}) => {
    /*
     *  Here we store the new fresh data fetched by 'checkForNewData' function
     *  Reason 1: we don't have to make another call when user clicks on 'refresh'
     *  Reason 2: if we just use mutate() useSWR destroys old data and all topic components will get demounted/mounted too.
     *  I think doing this way accomplish a better UX, no flickering, no loading icon.
     */
    const prefetchedData = useRef() as any;

    const { authHeaders } = useBackendAccess();
    const url = `${BACKEND_URL}/v2/homepage`;
    const fetcher = () => axios.get(url, { headers: authHeaders, params }).then(res => res.data);

    // Check if there are new topics, if so adds 'newStoriesAvailable' variable to the hook response
    const _checkForNewData = async currentData => {
        const newData = await fetcher();

        if (newData && newData.stories && newData.stories.length) {
            const newStoriesAvailableForFetching = findChanges(currentData, newData);

            if (newStoriesAvailableForFetching) {
                prefetchedData.current = newData; // store the new data in the ref
                return { ...currentData, newStoriesAvailable: newStoriesAvailableForFetching };
            }
            // In case when the new stories are no longer available (ex. curator removes them)
            else if (newStoriesAvailableForFetching === 0 && currentData.newStoriesAvailable > 0) {
                return { ...currentData, newStoriesAvailable: 0 };
            }
        }
        return currentData;
    };

    const { data, error, isValidating, mutate } = useSWR<useGetHomepageResponse | any>(authHeaders ? url : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    return {
        data,
        isLoading: isValidating,
        error,
        refresh: async () =>
            mutate(
                {
                    ...(data && { ...data }),
                    ...(prefetchedData.current?.stories && { stories: prefetchedData.current.stories }),
                    newStoriesAvailable: 0,
                },
                false
            ), // 'false' -> won't auto refresh data using API call
        checkForNewData: () => mutate(_checkForNewData, false),
        reset: () => mutate({ ...data, newStoriesAvailable: 0 }, false),
    };
};

// Finds if there are new stories available for fetching (returns a number of the new stories)
const findChanges = (currentData, freshData) => {
    if (freshData && freshData.stories && freshData.stories.length) {
        const freshStoriesID = freshData.stories.map(freshStory => freshStory.id);
        const currentStoriesID = (currentData && currentData.stories && currentData.stories.map(currentStory => currentStory.id)) || [];

        // LEFT JOIN (new stories, old stories)
        return freshStoriesID.filter(storyId => !currentStoriesID.includes(storyId)).length;
    }
    return 0;
};

export default useGetHomepage;
